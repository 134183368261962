import React from 'react';

const PolicyFooter = (props) => {
  const { setShowPrivacyPolicy, setShowTermsPolicy, vesselId } = props;
  return (
    <>
      <div className="copy-text text-black col-6" style={{ marginTop: '10px' }}>
        {`© ${new Date().getFullYear()} ThinkPalm`}
      </div>
      {!vesselId
        && (
        <div className="d-flex col-gap-1 text-black" style={{ marginTop: '10px' }}>
          <button className="" type="button" onClick={() => setShowPrivacyPolicy(true)}>
            Privacy Policy
          </button>
          <button className="" type="button" onClick={() => setShowTermsPolicy(true)}>
            Terms of Use
          </button>
        </div>
        )}
    </>
  );
};
export default PolicyFooter;
