/* eslint-disable prefer-template */
/* istanbul ignore file */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-bitwise */
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { AiFillCaretRight, AiFillCaretLeft } from 'react-icons/ai';
import _ from 'lodash';
import { Gantt, ViewMode } from 'gantt-task-react';
import moment from 'moment';
import { itemList, statusColor } from './utilities';
import { ViewSwitcher } from './ViewSwitcher';
import helper from '../../../../../utils/helper';

export default function App(props) {
  const { projects } = props;
  const { formatNumber } = helper;
  const [view, setView] = useState(ViewMode.Month);
  const [isChecked, setIsChecked] = useState(true);
  const [collapse, setCollapse] = useState(false);
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  } else if (view === ViewMode.Year) {
    columnWidth = 400;
  }

  const TaskListTableDefault = () => {
    return (
      <div className="Gantt-Task-List_Wrapper w-full">
        {projects?.map((t) => {
          return (
            <div className="Gantt-Task-List_Row">
              <div className="Gantt-Task-List_Cell">
                {/**
                 * Name
                 */}
                <div className="Gantt-Task-List_Cell table-cell-width-name">
                  <div className="Gantt-Task-List_Name-Container">
                    <div className="Gantt-Task-List_Cell__Link d-flex ms-4 align-items-center">
                      <span className="icon-disc me-2 font-14" style={{ color: statusColor(t?.priority) }} />

                      <div>
                        <div className="value font-14 w-175 text-ellipsis">
                          <span data-tooltip-id="project-gantt" data-tooltip-content={t?.name}>
                            {t?.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/**
                 * Spec Count
                 */}
                <div className={collapse ? 'display-none' : 'Gantt-Task-List_Cell table-cell-width gw-small ps-4'}>
                  <span data-tooltip-id="project-gantt" data-tooltip-content={t?.specCount}>
                    {_.truncate(t?.specCount, {
                      length: 10,
                      separator: ' '
                    })}
                  </span>

                </div>
                {/**
                 * End Time
                 */}
                <div
                  className={collapse ? 'display-none' : 'Gantt-Task-List_Cell table-cell-width ps-4'}
                >
                  <span
                    data-tooltip-id="project-gantt"
                    data-tooltip-content={t?.currencySymbol + ' ' + formatNumber(t?.estimatedBudget)}
                  >
                    {t?.currencySymbol}
                    {' '}
                    {_.truncate(formatNumber(t?.estimatedBudget), {
                      length: 19,
                      separator: ' '
                    })}
                  </span>

                </div>
                {/**
                 * End Time
                 */}
                <div className={collapse ? 'display-none' : 'Gantt-Task-List_Cell table-cell-width gw-small ps-4'}>
                  {_.truncate(t?.offhireDays, {
                    length: 10,
                    separator: ' '
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const TaskListHeaderDefault = () => {
    return (
      <div className="Gantt-Table bg-sky-white">
        <div className="Gantt-Table_Header h-48 font-semibold font-14">
          <div className="Gantt-Table_Header-Separator ml-12" />
          <div className="Gantt-Table_Header-Item table-cell-width-name-header">Project Name</div>
          <div className={`Gantt-Table_Header-Separator ${collapse ? 'display-none' : ''}`} />
          <div className={`Gantt-Table_Header-Item table-cell-width-header gw-small ${collapse ? 'display-none' : ''}`}>Specs</div>
          <div className={`Gantt-Table_Header-Separator ${collapse ? 'display-none' : ''}`} />
          <div className={`Gantt-Table_Header-Item table-cell-width-header ${collapse ? 'display-none' : ''}`}>Estimated Amount</div>
          <div className={`Gantt-Table_Header-Separator ${collapse ? 'display-none' : ''}`} />
          <div className={`Gantt-Table_Header-Item table-cell-width-header gw-small ${collapse ? 'display-none' : ''}`}>Off Hire Days</div>
          <span
            className="Gantt-Table_Header-Item table-cell-width-header gw-icon cursor-pointer"
            onClick={() => { setCollapse(!collapse); }}
          >
            {collapse
              ? <span data-tooltip-id="gantt-view" data-tooltip-content="Expand"><AiFillCaretRight /></span>
              : <span data-tooltip-id="gantt-view" data-tooltip-content="Collapse"><AiFillCaretLeft /></span>}
          </span>
        </div>
        <Tooltip id="gantt-view" place="top" className="tooltip mt-3" />
      </div>
    );
  };
  const handleSelect = () => {
    console.log('selected');
  };
  const getMonth = (month) => {
    if (month === 1) {
      return 'Jan';
    }
    if (month === 2) {
      return 'Feb';
    }
    if (month === 3) {
      return 'Mar';
    }
    if (month === 4) {
      return 'Apr';
    }
    if (month === 5) {
      return 'May';
    }
    if (month === 6) {
      return 'Jun';
    }
    if (month === 7) {
      return 'Jul';
    }
    if (month === 8) {
      return 'Aug';
    }
    if (month === 9) {
      return 'Sep';
    }
    if (month === 10) {
      return 'Oct';
    }
    if (month === 11) {
      return 'Nov';
    }
    if (month === 12) {
      return 'Dec';
    }
    return '';
  };
  const ToolTipContentDefault = (item) => {
    return (
      <div>
        <div className="tooltipDefaultContainer">
          <b>
            {`${item?.name} : ${moment(item?.start)?._d?.getDate()}-${getMonth(item?.start?.getMonth() + 1)}-${moment(
              item?.start
            )?._d?.getFullYear()} to ${moment(item?.end)?._d?.getDate()}-${getMonth(item?.end?.getMonth() + 1)}-${moment(
              item?.end
            )?._d?.getFullYear()}`}
          </b>
          {item?.trd && (
            <p className="tooltipDefaultContainerParagraph">
              {`TRD : ${item?.trd} day(s)`}
            </p>
          )}
          <p className="tooltipDefaultContainerParagraph">{item?.approvalStatus}</p>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="projectlist-wrapper project-top-10" data-testid="gantt-view">
        <div className="container-fluid">
          {projects && projects?.length > 0 ? (
            <>
              <ViewSwitcher
                onViewModeChange={(viewMode) => setView(viewMode)}
                onViewListChange={setIsChecked}
                isChecked={isChecked}
              />
              <div className="card">
                <Gantt
                  tasks={itemList(projects)}
                  TaskListTable={TaskListTableDefault}
                  viewMode={view}
                  ganttHeight={470}
                  className="gantt-task"
                  TooltipContent={(e) => ToolTipContentDefault(e?.task)}
                  listCellWidth={isChecked ? '155px' : ''}
                  columnWidth={columnWidth}
                  TaskListHeader={TaskListHeaderDefault}
                  onSelect={handleSelect}
                />
              </div>
            </>
          ) : (
            <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
              <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
              <div className="font-18 animate__animated animate__headShake">No data found</div>
            </div>
          )}
          <Tooltip id="project-gantt" place="top" className="tooltip mt-3" />
        </div>

      </div>

      {userRole === roles.ADMIN ? (
        <div className="footer">
          <div className="container">
            <div className="d-flex justify-content-between font-13 pb-5">
              <div className="copy-text">{`© ${new Date().getFullYear()} ThinkPalm`}</div>
              <div className="d-flex col-gap-1">
                <button onClick={() => setShowPrivacyPolicy(true)} className="link-btn" type="button">
                  Privacy Policy
                </button>
                <button className="link-btn" onClick={() => setShowTermsPolicy(true)} type="button">
                  Terms of Use
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="footer collapsed-tsi justify-content-between font-13">
          <div className="copy-text  m-auto">{`© ${new Date().getFullYear()} ThinkPalm`}</div>
        </div>
      )}
    </div>
  );
}
