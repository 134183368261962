/* eslint-disable radix */
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import showAlert from '../../../../../../../utils/alert';
import helper from '../../../../../../../utils/helper';
import ModalBox from '../../../../../../common/ModalBox';
import AddService from './Children/addService';
import Cards from './Children/cards';
import ListAcCodes from './Children/listAcCodes';
import { saveSpecServiceLine, getPublishUomList, getSpecServiceLine, getAcCode, deleteSpecServiceLine, updateAcCode, updateSpecServiceLines } from './services';

const ServiceLine = (props) => {
  const { updatedServiceLine } = useOutletContext() || '';
  const { projectSpecificationId, specDetails, isTsiDisabled, isTSI, isDraft,
    fromSpecify, fromSupplementary, activeTabSupplementary, projInfo,
    specDraftFromSpecify, projectData, reloadAfterGTMT } = props;
  const initialVal = {
    number: '',
    uom: '',
    uomId: '',
    serviceLineName: '',
    acCode: '',
    acCodeId: '',
    parentSpecId: projectSpecificationId,
    specificationLevel: 3,
    quantity: 0
  };
  const [openModal, setOpenModal] = useState(false);
  const [uomList, setUomList] = useState([]);
  const [acCodeList, setAcCodeList] = useState([]);
  const [serviceData, setServiceData] = useState(initialVal);
  const [allServiceLine, setAllServiceLine] = useState([]);
  const [sLoader, setSLoder] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [mappingModal, setMappingModal] = useState(false);
  const [mappedAccode, setMappedAccode] = useState('');
  const [selectedServiceLines, setSelectedServiceLines] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [newACCode, setnewACCode] = useState(false);
  const [multipleDeleteConfirm, setShowMultipleDeleteConfirm] = useState(false);
  const [nonKfpServiceLinesExists, setNonKfpServiceLinesExists] = useState(true);

  const { generateOptions, formValidation, restrictDecimal, fieldValidation,
    ZERO_UUID, roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const managementId = localStorage.getItem('managementId');
  const isVesselUser = (userRole === roles.VESSEL_USER);
  const getAllDetails = () => {
    setSLoder(true);
    getSpecServiceLine({ id: projectSpecificationId }).then((res) => {
      setSLoder(false);
      if (res.success) {
        setAllServiceLine(res.data);
        if ((res.data || [])?.some((x) => x.specificationLevel !== 4)) {
          setNonKfpServiceLinesExists(false);
        } else {
          setNonKfpServiceLinesExists(true);
        }
      }
    });
  };
  const saveApiCall = () => {
    const i = cloneDeep(serviceData);
    i.acCodeId = i.acCodeId ? i.acCodeId : ZERO_UUID;
    if (i.acCode && !i.acCodeId) {
      i.acCodeId = ZERO_UUID;
    }
    if (formValidation(['uomId', 'serviceLineName'], i)) {
      if (i.id) {
        updateSpecServiceLines(i).then((res) => {
          if (res.success) {
            showAlert('Successfully Updated', 'success');
            getAllDetails();
            setnewACCode(false);
            reloadAfterGTMT('', projectSpecificationId, false, true);
          }
        });
      } else {
        let isValid = false;
        if (parseInt(i.number) < i.nextNumber) {
          isValid = true;
        }
        if (!i.nextNumber) {
          isValid = true;
        }
        if (isValid) {
          saveSpecServiceLine(i).then((res) => {
            if (res.success) {
              showAlert('Successfully Saved', 'success');
              getAllDetails();
              setnewACCode(false);
              reloadAfterGTMT('', projectSpecificationId, false, true);
            }
          });
        } else showAlert('Invalid Specification Number', 'error');
      }
      setOpenModal(false);
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const deleteApiCall = (multipleDeleteFlag = false) => {
    const selectedData = selectedServiceLines
      ? allServiceLine.filter((x) => selectedServiceLines.includes(x.id))
        .map((item) => item.number) : [];
    if (multipleDeleteFlag) {
      deleteSpecServiceLine({ number: selectedData, id: projectSpecificationId })
        .then(() => {
          showAlert('Successfully Deleted', 'success');
          getAllDetails();
          setShowMultipleDeleteConfirm(false);
          setSelectedServiceLines([]);
        });
    } else {
      deleteSpecServiceLine({ number: [showDeleteConfirm], id: projectSpecificationId })
        .then(() => {
          showAlert('Successfully Deleted', 'success');
          getAllDetails();
          setShowDeleteConfirm(false);
        });
    }
  };
  const reloadData = () => {
    getAllDetails();
    getPublishUomList().then((res) => {
      if (res.success) {
        setUomList(generateOptions(res.data, 'uomId', 'uomName'));
      }
    });
  };

  useEffect(() => {
    getAcCode({
      page: 1,
      pageSize: 1000,
      managementGroupId: managementId || ZERO_UUID
    }).then((resp) => {
      if (resp.success) {
        setAcCodeList(generateOptions(resp.data.items, 'id', 'name', 'code'));
      }
    });
  }, [managementId]);

  useEffect(() => {
    reloadData();
  }, [updatedServiceLine]);

  const onDeleteService = (data) => {
    setShowDeleteConfirm(data.number);
  };
  const onEditService = (data) => {
    const selectedLine = { ...data };
    selectedLine.parentSpecId = projectSpecificationId;
    selectedLine.acCodeId = selectedLine.acCodeId === ZERO_UUID ? '' : selectedLine.acCodeId;
    setServiceData(selectedLine);
    setOpenModal(true);
  };
  const onAddService = () => {
    const selectedLine = { ...initialVal };
    selectedLine.parentSpecId = projectSpecificationId;
    const specLength = allServiceLine.filter((field) => field.specificationLevel === 3).length;
    if (specDraftFromSpecify === 'yes') {
      selectedLine.number = `${specDetails.specificationNo.replace(/[^0-9.]/g, '')}.${specLength + 1}${isVesselUser ? ' VES (Draft)' : ''}`;
    } else {
      selectedLine.number = `${specDetails.specificationNo}.${specLength + 1}`;
    }
    selectedLine.nextNumber = parseInt(specDetails.specificationNo) + 1;
    setServiceData(selectedLine);
    setOpenModal(true);
  };
  const onQuantitySelect = (data) => {
    const selectedLine = { ...data };
    selectedLine.parentSpecId = projectSpecificationId;
    setServiceData(selectedLine);
  };
  const onQuantityChange = (e) => {
    const selectedLine = { ...serviceData };
    const numis = restrictDecimal(fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 6)) || 0;
    selectedLine.quantity = numis;
    setServiceData(selectedLine);
  };
  const onQuantityCancel = () => {
    setServiceData({});
  };
  const onQuantityAdd = () => {
    saveApiCall();
    setServiceData({});
  };
  const onMappServiceLine = () => {
    setMappingModal(false);
    updateAcCode({
      specificationId: projectSpecificationId,
      acCodeId: mappedAccode,
      serviceLines: selectedServiceLines
    }).then((res) => {
      if (res.success) {
        showAlert('Successfully Saved', 'success');
        reloadAfterGTMT('', projectSpecificationId, false, true);
        setSelectedServiceLines([]);
        setMappedAccode('');
        getAllDetails();
      }
    });
  };
  const onMapAcCode = (id) => {
    setMappedAccode(id);
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      setSearchText(searchText);
    }
  };
  return (
    <div className="list-tab-content content-scroller-height" data-testid="service_line">
      <div className="d-flex my-4 justify-content-between tab-content-ctrl">
        <div className="d-flex">
          <div className="search  spec-search large">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
              />
              <span className="icon-search" id="search-click" onClick={() => { onSearch({ key: 'Enter' }); }} />
            </div>
            <input
              type="text"
              className="text-box  with-close large"
              value={searchText}
              placeholder="Search by Service Line"
              id="search"
              onKeyPress={onSearch}
              onChange={(e) => { setSearchText(e.target.value); }}
            />
          </div>
          {isTSI ? (
            <>
              <button type="button" disabled={isTsiDisabled || (fromSupplementary && !activeTabSupplementary) || projInfo?.isClosed} id="add_serviceline" className="large secondary-btn ms-4" onClick={() => { onAddService(); }}>
                Add Service Line
              </button>
              <button type="button" id="add_mapping" disabled={allServiceLine.length === 0 || nonKfpServiceLinesExists || !isTSI || projInfo?.isClosed || projectData?.isClosed || isDraft || isVesselUser} className="large link-btn ms-4" onClick={() => { setMappingModal(true); }}>
                AC Code Mapping
              </button>
              <button type="button" disabled={isTsiDisabled || selectedServiceLines.length === 0 || (fromSupplementary && !activeTabSupplementary) || projInfo?.isClosed} id="delete_serviceline" className="large secondary-btn ms-4" onClick={() => { setShowMultipleDeleteConfirm(true); }}>
                Delete Service Line(s)
              </button>
            </>
          ) : ''}
        </div>
      </div>
      {multipleDeleteConfirm ? (
        <ModalBox
          modalClassName="small"
          heading="Delete Service Line(s)"
          removeClass={!fromSpecify}
          onSave={() => deleteApiCall(multipleDeleteConfirm)}
          closeModal={() => {
            setShowMultipleDeleteConfirm(false);
            setSelectedServiceLines([]);
          }}
          buttonName="Delete"
        >
          Are you sure you want to delete the service line(s)?
        </ModalBox>
      ) : (
        ''
      )}
      <div className="">
        <Cards
          sLoader={sLoader}
          allServiceLine={allServiceLine}
          onEditService={onEditService}
          onDeleteService={onDeleteService}
          setSelectedServiceLines={setSelectedServiceLines}
          selectedServiceLines={selectedServiceLines}
          onQuantitySelect={onQuantitySelect}
          onQuantityChange={onQuantityChange}
          serviceData={serviceData}
          onQuantityCancel={onQuantityCancel}
          onQuantityAdd={onQuantityAdd}
          searchText={searchText}
          isTsiDisabled={isTsiDisabled}
          isTSI={isTSI}
          fromSupplementary={fromSupplementary}
          activeTabSupplementary={activeTabSupplementary}
          isVesselUser={isVesselUser}
          projInfo={projInfo}
          isDraft={isDraft}
          projectData={projectData}
        />
      </div>
      {openModal ? (
        <ModalBox
          modalClassName="medium"
          onSave={() => saveApiCall()}
          removeClass={!fromSpecify}
          closeModal={() => {
            setOpenModal(false);
            setnewACCode(false);
          }}
          heading={serviceData.id ? 'Edit Service Line' : 'Add Service Line'}
          buttonName="Save"
        >
          <AddService
            acCodeList={acCodeList}
            serviceData={serviceData}
            setServiceData={setServiceData}
            uomList={uomList}
            fromSupplementary={fromSupplementary}
            isVesselUser={isVesselUser}
            newACCode={newACCode}
            setnewACCode={setnewACCode}
            projectData={projectData}
          />
        </ModalBox>
      ) : (
        ''
      )}
      {showDeleteConfirm ? (
        <ModalBox
          modalClassName="small"
          heading="Delete Service Line"
          removeClass={!fromSpecify}
          onSave={() => deleteApiCall()}
          closeModal={() => { setShowDeleteConfirm(false); }}
          buttonName="Delete"
        >
          Are you sure you want to delete the service line?
        </ModalBox>
      ) : (
        ''
      )}
      {mappingModal ? (
        <ModalBox
          modalClassName="medium modal-scroll"
          onSave={() => onMappServiceLine()}
          removeClass={!fromSpecify}
          closeModal={() => setMappingModal(false)}
          heading="AC Code List"
          buttonName="Map to the Spec"
          saveDisable={!mappedAccode}
        >
          <ListAcCodes
            acCodeList={acCodeList}
            onMapAcCode={onMapAcCode}
            mappedAccode={mappedAccode}
          />
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};
export default ServiceLine;
