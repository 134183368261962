/* eslint-disable */
/* istanbul ignore file */
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import arrowRightG from '.././images/right-gray.svg';
import arrowDownG from '.././images/down-gray.svg';
import arrowRightB from '.././images/right-blue.svg';
import arrowDownB from '.././images/down-blue.svg';
import arrowRightP from '.././images/right-purple.svg';
import arrowDownP from '.././images/down-purple.svg';

const measureHeight = (node) => {
  return node ? node.getBoundingClientRect().height : 40;
};

function TreeNode({ index, style, data }) {
  const {
    setCheckList, setCompareData, recalculate, SaveAssignee, isTSI, disableFields, repairStarted, setSpecData,
    setAddServiceLine, nodes, handleNodeToggle, expandedNodes, itemHeights, setItemHeight, handleScrollToTop
  } = data;
  const node = nodes[index];
  const ref = useRef();
  const isDisabled = !isTSI || disableFields || repairStarted;

  const status = [
    {
      name: 'Y',
      value: 1,
      title: 'Yard',
      selected: false,
    },
    {
      name: 'V',
      value: 2,
      title: 'Vessel',
      selected: false,
    },
    {
      name: 'S',
      value: 3,
      title: 'Sub Contractor',
      selected: false,
    },
  ];

  useEffect(() => {
    if (ref.current) {
      const height = measureHeight(ref.current);
      if (itemHeights[index] !== height) {
        setItemHeight(index, height);
      }
    }
  }, [index, itemHeights, setItemHeight]);

  const handleToggle = () => {
    if (node.specDataList || node.serviceLineList) {
      handleNodeToggle(node.projectSpecId, node.specificationLevel);
    }
  };

  const getSpecificationNoClass = (level) => {
    if (level === 1) {
      return 'mt-1 ms-3 timeline-text-wrap w-6';
    } else if (level === 2) {
      return 'mt-1 ms-3 timeline-text-wrap w-7';
    }
    return 'mt-1 ms-4 timeline-text-wrap w-9';
  };

  const getSpecLevelClass = (level, type) => {
    if (level === 2 && type === 3) {
      return 'd-flex p-cl ms-5 cursor-pointer';
    } else if (level === 1 || level === 2) {
      return 'd-flex p-cl ms-2 cursor-pointer';
    }
    return 'd-flex p-cl ms-5 cursor-pointer';
  };

  const assigneeCursor = (value) => {
    if (value) return ' cursor-pointer';
    else return ' cursor-auto';
  };

  const handleSpecAssigned = (value, obj) => {

    setCheckList(prevList =>
      prevList.map(item =>
        item.projectSpecId === obj.parentSpecificationId
          ? {
            ...item,
            specDataList: item.specDataList.map(specItem =>
              specItem.projectSpecId === obj.projectSpecId
                ? { ...specItem, specAssigned: value }
                : specItem
            )
          }
          : item
      )
    );

    const updateSpecs = (specList) =>
      specList.map(specItem => {
        if (specItem.id !== obj.projectSpecId) return specItem;

        const isReset = value === 2 || value === 3;
        return {
          ...specItem,
          specAssigned: value,
          ownerEstimate: isReset ? 0 : specItem.ownerEstimate,
          serviceLineList: specItem.serviceLineList.map(service => ({
            ...service,
            specAssigned: value,
            ownerEstimate: isReset ? 0 : service.ownerEstimate,
            formula: isReset ? '' : service.formula,
          }))
        };
      });

    setCompareData(prevData => {
      const updatedData = prevData.map(section => ({
        ...section,
        sectionQuoteList: section.sectionQuoteList.map(quote => {
          if (quote.id !== obj.parentSpecificationId) return quote;

          const updatedSpecList = updateSpecs(quote.specList);
          let updatedOwnerEstimate = quote.ownerEstimate;

          if (value === 2 || value === 3) {
            const specToAdjust = quote.specList.find(s => s.id === obj.projectSpecId);
            if (specToAdjust) {
              updatedOwnerEstimate -= specToAdjust.ownerEstimate;
            }
          }

          return { ...quote, specList: updatedSpecList, ownerEstimate: updatedOwnerEstimate };
        }),
        ownerEstimate: section.sectionQuoteList.reduce((sum, quote) => sum + quote.ownerEstimate, 0)
      }));

      recalculate(updatedData);
      SaveAssignee({ specId: obj.projectSpecId, Assign: value });

      return updatedData;
    });
  };

  return (
    <div
      key={node.projectSpecId}
      id={node.projectSpecId}
      ref={ref}
      style={{ ...style, background: node.isFromYard ? '#DAFFE0' : '' }}
      // onClick={handleScrollToTop(node.projectSpecId)}
      className={node.specificationNo === 'DEF' ? 'd-none' : ''}
    >
      <div key={index} className={nodes.length !== index - 1 ? 'horizontal-line-section' : ''}>
        <div
          className={getSpecLevelClass(node.specificationLevel, node.type)}
          data-test="memberClick"
        >
          <div className="display-start cursor-pointer w-3/4" onClick={handleToggle}>
            {node.specificationLevel === 1 && (
              <div>
                {expandedNodes?.includes(node.projectSpecId) ? (
                  <img className="icon-sm" src={arrowDownG} alt="flag" />
                ) : (
                  <img className="icon-sm" src={arrowRightG} alt="flag" />
                )}
              </div>
            )}
            {node.type !== 3 ? (
              <div>
                {expandedNodes?.includes(node.projectSpecId) && node.serviceLineList ? (
                  <img className="icon-sm ms-4" src={node.tempOrder < 200 ? arrowDownB : arrowDownP} alt="flag" />
                ) : node.serviceLineList && (
                  <img className="icon-sm ms-4" src={node.tempOrder < 200 ? arrowRightB : arrowRightP} alt="flag" />
                )}
              </div>
            ) : (
              <div />
            )}
            <div className={getSpecificationNoClass(node.specificationLevel)}>
              <span
                data-tooltip-id="project-secName"
                data-tooltip-content={node?.tempOrder || node?.specificationNo || node.number}
                className={node.specificationLevel === 1 ? "font-gray" : node.specificationLevel === 2 ? "font-gray ms-2" : "font-dim ms-4"}
              >
                {node.specificationLevel === 1 ? node.tempOrder || node.specificationNo : node.specificationLevel === 2 ? node.specificationNo : node.number}
              </span>
              <Tooltip id="project-secName" place="top" className="tooltip mt-3" />
            </div>
            <div className="timeline-text-wrap w-3/4 mt-1">
              <span
                className={node.specificationLevel === 1 || node.specificationLevel === 2 ? "font-gray capitalize" : "font-dim capitalize"}
                data-tooltip-id="project-specNa"
                data-tooltip-content={node.title || ''}
              >
                {node.title}
              </span>
              <Tooltip id="project-specNa" place="top" className="tooltip left-o mt-3" />
            </div>
          </div>
          {node.type !== 3 && node.specificationLevel === 2 && (
            <div className="display-end gap-2 ms-auto me-2">
              {status?.map((g, f) => (
                <div
                  key={f}
                  className={
                    g.value === node.specAssigned
                      ? 'assignee-bg-blue' + assigneeCursor(!isDisabled)
                      : 'assignee-bg-gray' + assigneeCursor(!isDisabled)
                  }
                  onClick={e => {
                    e.stopPropagation();
                    if (!isDisabled && g.value !== node.specAssigned) {
                      handleSpecAssigned(g.value, node);
                    }
                  }}
                >
                  {g.name || ''}
                </div>
              ))}
              <button
                type="button"
                style={{ color: "#3456af" }}
                disabled={isDisabled}
                className="link-btn display-center mt-1 ms-3 pointer"
                data-tooltip-id="service-btn"
                data-tooltip-content="Add New Service Line"
                onClick={() => {
                  setAddServiceLine(true);
                  setSpecData(node);
                }}
              >
                <span className="icon-plus-square font-22" />
              </button>
              <Tooltip id="service-btn" place="left" className="tooltip ms-5" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function VirtualizedTreeView({ treeData, expandedNodes, listHeight, sidebarRef, syncScroll, ...props }) {
  const [itemHeights, setItemHeights] = useState([]);

  const setItemHeight = (index, size) => {
    setItemHeights((prevHeights) => {
      const updatedHeights = [...prevHeights];
      updatedHeights[index] = size;
      return updatedHeights;
    });
  };

  const nodes = useMemo(() => {
    const flattenTree = (nodes, level = 1) => {
      let flatList = [];
      nodes.forEach((node) => {
        flatList.push({ ...node, specificationLevel: level });
        if (expandedNodes?.includes(node.projectSpecId) && node.specDataList) {
          flatList = flatList.concat(flattenTree(node.specDataList, level + 1));
        }
        if (expandedNodes?.includes(node.projectSpecId) && node.serviceLineList) {
          node.serviceLineList.forEach((serviceLine) => {
            flatList.push({
              ...serviceLine,
              title: serviceLine.serviceLineName,
              specificationLevel: level + 1
            });
          });
        }
      });
      return flatList;
    };
    return flattenTree(treeData);
  }, [treeData, expandedNodes]);

  return (
    <List
      ref={sidebarRef}
      height={listHeight}
      itemCount={nodes.length}
      itemSize={(index) => itemHeights[index] || 40}
      itemData={{ nodes, expandedNodes, itemHeights, setItemHeight, ...props }}
      width="100%"
      onScroll={({ scrollOffset }) => syncScroll(scrollOffset)}
    >
      {TreeNode}
    </List>
  );
}

export default VirtualizedTreeView;
