import React from 'react';
import ModalBox from '../../../../common/ModalBox';

const DownloadConfirmModal = (props) => {
  const { setDownloadConfirmModal, handleContinueInDownload,
    handleWorkOrdersCheckboxChange, handlePoCheckboxChange,
    handleTCCheckboxChange, handleSLCheckboxChange,
    handleVSCheckboxChange, isChecked, isCheckedWo,
    isCheckedTC, isCheckedSL, isCheckedVS } = props;

  return (
    <div data-testid="DownloadConfirmModal">
      <ModalBox
        heading="Download Specs"
        closeModal={() => { setDownloadConfirmModal(false); }}
        buttonName="Ok"
        onSave={() => { handleContinueInDownload(); }}
      >

        <div>
          A link to download the Pdf report and the excel template
          will be emailed to you in few minutes.
        </div>
        <div className="mt-2 check-box">
          <input type="checkbox" id="Terms and conditions" name="TC" onChange={handleTCCheckboxChange} checked={isCheckedTC} />
          <label htmlFor="Terms and conditions">Include Terms and conditions</label>
        </div>
        <div className="mt-2 check-box">
          <input type="checkbox" id="Service lines" name="SL" onChange={handleSLCheckboxChange} checked={isCheckedSL} />
          <label htmlFor="Service lines">Include Service lines</label>
        </div>
        <div className="mt-2 check-box">
          <input type="checkbox" id="Vessel Specs" name="VS" onChange={handleVSCheckboxChange} checked={isCheckedVS} />
          <label htmlFor="Vessel Specs">Include Vessel Specs</label>
        </div>
        <div className="mt-2 check-box">
          <input type="checkbox" id="PO Orders" name="PO" onChange={handlePoCheckboxChange} checked={isChecked} />
          <label htmlFor="PO Orders">Include POs</label>
        </div>
        <div className="mt-2 check-box">
          <input type="checkbox" id="Work Orders" name="WO" onChange={handleWorkOrdersCheckboxChange} checked={isCheckedWo} />
          <label htmlFor="Work Orders">Include WOs</label>
        </div>
      </ModalBox>

    </div>
  );
};
export default DownloadConfirmModal;
