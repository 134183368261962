/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import InputType1 from '../../../common/InputType1';
import ModalBox from '../../../common/ModalBox';
import { TotalEstimate, SecTotal } from './totalEstimate';
import helper from '../../../../utils/helper';

const QuotationTable = (props) => {
  const { fieldValidation, formatNumber } = helper;
  const { specList, mainSec, showsubSec, onSecOpen, setSpecList, setShowMore,
    setShowAttach, onAddServiceLine, onRemoveLine } = props;
  const [addComment, setAddComment] = useState(false);
  const [comment, setComment] = useState('');

  const onServiceFieldChange = (e, index, sub, num) => {
    const currentSpecList = { ...specList };

    if (e.target.name === 'rate') {
      currentSpecList.sectionData[index].supplementarySpecDataList[sub]
        .serviceLineList[num][e.target.name] = fieldValidation(e.target.value.replace(/[^0-9.]/g, ''), 5, 'returnString');
    } else if (e.target.name === 'quantity') {
      currentSpecList.sectionData[index].supplementarySpecDataList[sub]
        .serviceLineList[num][e.target.name] = fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 6);
    } else {
      currentSpecList.sectionData[index].supplementarySpecDataList[sub]
        .serviceLineList[num][e.target.name] = fieldValidation(e.target.value.replace(/[^0-9.]/g, ''), 3, 'returnString');
    }
    const rowDetails = currentSpecList.sectionData[index].supplementarySpecDataList[sub]
      .serviceLineList[num];
    if (rowDetails.quantity && rowDetails.rate && rowDetails.discount) {
      currentSpecList.sectionData[index].supplementarySpecDataList[sub]
        .serviceLineList[num]
        .amount = (((rowDetails.quantity * rowDetails.rate) / 100) * (100 - rowDetails.discount)).toString();
    } else if (rowDetails.quantity && rowDetails.rate) {
      currentSpecList.sectionData[index].supplementarySpecDataList[sub]
        .serviceLineList[num].amount = (rowDetails.quantity * rowDetails.rate).toString();
    } else {
      currentSpecList.sectionData[index].supplementarySpecDataList[sub]
        .serviceLineList[num].amount = '0';
    }
    currentSpecList.rfqData.total = TotalEstimate(currentSpecList);
    currentSpecList.rfqData.yardFinalEstimate = (currentSpecList.rfqData
      .total - currentSpecList.rfqData.discount).toString();
    currentSpecList.rfqData.yardEstimateWithoutDiscount = (currentSpecList.rfqData
      .total).toString();
    currentSpecList.sectionData[index].total = SecTotal(currentSpecList.sectionData[index])
      .toString();
    setSpecList(currentSpecList);
  };
  const onSaveComment = () => {
    const currentSpecList = { ...specList };
    currentSpecList.sectionData[addComment[0]].supplementarySpecDataList[addComment[1]]
      .serviceLineList[addComment[2]].comments = comment;
    setSpecList(currentSpecList);
    setAddComment(false);
    setComment('');
  };
  // const formatNumber = (number) => {
  //   if (number == null) {
  //     return '';
  //   }

  //   return number.toLocaleString('en-IN', {

  //     style: 'decimal',

  //     maximumFractionDigits: 2,

  //     minimumFractionDigits: 2

  //   });
  // };
  return (
    <>
      <div className="spc-map ps-3">
        <div className="d-flex border-down py-2 ps-3">
          <div className="col-1 font-bold">SEC#</div>
          <div className="col-8 font-bold">SPEC SECTION</div>
          <div className="col-3 font-bold text-left-align">TOTAL</div>
        </div>
        {
        specList.sectionData?.map((sec, mainIndex) => {
          return (
            <div className={(sec.projectSpecId === mainSec && showsubSec) ? 'highlight-tender' : ''}>
              <div className="d-flex border-down py-2 ps-3">
                <div className="col-1 d-flex align-items-center">
                  <span
                    id={sec.projectSpecId}
                    className={sec.projectSpecId === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                    onClick={() => { onSecOpen(sec.projectSpecId, true); }}
                  />
                  <span>{sec.specificationNo}</span>
                </div>
                <div className="col-8">
                  <span
                    data-tooltip-id="project-repair"
                    data-tooltip-content={sec.title}
                  >
                    {_.truncate(sec.title, { length: 30 })}
                  </span>

                </div>
                <div className="col-3 text-left-align head-14">
                  {specList.rfqData?.currencySymbol}
                  {' '}
                  {formatNumber(SecTotal(sec))}
                </div>
              </div>
              {
              sec.projectSpecId === mainSec
              && sec.supplementarySpecDataList.map((subsec, subIndex) => {
                return (
                  <>
                    <div className="d-flex mb-2 py-2 ps-3">
                      <div className="col-1" />
                      <div className="col-11 d-flex align-items-center">
                        <span
                          id={subsec.projectSpecId}
                          className={subsec.projectSpecId === showsubSec ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                          onClick={() => { onSecOpen(subsec.projectSpecId, false); }}
                        />
                        <span className="me-4">{subsec.specificationNo}</span>
                        <div className="">
                          <span
                            data-tooltip-id="project-repair"
                            data-tooltip-content={subsec.title}
                          >
                            {_.truncate(subsec.title, { length: 55 })}
                          </span>

                        </div>
                        {subsec.projectSpecId === showsubSec && (
                        <>
                          <button type="button" id="viewmore" className="primary-btn small ms-5" onClick={() => { setShowMore(subsec); }}>View More</button>
                          <button type="button" id="attach" className="primary-btn small ms-5" onClick={() => { setShowAttach({ key1: mainIndex, key2: subIndex, section: subsec }); }}>Attachments</button>
                        </>
                        )}
                      </div>
                    </div>
                    {
                      subsec.projectSpecId === showsubSec
                       && subsec.serviceLineList.map((serv, num) => {
                         return (
                           <>
                             {num === 0 && (
                             <div className="d-flex ps-3 align-items-center mb-2">
                               <div className="col-1" />
                               <div className="col-1 bg-sky-white ps-3 border-down py-2">Service LN#</div>
                               <div className="col-3 bg-sky-white ps-3 border-down py-2">Service Lines</div>
                               <div className="col-1 bg-sky-white ps-3 border-down py-2">UOM</div>
                               <div className="col-1 bg-sky-white ps-3 border-down py-2">Qty</div>
                               <div className="col-1 bg-sky-white ps-3 border-down py-2">Rate</div>
                               <div className="col-1 bg-sky-white ps-3 border-down py-2">Disc[%]</div>
                               <div className="col-2 bg-sky-white ps-3 border-down py-2 text-left-align">Amount</div>
                               <div className="col-1 bg-sky-white ps-3 border-down py-2">Comment</div>
                             </div>
                             )}
                             <div className="d-flex ps-3">
                               <div className="col-1" />
                               <div className="col-1 ps-3 border-down">{serv.number}</div>
                               <div className="col-3 text-justify text-break-all ps-3 border-down">
                                 <span>
                                   {serv.serviceLineName}
                                 </span>

                               </div>
                               <div className="col-1 ps-3 border-down">
                                 <span
                                   data-tooltip-id="project-repair"
                                   data-tooltip-content={serv.uomName || '-'}
                                 >
                                   {_.truncate(serv.uomName || '-', { length: 10 })}
                                 </span>

                               </div>
                               {/* <div className="col-1 ps-3 border-down">
                               {serv.uomName || '-'}</div> */}
                               <div className="col-1 ps-3 border-down pb-1">
                                 <InputType1
                                   label=""
                                   name="quantity"
                                  //  min="0"
                                  //  type="number"
                                  //  step="0.01"
                                  //  maxLength={4}
                                   id={'quantity'.concat(num)}
                                   className="small w-100"
                                   value={serv.quantity}
                                   onChange={(e) => {
                                     onServiceFieldChange(e, mainIndex, subIndex, num);
                                   }}
                                 />
                               </div>
                               <div className="col-1 ps-3 border-down pb-1">
                                 <InputType1
                                   label=""
                                   name="rate"
                                  //  min="0"
                                  //  type="number"
                                  //  step="0.01"
                                  //  maxLength={4}
                                   id={'rate'.concat(num)}
                                   className="small w-100"
                                   value={!serv.rate || serv.rate === '0' ? '' : serv.rate}
                                   onChange={(e) => {
                                     onServiceFieldChange(e, mainIndex, subIndex, num);
                                   }}
                                 />
                               </div>
                               <div className="col-1 ps-3 border-down pb-1">
                                 <InputType1
                                   label=""
                                   name="discount"
                                   maxLength={4}
                                   id={'discount'.concat(num)}
                                   className="small w-100"
                                   value={!serv.discount || serv.discount === '0' ? '' : serv.discount}
                                   onChange={(e) => { onServiceFieldChange(e, mainIndex, subIndex, num); }}
                                 />
                               </div>
                               <div
                                 className="col-2 ps-3 border-down text-left-align"
                               >
                                 <span
                                   data-tooltip-id="project-repair"
                                   data-tooltip-content={(specList?.rfqData?.currencySymbol)?.concat(' ', (formatNumber(serv.amount || 0)))}
                                 >
                                   {' '}
                                   {_.truncate((specList?.rfqData?.currencySymbol)?.concat(' ', (formatNumber(serv.amount || 0))), { length: 10 })}

                                 </span>

                               </div>
                               {/* <div className="col-1 ps-3 border-down">

                                 {(specList?.rfqData?.currencySymbol)?.concat((serv.amount || '0'))}
                               </div> */}
                               <div className="col-1 ps-3 border-down align-items-center">
                                 <span id={'comment'.concat(num)} className="font-22 icon-add-comment brand-color pointer" onClick={() => { setAddComment([mainIndex, subIndex, num]); setComment(serv.comments); }} />
                                 {serv.isFromYard && specList?.rfqData.status !== 4
                                 && <span className="icon-delete font-20 red ms-4 pointer" onClick={() => { onRemoveLine(mainIndex, subIndex, num); }} />}
                               </div>
                             </div>
                           </>
                         );
                       })
                      }
                    {subsec.projectSpecId === showsubSec && specList?.rfqData?.status !== 4 && (
                    <div className="ps-3 d-flex my-2">
                      <div className="col-1" />
                      <div>
                        <button type="button" className="link-btn" onClick={() => onAddServiceLine(mainIndex, subIndex)}>Add Service Line</button>
                      </div>
                    </div>
                    )}
                  </>
                );
              })
            }
            </div>
          );
        })
      }
        <Tooltip id="project-repair" place="top" className="tooltip mt-3" />
      </div>
      {addComment && (
      <ModalBox
        modalClassName="medium"
        heading={specList.sectionData[addComment[0]]?.supplementarySpecDataList[addComment[1]]
          .serviceLineList[addComment[2]].comments ? 'Update Comment' : 'Add Comment'}
        onSave={() => onSaveComment()}
        closeModal={() => {
          setAddComment(false);
          setComment('');
        }}
        buttonName="Save"
      >
        <textarea disabled={specList?.variationQuoteStatus === 4} placeholder="Comment" className="text-box w-full h-text-area pt-2" onChange={(e) => { setComment(e.target.value); }} value={comment} />
      </ModalBox>
      )}
    </>
  );
};
export default QuotationTable;
