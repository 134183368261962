import { serviceHandler } from '../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../utils/apiUrlConstants';

const {
  CompareQuoteListUrl,
  CompareQuoteKFP,
  SaveCompareQuotes,
  CompareQuoteCommentSave,
  CompareQuoteAssignee,
  sendForApprovalUrl,
  GetPublishUnitOfMeasuresList,
  getProjectStatusUrl,
  GetProjectBasicDetailsUrl,
  updateProjectStatusUrl,
  DeviationUrl,
  ServiceLineNew,
  GetMgmtAcCodesUrl
} = apiEndPoints;

export const CompareQuotesYardList = (payload) => {
  return serviceHandler({
    url: `${CompareQuoteListUrl}?projectId=${payload?.projectId}`,
    methordType: 'get'
  });
};
export const getKfp = (payload) => {
  return serviceHandler({
    url: `${CompareQuoteKFP}?projectId=${payload?.projectId}`,
    methordType: 'get'
  });
};
export const saveCQ = (payload) => {
  return serviceHandler({
    url: `${SaveCompareQuotes}`,
    methordType: 'post',
    payload
  });
};
export const saveComment = (payload) => {
  return serviceHandler({
    url: `${CompareQuoteCommentSave}`,
    methordType: 'post',
    payload
  });
};
export const saveAssignee = (payload) => {
  return serviceHandler({
    url: `${CompareQuoteAssignee}?specId=${payload?.specId}&Assign=${payload?.Assign}`,
    methordType: 'post'
  });
};
export const sendForApproval = (payload) => {
  return serviceHandler({
    url: `${sendForApprovalUrl}?projectId=${payload?.projectId}&type=${payload?.type}`,
    methordType: 'get'
  });
};
export const getUnits = () => {
  return serviceHandler({
    url: `${GetPublishUnitOfMeasuresList}`,
    methordType: 'get'
  });
};
export const getprojectStatus = (payload) => {
  return serviceHandler({
    url: `${getProjectStatusUrl}?projectId=${payload?.projectId}&type=${payload?.type}`,
    methordType: 'get'
  });
};
export const getprojectDetails = (payload) => {
  return serviceHandler({
    url: `${GetProjectBasicDetailsUrl}?projectId=${payload?.projectId}`,
    methordType: 'post'
  });
};
export const updateStatus = (payload) => {
  return serviceHandler({
    url: `${updateProjectStatusUrl}`,
    methordType: 'post',
    payload
  });
};
export const saveDOCost = (payload) => {
  return serviceHandler({
    url: `${DeviationUrl}`,
    methordType: 'post',
    payload
  });
};
export const saveServiceLine = (payload) => {
  return serviceHandler({
    url: `${ServiceLineNew}`,
    methordType: 'post',
    payload
  });
};
export const getAcCode = (payload) => {
  return serviceHandler({
    url: `${GetMgmtAcCodesUrl}`,
    methordType: 'post',
    payload
  });
};
