/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
import { cloneDeep, toNumber } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import vesselThmd from '../../../../../images/vessel-thmd.jpg';
import showAlert from '../../../../../utils/alert';
import helper from '../../../../../utils/helper';
import MajorSpecPackagesList from '../../../../Admin/DataConfig/SpecificationSetup/SpecPackages/MajorSpecPackagesList';
import JobDetails from './JobDetails';
import { getNotification, GetProjectTypeList, GetSurveyTypeList, GetAdditionalJobList, getBackupTSI, getCurrencyList,
  getFleetandTSI, saveProjectBasicDetailsList, saveProjectSpecsTSI, getConfirmation, getGeneralTariff, getProjectDetailsTSI } from '../services';
import ProjectInfo from './projectInfo';
import Comments from '../../Comments';
import { getVesselManagementUsers } from '../../services';
import Loader from '../../../../common/loader';

const ProjectDetails = (props) => {
  const [surveyType, setSurveyType] = useState();
  const [vesselManagementUsers, setVesselManagementUsers] = useState([]);
  const [backupTsi, setBackupTsi] = useState();
  const [fleetAndTsiInfo, setFleetAndTsiInfo] = useState();
  const [projectCurrency, setProjectCurrency] = useState();
  const [projectType, setProjectType] = useState();
  const [projectTypeName, setProjectTypeName] = useState('');
  const [projectTypeShortName, setProjectTypeShortName] = useState('');
  const [surveyTypeName, setSurveyTypeName] = useState('');
  const [surveyTypeShortName, setSurveyTypeShortName] = useState('');
  const [currencyName, setCurrencyName] = useState('');
  const [isJobList, setIsJobList] = useState(false);
  const [isPlannedDryDock, setIsPlannedDryDock] = useState(false);
  const [IsemergencyDryDock, setIsEmergencyDryDock] = useState(false);
  const [jobList, setJobList] = useState();
  const [generalTarrif, setGeneralTarrif] = useState();
  const [selectedJobList, setSelectedJobList] = useState([]);
  const [isExpand, setIsExpand] = useState('');
  const [jobIds, setJobIds] = useState('');
  const [ismonth, setMonth] = useState(moment().format('MMM'));
  const [isyear, setYear] = useState(moment().format('YYYY'));
  const [loading, setLoading] = useState(false);
  const [projectDates, setProjectDates] = useState({
    dryDockingSurveyDueDate: '',
    intermediateSurveyDueDate: '',
    tailshaftSurveyDueDate: '',
    specialSurveyDueDate: ''
  });

  const [pType, setPType] = useState('');
  const {
    generateOptions,
    formValidation,
    ZERO_UUID,
    Priority, restrictDecimal, charValidation } = helper;
  const initialFormData = {
    id: '',
    name: '',
    vesselId: '',
    projectTypeId: '',
    surveyTypeId: '',
    projectType: '',
    priority: '',
    setName: '',
    fleetManagerId: '',
    backupTsiId: ZERO_UUID,
    tsiId: '',
    totalRepairDays: '',
    yardIndicativeBudget: 0,
    ownerIndicativeBudget: 0,
    currencyId: '',
    planApprovalStatus: 0,
    specApprovalStatus: 0,
    status: 0
  };

  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();
  const { vesselInfo, handleActiveComponent, handleSetProjectId, projectId,
    setStepperList, stepperList, setSurveyInfo, setIsSelectedVessel,
    isTsiDisabled } = props;
  const params = qs.parse(window.location.search);
  const year = vesselInfo && vesselInfo?.ddDueDate ? moment(moment(vesselInfo?.ddDueDate).format(' DD-MMM-YYYY'), 'DD-MMM-YYYY').format('YYYY') : ' ';
  const month = vesselInfo && vesselInfo?.ddDueDate ? moment(moment(vesselInfo?.ddDueDate).format(' DD-MMM-YYYY'), 'DD-MMM-YYYY').format('MMM') : ' ';
  const getSurveyNotifications = () => {
    getNotification({ id: vesselInfo?.vesselId }).then((res) => {
      if (res.data) {
        setSurveyInfo(res.data);
        const temp = { ...projectDates };
        temp.dryDockingSurveyDueDate = res.data.dryDockingSurveyDueDate;
        temp.intermediateSurveyDueDate = res.data.intermediateSurveyDueDate;
        temp.specialSurveyDueDate = res.data.specialSurveyDueDate;
        temp.tailshaftSurveyDueDate = res.data.tailshaftSurveyDueDate;
        setProjectDates(temp);
      }
    });
  };
  const [projectDetails, setProjectDetails] = useState();
  const getDetails = () => {
    setLoading(true);
    getProjectDetailsTSI({ id: projectId }).then((response) => {
      if (response?.success) {
        setProjectDetails(response?.data);
        setMonth(response?.data?.month);
        setYear(response?.data?.year);
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    if (projectId) {
      getDetails();
    }
  }, [projectId]);
  const getSurveyTypeList = () => {
    GetSurveyTypeList().then((res) => {
      if (res.success) {
        setSurveyType(generateOptions(res.data, 'id', 'name'));
      }
    });
  };
  const projectTypeList = () => {
    GetProjectTypeList().then((res) => {
      if (res.success) {
        setProjectType(generateOptions(res.data, 'id', 'name'));
      }
    });
  };
  const getJobList = (id) => {
    GetAdditionalJobList(vesselInfo?.vesselId || params?.vesselId,
      projectId || ZERO_UUID, id).then((res) => {
      if (res?.success) {
        setJobList(res?.data);
        const arr = [];
        res?.data?.map((item) => {
          if (item?.isChecked) {
            arr.push(item?.id);
          }
          return arr;
        });
        setJobIds(arr);
      }
    });
  };
  const getBackupTSIList = () => {
    const projId = params?.projectId || ZERO_UUID;
    getBackupTSI(vesselInfo?.vesselId, projId).then((res) => {
      if (res.success) {
        setBackupTsi(generateOptions(res?.data, 'id', 'name'));
      }
    });
  };
  const getProjectCurrencyList = () => {
    getCurrencyList().then((res) => {
      if (res.data) {
        setProjectCurrency(generateOptions(res?.data, 'id', 'currencyName', 'countryName'));
      }
    });
  };

  const diffInDays = () => {
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const todayDate = today.toISOString();
    const date1 = new Date(moment(todayDate).format(' DD-MMM-YYYY'));
    const date2 = new Date(moment(vesselInfo?.ddDueDate).format(' DD-MMM-YYYY'));
    const diffInTime = date2.getTime() - date1.getTime();
    const totalDiff = diffInTime / (1000 * 3600 * 24);
    return totalDiff;
  };
  const getFleetandTsiInfo = () => {
    const data = {
      vesselId: vesselInfo?.vesselId,
      userId: localStorage.getItem('USER_ID')
    };
    getFleetandTSI(data).then((res) => {
      if (res?.data) {
        setFleetAndTsiInfo(res?.data);
      }
    });
  };

  const getBasicDetails = () => {
    const payload = {
      id: projectId
    };
    getConfirmation(payload).then((res) => {
      if (res?.success) {
        setFormData(res?.data);
        setProjectTypeName(res?.data?.projectType);
        setSurveyTypeName(res?.data?.surveyType);
        setSurveyTypeShortName(res?.data?.surveyTypeShortName ? res?.data?.surveyTypeShortName : '');
        setProjectTypeShortName(res?.data?.projectTypeShortName ? res?.data?.projectTypeShortName : '');
        setMonth(res?.data?.month);
        setYear(res?.data?.year);
        setPType(res?.data?.projectType);
        setCurrencyName(res?.data?.currencySymbol);
        if ((res?.data?.projectType?.toLowerCase()?.includes('emergency'))
          || (res?.data?.projectType?.toLowerCase()?.includes('layup'))) {
          getGeneralTariff(projectId || ZERO_UUID, vesselInfo?.vesselId).then((response) => {
            if (response?.success) {
              res.data.specificationList.length && res.data.specificationList.forEach((i) => {
                i.childData.length && i.childData.forEach((j) => {
                  if (j.isChecked) {
                    response.data.forEach((k) => {
                      if (i.id === k.id) {
                        k.childData.forEach((c) => {
                          if (j.id === c.id) {
                            c.isChecked = true;
                          }
                        });
                      }
                    });
                  }
                });
              });
              setIsJobList(false);
              setIsEmergencyDryDock(true);
              setGeneralTarrif(response?.data);
            }
          });
        } else if (res?.data?.projectType?.toLowerCase()?.includes('plan')) {
          getJobList(res?.data?.projectTypeId);
          setIsJobList(true);
          setIsEmergencyDryDock(false);
          setIsPlannedDryDock(true);
        } else {
          getJobList(res?.data?.projectTypeId);
          setIsJobList(true);
          setIsEmergencyDryDock(false);
        }
      }
    });
  };
  const getVesselUsersBYRole = () => {
    getVesselManagementUsers(vesselInfo?.vesselId).then((res) => {
      if (res.data) {
        setVesselManagementUsers(res.data);
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (vesselInfo?.vesselId) {
      getSurveyNotifications();
      getBackupTSIList();
      getFleetandTsiInfo();
      getVesselUsersBYRole();
    }
    getSurveyTypeList();
    getProjectCurrencyList();
    projectTypeList();
    diffInDays();
  }, []);

  useEffect(() => {
    if (projectId) {
      getBasicDetails();
    }
  }, [projectId]);
  /* istanbul ignore next */
  const saveJobs = (id, status) => {
    setFormData(initialFormData);
    setIsJobList(false);
    setJobIds([]);
    setIsEmergencyDryDock(false);
    setIsPlannedDryDock(false);
    stepperList[1].isDone = true;
    setStepperList(stepperList);
    if (status === 1) {
      handleActiveComponent('attachments');
      handleSetProjectId(id);
    } else if (status === 0) {
      navigate('/user/projects');
    }
  };
  /* istanbul ignore next */
  const saveEmergencyDD = (id, status) => {
    const temp = cloneDeep(generalTarrif);
    temp.map((i) => {
      if (!i.isChecked) {
        i.isChecked = true;
      }
    });
    saveProjectSpecsTSI(temp, id).then((res) => {
      if (res.success) {
        setFormData(initialFormData);
        setIsJobList(false);
        setIsEmergencyDryDock(false);
        stepperList[1].isDone = true;
        setStepperList(stepperList);
        if (status === 1) {
          handleActiveComponent('attachments');
          handleSetProjectId(id);
        } else if (status === 0) {
          navigate('/user/projects');
        }
      }
    });
  };
  /* istanbul ignore next */
  const saveApiCall = (status) => {
    let validationArray = [];
    let validation = {};
    if (isPlannedDryDock) {
      validationArray = ['projectTypeId', 'surveyTypeId', 'currencyId', 'priority', 'totalRepairDays'];
      validation = formData.projectTypeId !== ZERO_UUID && formData.surveyTypeId !== ZERO_UUID
        && formData.currencyId !== ZERO_UUID && formData.priority !== 0
        && formData.totalRepairDays !== 0;
    } else {
      validationArray = ['projectTypeId', 'currencyId', 'priority', 'totalRepairDays'];
      validation = formData.projectTypeId !== ZERO_UUID
        && formData.currencyId !== ZERO_UUID && formData.priority !== 0
        && formData.totalRepairDays !== 0;
    }
    let dateEmpty = false;
    const dateValidation = [
      { field: 'dryDockingSurveyDueDate' },
      { field: 'tailshaftSurveyDueDate' },
      { field: 'intermediateSurveyDueDate' },
      { field: 'specialSurveyDueDate' }
    ];
    dateValidation.map((i) => {
      if (projectDates[i.field] === 'T00:00:00+00:00' && !dateEmpty === true) {
        dateEmpty = true;
      }
    });
    if (formValidation(validationArray, formData)) {
      if (validation && !dateEmpty) {
        const formValues = cloneDeep(formData);
        formValues.name = isPlannedDryDock
          ? `${vesselInfo?.name}_${projectTypeShortName}_${surveyTypeShortName}`
          : `${vesselInfo?.name}_${projectTypeShortName}_${ismonth}${isyear || moment().format('YYYY')}`;
        formValues.nameTooltip = isPlannedDryDock
          ? `${vesselInfo?.name}_${projectTypeName}_${surveyTypeName}`
          : `${vesselInfo?.name}_${projectTypeName}_${ismonth}${isyear}`;
        formValues.month = ismonth;
        formValues.year = isyear;
        formValues.vesselId = vesselInfo?.vesselId;
        formValues.id = params?.projectId || projectId || ZERO_UUID;
        formValues.fleetManagerId = fleetAndTsiInfo?.fleetManagerId;
        formValues.tsiId = fleetAndTsiInfo?.tsiUserId;
        formValues.surveyTypeId = isPlannedDryDock ? formValues.surveyTypeId
          || ZERO_UUID : ZERO_UUID;
        formValues.surveyDates = {
          imoNo: vesselInfo?.imoNo,
          surveys: [
            {
              surveyName: 'dryDockingsurvey',
              dueDate: projectDates?.dryDockingSurveyDueDate
            },
            {
              surveyName: 'tailshaftsurvey',
              dueDate: projectDates?.tailshaftSurveyDueDate
            },
            {
              surveyName: 'intermediatehullsurvey',
              dueDate: projectDates?.intermediateSurveyDueDate
            },
            {
              surveyName: 'specialsurvey',
              dueDate: projectDates?.specialSurveyDueDate
            }
          ]
        };
        formValues.status = status;
        formValues.jobIds = isJobList ? jobIds : [];
        setLoading(true);
        saveProjectBasicDetailsList(formValues).then((res) => {
          setLoading(false);
          if (res.success) {
            setFormData(initialFormData);
            setIsSelectedVessel(vesselInfo?.vesselId);
            if (isJobList || isPlannedDryDock) {
              saveJobs(res?.data?.id, status);
            } else if (IsemergencyDryDock) {
              saveEmergencyDD(res?.data?.id, status);
            }
            showAlert('Successfully saved as draft', 'success');
          }
        }).catch(() => setLoading(false));
      } else {
        showAlert('Please enter the mandatory(*) fields', 'error');
      }
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  /* istanbul ignore next */
  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      if (!e) fd[field] = '';
      else fd[field] = e?.value;
      if (field === 'projectTypeId') {
        setProjectTypeName(e?.name);
        setProjectTypeShortName(e?.shortName ? e?.shortName : '');
        setJobIds([]);
        if (e.name?.toLowerCase()?.includes('emergency')
          || (e.name?.toLowerCase()?.includes('layup'))) {
          getGeneralTariff(projectId || ZERO_UUID, vesselInfo?.vesselId).then((res) => {
            if (res.data) {
              setIsJobList(false);
              setIsEmergencyDryDock(true);
              setIsPlannedDryDock(false);
              setGeneralTarrif(res?.data);
            }
          });
        } else if (e.name?.toLowerCase()?.includes('plan')) {
          setIsPlannedDryDock(true);
          setIsJobList(true);
          setIsEmergencyDryDock(false);
          getJobList(e?.id);
        } else {
          setIsPlannedDryDock(false);
          setIsJobList(true);
          setIsEmergencyDryDock(false);
          getJobList(e?.id);
        }
      } else if (field === 'surveyTypeId') {
        setSurveyTypeName(e?.name);
        setSurveyTypeShortName(e?.shortName ? e?.shortName : '');
      } else if (field === 'currencyId') {
        setCurrencyName(e?.currencySymbol);
      }
    } else if (field === 'totalRepairDays') {
      fd[field] = toNumber(charValidation(e.target.value.replace(/[^0-9]/g, ''), 2));
    } else fd[field] = restrictDecimal(charValidation(e.target.value, 15));
    setFormData(fd);
  };

  const handleInputChange = (event, row) => {
    const temp = cloneDeep(jobList);
    let jobs = [];
    if (event.target.checked) {
      temp[row.index].isChecked = true;
    } else {
      temp[row.index].isChecked = false;
    }
    if (!jobIds.includes(event?.target?.id)) {
      jobs = [...jobIds, event?.target?.id];
    } else {
      jobs = jobIds.filter((a) => a !== event?.target?.id);
    }
    setJobIds(jobs);
    setJobList(temp);
  };
  /* istanbul ignore next */
  const handleChangeType = (index, type, subIndex = '') => {
    const temp = cloneDeep(generalTarrif);
    if (type === 'main') {
      if (temp[index]) { temp[index].isChecked = !temp[index]?.isChecked; }
      temp[index]?.childData.forEach((element, k) => {
        temp[index].childData[k].isChecked = temp[index].isChecked;
      });
    } else {
      temp[index].childData[subIndex].isChecked = !temp[index].childData[subIndex].isChecked;
      let found = true;
      temp[index].childData.forEach((element, k) => {
        if (!temp[index].childData[k].isChecked) {
          found = false;
        }
        temp[index].isChecked = found;
      });
    }
    setGeneralTarrif(temp);
  };

  const handleDates = (e, field) => {
    const temp = { ...projectDates };
    temp[field] = `${e.target.value}T00:00:00+00:00`;
    setProjectDates(temp);
  };

  return (
    <div className="col-8 card m-auto p-3em mb-5" data-testid="project-details">
      {loading ? <Loader /> : (
        <div>
          <h3 className="head-18 mb-2">Project Details</h3>
          <p>Enter dry docking project details</p>
          <div className="bg-sky-white card  p-4 my-4">
            <div className="d-flex justify-content-between align-items-center col-gap-1">
              <div className="d-flex">
                <img className="thmb-md ship-thumb" src={vesselInfo?.imageUrl || vesselThmd} alt="" />
                <div className="d-flex flex-column ">
                  <h3 className="head-16">{vesselInfo?.name}</h3>
                  <p className="mt-2">{vesselInfo?.vesselSubType}</p>
                </div>
              </div>
              <div className="d-flex  flex-column ">
                <div className="d-flex">
                  <label> DD Due Date : </label>
                  <p className="value  ms-2">{vesselInfo?.ddDueDate ? moment(vesselInfo?.ddDueDate).format(' DD-MMM-YYYY') : '-'}</p>
                </div>
                <div className="d-flex mt-2">
                  <label>Previous DD Date :</label>
                  <p className="value ms-2">{vesselInfo?.previousDdDate ? moment(vesselInfo?.previousDdDate).format('DD-MMM-YYYY') : '-'}</p>
                </div>
              </div>

              <button
                onClick={() => {
                  handleActiveComponent('vessel-details');
                }}
                type="button"
                data-testid="more"
                className="secondary-btn small "
              >
                More info
              </button>

            </div>
          </div>

          <div className="mb-5">
            <h6 className="head-16 mb-3">
              Project Name :
              {isPlannedDryDock ? (
                <span data-tooltip-id="project-det" data-tooltip-content={`${projectDetails?.name}_${projectTypeName}_${surveyTypeName}`} className="font-bold ms-2 text-capitalize">{`${vesselInfo?.name}_${projectTypeShortName}_${surveyTypeShortName}`}</span>)
                : IsemergencyDryDock ? (<span data-tooltip-id="project-det" data-tooltip-content={`${projectDetails?.name}_${projectTypeName}_${ismonth}${isyear || moment().format('YYYY')}`} className="font-bold ms-2 text-capitalize">{`${vesselInfo?.name}_${projectTypeShortName}_${ismonth}${isyear}`}</span>)
                  : (<span data-tooltip-content={`${vesselInfo?.name}_${projectTypeName}_${month}${year}`} className="font-bold ms-2 text-capitalize">{`${vesselInfo?.name}_${projectTypeShortName}_${month}${year}`}</span>)}
            </h6>
            <p className="d-flex align-items-center flex-start">
              <span className="icon-Info me-3 font-20  brand-color" />
              <p className="opacity-6">
                Project name will be automatically generated based
                on project type and date
              </p>
            </p>
          </div>
          <ProjectInfo
            vesselManagementUsers={vesselManagementUsers}
            surveyType={surveyType}
            backupTsi={backupTsi}
            projectCurrency={projectCurrency}
            projectType={projectType}
            Priority={Priority}
            isTsiDisabled={isTsiDisabled}
            handleChange={handleChange}
            formData={formData}
            diffInDays={diffInDays}
            fleetAndTsiInfo={fleetAndTsiInfo}
            IsemergencyDryDock={IsemergencyDryDock}
            isPlannedDryDock={isPlannedDryDock}
            currencyName={currencyName}
            ismonth={ismonth}
            isyear={isyear}
            setMonth={setMonth}
            setYear={setYear}
            pType={pType}
            setPType={setPType}
            handleDates={handleDates}
            projectDates={projectDates}
          />
          {isJobList && jobList && jobList?.length > 0 && (
            <JobDetails
              tableData={jobList}
              isTsiDisabled={isTsiDisabled}
              handleInputChange={handleInputChange}
              selectedJobList={selectedJobList}
            />
          )}
          {IsemergencyDryDock && generalTarrif && generalTarrif?.length > 0 && (
            <>
              <label className="d-flex align-items-center emergency-header required">
                List of GT to be included
              </label>
              <MajorSpecPackagesList
                isExpand={isExpand}
                formDataToFillList={generalTarrif}
                isTsiDisabled={isTsiDisabled}
                setIsExpand={setIsExpand}
                handleChangeType={handleChangeType}
              />

            </>
          )}
          <div className="d-flex justify-content-between mt-4">
            {projectId && projectDetails?.planApprovalStatus === 3
              ? <Comments id={projectId} /> : <div />}
            <div className="d-flex justify-content-end">
              <button
                type="button"
                data-testid="project-cancel"
                disabled={isTsiDisabled}
                className="secondary-btn large me-4"
                onClick={() => {
                  setFormData(initialFormData);
                  setSelectedJobList([]);
                  setGeneralTarrif([]);
                  setProjectTypeName('');
                  setProjectTypeShortName('');
                  setSurveyTypeName('');
                  setSurveyTypeShortName('');
                  setCurrencyName('');
                  setIsJobList(false);
                  setIsEmergencyDryDock(false);
                  getSurveyNotifications();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="secondary-btn large me-4"
                data-testid="project-previous"
                onClick={() => {
                  if (isTsiDisabled) {
                    handleActiveComponent('vessel-details');
                  } else { saveApiCall(1); }
                }}
              >
                Previous
              </button>
              <button
                type="button"
                className="secondary-btn large me-4"
                data-testid="project-save"
                disabled={isTsiDisabled}
                onClick={() => { saveApiCall(0); }}
              >
                Save as draft
              </button>
              <button
                type="button"
                className="primary-btn large"
                data-testid="project-continue"
                onClick={() => {
                  if (isTsiDisabled) {
                    handleActiveComponent('attachments');
                  } else { saveApiCall(1); }
                }}
              >
                Continue
              </button>
            </div>
          </div>

        </div>
      )}
      <Tooltip id="project-det" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default ProjectDetails;
