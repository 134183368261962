/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import { number } from 'mathjs';

import helper from '../../../../../../utils/helper';
import InputType1 from '../../../../../common/InputType1';
import SidePanel from '../../../../../common/sidePanel';
import showAlert from '../../../../../../utils/alert';
import { GetFuelData } from '../../../../../Admin/DataConfig/GeneralConfig/services';

const CostCalculator = (props) => {
  const { onClose, showSideBarCost, sidePanelData, setSidePanelData, postDOCostDetails, setCompareData,
    disableFields, recalculate } = props;
  const [tableData, setTableData] = useState([]);

  /* istanbul ignore next */
  const reloadTable = () => {
    GetFuelData({}).then((res) => {
      setTableData(res.data);
    });
  };

  /* istanbul ignore next */
  const options = tableData?.map((opt) => {
    return { label: opt.name, value: opt.id };
  });
  const optionsForFuelTypeOne = options.filter((option) => option.value !== sidePanelData?.fuelType2Id);
  const optionsForFuelTypeTwo = options.filter((option) => option.value !== sidePanelData?.fuelType1Id);

  /* istanbul ignore next */
  useEffect(() => {
    reloadTable();
  }, []);

  const { charValidation, ZERO_UUID, roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isViewer = (userRole === roles.VIEWER);

  /* istanbul ignore next */
  const handleFormChange = (field, value) => {
    setSidePanelData((prevData) => {
      let sanitizedValue = typeof value === 'string' ? value.replace(/[^0-9]/g, '') : '';
      const updatedData = { ...prevData };

      if (field === 'fuelType2Id' || field === 'fuelType1Id') {
        updatedData[field] = value?.value;
      } else if (field === 'daysAfloat' || field === 'inDockDays') {
        if (sanitizedValue.match(/^(?!00)[0-9]{0,2}$/) && !sanitizedValue.includes('.')) {
          if (sanitizedValue.length === 2 && sanitizedValue.startsWith('0')) {
            sanitizedValue = sanitizedValue.substring(1);
          }
          updatedData[field] = sanitizedValue;
        }
      } else if (field === 'offHireDays' || field === 'deviationDays') {
        updatedData[field] = toNumber(charValidation(sanitizedValue, 2));
      } else {
        updatedData[field] = toNumber(charValidation(sanitizedValue, 8));
      }

      updatedData.repairDays = number(updatedData.daysAfloat) + number(updatedData.inDockDays);
      updatedData.fuelCost = toNumber(
        (updatedData.fuelPriceType1 * updatedData.fuelType1Consumption)
        + (updatedData.fuelPriceType2 * updatedData.fuelType2Consumption)
      );
      updatedData.offHireCost = toNumber((updatedData.deviationDays + updatedData.repairDays) * updatedData.hireRate);
      updatedData.deviationCost = toNumber(updatedData.fuelCost + updatedData.offHireCost + updatedData.miscCost);

      return updatedData;
    });
  };

  const disabled = true;

  return (
    <SidePanel
      size="medium"
      title="Editing Deviation and Off Hire"
      showView={showSideBarCost}
      onClose={onClose}
      isTsiDisabled={disableFields || isViewer}
      onSave={() => {
        let valid = false;
        if (sidePanelData?.fuelPriceType1
          && sidePanelData?.fuelPriceType2
          && sidePanelData?.fuelType1Consumption
          && sidePanelData?.fuelType2Consumption
          && sidePanelData?.hireRate
          && sidePanelData?.miscCost
          && sidePanelData?.deviationDays) {
          valid = true;
        }

        if (valid) {
          postDOCostDetails(sidePanelData);
          onClose();
          /* istanbul ignore next */
          setCompareData((prevData) => {
            const updatedData = prevData.map((i) => (i.rfqId === sidePanelData?.rfqId
              ? {
                ...i,
                trd: sidePanelData?.repairDays,
                daysAfloat: sidePanelData?.daysAfloat,
                inDockDays: sidePanelData?.inDockDays,
                offhireCost: toNumber(sidePanelData?.offHireCost),
                deviationCost: toNumber(sidePanelData?.deviationCost),
                fuelCost: sidePanelData?.fuelCost,
                fuelPriceType1: sidePanelData?.fuelPriceType1,
                fuelPriceType2: sidePanelData?.fuelPriceType2,
                fuelType1Consumption: sidePanelData?.fuelType1Consumption,
                fuelType2Consumption: sidePanelData?.fuelType2Consumption,
                fuelType1Id: sidePanelData?.fuelType1Id,
                fuelType2Id: sidePanelData?.fuelType2Id,
                hireRate: sidePanelData?.hireRate,
                miscCost: sidePanelData?.miscCost,
                deviationDays: sidePanelData?.deviationDays
              }
              : i));
            setTimeout(() => recalculate(updatedData), 0);

            return updatedData;
          });
        } else {
          showAlert('Please enter the mandatory(*) fields', 'error');
        }
      }}
      className="sidebar-costcalc"
    >
      <div data-testid="edit-modal">
        <div className="row pb-3 ">
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                In Dock Days
              </span>
              <span className="text-primaryRed-1">*</span>
            </div>
            <InputType1
              value={sidePanelData?.inDockDays}
              id="inDockDays"
              data-test="change9"
              onChange={(e) => handleFormChange('inDockDays', e.target.value)}
              className="modal-input mt-2"
              type="number"
              disabled={disableFields || isViewer}
            />
          </div>
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Days Afloat
              </span>
              <span className="text-primaryRed-1">*</span>
            </div>
            <InputType1
              value={sidePanelData?.daysAfloat}
              id="daysAfloat"
              data-test="change10"
              onChange={(e) => handleFormChange('daysAfloat', e.target.value)}
              className="modal-input mt-2"
              type="number"
              disabled={disableFields || isViewer}
            />
          </div>
        </div>
        <div className="row pb-3 ">
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Total Deviation Days
              </span>
              <span className="text-primaryRed-1">*</span>
            </div>
            <InputType1
              autoFocus
              value={sidePanelData?.deviationDays ? sidePanelData?.deviationDays : ''}
              id="deviationDays"
              data-test="change1"
              onChange={(e) => handleFormChange('deviationDays', e.target.value)}
              className="modal-input mt-2"
              type="number"
              disabled={disableFields || isViewer}
            />
          </div>
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Total Repair Days
              </span>
            </div>
            <InputType1
              value={sidePanelData?.repairDays}
              disabled={disabled || disableFields || isViewer}
              id="repairDays"
              data-test="change2"
              className="modal-input mt-2"
              type="number"
            />
          </div>
        </div>
        <div className="row pb-3  pt-3">
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Total Off Hire Days
              </span>
            </div>
            <InputType1
              disabled
              data-tooltip-id="project-calc"
              data-tooltip-content="Total Deviation Days + Total Repair Days"
              value={toNumber(sidePanelData?.repairDays) + toNumber(sidePanelData?.deviationDays)}
              id="offHireDays"
              className="modal-input mt-2"
              type="number"
            />
          </div>
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Hire Rate
              </span>
              <span className="text-primaryRed-1">*</span>
            </div>
            <InputType1
              value={sidePanelData?.hireRate ? sidePanelData?.hireRate : ''}
              id="hireRate"
              data-test="change3"
              onChange={(e) => handleFormChange('hireRate', e.target.value)}
              className="modal-input mt-2"
              type="number"
              disabled={disableFields || isViewer}
            />
          </div>
        </div>
        <div className="row pb-3  pt-3">
          <div className="col-6">
            <div className="">
              <span className="text-content-grey text-capitalize">fuel Type1</span>
              <span className="text-primaryRed-1">*</span>
              <div className="mb-3 mt-3">
                <Select
                  options={optionsForFuelTypeOne}
                  className="modal-select"
                  value={sidePanelData?.fuelType1Id && sidePanelData?.fuelType1Id !== ZERO_UUID ? optionsForFuelTypeTwo.find((option) => option.value
                    === sidePanelData?.fuelType1Id) : ''}
                  onChange={(e) => {
                    handleFormChange('fuelType1Id', e);
                  }}
                  data-test="select1"
                  placeholder="Select Category"
                  noMargin
                  isDisabled={disableFields || isViewer}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Fuel Price -Type 1
              </span>
              <span className="text-primaryRed-1">*</span>
            </div>
            <InputType1
              value={sidePanelData?.fuelPriceType1 ? sidePanelData?.fuelPriceType1 : ''}
              id="fuelPriceType1"
              data-test="change4"
              onChange={(e) => handleFormChange('fuelPriceType1', e.target.value)}
              className="modal-input mt-2"
              type="number"
              disabled={disableFields || isViewer}
            />
          </div>
        </div>
        <div className="row pb-3 ">
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Fuel Type 1 Consumption
              </span>
              <span className="text-primaryRed-1">*</span>
            </div>
            <InputType1
              value={sidePanelData?.fuelType1Consumption ? sidePanelData?.fuelType1Consumption : ''}
              id="fuelType1Consumption"
              data-test="change5"
              onChange={(e) => handleFormChange('fuelType1Consumption', e.target.value)}
              className="modal-input mt-2"
              type="number"
              disabled={disableFields || isViewer}
            />
          </div>
          <div className="col-6">
            <div className="">
              <span className="text-content-grey text-capitalize">fuel Type2</span>
              <span className="text-primaryRed-1">*</span>
              <div className="mb-3 mt-3">
                <Select
                  options={optionsForFuelTypeTwo}
                  className="modal-select"
                  value={sidePanelData?.fuelType2Id && sidePanelData?.fuelType2Id !== ZERO_UUID ? optionsForFuelTypeTwo.find((option) => option.value
                   === sidePanelData?.fuelType2Id) : ''}
                  onChange={(e) => {
                    handleFormChange('fuelType2Id', e);
                  }}
                  data-test="select2"
                  placeholder="Select Category"
                  noMargin
                  isDisabled={disableFields || isViewer}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row pb-3 ">
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Fuel Price -Type 2
              </span>
              <span className="text-primaryRed-1">*</span>
            </div>
            <InputType1
              value={sidePanelData?.fuelPriceType2 ? sidePanelData?.fuelPriceType2 : ''}
              id="fuelPriceType2"
              data-test="change6"
              onChange={(e) => handleFormChange('fuelPriceType2', e.target.value)}
              className="modal-input mt-2"
              type="number"
              disabled={disableFields || isViewer}
            />
          </div>
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Fuel Type 2 Consumption
              </span>
              <span className="text-primaryRed-1">*</span>
            </div>
            <InputType1
              value={sidePanelData?.fuelType2Consumption ? sidePanelData?.fuelType2Consumption : ''}
              data-test="change7"
              onChange={(e) => handleFormChange('fuelType2Consumption', e.target.value)}
              id="fuelType2Consumption"
              className="modal-input mt-2"
              type="number"
              disabled={disableFields || isViewer}
            />
          </div>
        </div>
        <div className="row pb-3 ">
          <div className="col-6">
            <div className="">
              <span className="text-content-grey">
                Misc Cost (Cyl LO/UREA etc)
              </span>
              <span className="text-primaryRed-1">*</span>
            </div>
            <InputType1
              value={sidePanelData?.miscCost ? sidePanelData?.miscCost : ''}
              id="miscCost"
              data-test="change8"
              onChange={(e) => handleFormChange('miscCost', e.target.value)}
              className="modal-input mt-2"
              type="number"
              disabled={disableFields || isViewer}
            />
          </div>
          <div className="col-6">
            <div
              className=""
              data-tooltip-id="project-calc"
              data-tooltip-content="Total Off Hire Days x Hire Rate"
            >
              <span className="text-content-grey">
                Estimated Off Hire Cost
              </span>
            </div>
            <InputType1
              disabled={disabled || disableFields || isViewer}
              value={sidePanelData?.offHireCost ? sidePanelData?.offHireCost : ''}
              id="offHireCost"
              className="modal-input mt-2"
            />
          </div>
        </div>
        <div className="row pb-3 ">
          <div className="col-6">
            <div className="">
              <span
                className="text-content-grey"
                data-tooltip-id="project-calc"
                data-tooltip-content="(Fuel Price -Type 1 x Fuel Type 1 Consumption) + (Fuel Price -Type 2 x Fuel Type 2 Consumption)"
              >
                Estimated Fuel Cost
              </span>

            </div>
            <InputType1
              disabled={disabled || disableFields || isViewer}
              value={sidePanelData?.fuelCost ? sidePanelData?.fuelCost : ''}
              id="fuelCost"
              className="modal-input mt-2"
              type="number"
            />
          </div>
          <div className="col-6">
            <div
              className=""
              data-tooltip-id="project-calc"
              data-tooltip-content="Estimated Off Hire Cost + Estimated Fuel Cost + Misc Cost"
            >
              <span className="text-content-grey">
                Estimated Deviation Cost
              </span>

            </div>
            <InputType1
              disabled={disabled || disableFields || isViewer}
              value={sidePanelData?.deviationCost ? sidePanelData?.deviationCost : ''}
              id="deviationCost"
              className="modal-input mt-2"
              type="number"
            />
            <Tooltip id="project-calc" place="top" className="tooltip mt-3" />
          </div>
        </div>
      </div>
    </SidePanel>
  );
};

export default CostCalculator;
