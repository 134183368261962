/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable radix */
import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import InputType1 from '../../../common/InputType1';
import ModalBox from '../../../common/ModalBox';
import { TotalEstimate, SecTotal } from './totalEstimate';
import helper from '../../../../utils/helper';
import RecievedMsg from '../../../TSI/Projects/ChatScreen/recievedMsg';
import SentMsg from '../../../TSI/Projects/ChatScreen/sentMsg';

const QuotationTable = (props) => {
  const { formatNumber, fieldValidation, roles } = helper;

  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles.ADMIN;
  const { specList, mainSec, showsubSec, onSecOpen, setSpecList, setShowMore,
    setShowAttach, onAddServiceLine, onRemoveLine } = props;
  const [addComment, setAddComment] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState({
    userId: null,
    userName: null,
    timeStamp: null,
    comment: null,
    sectionIndex: null,
    specIndex: null,
    slIndex: null
  });
  const [emptyGuid, setEmptyGuid] = useState('00000000-0000-0000-0000-000000000000');

  useEffect(() => {
    const tempData = { ...specList };
    tempData?.sectionData?.forEach((sec, index) => {
      sec?.specDataList?.forEach((spec) => {
        spec?.serviceLineList?.forEach((serv) => {
          if (serv?.specificationLevel !== 4) {
            serv.discount = serv.discount !== null ? serv.discount : tempData?.rfqData?.discountPercentage.toString();
            if (serv.quantity !== null && serv.rate !== null) {
              serv.amount = (((serv.quantity * serv.rate) / 100)
                * (100 - serv.discount)) ? (((serv.quantity * serv.rate) / 100)
                  * (100 - serv.discount)).toString() : null;
            }
          }
        });
      });
      tempData.sectionData[index].total = SecTotal(tempData.sectionData[index]);
    });
    tempData.rfqData.total = TotalEstimate(tempData);
    tempData.rfqData.yardFinalEstimate = (tempData?.rfqData
      ?.total - tempData?.rfqData?.discount)?.toFixed(2)?.toString();
    tempData.rfqData.yardEstimateWithoutDiscount = (tempData?.rfqData
      ?.total)?.toString();
    setSpecList(tempData);
  }, []);

  const onServiceFieldChange = (e, index, sub, num) => {
    const currentSpecList = { ...specList };
    if (e.target.name === 'rate') {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num][e.target.name] = fieldValidation(e.target.value.replace(/[^0-9.]/g, ''), 5, 'returnString');
    } else if (e.target.name === 'quantity') {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num][e.target.name] = fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 6);
    } else {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num][e.target.name] = fieldValidation(e.target.value.replace(/[^0-9.]/g, ''), 3, 'returnString');
    }
    const rowDetails = currentSpecList.sectionData[index].specDataList[sub]
      .serviceLineList[num];
    if (rowDetails.quantity && rowDetails.rate && rowDetails.discount) {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num]
        .amount = (((rowDetails.quantity * rowDetails.rate) / 100) * (100 - rowDetails.discount))
          .toFixed(2).toString();
    } else if (rowDetails.quantity && rowDetails.rate) {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num].amount = (rowDetails.quantity * rowDetails.rate)
          .toFixed(2).toString();
    } else {
      currentSpecList.sectionData[index].specDataList[sub]
        .serviceLineList[num].amount = '0';
    }
    currentSpecList.rfqData.total = TotalEstimate(currentSpecList);
    currentSpecList.rfqData.yardFinalEstimate = (currentSpecList.rfqData
      .total - currentSpecList.rfqData.discount).toString();
    currentSpecList.rfqData.yardEstimateWithoutDiscount = (currentSpecList.rfqData
      .total - currentSpecList.rfqData.discount).toString();
    currentSpecList.sectionData[index].total = SecTotal(currentSpecList.sectionData[index])
      .toString();
    setSpecList(currentSpecList);
  };
  const onSaveComment = () => {
    const currentSpecList = { ...specList };
    const newCmt = { ...comment };
    delete newCmt.sectionIndex;
    delete newCmt.specIndex;
    delete newCmt.slIndex;
    if (!currentSpecList.sectionData[comment.sectionIndex].specDataList[comment.specIndex]
      .serviceLineList[comment.slIndex].commentList) {
      currentSpecList.sectionData[comment.sectionIndex].specDataList[comment.specIndex]
        .serviceLineList[comment.slIndex].commentList = [];
    }
    currentSpecList.sectionData[comment.sectionIndex].specDataList[comment.specIndex]
      .serviceLineList[comment.slIndex].commentList.push(newCmt);
    setSpecList(currentSpecList);
    setAddComment(false);
    setCommentList([]);
  };
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [addComment]);

  return (
    <>
      <div className="spc-map ps-3">
        <div className="d-flex border-down py-2 ps-3">
          <div className="col-1 font-bold">SEC#</div>
          <div className="col-8 font-bold">SPEC SECTION</div>
          <div className="col-3 font-bold text-left-align px-1">TOTAL</div>
        </div>
        {specList.sectionData?.map((sec, mainIndex) => {
          const isNewSupp = sec?.specDataList?.some((specs) => specs.type === 4 && specs.suppSpecSentStatus === 2);
          return (
            <div className={(sec?.projectSpecId === mainSec && showsubSec) ? 'highlight-tender ' : ''}>
              <div className={isNewSupp ? 'd-flex border-down py-2 ps-2 new-Supp-BG' : 'd-flex border-down py-2 ps-3'}>
                <div className="col-1 d-flex align-items-center">
                  <span
                    id={sec.projectSpecId}
                    className={sec.projectSpecId === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                    onClick={() => { onSecOpen(sec.projectSpecId, true); }}
                  />
                  <span>{sec.specificationNo}</span>
                </div>
                <div className="col-8">
                  <span data-tooltip-id="project-qttable" data-tooltip-content={sec.title}>{_.truncate(sec.title, { length: 30 })}</span>

                </div>
                <div className="col-3 text-left-align head-14 px-1">
                  {specList.rfqData?.currencySymbol}
                  {' '}
                  {formatNumber(sec.total)}
                </div>
              </div>
              {sec.projectSpecId === mainSec && sec.specDataList.map((subsec, subIndex) => {
                return (
                  <>
                    <div className={subsec.type === 4 && (subsec.suppSpecSentStatus === 0 || subsec.suppSpecSentStatus === 1) ? 'd-none' : 'd-flex mb-2'}>
                      <div className="col-1" />
                      <div className={subsec.type === 4 && subsec.suppSpecSentStatus === 2 ? 'col-11 d-flex align-items-center py-2 ps-2 new-Supp-BG' : 'col-11 d-flex align-items-center py-2 ps-3'}>
                        <span
                          id={subsec.projectSpecId}
                          className={subsec.projectSpecId === showsubSec ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180 ms-1' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2 ms-1'}
                          onClick={() => { onSecOpen(subsec.projectSpecId, false); }}
                        />
                        <span className="me-4">{subsec.specificationNo}</span>
                        <div className="">
                          <span data-tooltip-id="project-qttable" data-tooltip-content={subsec.title}>
                            {_.truncate(subsec.title, { length: 30 })}
                          </span>
                        </div>
                        {subsec.projectSpecId === showsubSec && (
                          <>
                            <button type="button" id="viewmore" className="primary-btn small ms-5" onClick={() => { setShowMore(subsec); }}>View More</button>
                            <button type="button" id="attach" className="primary-btn small ms-5" onClick={() => { setShowAttach({ key1: mainIndex, key2: subIndex, section: subsec }); }}>Attachments</button>
                          </>
                        )}
                      </div>
                    </div>
                    {
                      subsec.projectSpecId === showsubSec
                      && subsec.serviceLineList.map((serv, num) => {
                        return (
                          <>
                            {num === 0 && (
                              <div className="d-flex ps-3 align-items-center q-table-mt1">
                                <div className="col-001" />
                                <div className="col-001 bg-sky-white ps-3 border-down py-2">Service LN#</div>
                                <div className="col-4 bg-sky-white ps-3 border-down py-2">Service Lines</div>
                                <div className="col-001 bg-sky-white ps-3 border-down py-2">UOM</div>
                                <div className="col-00 bg-sky-white ps-2 border-down py-2">Qty</div>
                                <div className="col-00 bg-sky-white ps-2 border-down py-2">Rate</div>
                                <div className="col-00 bg-sky-white ps-3 border-down py-2">Disc[%]</div>
                                <div className="col-001 bg-sky-white ps-3 border-down py-2 text-left-align">Amount</div>
                                <div className="bg-sky-white ps-3 border-down py-2 px-2">Comments </div>
                              </div>
                            )}
                            <div className={serv.isRemoved ? 'd-flex ps-3 my-2 border-down removed-SL' : 'd-flex ps-3 my-2 border-down'}>
                              <div className="col-001" />
                              <div className="col-001 ps-3 ">{serv.number}</div>
                              <div className="col-4 ps-3 text-justify text-break-all">
                                <span>{serv.serviceLineName}</span>
                              </div>
                              <div className="col-001 ps-3  ">
                                <span data-tooltip-id="project-qttable" data-tooltip-content={serv.uomName}>
                                  {_.truncate(serv.uomName, { length: 13 }) || '-'}
                                </span>
                              </div>
                              <div className="col-00 ps-2 pb-1">
                                <InputType1
                                  label=""
                                  disabled={(serv.specificationLevel === 4 && serv.quantityEnterBy === 'TSI') || serv.isRemoved}
                                  name="quantity"
                                  id={'quantity'.concat(num)}
                                  className="small"
                                  value={serv.quantity}
                                  onChange={(e) => {
                                    onServiceFieldChange(e, mainIndex, subIndex, num);
                                  }}
                                />
                              </div>
                              <div className="col-00 ps-2 pb-1">
                                <InputType1
                                  label=""
                                  disabled={serv.specificationLevel === 4 || serv.isRemoved}
                                  name="rate"
                                  id={'rate'.concat(num)}
                                  className="small"
                                  value={!serv.rate || serv.rate === '0' ? '' : serv.rate}
                                  onChange={(e) => {
                                    onServiceFieldChange(e, mainIndex, subIndex, num);
                                  }}
                                />
                              </div>
                              <div className="col-00 ps-3  pb-1">
                                <InputType1
                                  label=""
                                  disabled={serv.specificationLevel === 4 || serv.isRemoved}
                                  name="discount"
                                  id={'discount'.concat(num)}
                                  className="small"
                                  value={!serv.discount || serv.discount === '0' ? '' : serv.discount}
                                  onChange={(e) => {
                                    onServiceFieldChange(e, mainIndex, subIndex, num);
                                  }}
                                />
                              </div>
                              <div className="ps-3 col-001 text-left-align">
                                <span data-tooltip-id="project-qttable" data-tooltip-content={formatNumber(serv.amount || '0')}>
                                  {specList.rfqData?.currencySymbol?.concat(' ', _.truncate(formatNumber(serv.amount || '0'), { length: 9 }))}
                                </span>

                              </div>
                              <div className="col-1 d-flex justify-content-end pe-4">
                                <span
                                  id={'comment'.concat(num)}
                                  className="font-22 icon-add-comment brand-color pointer me-2"
                                  onClick={() => {
                                    setAddComment(true);
                                    setCommentList(serv.commentList);
                                    const now = new Date();
                                    const isoString = now.toISOString();
                                    setComment({
                                      userId: emptyGuid,
                                      userName: 'Yard user',
                                      timeStamp: isoString,
                                      comment: null,
                                      sectionIndex: mainIndex,
                                      specIndex: subIndex,
                                      slIndex: num
                                    });
                                  }}
                                />
                                {(serv.commentList?.length) && (
                                  <span className="dot-indicator-comment" />
                                )}
                                {serv.isFromYard && !specList?.isSubmit
                                  && <span className="icon-delete font-20 red ms-4 pointer" onClick={() => { onRemoveLine(mainIndex, subIndex, num); }} />}
                              </div>
                            </div>
                          </>
                        );
                      })
                    }
                    {subsec.projectSpecId === showsubSec && !specList?.isSubmit && (
                      <div className="ps-3 d-flex my-2">
                        <div className="col-1" />
                        <div>
                          <button type="button" className="link-btn" onClick={() => onAddServiceLine(mainIndex, subIndex)}>Add Service Line</button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          );
        })}
      </div>
      {addComment && (
        <ModalBox
          modalClassName="medium"
          heading="Add Comment"
          onSave={() => onSaveComment()}
          closeModal={() => {
            setAddComment(false);
            setComment({
              userId: null,
              userName: null,
              timeStamp: null,
              comment: null,
              sectionIndex: null,
              specIndex: null,
              slIndex: null
            });
            setCommentList([]);
          }}
          buttonName="Save"
        >
          <div className="modal-sp">
            {commentList?.map((item) => {
              const msg = {
                ...item,
                name: item.userName,
                updatedAt: item.timeStamp,
                message: item.comment
              };
              if (localStorage.getItem('USER_ID') === msg?.userId || msg?.userId === emptyGuid) {
                return <SentMsg send={{ ...msg, showUserName: true }} />;
              }
              return <RecievedMsg item={msg} />;
            })}
            <div ref={messagesEndRef} />
          </div>
          <textarea
            placeholder="Type Messages here..."
            className="text-box w-full h-text-area p-3"
            onChange={(e) => {
              setComment({ ...comment, comment: e.target.value });
            }}
            value={comment.comment}
          />
        </ModalBox>
      )}
      <Tooltip id="project-qttable" place="top" className="tooltip mt-3" />
    </>
  );
};
export default QuotationTable;
