/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import helper from '../../../utils/helper';
import Loader from '../../common/loader2';
import ModalBox from '../../common/ModalBox';
import ConfirmButton from './NewProject/ConfirmPage/ConfirmButton';
import { getProjectHasApprover, sendForApproval } from './NewProject/services';
import ServiceLineModal from './PhasePage/Specify/ServiceLineModal';
import { listKfpBySpec, unmappedDeferredSpecList, GetEmptyServiceLineList } from './PhasePage/Specify/services';
import showAlert from '../../../utils/alert';

const SpecifyApproval = (props) => {
  const { id } = useParams();
  const { projInfo, projectId, setActivateSL, reloadServiceLine } = props;
  const { projectPhase } = helper;
  const [isOpened, setIsOpened] = useState(false);
  const [isPreOpened, setIsPreOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showServiceLineModalSpecify, setShowServiceLineModalSpecify] = useState(false);
  const [emptySpecListGT, setEmptySpecListGT] = useState();
  const [emptySpecListMT, setEmptySpecListMT] = useState();
  const [continuesrv, setContinueSrv] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isTsiSelected, setIsTsiSelected] = useState('');
  const [unmappedList, setUnmappedList] = useState([]);

  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);
  /* istanbul ignore file */
  const getName = (resubmit = false) => {
    let n = 'Submit';
    if (resubmit) {
      n = 'Resubmit';
    }
    if (hasApprover) {
      n += ' for Approval';
    }
    return n;
  };
  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(projectId, type)?.then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);
  const getEmptySpecificationSpecifyMT = () => {
    GetEmptyServiceLineList({ projectId, type: 2 }).then((res) => {
      if (res.success) {
        setLoading(false);
        setEmptySpecListMT(res.data);
      }
      GetEmptyServiceLineList({ projectId, type: 1 }).then((res2) => {
        if (res2.success) {
          setLoading(false);
          setSubmitClicked(false);
          setEmptySpecListGT(res2.data);
        }
        listKfpBySpec({ id: projectId }).then((resp) => {
          if (resp.success) {
            setIsTsiSelected(resp.data);
          }
          setSubmitClicked(true);
        });
      });
    });
  };

  useEffect(() => {
    if (submitClicked && emptySpecListGT && isTsiSelected) {
      if (continuesrv
        || (emptySpecListGT?.length === 0
        && emptySpecListMT?.length === 0
        && isTsiSelected?.length === 0)) {
        setIsPreOpened(true);
      } else {
        setShowServiceLineModalSpecify(true);
      }
    }
  }, [emptySpecListMT, emptySpecListGT, isTsiSelected, submitClicked, continuesrv]);

  const confirmModal = () => {
    setLoading(true);
    sendForApproval(projectId, projectPhase.SPECIFY).then((response) => {
      if (response?.success) {
        setIsOpened(true);
        setIsPreOpened(false);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleSubmitForApproval = () => {
    unmappedDeferredSpecList({ id }).then((resp) => {
      if (resp.success) {
        setUnmappedList(resp.data);
      }
      if (resp.data.length > 0) {
        showAlert('Please map/delete the open deferred specs to continue', 'error');
      } else {
        setContinueSrv(false);
        getEmptySpecificationSpecifyMT();
        setSubmitClicked(true);
        setEmptySpecListGT('');
        setIsTsiSelected('');
      }
    });
  };
  return (
    <>
      <div data-testid="s-a">
        <button
          type="button"
          className="primary-btn large mr-10"
          id="spec-download"
          onClick={() => setIsPreOpened(true)}
        >
          {projInfo?.specApprovalStatus === 3 ? getName(true) : getName()}
        </button>
        {isOpened ? (
          <ModalBox isCloseModal={false} modalClassName="medium modal-scroll">
            {loading ? (
              <Loader />
            ) : (
              <div className="text-black">
                <ConfirmButton projectId={projectId} showCreateMsg="" name={projInfo?.name} nameTooltip={projInfo?.nameTooltip} projectDetails={projInfo} />
              </div>
            )}
          </ModalBox>
        ) : (
          ''
        )}
        {isPreOpened ? (
          <ModalBox isCloseModal={false} modalClassName="medium modal-scroll">
            {loading ? (
              <Loader />
            ) : (
              <div className="d-flex justify-content-center flex-column align-items-center" data-testid="preconfirmbuttonpage">
                <div className="head-18 pb-4">
                  <span
                    data-tooltip-id="project-specify"
                    data-tooltip-content={projInfo?.nameTooltip}
                    className="white-space-preserve"
                  >
                    {' '}
                    {projInfo?.name}

                  </span>

                </div>
                <div className="mt-3">
                  {hasApprover ? <p className="font-15 font-semibold text-black">Project will be sent for next level of approval</p>
                    : ''}
                </div>
                <span className="head-18">
                  {`On ${projInfo?.specApprovalStatus === 3 ? 're' : ''}submitting, the Specify phase will be closed and
                  project will move to tender phase.
                  Do you want to continue?`}
                </span>

                <div className="d-flex">
                  <button
                    type="button"
                    className="secondary-btn large m-3 p-3"
                    id="backtoprojects"
                    onClick={() => {
                      setIsPreOpened(false);
                      reloadServiceLine();
                    }}
                  >
                    Cancel
                  </button>
                  <button disabled={loading} type="button" className="primary-btn large m-3 p-3" id="save" onClick={() => confirmModal()}>
                    {`${projInfo?.specApprovalStatus === 3 ? 'Resubmit' : 'Confirm'}`}
                  </button>
                </div>
              </div>
            )}
          </ModalBox>
        ) : (
          ''
        )}
        {showServiceLineModalSpecify && (
        <ServiceLineModal
          setShowServiceLineModalSpecify={setShowServiceLineModalSpecify}
          emptySpecListGT={emptySpecListGT || []}
          emptySpecListMT={emptySpecListMT || []}
          setIsPreOpened={setIsPreOpened}
          setContinueSrv={setContinueSrv}
          isTsiSelected={isTsiSelected || []}
          setIsTsiSelected={setIsTsiSelected}
          setActivateSL={setActivateSL}
          unmappedList={unmappedList}
        />
        )}

        <Tooltip id="project-specify" place="top" className="tooltip mt-3" />
      </div>
    </>
  );
};
export default SpecifyApproval;
