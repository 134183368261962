import React from 'react';
import moment from 'moment';
import userImg from '../../../../images/user-placeholder.jpg';

const SentMsg = (props) => {
  const { send } = props;
  return (
    <div className="d-flex w-full justify-content-end col-gap-1 pb-4" data-testid="sent-msg">
      <div>
        <div className="card p-4 bg-sky-white font-14">
          <p className="value pb-2">
            {/* {send?.name} */}
            {send.showUserName ? send.name : 'Me'}
          </p>
          <p className="left-word-break ">{send?.message}</p>
        </div>
        <div className="d-flex justify-content-end font-13 mt-3">
          {moment.utc(send?.updatedAt).format(' DD-MMM-YYYY HH:mm:ss')}
          (UTC)
        </div>
      </div>
      <div className="col-2 width-fit ">
        {send.profileImageUrl ? (
          <img src={send?.profileImageUrl} className="thmb-md ship-thumb" alt="" />
        ) : (
          <img src={userImg} alt="" className="thmb-md ship-thumb" />
        )}
      </div>
    </div>
  );
};

export default SentMsg;
