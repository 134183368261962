/* eslint-disable no-unused-vars */
/* istanbul ignore file */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import brandLogo from '../../images/brand-logo.svg';
import { apiLogin, termsAndConditions } from './services';
import showAlert from '../../utils/alert';
import helper from '../../utils/helper';
import Caption from './common/caption';
import Conditions from '../Terms';
import ModalBox from '../common/ModalBox';

const login = () => {
  const { loginSetUP, roles } = helper;
  const navigate = useNavigate();
  const location = window.location.pathname;
  const initialState = {
    userName: '',
    passWord: ''
  };
  const [state, setstate] = useState(initialState);
  const [remember, setRemember] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginProcess, setIsLoginProcess] = useState(false);

  const SSORedirect = process.env.REACT_APP_DRYD_SSO_REDIRECT_URL;

  const adminLandingUrl = '/admin/dashboard';
  const tsiLandingUrl = '/user/dashboard';

  function encryptPassword(password) {
    const secretKey = 'DryDockKey';
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  }

  function decryptPassword(encryptedPassword) {
    const secretKey = 'DryDockKey';
    const decrypted = CryptoJS.AES.decrypt(encryptedPassword, secretKey);
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  const redirectToLanding = (value) => {
    const userRole = value?.toLowerCase();
    if (userRole === roles.ADMIN) {
      navigate(adminLandingUrl);
    } else if (userRole === roles.AUTHOR
      || userRole === roles.EDITOR
      || userRole === roles.VIEWER
      || userRole === roles.VESSEL_USER) {
      navigate(tsiLandingUrl);
      localStorage.setItem('isFirst', 'true');
    } else {
      localStorage.clear();
      showAlert('User does not have access rights to login to the screen', 'error');
    }
  };

  const redirectActions = (user) => {
    if (user) {
      loginSetUP(user);
      redirectToLanding(user.role);
      if (remember) {
        localStorage.setItem('USERNAME', state.userName);
        localStorage.setItem('PASSWORD', encryptPassword(state.passWord));
        localStorage.setItem('REMEMBER', 'REMEMBER');
      } else {
        localStorage.removeItem('USERNAME');
        localStorage.removeItem('PASSWORD');
        localStorage.removeItem('REMEMBER');
      }
    }
    if (localStorage.getItem('redirectURL')) {
      navigate(localStorage.getItem('redirectURL'),
        { state: { from: 'Comparequotes', redirect: true } });
      localStorage.removeItem('redirectURL');
    }
  };

  const acceptTerms = () => {
    const uId = localStorage.getItem('USER_ID');
    loginSetUP(openModal);
    termsAndConditions(uId);
    redirectActions(openModal);
    setOpenModal(false);
  };

  function encrypt(input) {
    const salt = 'DryDock';
    const hmac = CryptoJS.HmacSHA256(input, salt);
    return hmac.toString(CryptoJS.enc.Hex);
  }

  const handleLogin = () => {
    if (state && state.userName && state.passWord) {
      setIsLoginProcess(true);
      const payload = {
        userName: state.userName,
        passWordHash: encrypt(state.passWord)
      };
      apiLogin(payload).then((res) => {
        localStorage.setItem('USER_ID', res.data.userId);
        setIsLoginProcess(false);
        if (res.success) {
          if (res.data?.licenceMessage) {
            showAlert(res.data?.licenceMessage, 'warning-without-autoclose');
          }
          if (!res?.data?.isTermsAgreed) {
            setOpenModal(res.data);
          } else {
            const user = res.data;
            redirectActions(user);
          }
        }
      }).catch(() => {
        setIsLoginProcess(false);
      });
    } else {
      showAlert('Please enter vaild username and password', 'error');
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleChange = (e, field) => {
    const fd = cloneDeep(state);
    const trimmedValue = e.target.value.replace(/\s+/g, '');
    fd[field] = trimmedValue;
    setstate(fd);
  };

  useEffect(() => {
    if (localStorage.getItem('USER_ID') && localStorage.getItem('USER_ROLE') && localStorage.getItem('USER_TOKEN')) {
      const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
      redirectToLanding(userRole);
    } else {
      const uName = localStorage.getItem('USERNAME');
      const uPassword = localStorage.getItem('PASSWORD');
      if (uName && uPassword) {
        const d = {
          userName: uName || '',
          passWord: decryptPassword(uPassword) || ''
        };
        setstate(d);
        const REMEMBER = localStorage.getItem('REMEMBER');
        if (REMEMBER === 'REMEMBER') {
          setRemember(true);
        }
      }
    }
  }, []);

  return (
    <div data-testid="loginpage relative">
      <div className="wrapper login-wrap ">
        <Caption />
        <div className="card login-card">
          <div className="brand-logo mb-4">
            <a href="#">
              <img src={brandLogo} alt="Maridock Logo" className="brand-img" />
            </a>
          </div>

          <h4 className="main-head">Log in</h4>
          <p className="mb-5 mt-2">Welcome to Dry Docking solution. Please enter your credentials to start using the app. </p>

          <div className="mb-5 d-flex flex-column">
            <label htmlFor="" className="mb-1">
              Username
            </label>
            <input
              id="userName"
              autoFocus
              value={state.userName}
              onChange={(e) => handleChange(e, 'userName')}
              type="text"
              className="text-box xlarge"
            />
          </div>

          <div className="mb-5 mt-5 d-flex flex-column">
            <label htmlFor="" className="mb-1">
              Password
            </label>
            <div className="relative">
              <input
                id="passWords"
                value={state.passWord}
                onKeyPress={handleEnterPress}
                onChange={(e) => handleChange(e, 'passWord')}
                type={showPassword ? 'text' : 'password'}
                className="text-box xlarge w-full"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="link-btn login-visible-btn"
                id="showPassword"
              >
                {showPassword ? <span className="icon-eye-close font-24" /> : <span className="icon-eye font-24" />}
              </button>
            </div>

          </div>

          <div className="d-flex flex-row justify-content-between my-4">
            <div className="check-box">
              <input
                type="checkbox"
                id="Remember"
                name=""
                value="Remember"
                checked={remember}
                onClick={() => { setRemember(!remember); }}
              />
              <label htmlFor="Remember"> Remember me</label>
            </div>
            <button type="button" className="link-btn" onClick={() => navigate('/forget-password')}>
              <span className={location.includes('/forget-password') ? 'active' : ''} />
              Forgot Password?
            </button>
          </div>

          <button
            disabled={isLoginProcess}
            id="login"
            className="primary-btn xlarge full-width my-4"
            onClick={() => handleLogin()}
            type="button"
          >
            Login
          </button>
          {/* <div className="or-serator">or</div>
          <a
            href={SSORedirect}
            type="button"
            className="primary-btn office-btn xlarge full-width my-2"
          >
            <span className="icon-office360 font-26 me-4" />
            Office 365
          </a> */}
        </div>
      </div>
      {openModal ? (
        <ModalBox
          modalClassName="medium"
          heading="Terms and Conditions"
          isCloseModal={false}
        >
          <Conditions
            token={openModal?.token}
            acceptTerms={acceptTerms}
            setOpenModal={setOpenModal}
          />
        </ModalBox>
      ) : (
        ''
      )}
      <div className="footer"><div className="container login-footer">{`© ${new Date().getFullYear()} ThinkPalm`}</div></div>
    </div>
  );
};
export default login;
