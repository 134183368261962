/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { MdRestore } from 'react-icons/md';
import _ from 'lodash';
import AreaLoader from '../../../../../common/areaLoader';
import ModalBox from '../../../../../common/ModalBox';
import RecievedMsg from '../../../ChatScreen/recievedMsg';
import SentMsg from '../../../ChatScreen/sentMsg';
import helper from '../../../../../../utils/helper';

const Table1Container = (props) => {
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles.ADMIN;
  const isTSI = userRole === roles.AUTHOR
    || userRole === roles.EDITOR
    || userRole === roles.ADMIN;
  const { specList, mainSec, showsubSec, onSecOpen, setShowMore, specClick, serviceClick, serviceLineIds,
    setDeleteModal, setRestoreModal, enableDelete, enableRestore, projectBasicDetails, onSaveSLComments } = props;
  const [areaLoaders, setAreaLoader] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState({
    projectId: null,
    specId: null,
    serviceLineId: null,
    projectRfqId: null,
    comment: null
  });
  useEffect(() => {
    setAreaLoader(true);
    setTimeout(() => {
      setAreaLoader(false);
    }, 1000);
  }, []);
  const handleAssignedTo = (subsec) => {
    if (subsec.specAssigned === 1) {
      return 'Y';
    }
    if (subsec.specAssigned === 2) {
      return 'V';
    }
    if (subsec.specAssigned === 3) {
      return 'S';
    }
    return '';
  };
  const onSaveComment = (data) => {
    onSaveSLComments(data);
    setAddComment(false);
    setComment({
      projectId: null,
      specId: null,
      serviceLineId: null,
      projectRfqId: null,
      comment: null
    });
    setCommentList([]);
  };

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [addComment]);

  return (
    <>
      <div className="spc-map ps-3">
        <div className="d-flex border-down py-2 ps-3">
          <div className="col-1 font-bold">SEC#</div>
          <div className="col-8 font-bold">SPEC SECTION</div>
          <div className="col-3 font-bold text-left-align">TOTAL</div>
        </div>
        {areaLoaders ? (
          <div className="d-flex justify-content-center mt-4">
            <AreaLoader />
          </div>
        ) : (
          <>
            {specList?.sectionData?.length < 1 && <div className="d-flex justify-content-center mt-4 opacity-6">No data found</div>}
            {specList.sectionData?.map((sec) => {
              return (
                <div className={sec.specificationNo === 'DEF' ? 'd-none' : ''}>
                  <div key={sec} className={sec.projectSpecId === mainSec && showsubSec ? 'highlight-tender' : ''}>
                    <div className="d-flex border-down py-2 ps-3">
                      <div className="col-1 d-flex align-items-center">
                        <span
                          id={sec.projectSpecId}
                          data-test="secOpen"
                          className={
                            sec.projectSpecId === mainSec
                              ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180'
                              : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                          }
                          onClick={() => {
                            onSecOpen(sec.projectSpecId, true);
                          }}
                        />
                        <span data-tooltip-id="project-sec" data-tooltip-content={sec.specificationNo}>
                          {_.truncate(sec.specificationNo, { length: 7 })}
                        </span>
                      </div>
                      <div className="col-8">
                        <span data-tooltip-id="project-sec" data-tooltip-content={sec.title}>
                          {_.truncate(sec.title, { length: 40 })}
                        </span>
                      </div>

                      <Tooltip id="project-sec" place="top" className="tooltip mt-3" />
                      <div className="col-3 text-left-align">
                        {specList.currencySymbol}
                        {' '}
                        0
                      </div>
                    </div>
                    {sec.projectSpecId === mainSec
                      && sec.specDataList.map((subsec) => {
                        return (
                          <>
                            <div key={subsec} className="d-flex mb-2 py-2 ps-3">
                              <div className="col-1" />
                              <div className={!subsec.serviceLineList.length || projectBasicDetails?.phase > 3 || !isTSI ? 'check-box align-items-center disabled-field' : 'check-box align-items-center'}>
                                <input
                                  type="checkbox"
                                  id={subsec.projectSpecId}
                                  data-test="spec-check"
                                  className="me-2 pointer"
                                  disabled={!subsec.serviceLineList.length || projectBasicDetails?.phase > 3 || !isTSI}
                                  checked={serviceLineIds.activeSpecId === subsec.projectSpecId && subsec.serviceLineList.length === serviceLineIds.serviceLineList.length}
                                  onClick={(e) => { specClick(e, sec.projectSpecId, subsec.projectSpecId); }}
                                />
                                <label htmlFor={subsec.projectSpecId} />
                              </div>
                              <div className="col-11 d-flex align-items-center">
                                <span
                                  id={subsec.projectSpecId}
                                  data-test="subSecOpen"
                                  className={
                                    subsec.projectSpecId === showsubSec
                                      ? 'font-14 icon-sort-up brand-color pointer me-2 rotate-180'
                                      : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'
                                  }
                                  onClick={() => {
                                    onSecOpen(subsec.projectSpecId, false);
                                  }}
                                />
                                <div className="d-flex col-7">
                                  <span className="me-4" data-tooltip-id="project-sec" data-tooltip-content={subsec.specificationNo}>
                                    {_.truncate(subsec.specificationNo, { length: 6 })}
                                  </span>
                                  <div className="" data-tooltip-id="project-sec" data-tooltip-content={subsec.title}>
                                    {_.truncate(subsec.title, { length: 80 })}
                                  </div>
                                </div>
                                <div className="col-3 d-flex justify-content-end">
                                  {subsec.projectSpecId === showsubSec && (
                                    <div className="col-5 d-flex justify-content-end">
                                      <button
                                        type="button"
                                        id="viewmore"
                                        className="primary-btn small"
                                        onClick={() => {
                                          setShowMore(subsec);
                                        }}
                                      >
                                        View More
                                      </button>
                                    </div>
                                  )}
                                </div>
                                <div className="col-02 d-flex justify-content-end align-items-center gap-1">
                                  <div className={subsec.projectSpecId === showsubSec ? 'bg-sky-blue px-2 cursor-auto' : 'bg-sky-blue px-2 cursor-auto'}>
                                    {handleAssignedTo(subsec)}
                                  </div>
                                  <button
                                    type="button"
                                    id="restore"
                                    className={!enableDelete || !subsec?.isSelected ? 'link-btn disabled' : 'link-btn'}
                                    data-tooltip-id="project-sec"
                                    disabled={!enableDelete || !subsec?.isSelected}
                                    data-tooltip-content="Restore Service Line(s)"
                                    onClick={() => { setRestoreModal(true); }}
                                  >
                                    <MdRestore fontSize={24} />
                                  </button>
                                  <button
                                    type="button"
                                    id="delete"
                                    className={!enableRestore || !subsec?.isSelected ? 'link-btn d-flex align-items-center disabled' : 'link-btn d-flex align-items-center'}
                                    data-tooltip-id="project-sec"
                                    data-tooltip-content="Delete Service Line(s)"
                                    disabled={!enableRestore || !subsec?.isSelected}
                                    onClick={() => { setDeleteModal(true); }}
                                  >
                                    <span className="icon-delete font-24" />
                                  </button>
                                </div>
                              </div>
                            </div>
                            {subsec.projectSpecId === showsubSec
                              && subsec.serviceLineList.map((serv, num) => {
                                return (
                                  <>
                                    {num === 0 && (
                                      <div key={serv} className="d-flex ps-3 align-items-center">
                                        <div className="col-1" />
                                        <div className="w-2 bg-sky-white ps-3 border-down py-2">&nbsp;&nbsp;&nbsp;</div>
                                        <div className="col-1 bg-sky-white ps-3 border-down py-2">Service LN#</div>
                                        <div className="col-03 bg-sky-white ps-3 border-down py-2">Service Lines</div>
                                        <div className="col-1 bg-sky-white ps-3 border-down py-2">AC Code</div>
                                        <div className="col-1 bg-sky-white ps-3 border-down py-2">Qty</div>
                                        <div className="col-1 bg-sky-white ps-3 border-down py-2">UOM</div>
                                        <div className="col-1 bg-sky-white ps-3 border-down py-2 text-right">Rate</div>
                                        <div className="col-1 bg-sky-white ps-1 border-down py-2 text-center">Disc[%]</div>
                                        <div className="col-1 bg-sky-white ps-2 border-down py-2 text-left-align">Amount</div>
                                        <div className="col-1 bg-sky-white ps-3 border-down py-2 text-right px-2">Comment</div>
                                      </div>
                                    )}
                                    <div className={serv.isRemoved ? 'd-flex pb-2 ps-3 py-1 removed-SL' : 'd-flex pb-2 ps-3 py-1'}>
                                      <div className="col-1" />
                                      <div className={projectBasicDetails?.phase > 3 || !isTSI ? 'w-2 check-box align-items-center disabled-field' : 'w-2 check-box align-items-center'}>
                                        <input
                                          type="checkbox"
                                          id={serv.id}
                                          data-test="serv-check"
                                          className="me-2 pointer"
                                          disabled={projectBasicDetails?.phase > 3 || !isTSI}
                                          checked={serviceLineIds.serviceLineList.indexOf(serv.id) > -1}
                                          onClick={(e) => {
                                            serviceClick(e, sec.projectSpecId, subsec.projectSpecId, serv.id);
                                          }}
                                        />
                                        <label htmlFor={serv.id} />
                                      </div>
                                      <div className="col-1 ps-3 border-down" data-tooltip-id="project-sec" data-tooltip-content={serv.number}>
                                        {serv.number}
                                      </div>
                                      <div className="col-03 ps-3 text-justify text-break-all border-down">
                                        <span data-tooltip-id="project-sec" data-tooltip-content={serv.serviceLineName}>
                                          {_.truncate(serv.serviceLineName, { length: 40 })}
                                        </span>
                                      </div>
                                      <div className="col-1 ps-3 border-down">
                                        <span data-tooltip-id="project-sec" data-tooltip-content={serv.acCode}>
                                          {_.truncate(serv.acCode, { length: 10 })}
                                        </span>
                                      </div>
                                      <div className="col-1 ps-3 border-down">
                                        <span data-tooltip-id="project-sec" data-tooltip-content={serv.quantity}>
                                          {_.truncate(serv.quantity, { length: 10 })}
                                        </span>
                                      </div>
                                      <div className="col-1 ps-3 border-down">
                                        <span data-tooltip-id="project-sec" data-tooltip-content={serv.uomName}>
                                          {_.truncate(serv.uomName, { length: 10 })}
                                        </span>
                                      </div>
                                      <div className="col-1 ps-3 border-down text-right">
                                        <span data-tooltip-id="project-sec" data-tooltip-content={serv.rate} />
                                        {_.truncate(serv.rate, { length: 10 })}
                                      </div>
                                      <div className="col-1 ps-1 border-down text-center">
                                        <span data-tooltip-id="project-sec" data-tooltip-content={serv.discount}>
                                          {serv.discount}
                                        </span>
                                      </div>
                                      <div className="col-1 ps-2 border-down text-left-align">
                                        <span data-tooltip-id="project-sec" data-tooltip-content={serv.amount}>
                                          {_.truncate(serv.amount, { length: 10 })}
                                        </span>
                                      </div>
                                      <div className="col-1 ps-2 border-down d-flex justify-content-end">
                                        {(isAdmin || isTSI) && (
                                          <>
                                            <span
                                              className="font-22 icon-add-comment pe-2 brand-color pointer"
                                              onClick={() => {
                                                setAddComment(true);
                                                setCommentList(serv.commentList);
                                                setComment({
                                                  projectId: specList.projectId,
                                                  specId: subsec.projectSpecId,
                                                  serviceLineId: serv.id,
                                                  projectRfqId: subsec.projectReceivedQuoteId,
                                                  comment: null
                                                });
                                              }}
                                            />
                                            {(serv.commentList?.length > 0) && (
                                              <span className="dot-indicator-comment" />
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </>
        )}
        <Tooltip id="project-sec" place="top" className="tooltip mt-3" />
      </div>
      {addComment && (
        <ModalBox
          modalClassName="medium"
          heading="Add Comment"
          onSave={() => onSaveComment(comment)}
          closeModal={() => {
            setAddComment(false);
            setComment({
              projectId: null,
              specId: null,
              serviceLineId: null,
              projectRfqId: null,
              comment: null
            });
            setCommentList([]);
          }}
          buttonName="Save"
        >
          <div className="modal-sp">
            {commentList?.map((item) => {
              const msg = {
                ...item,
                name: item.userName,
                updatedAt: item.timeStamp,
                message: item.comment
              };
              if (localStorage.getItem('USER_ID') === msg?.userId) {
                return <SentMsg send={{ ...msg, showUserName: true }} />;
              }
              return <RecievedMsg item={msg} />;
            })}
            <div ref={messagesEndRef} />
          </div>
          <textarea
            disabled={specList?.variationQuoteStatus === 4}
            placeholder="Type Messages here..."
            className="text-box w-full h-text-area pt-3 mt-3"
            onChange={(e) => {
              setComment({ ...comment, comment: e.target.value });
            }}
            value={comment.comment}
          />
        </ModalBox>
      )}
    </>
  );
};
export default Table1Container;
