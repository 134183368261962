/* istanbul ignore file */
import React from 'react';
import SidePanel from '../../../../../common/sidePanel';
import SendSupplementarySpecs from './SendSupplementary';

const EmailPreview = (props) => {
  const { showEmailPreview, setShowEmailPreview, yardMailList, previewList } = props;
  return (
    <div data-testid="supplementary-email-preview">
      <SidePanel
        data-testid="EmailPreview"
        title="Supplementary Spec Email Preview"
        size="large"
        showView={showEmailPreview}
        onClose={() => setShowEmailPreview(false)}
        bgColor="#fff"
      >

        <SendSupplementarySpecs
          yardMailList={yardMailList}
          previewList={previewList}
        />

        <footer className="side-panel__footer" data-testid="EmailPreview">
          <>
            <button
              type="button"
              className="primary-btn me-4 large"
              onClick={() => setShowEmailPreview(false)}
            >
              Close
            </button>
          </>
        </footer>
      </SidePanel>
    </div>
  );
};
export default EmailPreview;
