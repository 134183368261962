/* eslint-disable no-unused-vars */
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import SidePanel from '../../../common/sidePanelCustom';
import SpecCard from './SpecCard';
import helper from '../../../../utils/helper';
import { getSpecListForDownload } from '../PhasePage/Repair/services';
import { getProjectDetailsTSI } from '../NewProject/services';
import DownloadConfirmModal from '../PhasePage/Specify/downloadConfirmModal';
import { specificationPdfExport } from '../PhasePage/Specify/services';
import showAlert from '../../../../utils/alert';

const SpecDownload = () => {
  const { roles } = helper;
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [specList, setSpecList] = useState([]);
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [projectCurrecySymbol, setProjectCurrencySymbol] = useState('');
  const [specToDownload, setSpecToDownload] = useState([]);
  const [sectionToDownload, setSectionToDownload] = useState([]);
  const [downloadConfirmModal, setDownloadConfirmModal] = useState('');
  const [isDownload, setIsDownload] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isCheckedWo, setIsCheckedWo] = useState(true);
  const [isCheckedTC, setIsCheckedTC] = useState(true);
  const [isCheckedSL, setIsCheckedSL] = useState(true);
  const [isCheckedVS, setIsCheckedVS] = useState(true);
  const [downloadWithWorkOrders, setDownloadWithWorkOrders] = useState(false);
  const [downloadWithPOOrders, setDownloadWithPOOrders] = useState(false);
  const [downloadWithTCOrders, setDownloadWithTCOrders] = useState(false);
  const [downloadWithSLOrders, setDownloadWithSLOrders] = useState(false);
  const [downloadWithVSOrders, setDownloadWithVSOrders] = useState(false);
  const handleWorkOrdersCheckboxChange = () => {
    setDownloadWithWorkOrders(!downloadWithWorkOrders);
    setIsCheckedWo(!isCheckedWo);
  };
  const handlePoCheckboxChange = () => {
    setDownloadWithPOOrders(!downloadWithPOOrders);
    setIsChecked(!isChecked);
  };

  const handleTCCheckboxChange = () => {
    setDownloadWithTCOrders(!downloadWithTCOrders);
    setIsCheckedTC(!isCheckedTC);
  };

  const handleSLCheckboxChange = () => {
    setDownloadWithSLOrders(!downloadWithSLOrders);
    setIsCheckedSL(!isCheckedSL);
  };

  const handleVSCheckboxChange = () => {
    setDownloadWithVSOrders(!downloadWithVSOrders);
    setIsCheckedVS(!isCheckedVS);
  };
  const setDownloadChecked = () => {
    setDownloadWithWorkOrders(false);
    setDownloadWithPOOrders(false);
    setDownloadWithTCOrders(false);
    setDownloadWithSLOrders(false);
    setDownloadWithVSOrders(false);
    setIsCheckedWo(true);
    setIsChecked(true);
    setIsCheckedTC(true);
    setIsCheckedSL(true);
    setIsCheckedVS(true);
  };
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR || userRole === roles.EDITOR
    || userRole === roles?.ADMIN || userRole === roles.VESSEL_USER;

  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  const getSpecListData = () => {
    setIsLoading(true);
    getSpecListForDownload({ id, type: '' }).then((resp) => {
      setIsLoading(false);
      if (resp.success) {
        setSpecList(resp.data);
      }
    });
  };
  useEffect(() => {
    getProjectDetailsTSI({ id }).then((res) => {
      setProjectCurrencySymbol(res?.data?.currencySymbol);
    });
    getSpecListData();
  }, []);
  const handleContinueInDownload = () => {
    const payload = {
      projectId: id,
      specIds: specToDownload,
      isPoNotNeeded: downloadWithPOOrders,
      isWoNotNeeded: downloadWithWorkOrders,
      isTermsAndConditionsNotNeeded: downloadWithTCOrders,
      isServiceLineNotNeeded: downloadWithSLOrders,
      isVesselAssignedSpecNotNeeded: downloadWithVSOrders
    };
    setIsDownload(true);
    setDownloadConfirmModal(false);
    specificationPdfExport(payload).then((resp) => {
      showAlert(`${resp.data}`, 'success');
      setIsDownload(false);
      setSpecToDownload([]);
      setSectionToDownload([]);
    });
  };
  return (
    <div data-testid="sp-download">
      <div className="sub-header z-index-998">
        <div className="d-flex  col-gap-3">
          <div className="d-flex justify-content-start align-items-center">
            <button disabled={window.history?.length < 3} className="link-btn ms-5" type="button" onClick={() => window.history.back()}>
              <span className="icon-arrow-left2 me-2" />
              Back
            </button>
            <h2 className="main-head me-5 ms-5">Download Specs</h2>
          </div>
        </div>
      </div>
      <div className="content-wrap mt-4 pb-s4">
        <div className="container-fluid card-table ">
          {' '}
          <SpecCard
            isLoading={isLoading}
            isTSI={isTSI}
            specList={specList}
            setSpecList={setSpecList}
            mainSec={mainSec}
            showsubSec={showsubSec}
            onSecOpen={onSecOpen}
            projectId={id}
            projectCurrecySymbol={projectCurrecySymbol}
            specToDownload={specToDownload}
            setSpecToDownload={setSpecToDownload}
            sectionToDownload={sectionToDownload}
            setSectionToDownload={setSectionToDownload}
          />
        </div>
      </div>
      <div className="fixed-footer card d-flex justify-content-end">
        <div className="text-black font-12 col-6">
          <div className=" footer-jobprogress">{`© ${new Date().getFullYear()} ThinkPalm`}</div>

        </div>
        <button
          type="button"
          disabled={isDownload}
          className="secondary-btn large mr-10"
          id="specs-download"
          onClick={() => {
            setDownloadChecked();
            setDownloadConfirmModal(true);
          }}
        >
          Download Specs
        </button>
      </div>
      {downloadConfirmModal && (
        <DownloadConfirmModal
          setDownloadConfirmModal={setDownloadConfirmModal}
          handleContinueInDownload={handleContinueInDownload}
          isDownload={isDownload}
          handleWorkOrdersCheckboxChange={handleWorkOrdersCheckboxChange}
          handlePoCheckboxChange={handlePoCheckboxChange}
          handleTCCheckboxChange={handleTCCheckboxChange}
          handleSLCheckboxChange={handleSLCheckboxChange}
          handleVSCheckboxChange={handleVSCheckboxChange}
          isChecked={isChecked}
          isCheckedWo={isCheckedWo}
          isCheckedTC={isCheckedTC}
          isCheckedSL={isCheckedSL}
          isCheckedVS={isCheckedVS}
        />
      )}
    </div>
  );
};
export default SpecDownload;
