/* eslint-disable no-use-before-define */
/* istanbul ignore file */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _, { toNumber } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import qs from 'query-string';
import { useNavigate } from 'react-router-dom';
import Loader from '../../common/loader2';
import {
  projectSpecList, saveQuote, updateQuoteStatus, currencyList, getPublishUomList, quoteExcelExport,
  downloadDocuments, getRfqVersion
} from './service';
import ContentWrap from './children/contentWrap';
import QuoteFooter from './children/quoteFooter';
import showAlert from '../../../utils/alert';
import PageHeader from './children/pageHeader';
import CardSeletion from './cardSelection';
import Agreement from './Agreement';
import SidePanel from '../../common/sidePanel';
import ViewMore from './ViewMore';
import Attachments from './Attachments';
import helper from '../../../utils/helper';
import ModalBox from '../../common/ModalBox';
import SubmitConfirm from './children/submitConfirm';
import AddServiceLine from './AddServiceLine';
import { UpdateCalculations, UpdateServiceNumbers } from './children/totalEstimate';
import ExitPage from './Agreement/exitPage';
import DownloadConfirmModal from './DownloadConfirmationModal';
import PolicyFooter from '../../Admin/policyFooter';
import PrivacyPolicy from '../../Policies/privacy';
import TermsOfUse from '../../Policies/TermsOfUse';

let isAccepted = 0;
const YardQuotations = ({ variation }) => {
  const navigate = useNavigate();
  const [specList, setSpecList] = useState({});
  const [mainSec, setMainSec] = useState('');
  const [isDownloadQuote, setIsDownloadQuote] = useState(false);
  const [showsubSec, setShowSubSec] = useState('');
  const [viewPage, setViewPage] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [showAttach, setShowAttach] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCurrency, setAllCurrency] = useState([]);
  const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);
  const [addServiceLine, setAddServiceLine] = useState(false);
  const [newServiceData, setNewServiceData] = useState({});
  const [uomList, setUomList] = useState([]);
  const [saveClicked, setSaveClicked] = useState(false);
  const [statusState, setStatusState] = useState();
  const [loadings, setLoadings] = useState(false);
  const [downloadConfirmModal, setDownloadConfirmModal] = useState('');
  const [isDownload, setIsDownload] = useState(false);
  const { getDropDownOptions } = helper;
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const params = qs.parse(window.location.search);
  const [modalBox, setModalBox] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(false);

  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  const getSpecList = () => {
    setLoading(true);
    sessionStorage.removeItem('version');
    projectSpecList({ key: params.key }).then((res) => {
      if (res.success) {
        setLoading(false);
        sessionStorage.setItem('version', res?.data?.version);
        setStatusState(res.data.rfqData.status);
        setIsManual(res.data.rfqData.isManual);
        setSpecList(res.data);
        currencyList(res.data?.projectId).then((currency) => {
          if (currency.success) {
            setAllCurrency(getDropDownOptions(currency.data, 'id', 'currencyName'));
          }
        });
        getPublishUomList(res.data?.projectId).then((uom) => {
          if (uom.success) {
            setUomList(getDropDownOptions(uom.data, 'uomId', 'uomName'));
          }
        });
        if (res.data.rfqData.status === 3) {
          navigate('/ExitPage');
        } else if (isAccepted !== 2) {
          if (res.data.rfqData.isManual) {
            setViewPage(1);
            renderPageContent(1);
          } else if ((res.data.rfqData.status === 5 || res.data.rfqData.status === 4)
              || (res.data.rfqData.status === 2 && !res.data.rfqData.isManual)) {
            setViewPage(3); // 3
          } else if (res.data.rfqData.status === 5) {
            setViewPage(3);
          } else {
            setViewPage(1);
          }
        }
      } else {
        setLoading(false);
        setSpecList({ rfqData: { status: 4, currencySymbol: '', statusFailed: true } });
        setViewPage(3);
      }
    }).catch(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    getSpecList();
  }, []);
  const onCancel = () => {
    getSpecList();
  };
  const onAddServiceLine = (index1, index2) => {
    setAddServiceLine({ index1, index2 });
  };
  const updateAgreementStatus = (status, a) => {
    if (!isManual) {
      isAccepted = status;
    }
    setStatusState(status);
  };
  const onSave = (isSubmit, showmsg = true) => {
    const a = {
      key: params.key,
      receivedQuotes: [],
      rfqData: specList.rfqData,
      isSubmit
    };
    if (!showmsg) {
      a.rfqData.status = statusState;
    }
    specList?.sectionData?.map((sub) => {
      const b = {
        projectSpecId: sub.projectSpecId,
        projectRfqId: specList.rfqData.id,
        projectId: specList.projectId,
        title: sub.title,
        specLevel: sub.specificationLevel,
        specNo: sub.specificationNo,
        type: sub.type,
        total: sub.total.toString(),
        parentSpec: sub.parentSpecificationId,
        specDocuments: sub.specDocuments,
        id: sub.projectReceivedQuoteId
      };
      a.receivedQuotes.push(b);
      sub.specDataList.map((ser) => {
        const c = {
          projectSpecId: ser.projectSpecId,
          serviceLineQuote: ser.serviceLineList,
          title: ser.title,
          specLevel: ser.specificationLevel,
          specNo: ser.specificationNo,
          type: ser.type,
          total: ser.total.toString(),
          parentSpec: ser.parentSpecificationId,
          specDocuments: ser.specDocuments,
          id: ser.projectReceivedQuoteId
        };
        a.receivedQuotes.push(c);
        return null;
      });
      return null;
    });
    saveQuote(a).then((res) => {
      if (res.success) {
        setSaveClicked(true);
        getSpecList();
        if (showmsg) {
          if (isSubmit) {
            setShowSubmitConfirm(2);
          } else {
            showAlert('Successfully saved as draft', 'success');
          }
        }
        setIsOpenModal(false);
      }
    });
  };
  const downloadQuote = () => {
    setIsDownloadQuote(true);
    quoteExcelExport(specList?.rfqData?.id).then((res) => {
      setIsDownloadQuote(false);
      if (res.success) {
        showAlert('Successfully downloaded', 'success');
      }
    });
  };

  const onDownload = () => {
    setDownloadConfirmModal(true);
  };
  /* istanbul ignore next */
  const handleContinueInDownload = () => {
    downloadDocuments({ key: params.key, isManual: true }).then((res) => {
      showAlert(`${res.data}`, 'success');
      setLoadings(false);
    });
    updateQuoteStatus({ key: params.key, status: statusState, isManual }).then((res) => {});
    setDownloadConfirmModal(false);
  };
  const onSubmitQuote = (isSubmit) => {
    if (isSubmit) {
      setShowSubmitConfirm(1);
    } else {
      onSave(isSubmit);
    }
  };
  const onContiue = (pg, type) => {
    setViewPage(pg);
    if (type !== 'empty') {
      setIsManual(type);
    }
  };

  const navigateToForm = () => {
    setViewPage(3);
    isAccepted = 0;
    updateQuoteStatus({ key: params.key, status: statusState, isManual }).then((res) => {
      if (res.success) {
        onSave(false, false);
      }
    });
  };
  /* istanbul ignore next */
  const navigateToFormExit = () => {
    setViewPage(4);
    updateQuoteStatus({ key: params.key, status: statusState, isManual }).then((res) => {});
  };
  const navigateToExit = () => {
    updateQuoteStatus({ key: params.key, status: 3, isManual }).then((res) => { });
  };
  /* istanbul ignore next */
  const onRemoveLine = (index, subindex, numb) => {
    let currentSpecList = { ...specList };
    currentSpecList?.sectionData[index]?.specDataList[subindex]
      .serviceLineList.splice(numb, 1);
    currentSpecList = UpdateCalculations(currentSpecList, index);
    currentSpecList = UpdateServiceNumbers(currentSpecList, index, subindex);
    setSpecList(currentSpecList);
  };
  /* istanbul ignore next */
  const onNewServiceSave = () => {
    let currentSpecList = { ...specList };
    if (newServiceData.discount === '0') {
      newServiceData.discount = specList.rfqData.discountPercentage.toString();
      const D = toNumber(newServiceData.discount);
      const Q = toNumber(newServiceData.quantity);
      const R = toNumber(newServiceData.rate);
      if (Q && R) {
        newServiceData.amount = (((Q * R) / 100) * (100 - D))
          ? (((Q * R) / 100) * (100 - D)).toString() : null;
      }
    }
    currentSpecList.sectionData[addServiceLine.index1].specDataList[addServiceLine.index2]
      .serviceLineList.push(newServiceData);
    currentSpecList = UpdateCalculations(currentSpecList, addServiceLine.index1);
    setSpecList(currentSpecList);
    setAddServiceLine(false);
  };

  useEffect(() => {
    let intervalId;
    if (specList?.rfqData?.yardId) {
      intervalId = setInterval(() => {
        getRfqVersion(
          { projectId: specList.projectId, yardId: specList.rfqData.yardId }
        ).then((res) => {
          const version = sessionStorage.getItem('version');
          if (res.success) {
            if (res?.data?.version !== 0) {
              setSubmitStatus(res?.data?.isSubmit);
            }
            if (res?.data?.version > version) setModalBox(true);
          }
        });
      }, 30000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [specList?.rfqData?.yardId]);

  const renderPageContent = (page) => {
    if (page === 4) {
      return (
        <div>
          <ExitPage isManual={isManual} />
        </div>
      );
    }
    if (page === 2 || specList?.rfqData?.isManual) {
      return (
        <>
          {modalBox
            && (
              <ModalBox
                modalClassName="medium"
                heading="Specs Updates Received"
                onSave={() => {
                  setModalBox(false);
                  setViewPage(2);
                  renderPageContent(2);
                }}
                removeCancelBtn
                closeModal={() => setModalBox(false)}
                buttonName="Reload"
              >
                <div className="text-content-grey">
                  Please reload to view the updated specs list.
                </div>
              </ModalBox>
            )}
          <div className="quote-content-yard-quatation">
            <Agreement
              onContiue={onContiue}
              params={params}
              specList={specList}
              setSpecList={setSpecList}
              isManual={isManual}
              updateAgreementStatus={updateAgreementStatus}
              navigateToForm={navigateToForm}
              navigateToFormExit={navigateToFormExit}
              navigateToExit={navigateToExit}
              statusState={statusState}
              onDownload={onDownload}
              loading={loadings}
            />

          </div>
          <div className="mb-5">
            <div className="">
              <div className="footer justify-content-between font-13">
                <PolicyFooter
                  setShowPrivacyPolicy={setShowPrivacyPolicy}
                  setShowTermsPolicy={setShowTermsPolicy}
                />
              </div>
            </div>

          </div>
        </>
      );
    }
    if (page === 3 || specList?.rfqData?.status > 1) {
      if (loading) {
        return <Loader />;
      }
      return (
        <>
          {modalBox
            && (
            <ModalBox
              modalClassName="medium"
              heading="Specs Updates Received"
              onSave={() => {
                onSave(false, false);
                setModalBox(false);
                setViewPage(3);
                renderPageContent(3);
              }}
              removeCancelBtn
              closeModal={() => setModalBox(false)}
              buttonName="Save & Reload"
            >
              <div className="text-content-grey">
                Please reload to view the updated specs list.
              </div>
            </ModalBox>
            )}
          <div className="card m-3 py-2 px-4 quote-content">
            <ContentWrap
              specList={specList}
              setSpecList={setSpecList}
              onSecOpen={onSecOpen}
              mainSec={mainSec}
              showsubSec={showsubSec}
              setShowMore={setShowMore}
              setShowAttach={setShowAttach}
              allCurrency={allCurrency}
              params={params}
              downloadQuote={downloadQuote}
              isDownloadQuote={isDownloadQuote}
              saveClicked={saveClicked}
              onAddServiceLine={onAddServiceLine}
              onRemoveLine={onRemoveLine}
              onNewServiceSave={onNewServiceSave}
              onSave={onSave}
              isOpenModal={isOpenModal}
              setIsOpenModal={setIsOpenModal}
            />
            <QuoteFooter onSave={onSubmitQuote} onCancel={onCancel} submitStatus={submitStatus} />
          </div>
          <div className="mb-5">
            <div className="">
              <div className="footer justify-content-between font-13">
                <PolicyFooter
                  setShowPrivacyPolicy={setShowPrivacyPolicy}
                  setShowTermsPolicy={setShowTermsPolicy}
                />
              </div>
            </div>

          </div>
        </>
      );
    } if (page === 1) {
      return <div className="quote-content"><CardSeletion onContiue={onContiue} loading={loading} /></div>;
    }
    return null;
  };
  return (
    <div data-testid="y-quotation" className="">
      <PageHeader specList={specList} />
      {renderPageContent(viewPage)}
      <SidePanel
        title={<span data-tooltip-id="project-more" data-tooltip-content={showMore.title}>{`${showMore.specificationNo}. ${_.truncate(showMore.title, { length: 55 })}`}</span>}
        size="large"
        onClose={() => { setShowMore(false); }}
        showView={showMore}
      >
        {showMore && <ViewMore showMore={showMore} />}
      </SidePanel>
      <SidePanel
        title={<span data-tooltip-id="project-more" data-tooltip-content={showAttach?.section?.title}>{`${showAttach?.section?.specificationNo}. ${_.truncate(showAttach?.section?.title, { length: 20 })}`}</span>}
        size="large"
        onClose={() => { setShowAttach(false); }}
        showView={showAttach}
      >
        {showAttach && (
        <Attachments
          showAttach={showAttach}
          specList={specList}
          setSpecList={setSpecList}
        />
        )}
      </SidePanel>
      <SidePanel
        title="Add New Service Line"
        size="small"
        onClose={() => { setAddServiceLine(false); }}
        showView={addServiceLine}
        onSave={onNewServiceSave}
        isTsiDisabled={!newServiceData.serviceLineName}
      >
        {addServiceLine
        && (
        <AddServiceLine
          specList={specList}
          setSpecList={setSpecList}
          setNewServiceData={setNewServiceData}
          newServiceData={newServiceData}
          addServiceLine={addServiceLine}
          uomList={uomList}
        />
        )}
      </SidePanel>
      {showSubmitConfirm && (
      <ModalBox
        modalClassName="medium"
        heading=""
        onSave={() => { onSave(true); }}
        closeModal={() => {
          setShowSubmitConfirm(false);
        }}
        // buttonName="Confirm"
      >
        <SubmitConfirm
          showSubmitConfirm={showSubmitConfirm}
          setShowSubmitConfirm={setShowSubmitConfirm}
          onSave={onSave}
        />
      </ModalBox>
      )}
      {downloadConfirmModal && (
        <DownloadConfirmModal
          setDownloadConfirmModal={setDownloadConfirmModal}
          handleContinueInDownload={handleContinueInDownload}
          isDownload={isDownload}
        />
      )}
      <Tooltip id="project-more" place="top" className="tooltip mt-4" />
      <div className="">
        <div className="">
          <div className="footer justify-content-between font-13">
            <PolicyFooter
              setShowPrivacyPolicy={setShowPrivacyPolicy}
              setShowTermsPolicy={setShowTermsPolicy}
            />
          </div>
        </div>

      </div>
      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
    </div>
  );
};
export default YardQuotations;
