import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import showAlert from '../../../../utils/alert';
import { wohaTemplate, pohaTemplate, paintTemplate } from './services';
import helper from '../../../../utils/helper';

const UploadCard = (props) => {
  const { file, setFile, onUpload, poha, isUploading, paintcost } = props;
  const acceptedFiles = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const { MAX_FILE_SIZE } = helper;
  const downloadTemplate = () => {
    if (poha) {
      pohaTemplate();
    } else if (paintcost) {
      paintTemplate();
    } else {
      wohaTemplate();
    }
  };
  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE}`, 'error');
      } else {
        setFile(first);
      }
    } else if (first) {
      showAlert('Please upload a excel file type', 'error');
    }
  };
  return (
    <div className="card p-4 mt-4  border-brand" data-testid="upload-card">
      <div className="row">
        <div className="col-4 mt-4">
          <h3 className="mb-4">Upload Excel File</h3>
          <p>
            <button
              type="button"
              className="secondary-btn large"
              id="spec-download"
              onClick={() => {
                downloadTemplate();
              }}
            >
              Download Excel Template
            </button>
          </p>
        </div>
        {!file ? (
          <div id="fileName-div" className="drag-drop-file  file-waiting col-8">
            <input className="p-5 align-self-center" id="fileName" title="" type="file" name="fileName" onChange={(e) => { ondrop(e); }} isMandatory="true" />
            <div className="d-flex row w-full">
              <div className="d-flex flex-column justify-content-center align-items-center col-6">
                <div className="icon-buble mb-2">
                  <span className="icon-upload-cloud" />
                </div>
                <p>Choose a file or drag it here</p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center col-6">
                <div className="div_btn">
                  Browse File
                </div>
              </div>
            </div>
          </div>
        )
          : (
            <div id="fileName-div" className="drag-drop-file my-4 file-added col-8 d-flex align-items-center flex-row">
              <div className="font-18 d-flex col-7 align-items-center">
                <span className="icon-file-excel me-2 font-20"> </span>
                <div><span data-tooltip-id="project-variations" data-tooltip-content={file.name}>{_.truncate(file.name, { length: 25 })}</span></div>
                <span className="icon-close ms-2 font-20 red" id="clearFile" onClick={() => { setFile(''); }}> </span>
              </div>
              <div>
                <button disabled={isUploading} type="button" id="upload" className="primary-btn" onClick={onUpload}>
                  {isUploading ? 'Uploading..' : 'Upload'}
                </button>
              </div>
            </div>
          )}
      </div>
      <Tooltip id="project-variations" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default UploadCard;
