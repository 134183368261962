import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import showAlert from '../../../../../../utils/alert';
import helper from '../../../../../../utils/helper';
import Loader from '../../../../../common/areaLoader';
import ModalBox from '../../../../../common/ModalBox';
import SidePanel from '../../../../../common/sidePanel';
import { getCurrencyList, s3UploadMutiple, getProjectDetailsTSI } from '../../../NewProject/services';
import { getAcCode } from '../../Specify/SpecsList/ServiceLine/services';
import Attachments from '../../Tender/ReceivedQuotes/attachments';
import AddNewSpecLine from './addNewSpecLine';
import NonSpecTable from './nonSpecTable';
import { getNonSpecList, saveNonSpecLine, currencyConverter, deleteNonSpec } from './services';

const NonSpecifications = (props) => {
  const { id, isTSI } = props;
  const { MAX_FILE_SIZE, generateOptions,
    charValidation, ZERO_UUID, handleValidation, formValidation } = helper;
  const initialFormData = {
    id: '',
    projectId: id,
    title: '',
    specificationNo: '',
    specificationLevel: 0,
    parentSpecificationId: '',
    accodeId: '',
    accode: '',
    amount: '',
    currencyId: '',
    ownerCost: 0,
    projectCurrency: '',
    item: '',
    prPoNo: '',
    specDocuments: []
  };
  const acceptedFiles = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/tiff',
    'application/pdf'];
  const [nonSpecList, setNonSpecList] = useState();
  const [mainSec, setMainSec] = useState('');
  const [searchText, setSearchText] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [specModal, setSpecModal] = useState(false);
  const [acCodeList, setAcCodeList] = useState();
  const [projectCurrencyId, setProjectCurrencyId] = useState();
  const [projectCurrency, setProjectCurrency] = useState();
  const [secItem, setSecItem] = useState();
  const [formData, setFormData] = useState(initialFormData);
  const [viewAttachments, setViewAttachments] = useState(false);
  const [attachments, setAttachments] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [projInfoDetail, setProjInfoDetail] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [newACCode, setnewACCode] = useState(false);

  const projectDetails = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      setProjInfoDetail(res?.data);
    });
  };
  const getNonSpecListData = () => {
    setIsLoading(true);
    getNonSpecList(id, searchText).then((resp) => {
      if (resp.success) {
        setNonSpecList(resp.data);
      }
      setIsLoading(false);
    });
  };
  const deleteApiCall = () => {
    deleteNonSpec(openDeleteModal.id).then((res) => {
      setOpenDeleteModal(false);
      getNonSpecListData();
      if (res.success) {
        showAlert('Successfully Deleted', 'success');
      }
    });
  };
  const editData = (data1, data2) => {
    setProjectCurrencyId(data2?.projectCurrencyId);
    setSecItem(data2);
    setFormData(data1);
    setSpecModal(true);
  };
  const deleteData = (data) => {
    setOpenDeleteModal(data);
  };
  const getCurrency = (cId, pId, amount) => {
    currencyConverter(cId, pId, amount).then((resp) => {
      if (resp.success) {
        const fd = cloneDeep(formData);
        fd.ownerCost = resp.data;
        fd.currencyId = cId;
        fd.amount = amount;
        setFormData(fd);
      }
    });
  };
  const managementId = localStorage.getItem('managementId');
  const getAcCodes = () => {
    getAcCode({ page: 1,
      pageSize: 1000,
      managementGroupId: managementId || ZERO_UUID }).then((resp) => {
      if (resp.success) {
        setAcCodeList(generateOptions(resp.data.items, 'id', 'name', 'code'));
      }
    });
  };
  const getProjectCurrencyList = () => {
    getCurrencyList().then((res) => {
      if (res.data) {
        setProjectCurrency(generateOptions(res?.data, 'id', 'currencyName'));
      }
    });
  };

  useEffect(() => {
    getAcCodes();
  }, [managementId]);

  useEffect(() => {
    getNonSpecListData();
    projectDetails();
    getProjectCurrencyList();
  }, []);

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getNonSpecListData();
    }
  };
  const onAddSpecLine = (sec) => {
    setProjectCurrencyId(sec?.projectCurrencyId);
    setSecItem(sec);
    setSpecModal(true);
  };
  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      if (field === 'currencyId') {
        getCurrency(e.value, projectCurrencyId, formData?.amount);
      }
      fd[field] = e.value;
      if (field === 'accodeId') {
        fd.accode = e?.label;
      }
    } else if (field === 'amount') {
      fd[field] = charValidation(e.target.value, 8);
      if (fd.currencyId) {
        getCurrency(fd.currencyId, projectCurrencyId, fd[field]);
      }
    } else if (field === 'accode') {
      fd[field] = charValidation(e?.target?.value);
      fd.accodeId = ZERO_UUID;
    } else {
      fd[field] = charValidation(e.target.value, 200);
    }
    setFormData(fd);
    handleValidation(['amount', 'currencyId', 'title', 'accodeId'], fd, field);
  };
  const onSecOpen = (identity) => {
    const newId = mainSec === identity ? '' : identity;
    setMainSec(newId);
  };
  const viewAttachment = (item) => {
    setAttachments(item?.specDocuments);
    setViewAttachments(true);
  };
  const saveApiCall = () => {
    const payload = cloneDeep(formData);
    payload.id = formData.id || ZERO_UUID;
    payload.specificationNo = secItem.specificationNo;
    payload.specificationLevel = secItem.specificationLevel;
    payload.projectCurrency = secItem.projectCurrencyId;
    payload.parentSpecificationId = secItem.projectNonSpecId;
    payload.projectNonSpecId = secItem.projectNonSpecId;
    // payload.currencyId = payload.currencyId || ZERO_UUID;
    payload.accodeId = payload.accodeId || ZERO_UUID;
    // payload.amount = payload.amount || 0;
    payload.projectId = id;
    if (formValidation(['title', 'amount', 'currencyId'], payload)) {
      saveNonSpecLine(payload).then((resp) => {
        if (resp.success) {
          if (formData.id) { showAlert('Successfully Updated', 'success'); } else { showAlert('Successfully Saved', 'success'); }
          setSpecModal(false);
          getNonSpecListData();
          setFormData(initialFormData);
          setnewACCode(false);
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const ondrop = async (e) => {
    if (e.target.files.length < 6) {
      const name = formData.specDocuments.map((items) => {
        return items.name;
      });
      const selectedFiles = [];
      let isDuplicate = false;
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');
        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        else if (name.indexOf(item.name) < 0) {
          selectedFiles.push(item);
        } else {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        showAlert('Duplicate Files Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        setSaveDisabled(true);
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = _.cloneDeep(formData);
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              fname: item.name,
              path: item.path,
              type: item.fileType,
              size: item.size
            };

            return attach.specDocuments.push(newItem);
          });
          setFormData(attach);
          if (res.success) {
            setSaveDisabled(false);
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 5 files at a time', 'error');
    }
    e.target.value = '';
  };
  useEffect(() => {
    if (!searchText) {
      getNonSpecListData();
    }
  }, [searchText]);
  return (
    <div className=" align-items-center" data-testid="non-spec">
      <div className=" d-flex justify-content-between align-items-center ps-3 mt-3 ml-8">
        <div className="search large search-large spec-search ">
          <div className="d-flex justify-content-end">
            <span
              className={searchText ? ' icon-close-line ' : ''}
              onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
            />
            <span
              className="icon-search"
              id="search-click"
              onClick={() => {
                onSearch({ key: 'Enter' });
              }}
            />
          </div>
          <input
            type="text"
            value={searchText}
            className="text-box large with-close"
            placeholder="Search by Non Spec Name, Non Spec Line Name"
            onKeyPress={onSearch}
            id="search"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="p-1">
        {isLoading ? <div className="d-flex justify-content-center"><Loader /></div> : (
          <NonSpecTable
            projInfoDetail={projInfoDetail}
            specList={nonSpecList}
            mainSec={mainSec}
            onSecOpen={onSecOpen}
            onAddSpecLine={onAddSpecLine}
            viewAttachment={viewAttachment}
            isTSI={isTSI}
            editData={editData}
            deleteData={deleteData}
          />
        )}
      </div>
      {specModal && (
        <ModalBox
          modalClassName="medium"
          onSave={() => saveApiCall()}
          closeModal={() => {
            setSpecModal(false);
            setFormData(initialFormData);
            setnewACCode(false);
          }}
          heading={`${formData.id ? 'Update' : 'Add'} Non Spec Line`}
          buttonName={formData.id ? 'Update' : 'Save'}
          saveDisable={saveDisabled}
        >
          <AddNewSpecLine
            formData={formData}
            setFormData={setFormData}
            ondrop={ondrop}
            handleChange={handleChange}
            acCodeList={acCodeList}
            secItem={secItem}
            projectCurrency={projectCurrency}
            newACCode={newACCode}
            setnewACCode={setnewACCode}
            projInfoDetail={projInfoDetail}
          />
        </ModalBox>
      )}

      <SidePanel
        title="Attachments"
        size="medium"
        onClose={() => {
          setViewAttachments(false);
        }}
        showView={viewAttachments}
      >
        <Attachments allFiles={attachments} fromNonSpec />
      </SidePanel>
      {openDeleteModal ? (
        <ModalBox
          openDeleteModal={openDeleteModal}
          modalClassName="medium"
          heading="Delete"
          onSave={() => deleteApiCall()}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete Non Spec Line ?</p>
        </ModalBox>
      ) : (
        ''
      )}
    </div>
  );
};

export default NonSpecifications;
