/* eslint-disable no-unused-vars */
import React from 'react';
import helper from '../../../../../../utils/helper';

const ReceivedQuotesFooter = (props) => {
  const { formatNumber } = helper;

  const { specList } = props;
  return (
    <div data-testid="received-footer">
      <div className="border-down my-3" />
      <div className="bg-sky-white p-3 d-flex justify-content-end sm-radius">
        <div className="col-3 pe-4">
          <label>
            Additional Yard Discount (
            {specList?.rfqData?.currencySymbol}
            )
          </label>
          <div className="head-14">
            {formatNumber(specList?.rfqData?.discount) || 0}
          </div>
        </div>
        <div className="col-4 pe-4">
          <label>
            Estimate Without Discount (
            {specList?.rfqData?.currencySymbol}
            )
          </label>
          <div className="head-14">
            {formatNumber(specList?.rfqData?.yardEstimateWithoutDiscount) || 0}
          </div>
        </div>
        <div className="col-3 pe-4">
          <label>
            Yard Final Estimate (
            {specList?.rfqData?.currencySymbol}
            )
          </label>
          <div className="head-14">
            {formatNumber(specList?.rfqData?.yardFinalEstimate) || 0}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReceivedQuotesFooter;
