/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import helper from '../../../../../../../utils/helper';

const GtSpecCard = (props) => {
  const {
    gtSpecData, onDeleteSpec, onEditSpec, gtEditSpec, createDraft, isTsiDisabled, isVesselUser, selectedSpec, mergeDraft,
    onSpecSelect, onAccordianClose, closedSection, replaceDraft, projInfo, formDataToFillList, showDataGT, setShowDataGT
  } = props;
  const { roles } = helper;
  const [generalTarrif, setGeneralTarrif] = useState(false);
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  // eslint-disable-next-line max-len
  const isReplace = (userRole === roles.ADMIN || userRole === roles.AUTHOR || userRole === roles.EDITOR);
  const isTSI = (userRole === roles.AUTHOR || userRole === roles.EDITOR);
  const disableDelete = !!(projInfo.projectTypeShortName === 'PDD' || projInfo.projectTypeShortName === 'RETR');
  const projType = projInfo.projectType?.toLowerCase()?.includes('emergency') || projInfo.projectType?.toLowerCase()?.includes('layup');
  const updateView = () => {
    setShowDataGT(!showDataGT);
  };
  const handleChangeType = (index, type, subIndex = '') => {
    const temp = cloneDeep(generalTarrif);
    if (type === 'main') {
      if (temp[index]) { temp[index].isChecked = !temp[index]?.isChecked; }
      temp[index]?.childData.forEach((element, k) => {
        temp[index].childData[k].isChecked = temp[index].isChecked;
      });
    } else {
      temp[index].childData[subIndex].isChecked = !temp[index].childData[subIndex].isChecked;
      let found = true;
      temp[index].childData.forEach((element, k) => {
        if (!temp[index].childData[k].isChecked) {
          found = false;
        }
        temp[index].isChecked = found;
      });
    }
    setGeneralTarrif(temp);
  };
  return (
    <>
      <div className="border p-3 md-radius">
        <div className="d-flex align-items-center ms-3  pointer" id="accord" onClick={updateView}>
          {showDataGT ? <span className="icon-arrow-thin-down font-10 me-3 brand-color" />
            : <span className="icon-arrow-thin-right font-16 me-3 brand-color" />}
          <p className="link-btn">General Tariff</p>
        </div>
        {showDataGT && gtSpecData?.map((item) => {
          return (
            <div key={item.id} className={item.isDraftCreated ? 'draft-icom' : ''}>
              <div className={item.isDraftCreated ? 'draft-ico collapse-head d-flex justify-content-between mt-1' : 'collapse-head d-flex justify-content-between mt-1'}>
                <div className="d-flex align-items-center pointer" data-test="click1" id={item.id} onClick={() => { onAccordianClose(item.id); }}>
                  {closedSection.indexOf(item.id) < 0
                    ? <span className="icon-arrow-thin-right font-16 me-3 brand-color" />
                    : <span className="icon-arrow-thin-down font-10 me-3 brand-color" />}
                  <span
                    className="count me-2"
                    data-tooltip-id="project-title"
                    data-tooltip-content={item.specificationNo}
                  >
                    {_.truncate(item.specificationNo, { length: 8 })}

                  </span>
                  <span
                    data-tooltip-id="project-specificationNo"
                    data-tooltip-content={item.title}
                  >
                    {_.truncate(item.title, { length: 23 })}

                  </span>
                </div>
                <Tooltip id="project-specificationNo" place="top" className="tooltip m-4" />
                {projType && (
                  <button type="button" disabled={isTsiDisabled} data-test="click2" className="link-btn ms-3 pointer" onClick={() => { gtEditSpec(item); }}>
                    <span className="icon-plus-square font-24" />
                  </button>
                )}
              </div>
              {closedSection.indexOf(item.id) > -1
                && (
                  <div className="collapse-body">
                    {
                      item?.specifications?.map((field) => {
                        let clName = 'spec-item pointer ';
                        if (field.id === selectedSpec.id) {
                          clName = clName.concat('active ');
                        }
                        if (field?.completionStatus === 1) {
                          clName = clName.concat('spec-partial ');
                        }
                        if (field?.completionStatus === 2) {
                          clName = clName.concat('spec-complete ');
                        }
                        if (field?.completionStatus === 3) {
                          clName = clName.concat('spec-approve ');
                        }
                        if (isVesselUser && field?.isDraftCreated) {
                          clName = clName.concat('opacity-4');
                        }
                        if (!isVesselUser && field?.isDraft) {
                          clName = clName.concat('opacity-4');
                        }
                        return (
                          <div
                            key={field.id}
                            className={clName}
                            id={field.id}
                            onClick={() => { onSpecSelect(field, 'gt'); }}
                          >
                            <div className="d-flex justify-content-between align-content-center">
                              <div className="d-flex spec-content">
                                <span
                                  className="count me-2 flex-1"
                                  data-tooltip-id="project-title"
                                  data-tooltip-content={field.specificationNo}
                                >
                                  {_.truncate(
                                    field.specificationNo, { length: field?.isDraft ? 18 : 10 }
                                  )}
                                </span>
                                <span
                                  className="spec-name"
                                  data-tooltip-id="project-field"
                                  data-tooltip-content={field.title}
                                >
                                  {field.title.toUpperCase()}
                                </span>
                              </div>
                              <Tooltip id="project-field" place="top" className="tooltip mt-4" />
                              <div className="d-flex button-ctrl">
                                {' '}
                                {isVesselUser && field?.isDraft ? (
                                  <button
                                    type="button"
                                    className="link-btn ms-3"
                                    disabled={isTsiDisabled}
                                    onClick={() => { onDeleteSpec(field.id, true, field?.isDraft); }}
                                  >
                                    <span
                                      className="icon-delete font-20"
                                      data-tooltip-id="project-title"
                                      data-tooltip-content={field.isMapped ? 'These jobs are added specifically from plan phase and cannot be deleted' : ''}
                                    />
                                  </button>
                                ) : ''}
                              </div>
                              {isTSI && !disableDelete
                                && (
                                  <div className="d-flex button-ctrl">
                                    <button
                                      type="button"
                                      className="link-btn ms-3"
                                      disabled={field.isMapped
                                        || isTsiDisabled
                                        || (field?.isLocked && userRole === roles?.VESSEL_USER)}
                                      onClick={() => { onEditSpec(item.id, field); }}
                                    >
                                      <span className="icon-edit font-20" />
                                    </button>
                                    <button
                                      type="button"
                                      className="link-btn ms-3"
                                      disabled={field.isMapped
                                        || isTsiDisabled
                                        || (field?.isLocked && userRole === roles?.VESSEL_USER)}
                                      onClick={() => { onDeleteSpec(field.id, true, field?.isDraft); }}
                                    >
                                      <span
                                        className="icon-delete font-20"
                                        data-tooltip-id="project-title"
                                        data-tooltip-content={field.isMapped ? 'These jobs are added specifically from plan phase and cannot be deleted' : ''}
                                      />
                                    </button>
                                  </div>
                                )}
                            </div>
                            <div className="d-flex justify-content-between">
                              <div className="spec-ctrl mt-1 d-flex">
                                <div className="">
                                  <span className="icon-woha font-16 me-2" />
                                  {field.wodCount}
                                </div>
                                <div className="ms-5">
                                  <span className="icon-poha font-16 me-2" />
                                  {field.poCount}
                                </div>
                              </div>
                              {isVesselUser && !field?.isDraft && !field?.isDraftCreated && projInfo.phase === 2 ? (
                                <div>
                                  <button onClick={() => createDraft(field, 'gt')} type="button" className="primary-btn small">Edit</button>
                                </div>
                              ) : ''}
                              {isReplace && !field?.isDraft && field?.isDraftCreated ? (
                                <div className="d-flex">
                                  <div className="me-2">
                                    <button
                                      data-tooltip-id="project-replace"
                                      onClick={() => replaceDraft(field, 'gt')}
                                      type="button"
                                      className="primary-btn small"
                                    >
                                      Replace
                                    </button>
                                    <Tooltip id="project-replace" place="top" className="tooltip m-4" />
                                  </div>
                                  <div>
                                    <button
                                      data-tooltip-id="project-merge"
                                      onClick={() => mergeDraft(field, 'gt')}
                                      type="button"
                                      className="primary-btn small"
                                    >
                                      Merge
                                    </button>
                                    <Tooltip id="project-merge" place="top" className="tooltip m-4" />
                                  </div>
                                </div>
                              ) : ''}
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                )}
            </div>
          );
        })}
      </div>
    </>
  );
};
export default GtSpecCard;
