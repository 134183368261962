import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import showAlert from '../../../../utils/alert';
import { s3Upload, saveReferenceDoc, getReferenceDoc, deleteReferenceDoc } from './services';
import ModalBox from '../../../common/ModalBox';
import CardWrapper from './cardWrapper';
import helper from '../../../../utils/helper';
import Addattachments from './Addattachments';
import Pagination from '../../../common/pagination';
import PolicyFooter from '../../policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const acceptedFiles = ['image/jpeg', 'image/jpg', 'image/png', 'image/tiff', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
let isSearched = false;
const ReferenceDoc = () => {
  // eslint-disable-next-line no-unused-vars
  const [count, setCount] = useOutletContext() || '';
  const userRole = localStorage.getItem('USER_ROLE');
  const { formValidation, handleValidation, MAX_FILE_SIZE } = helper;
  const [allFiles, setAllFiles] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState(allFiles);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [formData, setFormData] = useState({ fileName: '', name: '' });
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [fileLoading, setFileLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const getDocs = (page) => {
    getReferenceDoc({
      page: page || currentpage,
      pageSize,
      search: isSearched ? searchText : ''
    }).then((res) => {
      setLoading(false);
      if (res.success) {
        setAllFiles(res.data);
        setCount(res?.data?.totalItems || 0);
      }
    });
  };
  /* istanbul ignore next */
  const saveApiCall = () => {
    if (formValidation(['name', 'fileName'], formData)) {
      saveReferenceDoc(formData).then((res) => {
        setLoading(false);
        if (res.success) {
          setShowUpdateModal(false);
          setFormData({ fileName: '', name: '' });
          showAlert('Successfully Saved', 'success');
          getDocs();
        }
      });
    } else {
      const element = document.getElementById('fileName-div');
      if (element) {
        element.classList.add('file-not-added');
        element.classList.remove('file-added');
      }

      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const handleChange = (e, field) => {
    const referenceDoc = { ...formData };
    referenceDoc[e.target.name] = e.target.value;
    setFormData(referenceDoc);
    handleValidation(['name'], referenceDoc, field);
  };

  useEffect(() => {
    getDocs();
  }, [pageSize, currentpage]);

  const deleteData = (data) => {
    setSelectedData(data);
    setOpenDeleteModal(true);
  };
  const deleteReferenceDocApi = () => {
    deleteReferenceDoc({ id: selectedData.id }).then((res) => {
      if (res.success) {
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
        getDocs();
      }
    });
  };
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const showUpModal = () => {
    setShowUpdateModal(true);
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearched = true;
      getDocs(1);
      setCurrentpage(1);
    }
  };
  /* istanbul ignore next */
  const ondrop = async (e) => {
    const [first] = e.target.files;
    if (first && acceptedFiles.includes(first.type)) {
      const fileSize = first.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        const element = document.getElementById('fileName-div');
        if (element) {
          element.classList.add('file-not-added');
          element.classList.remove('file-added');
        }
      } else {
        setFileLoading(true);
        const body = new FormData();
        body.append('file', first);
        const res = await s3Upload(body);
        if (res && res.data) {
          setFileLoading(false);
          const referenceDoc = { ...formData };
          referenceDoc.fileName = res.data;
          setFormData(referenceDoc);
          showAlert('successfully Uploaded ', 'success');
        } else {
          setFileLoading(false);
        }
        const element = document.getElementById('fileName-div');
        if (element) {
          element.classList.remove('file-not-added');
          element.classList.add('file-added');
        }
      }
    } else if (first) {
      showAlert('Please upload a valid file type', 'error');
      const element = document.getElementById('fileName-div');
      if (element) {
        element.classList.add('file-not-added');
        element.classList.remove('file-added');
      }
    }
  };
  useEffect(() => {
    if (!searchText) {
      getDocs(1);
    }
  }, [searchText]);
  useEffect(() => {
    setLoading(true);
  }, []);
  return (
    <div className="container" data-testid="reference-doc">
      <div className="d-flex my-4 justify-content-between align-items-center">
        <div className="d-flex col-gap-1 spec-search align-items-center">
          <div className="search large">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
              />
              <span className="icon-search" onClick={() => { onSearch({ key: 'Enter' }); }} />
            </div>
            <input
              type="text"
              className="text-box large with-close"
              placeholder="Search by File Name"
              onKeyPress={onSearch}
              value={searchText}
              id="search"
              onChange={(e) => {
                setSearchText(e.target.value);
                isSearched = false;
              }}
            />
          </div>
          {userRole === 'Admin' ? <button className="primary-btn large" id="add-name" type="button" onClick={() => showUpModal()}>Upload Doc</button> : ''}
        </div>
      </div>

      <div className="scorll-body-wrap">

        <CardWrapper
          loading={loading}
          allFiles={allFiles}
          deleteData={deleteData}
          onSearch={onSearch}
          userRole={userRole}
        />
        {allFiles?.totalItems > 10 && (
          <Pagination
            pageClick={pageClick}
            pageCount={allFiles.totalPages}
            records={allFiles.totalItems}
            size={pageSize}
            onSizeChange={onSizeChange}
            currentpage={currentpage}
          />
        )}
      </div>

      {openDeleteModal ? (
        <ModalBox
          modalClassName="medium"
          heading="Delete"
          onSave={() => deleteReferenceDocApi()}
          closeModal={() => setOpenDeleteModal(false)}
          buttonName="Delete"
        >
          <p>Are you sure you want to delete the reference document?</p>
        </ModalBox>
      ) : (
        ''
      )}
      {showUpdateModal ? (
        <ModalBox
          modalClassName="medium"
          heading="Add Attachment"
          onSave={saveApiCall}
          closeModal={() => setShowUpdateModal(false)}
          buttonName="Save"
        >
          <Addattachments
            handleChange={handleChange}
            formData={formData}
            ondrop={ondrop}
            setFormData={setFormData}
            fileLoading={fileLoading}
          />
        </ModalBox>
      ) : (
        ''
      )}
      {userRole === 'Admin' ? (
        <div className="non-sticky-footer">
          <div className="container">
            <div className="d-flex justify-content-between font-13 mx-0 my-2">
              <PolicyFooter
                setShowPrivacyPolicy={setShowPrivacyPolicy}
                setShowTermsPolicy={setShowTermsPolicy}
              />
            </div>
          </div>

        </div>
      ) : (
        <div className="footer justify-content-between font-13">
          <div className="copy-text m-auto">
            {`© ${new Date().getFullYear()} ThinkPalm`}
          </div>

        </div>
      )}

      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
    </div>
  );
};
export default ReferenceDoc;
