/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../../../utils/helper';

const SpecLineTable = ({
  onSelectServiceLine,
  selectedServiceLines,
  specLines,
  editData,
  deleteData,
  searchTextFixed,
  isTsiDisabled,
  currency,
  isTSI,
  viewAttachment,
  projInfo
}) => {
  const filterTableData = [];
  specLines?.forEach((i) => {
    if (searchTextFixed && typeof i.nonSpecItem === 'string') {
      if (i.nonSpecItem.toLowerCase().includes(searchTextFixed)) {
        filterTableData.push(i);
      }
    }
  });
  const { formatNumber } = helper;
  const tableData = searchTextFixed ? filterTableData : specLines;
  return (
    <div data-testid="non-spec-table" className="content-scroller-height">
      {searchTextFixed ? <p>{`${tableData.length} Result(s) Found`}</p> : ''}
      {tableData && tableData.length ? (
        <>

          <>
            <table className="w-full html-table ">
              <colgroup>
                <col className="col-2" />
                <col className="col-2" />
                <col className="col-2" />
                <col className="col-2" />
                <col className="col-2" />
                <col className="col-2" />
                <col className="col-2" />
                <col className="col-2" />
              </colgroup>
              <thead>
                <tr className="">
                  <th className="">Non Spec</th>
                  <th className="">PO/PR</th>
                  <th className="">Item</th>
                  <th className="non-spec-text-end">Amount</th>
                  <th className="non-spec-text-center">AC Code</th>
                  <th className="non-spec-text-end">Owner's Cost</th>
                  <th className="">Attachments</th>
                  <th className="">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item) => {
                  return (
                    <tr>
                      <td className="">
                        <span>
                          {item.specificationNo}
                        </span>

                        <span
                          className="ms-2"
                          data-tooltip-id="project-nonSpecItem"
                          data-tooltip-content={item.nonSpecItem}
                        >
                          {_.truncate(item.nonSpecItem, { length: 10 })}
                        </span>

                      </td>
                      <td
                        className=""
                      >
                        <span
                          data-tooltip-id="project-nonSpecItem"
                          data-tooltip-content={item.prPoNo}
                        >
                          {item.prPoNo ? _.truncate(item.prPoNo,
                            { length: 10 }) : '-'}
                        </span>

                      </td>
                      <td className="">
                        <span
                          data-tooltip-id="project-nonSpecItem"
                          data-tooltip-content={item.item}
                        >
                          {item?.item ? _.truncate(item?.item,
                            { length: 12 }) : '-'}

                        </span>

                      </td>
                      <td className="non-spec-text-end">
                        <span
                          data-tooltip-id="project-nonSpecItem"
                          data-tooltip-content={`${item?.currencySymbol} ${formatNumber(item.amount)}`}
                        >
                          {' '}
                          {item?.amount ? item?.currencySymbol : ''}
                          {' '}
                          {item?.amount ? _.truncate((item?.amount),
                            { length: 10 }) : '-'}

                        </span>

                      </td>
                      <td className="non-spec-text-center">
                        <span
                          data-tooltip-id="project-nonSpecItem"
                          data-tooltip-content={item.acCodeName}
                        >
                          {_.truncate(item.acCodeName,
                            { length: 10 })}
                        </span>

                      </td>
                      <td className="non-spec-text-end">
                        <span
                          data-tooltip-id="project-nonSpecItem"
                          data-tooltip-content={`${currency} ${formatNumber(item.ownerCost)}`}
                        >
                          {item?.ownerCost ? currency : ''}
                          {' '}
                          {item?.ownerCost ? _.truncate(formatNumber(item?.ownerCost),
                            { length: 10 }) : '-'}
                        </span>

                      </td>
                      <td>
                        <div id="viewAttach" className="col-2 ps-3 border-down rq-attachment" onClick={() => viewAttachment(item)}>
                          {item?.attachments?.length > 0 && (
                          <span className="dot-indicator-non-spec" />
                          )}
                          <button
                            className="brand-color ml-30"
                            id="viewAttachBtn"
                            type="button"
                            onClick={() => {
                              viewAttachment(item);
                            }}
                          >
                            <span
                              id="attachment-click"
                              className="icon-attachments font-20 brand-color"
                            />

                          </button>
                        </div>
                      </td>
                      <td className="">
                        <div className="d-flex">
                          <button
                            disabled={projInfo?.isClosed === true}
                            type="button"
                            id="edit"
                            className="link-btn me-3"
                            onClick={() => editData(item)}
                          >
                            <span className="icon-edit font-20" />
                          </button>
                          <button
                            disabled={projInfo?.isClosed === true}
                            type="button"
                            id="delete"
                            className="link-btn"
                            onClick={() => { deleteData(item); }}
                          >
                            <span className="icon-delete font-20" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>

        </>
      ) : (
        <div className="card mt-3 p-4 bg-white d-flex justify-content-center">No data found</div>
      )}
      <Tooltip id="project-nonSpecItem" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default SpecLineTable;
