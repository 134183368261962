import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import qs from 'query-string';
import ProjectHead from '../../ProjectHead';
import CostSummary from './Children2/CostSummary';
import Sections from './Children2/Sections';
import VerticalBarChart from './Children2/VerticalBarChart';
import { getProjectDetailsTSI } from '../../NewProject/services';
import { getProjectRepairData, getProjectRepairCostSummary, fileDownloadCostSummary } from './services';
import Loader from '../../../../common/loader';

const MoreInfo = () => {
  const params = qs.parse(window.location.search);
  const { id } = useParams();
  const navigate = useNavigate();
  const initialData = {
    projectId: id,
    lastPort: '',
    lastPortDistance: 0,
    nextPort: '',
    nextPortDistance: 0,
    repairStartDate: '',
    hireRate: 0,
    fuelPrice: 0,
    fuelConsumption: 0,
    fuelCost: 0,
    repairDays: 0,
    deviationDays: 0,
    deviationOffhire: 0,
    offhireDays: 0,
    hireRateLocal: 0,
    fuelPriceLocal: 0,
    fuelConsumptionLocal: 0,
    fuelCostLocal: 0,
    deviationOffhireLocal: 0
  };
  const [formData, setFormData] = useState(initialData);
  const [projectBasicDetails, setProjectBasicDetails] = useState();
  const [repairCostSummary, setRepairCostSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [costSummaryLoader, setCostSummaryLoader] = useState(false);
  const reloadTable = () => {
    setCostSummaryLoader(true);
    getProjectRepairData(id).then((res) => {
      setCostSummaryLoader(false);
      if (res.data) {
        setFormData(res.data);
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getProjectDetailsTSI({ id }).then((res) => {
      setProjectBasicDetails(res.data);
    });
    getProjectRepairCostSummary(id).then((res) => {
      setLoading(false);
      setRepairCostSummary(res.data);
    });
    reloadTable();
  }, []);

  const sampleGraph = {
    currentOwner: 0,
    currentYard: 0,
    currentTotal: 0,
    deviationOwner: 0,
    deviationYard: 0,
    deviationTotal: 0,
    estimateOwner: 0,
    estimateYard: 0,
    estimateTotal: 0
  };

  const downloadData = () => {
    fileDownloadCostSummary(id);
  };
  return (
    <>
      <div className="container-fluid phase-wraper tsi-user page-wrapper " data-testid="more-info">
        <div className="header">
          <ProjectHead />
          <div className="sub-header d-flex justify-content-between align-items-center">
            <div className="head-18">Project Cost Summary</div>
          </div>
        </div>
        {costSummaryLoader || loading
          ? (
            <Loader />
          ) : (
            <div>
              <div className="d-flex justify-content-between my-3">
                <div className="d-flex pt-3 pb-3 align-items-center">
                  <div className="align-items-center me-3">
                    <span className="icon-yard  large brand-color md-radius bg-white p-3 font-35 " />
                  </div>
                  <div className="ps-2">
                    <div className="head-16">{formData.yardName}</div>
                    <div className="d-flex">
                      <div className="bg-white px-3 py-1 ra-border-10">
                        <span className="icon-location font-14 me-1 pt-1 brand-color" />
                        <span className="text-content-grey">{formData.yardLocation}</span>
                      </div>
                      <div className="ms-4 d-flex align-items-center">
                        <span className="text-content-grey">Repair Start Date :</span>
                        <span className="mx-2 brand-color font-20 icon-calander icon-date" />
                        <span className="mt-1 value">{formData.repairStartDate ? moment(formData.repairStartDate).format('DD-MMMM YYYY') : ''}</span>
                      </div>
                      <div className="align-items-center bg-white d-flex font-13 justify-content-center ms-4 px-3 text-content-grey">
                        <span className="">Currency :&nbsp;</span>
                        <span className="">{projectBasicDetails?.currency}</span>
                        <span className="">{` ( ${projectBasicDetails?.currencySymbol} ) `}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end flex-column pe-4">
                  <div className="d-flex my-1">
                    <div className=" text-content-grey me-2">Last Porting Location : </div>
                    <div className="value">{formData?.lastPort}</div>
                  </div>
                  <div className="d-flex my-1">
                    <div className=" text-content-grey me-2">Next Porting Location : </div>
                    <div className="value">{formData?.nextPort}</div>
                  </div>
                </div>
              </div>

              <div className="pe-2 ps-3">
                <div className="row">
                  <VerticalBarChart
                    graphData={repairCostSummary?.graphData || sampleGraph}
                    projectBasicDetails={projectBasicDetails}
                  />
                  <CostSummary
                    formData={formData}
                    projectBasicDetails={projectBasicDetails}
                  />
                </div>
              </div>
              <div className=" pb-5">
                <Sections
                  loading={loading}
                  projectId={id}
                  repairCostSummary={repairCostSummary}
                  projectBasicDetails={projectBasicDetails}
                />
              </div>
            </div>
          )}
      </div>

      <div className="footer justify-content-between font-13 mb-50" />

      <div className="fixed-footer card d-flex justify-content-end col-gap-1">
        <div className="text-black font-12 col-6">
          <div className="footer-tender">{`© ${new Date().getFullYear()} ThinkPalm`}</div>

        </div>
        <button
          type="button"
          className="secondary-btn large mr-10"
          onClick={() => {
            navigate(`/user/project/${id}/repair?vesselId=${params.vesselId}`);
          }}
        >
          Back to Repair
        </button>
        <button
          type="button"
          className="primary-btn large mr-10"
          onClick={() => {
            downloadData();
          }}
        >
          Download
        </button>
      </div>
    </>
  );
};

export default MoreInfo;
