import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import SidePanel from '../../../../common/sidePanel';
import VesselDetails from './VesselDetails';
import ProjectDetails from './ProjectDetails';
import Attachments from './Attachments';
import vesselThmd from '../../../../../images/vessel-thmd.jpg';
import { getVesselDetails, getVesselSurvey, getProjectAttachments, deleteProjectAttachment, downloadAttachmentsByCategory } from '../../NewProject/services';

const SidePanelDeets = (props) => {
  const { showView, setShowView, projInfo, IsemergencyDryDock } = props;
  const [vesselInfo, setVesselInfo] = useState('');
  const [activeComp, setActiveComp] = useState('0');
  const [surveyInfo, setSurveyInfo] = useState({});
  const [allFiles, setAllFiles] = useState([]);
  const [isDownloading, setIsDownLoading] = useState(false);
  const info = { ...surveyInfo, ...projInfo };
  const params = qs.parse(window.location.search);
  const { id } = useParams();

  const onViewClose = () => {
    setShowView(false);
  };

  const vesselDetails = () => {
    getVesselDetails({ id: params.vesselId }).then((res) => {
      if (res?.success) {
        setVesselInfo(res?.data);
      }
    });
  };

  const getVessel = () => {
    getVesselSurvey({ id: params.vesselId }).then((response) => {
      if (response?.success) {
        setSurveyInfo(response?.data);
      }
    });
  };

  const getAllAttachments = () => {
    getProjectAttachments({ id, phase: 1, isLifoOrder: false }).then((res) => {
      if (res?.success) {
        setAllFiles(res?.data);
      }
    });
  };

  const download = (docId) => {
    const payload = {
      projectId: id,
      categoryId: docId
    };
    setIsDownLoading(true);
    downloadAttachmentsByCategory(payload).then(() => {
      setIsDownLoading(false);
    });
  };

  useEffect(() => {
    vesselDetails();
    getVessel();
    getAllAttachments();
  }, []);

  return (
    <div data-testid="sidepaneldeets">
      <SidePanel
        title={(
          <div
            className="d-flex align-items-center"
          >
            <img className="thmb-sm ship-thumb" src={projInfo?.imageUrl || vesselThmd} alt="" />
            <h4
              className="head-16 white-space-preserve"
              data-tooltip-id="project-imagenames"
              data-tooltip-content={projInfo?.nameTooltip}
            >
              {projInfo?.name}
            </h4>
          </div>
)}
        size="large "
        showView={showView}
        onClose={onViewClose}
      >
        <Tooltip id="project-imagenames" place="top" className="tooltip" />
        <Tooltip id="project-imagename" place="top" className="tooltip mt-3" />
        <div className="list-tab-head mt-2 mx-4 d-flex gutter-2 border-down ">
          <div className={`tab-items ${activeComp === '0' ? (' active ') : ('')} px-5`} data-test="click1" onClick={() => { setActiveComp('0'); }}>
            Vessel Details
          </div>
          <div className={`tab-items ${activeComp === '1' ? (' active ') : ('')}  px-5 mx-2`} data-test="click2" onClick={() => { setActiveComp('1'); }}>
            Project Details
          </div>
          <div className={`tab-items ${activeComp === '2' ? (' active ') : ('')} px-5`} data-test="click3" onClick={() => { setActiveComp('2'); }}>
            Vessel Plans and Drawings
          </div>
        </div>
        <div className="m-4">
          {activeComp === '0' ? (
            <VesselDetails vesselInfo={vesselInfo} />
          ) : ('')}
          {activeComp === '1' ? (
            <ProjectDetails
              info={info}
              projInfo={projInfo}
              IsemergencyDryDock={IsemergencyDryDock}
            />
          ) : ('')}
          {activeComp === '2' ? (
            <Attachments
              allFiles={allFiles}
              download={download}
              isDownloading={isDownloading}
              getAllAttachments={getAllAttachments}
              projectDetails={{ ...projInfo }}
              deleteProjectAttachment={deleteProjectAttachment}
            />
          ) : ('')}
        </div>
      </SidePanel>
    </div>
  );
};
export default SidePanelDeets;
