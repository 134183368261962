import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import Card from './Card';
import ProjectDetails from './ProjectDetails';
import ListofJobs from './ListofJobs';
import Attachments from './Attachments';
import VesselInfo from './vesselInfo';
import { getProjectDetailsTSI, getProjectAttachments, getNotification, getVesselDetails, downloadAttachmentsByCategory, deleteProjectAttachment } from '../../NewProject/services';
import MajorSpecPackagesList from '../../../../Admin/DataConfig/SpecificationSetup/SpecPackages/MajorSpecPackagesList';
// import Loader from '../../../../common/loader2';
import Loader2 from '../../../../common/loader2';
import helper from '../../../../../utils/helper';

const Plan = () => {
  const { id } = useParams();
  const { roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = (userRole === roles.VESSEL_USER);
  const params = qs.parse(window.location.search);
  const [projectDetails, setProjectDetails] = useState();
  const [attachments, setAttatchments] = useState();
  const [isExpand, setIsExpand] = useState();
  const [surveyInfo, setSurveyInfo] = useState({});
  const [vesselInfo, setVesselInfo] = useState({});
  const [isDownloading, setIsDownLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [isPlannedDryDock, setIsPlannedDryDock] = useState(false);
  const [IsemergencyDryDock, setIsEmergencyDryDock] = useState(false);
  const getDetails = () => {
    getProjectDetailsTSI({ id }).then((response) => {
      setLoading(false);
      if (response?.success) {
        setProjectDetails(response?.data);
        // setIsPlannedDryDock(false);
        if (response?.data?.projectType?.toLowerCase()?.includes('emergency')) {
          setIsEmergencyDryDock(true);
        } else {
          setIsEmergencyDryDock(false);
        }
      }
    });
  };
  const getAllAttachments = () => {
    getProjectAttachments({ id, phase: 1, isLifoOrder: false }).then((response) => {
      setLoading(false);
      if (response?.success) {
        setAttatchments(response?.data);
      }
    });
  };
  const getSurveyNotifications = () => {
    getNotification({ id: params.vesselId }).then((res) => {
      setLoading(false);
      if (res.data) {
        setSurveyInfo(res.data);
      }
    });
  };
  const getVesselInfo = () => {
    getVesselDetails({ id: params.vesselId }).then((res) => {
      setLoading(false);
      if (res?.success) {
        setVesselInfo(res?.data);
      }
    });
  };
  const download = (docId) => {
    const payload = {
      projectId: id,
      categoryId: docId
    };
    setIsDownLoading(true);
    downloadAttachmentsByCategory(payload).then(() => {
      setLoading(false);
      setIsDownLoading(false);
    });
  };
  useEffect(() => {
    getDetails();
    getAllAttachments();
    getSurveyNotifications();
    getVesselInfo();
    setLoading(true);
  }, []);
  return (
    <div data-testid="indexpage" className="creat-p">
      {!loading ? (
        <div className="col-lg-8  col-sm-12 card m-auto p-3em mb-50 ">
          <div><Card projectDetails={projectDetails} /></div>
          <div className="line-card p-5 mb-4">
            <VesselInfo vesselInfo={vesselInfo} />
          </div>
          <div className="line-card p-5 mb-4">
            <ProjectDetails projectDetails={{ ...projectDetails, ...surveyInfo }} />
          </div>
          {projectDetails?.projectJobList.length > 0 && !IsemergencyDryDock
            && (
              <div className=""><ListofJobs projectDetails={projectDetails} /></div>
            )}
          {projectDetails?.specificationList.length > 0 && IsemergencyDryDock
            && (
              <div className=" pt-5">
                <p className="head-14">GT specs - Emergency Dry Docking</p>
                <MajorSpecPackagesList
                  isExpand={isExpand}
                  formDataToFillList={projectDetails?.specificationList}
                  setIsExpand={setIsExpand}
                  from="confirm"
                />
              </div>
            )}
          <div className="">
            <Attachments
              attachments={attachments}
              download={download}
              isDownloading={isDownloading}
              getAllAttachments={getAllAttachments}
              projectDetails={{ ...projectDetails, ...surveyInfo }}
              deleteProjectAttachment={deleteProjectAttachment}
              getDetails={getDetails}
            />
          </div>
        </div>
      ) : <div className="col-8 m-auto p-3em mb-50"><Loader2 /></div>}
      {isVesselUser && (
        <div className="footer justify-content-between font-13">
          <div className="vessel-footer">
            {`© ${new Date().getFullYear()} ThinkPalm`}
          </div>
        </div>
      )}

    </div>

  );
};
export default Plan;
