import React from 'react';
import moment from 'moment/moment';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import Pagination from '../../../../common/pagination';

const CostUpdateReportComponent = ({ tableData, setPage, setPageSize, page, pageSize,
  formatNumber, downloadReport }) => {
  const pageClick = (e) => {
    setPage(e.selected + 1);
    window.scrollTo(0, 0);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };
  return (
    <div data-testid="CostUpdateReportComponent">
      {tableData && tableData?.costData && tableData?.costData.items?.length ? (
        <div className="scroll-wrap scroll-wrap1">
          {tableData?.costData.items.map((i) => {
            return (
              <div className="border p-3 mt-4">
                <div>
                  <div className="d-flex">
                    <div className="head-14">{i.serviceLine}</div>
                    <div className="mx-4">-</div>
                    <div className="head-14">{i.serviceLineName}</div>
                  </div>
                  <div className="bg-blue font-16 font-bold mt-2 py-2 row">
                    <div className="d-flex  col-3">
                      <div className="ms-2 ">Date</div>
                    </div>
                    <div className="d-flex col-1 ">
                      <div className="ms-2 ">Quantity</div>
                    </div>
                    <div className="d-flex  col-2">
                      <div className="ms-2 ">Unit Price</div>
                    </div>
                    <div className="d-flex col-2 ">
                      <div className="ms-2 ">Updated Quantity</div>
                    </div>
                    <div className="d-flex  col-2">
                      <div className="ms-2 ">Updated Unit Price</div>
                    </div>
                    <div className="d-flex col-2 ">
                      <div className="ms-2 ">Description</div>
                    </div>
                  </div>

                  {i.costUpdateLists
                    && i.costUpdateLists.map((c) => {
                      return (
                        <div className="pt-3 row">
                          <div className="d-flex  col-3">
                            <div className="">
                              {c.updatedAt
                                ? (
                                  <div>
                                    {moment.utc(c.updatedAt.split('.')[0]).format('DD-MMM-YYYY HH:mm')}
                                    {' '}
                                    (UTC)
                                  </div>
                                )
                                : ''}

                            </div>
                          </div>
                          <div className="d-flex col-1 ">
                            <div className="ms-2">{c.quantity}</div>
                          </div>
                          <div className="d-flex  col-2">
                            <div className="ms-2">
                              {tableData.currencySymbol}
                              {' '}
                              {c.rate ? formatNumber(c.rate) : 0}
                            </div>
                          </div>
                          <div className="d-flex col-2 ">
                            <div className="ms-2">{c.updatedQuantity}</div>
                          </div>
                          <div className="d-flex  col-2">
                            <div className="ms-2">
                              {tableData.currencySymbol}
                              {' '}
                              {c.updatedRate ? formatNumber(c.updatedRate) : 0}
                            </div>
                          </div>
                          <div className="d-flex col-2 ">
                            <div className="ms-2 text-ellipsis" data-tooltip-id="project-variation" data-tooltip-content={c.description}>
                              {c.description}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
          {tableData?.costData?.totalItems > 10 && (
            <Pagination
              pageClick={pageClick}
              pageCount={tableData?.costData?.totalPages}
              currentpage={page}
              onSizeChange={onSizeChange}
              size={pageSize}
            />
          )}
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-6">
              <div className=" footer-jobprogress">{`© ${new Date().getFullYear()} ThinkPalm`}</div>

            </div>
            <button
              onClick={() => downloadReport()}
              type="button"
              className="primary-btn large mx-3"
            >
              Download Report
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
            <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
            <div className="font-18 animate__animated animate__headShake">No data found</div>
          </div>
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-6">
              <div className="mb-2 mt-2">{`© ${new Date().getFullYear()} ThinkPalm`}</div>

            </div>
          </div>
        </>
      )}
      <Tooltip id="project-variation" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default CostUpdateReportComponent;
