import axios from 'axios';
import { serviceHandler } from '../../../utils/serviceHandler';
import apiEndPoints from '../../../utils/apiUrlConstants';
import helper from '../../../utils/helper';

const {
  ProjectSpecList,
  ProjectSpecDetailsList,
  CurrencyList,
  SaveQuote,
  UpdateQuoteStatus,
  TermsAndCondition,
  DownloadDocuments,
  YardS3Upload,
  YardUom,
  DownloadQuoteAsExcel,
  ProjectSpecDetails,
  GetRfqVersionUrl
} = apiEndPoints;
const { downloadFile, getExportedFileName } = helper;

export const projectSpecList = (payload) => {
  return serviceHandler({
    url: `${ProjectSpecList}?key=${payload.key}`,
    methordType: 'get',
    payload
  });
};
export const projectSpecDetailsList = (payload) => {
  return serviceHandler({
    url: `${ProjectSpecDetailsList}?projectSpecId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const currencyList = (payload) => {
  return serviceHandler({
    url: `${CurrencyList}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const saveQuote = (payload) => {
  return serviceHandler({
    url: `${SaveQuote}`,
    methordType: 'post',
    payload
  });
};
export const updateQuoteStatus = (payload) => {
  return serviceHandler({
    url: `${UpdateQuoteStatus}`,
    methordType: 'post',
    payload
  });
};
export const termsAndCondition = (payload) => {
  return serviceHandler({
    url: `${TermsAndCondition}?key=${payload.key}`,
    methordType: 'get',
    payload
  });
};

export const downloadDocuments = (payload) => {
  return axios
    .get(`${DownloadDocuments}?key=${payload.key}&isManual=${payload.isManual}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const yardS3Upload = (payload) => {
  return serviceHandler({
    url: `${YardS3Upload}`,
    methordType: 'post',
    payload
  });
};
export const getPublishUomList = (payload) => {
  return serviceHandler({
    url: `${YardUom}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const projectSpecDetails = (payload) => {
  return serviceHandler({
    url: `${ProjectSpecDetails}?projectSpecId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const quoteExcelExport = (rfqId) => {
  return axios
    .get(`${DownloadQuoteAsExcel}?projectRfqId=${rfqId}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const getRfqVersion = (payload) => {
  return serviceHandler({
    url: `${GetRfqVersionUrl}?projectId=${payload.projectId}&yardId=${payload.yardId}`,
    methordType: 'get'
  });
};
