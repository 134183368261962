import React, { useState } from 'react';

import SideBarHeader from './SideBarHeader';
import SectionsList from './SectionsList';
import CostSummaryNames from './CostSummaryNames';
import AddServiceLine from '../AddServiceLine';

const SideBarHome = (props) => {
  const { newServiceLine, units, acCodes, newACCode, setnewACCode } = props;
  const [addServiceLine, setAddServiceLine] = useState(false);
  const [specData, setSpecData] = useState('');
  const [newSL, setNewSL] = useState({});

  const onClose = () => {
    setAddServiceLine(false);
  };

  return (
    <div className="data-container-cq mb-container text-xl w-35">
      <AddServiceLine
        addServiceLine={addServiceLine}
        onClose={onClose}
        specData={specData}
        newServiceLine={newServiceLine}
        units={units}
        acCodes={acCodes}
        newSL={newSL}
        setNewSL={setNewSL}
        newACCode={newACCode}
        setnewACCode={setnewACCode}
      />
      <SideBarHeader {...props} />
      <SectionsList setSpecData={setSpecData} setAddServiceLine={setAddServiceLine} {...props} />
      <CostSummaryNames {...props} />
    </div>
  );
};

export default SideBarHome;
