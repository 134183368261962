/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '../../../../common/loader';
// import Footer from '../../../../Login/common/footer';
import Header from '../../../Header';
import SpecCard from '../../PhasePage/Repair/Children/SpecCard';
import FooterApproval from '../../PhasePage/Tender/CompareQuotes/FooterApproval';
import { getAllSpecList } from './Services';

const ClosedVessel = () => {
  const [expandedSidebar, setExpandedSidebar] = useState(true);
  const [specList, setSpecList] = useState({});
  const [mainSec, setMainSec] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showsubSec, setShowSubSec] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showGallery, setShowGallery] = useState(false);
  const { state } = useLocation();
  console.log(state, 'aaaa');
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };

  const showGalleryDeets = (spec) => {
    setShowGallery(spec);
  };

  const onCloseGallery = () => {
    setShowGallery(false);
  };

  const a = useLocation();
  const id = a.search.replace('?id=', '');
  const getSpecListData = () => {
    getAllSpecList({ projectId: id, searchString: searchText }).then((resp) => {
      if (resp.success) {
        setSpecList(resp.data);
        setLoading(false);
      }
    });
  };
  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getSpecListData();
    }
  };
  useEffect(() => {
    if (!searchText) {
      getSpecListData();
    }
  }, [searchText]);
  useEffect(() => {
    setLoading(true);
  }, []);
  const onReloadSpecData = () => {
    getSpecListData();
  };
  const [showAdd, setShowAdd] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [file, setFile] = useState('');
  const [allData, setAllData] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [newImage, setNewImage] = useState({ specId: showGallery.projectSpecId, imageName: '', imageDescription: '', imageUrlList: [] });

  return (
    <div data-testid="ClosedVessel">
      {' '}
      <div className="page-wrapper">
        <div className="header" data-testid="admin-main">
          <Header />
        </div>
        <div className="sub-header" data-testid="data-config">
          <div className="d-flex  col-gap-3">
            <div className="d-flex justify-content-start col-10 ">
              <button onClick={() => setExpandedSidebar(!expandedSidebar)} className="link-btn ms-5 me-5 hamb-menu col-gap-6" type="button">
                {expandedSidebar ? <span className="icon-menu" /> : <span className="icon-arrow-thin-right" />}
              </button>
              <h6 className="main-head ms-5">{state?.projectDetails?.projName}</h6>
              {/* {count === '' ? (
                <span />
              ) : (
                <button type="button" className="mt-1 primary-btn small count-badge">
                  {count}
                </button>
              )} */}
            </div>
            {/* <div className="ms-5">
              <button
                onClick={() => {
                  navigate(`/user/projects`);
                }}
                type="button"
                className="rnd-btn"
              >
                Back
              </button>
            </div> */}
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="dd-history-footer">
              {/* {specList?.specQuoteList?.length > 0 && ( */}
              <div className="card  mx-5 mb5em">
                <div className="pt-5 ps-5 d-flex">
                  <span className="icon-yard brand-color large  md-radius bg-white p-2 ms-3 font-35 " />
                  <div>
                    <div className="font-14 head-14 ms-4">{state?.projectDetails?.yardName}</div>
                    {/* <div className="ms-4">
                    {state?.projectDetails?.managementName}
                  </div> */}
                  </div>
                  <div className="border-down mt-2" />
                </div>
                <div className="d-flex m-5 col-gap-1 spec-search">
                  <div className="search large search-large spec-search ms-4">
                    <div className="d-flex justify-content-end">
                      <span
                        className={searchText ? ' icon-close-line ' : ''}
                        onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
                      />
                      <span
                        className="icon-search"
                        id="lens"
                        onClick={() => {
                          onSearch({ key: 'Enter' });
                        }}
                      />
                    </div>
                    <input
                      type="text"
                      value={searchText}
                      className="text-box large with-close"
                      placeholder="Search by Sec No.,Sec Name, Spec No., Spec Name, ServiceLine No., ServiceLine Name "
                      id="search"
                      onKeyPress={onSearch}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="border-down mt-3" />
                {specList?.specQuoteList?.length < 1 && <div className="d-flex justify-content-center mt-4 opacity-6">No data found</div>}
                {specList?.specQuoteList?.length > 0 && (
                <div className="px-4">
                  {' '}
                  <div className="px-4">
                    <SpecCard
                      specList={specList}
                      setSpecList={setSpecList}
                      onSecOpen={onSecOpen}
                      setMainSec={setMainSec}
                      mainSec={mainSec}
                      showGalleryDeets={showGalleryDeets}
                      onCloseGallery={onCloseGallery}
                      showGallery={showGallery}
                      setShowGallery={setShowGallery}
                      onReloadSpecData={onReloadSpecData}
                      newImage={newImage}
                      setNewImage={setNewImage}
                      fileNames={fileNames}
                      setFileNames={setFileNames}
                      allData={allData}
                      setAllData={setAllData}
                      file={file}
                      setFile={setFile}
                      confirmDelete={confirmDelete}
                      setConfirmDelete={setConfirmDelete}
                      showAdd={showAdd}
                      setShowAdd={setShowAdd}
                      showsubSec={showsubSec}
                      projectId={id}
                    />
                  </div>
                </div>
                )}
              </div>

            </div>

            <FooterApproval fromFooter />

          </>
        )}

      </div>
    </div>
  );
};

export default ClosedVessel;
