import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <div className="container copy-right">{`© ${new Date().getFullYear()} ThinkPalm`}</div>
    </div>
  );
}
export default Footer;
