/* eslint-disable no-unused-vars */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const ReceivedQuotesHeader = (props) => {
  const { onSearch, setSearchText, specList, searchText } = props;
  return (
    <>
      <div className="bg-sky-white p-3 d-flex flex-wrap sm-radius mt-4" data-testid="received-header">
        <div className="col-2">
          <label>Yard Name :</label>
        </div>

        <div className="col-4">
          <div
            className="value text-ellipsis"
          >
            <span
              data-tooltip-id="project-rfqData"
              data-tooltip-content={specList?.rfqData?.yardName}
            >
              {specList?.rfqData?.yardName}
            </span>

          </div>
          <Tooltip id="project-rfqData" place="top" className="tooltip mt-3" />
        </div>
        <div className="col-2">
          <label>Days In Dock :</label>
        </div>
        <div className="col-4">
          <div className="value text-ellipsis">{specList?.rfqData?.inDockDays}</div>
        </div>
        <div className="col-2">
          <label>Days Afloat :</label>
        </div>
        <div className="col-4">
          <div className="value text-ellipsis">{specList?.rfqData?.daysAfloat}</div>
        </div>
        <div className="col-2">
          <label>Total Repair days :</label>
        </div>
        <div className="col-4">
          <div className="value text-ellipsis">{specList?.rfqData?.repairDays}</div>
        </div>
        <div className="col-2">
          <label>
            Discount [%] :
          </label>

        </div>
        <div className="col-4">
          <div className="value text-ellipsis">{specList?.rfqData?.discountPercentage}</div>
        </div>
        <div className="col-2">
          <label>
            Payment terms :
          </label>

        </div>
        <div className="col-4">
          <div
            className="value text-ellipsis"
          >
            <span
              data-tooltip-id="project-rfqData"
              data-tooltip-content={specList?.rfqData?.paymentTerms}
            >
              {specList?.rfqData?.paymentTerms ? specList?.rfqData?.paymentTerms : '---'}
            </span>

          </div>
        </div>
        <div className="col-2">
          <label>
            Yard Comments :
          </label>
        </div>
        <div className="col-4">
          <div
            className="value text-ellipsis"
          >
            <span
              data-tooltip-id="project-rfqData"
              data-tooltip-content={specList?.rfqData?.comment}
            >
              {specList?.rfqData?.comment ? specList?.rfqData?.comment : '---'}
            </span>

          </div>
        </div>
        <div className="col-2">
          <label>
            Yard Quote Type :
          </label>
        </div>
        <div className="col-4">
          <div className="value text-ellipsis">{specList?.rfqData?.isManual ? 'Excel' : 'Application'}</div>
        </div>
      </div>
      <div className="border-down my-3" />
      <div>
        <div className="d-flex justify-content-between align-items-center my-4">
          <div className="search large search-large spec-search ">
            <div className="d-flex justify-content-end">
              <span
                className={searchText ? ' icon-close-line ' : ''}
                data-test="search-click1"
                onClick={() => { setSearchText(''); document.getElementById('search')?.focus(); }}
              />
              <span
                className="icon-search"
                id="search-click"
                onClick={() => {
                  onSearch({ key: 'Enter' });
                }}
              />
            </div>
            <input
              type="text"
              className="text-box large with-close"
              placeholder="Search by Sec No.,Sec Name, Spec No., Spec Name, ServiceLine No., ServiceLine Name"
              value={searchText}
              onKeyPress={onSearch}
              id="search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          <div>
            <input
              type="text"
              data-tooltip-id="project-rfqData"
              data-tooltip-content={`Currency ${specList?.rfqData?.currencyName}`}
              className="text-box medium text-ellipsis "
              disabled
              value={`Currency ${specList?.rfqData?.currencyName}`}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ReceivedQuotesHeader;
