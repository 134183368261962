import axios from 'axios';
import { serviceHandler } from '../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../utils/apiUrlConstants';
import helper from '../../../../../utils/helper';

const {
  // GetAllProjectSpecList
  RepairSpecList,
  RepairSpecDetails,
  SaveSectionDetails,
  SaveWorkOrderStatus,
  RepairSpecUsers,
  getProjectRepairUrl,
  saveProjectRepairUrl,
  GetGalleryImages,
  SaveGalleryImages,
  NotCompletedSpecList,
  getProjectRepairCostUrl,
  saveProjectRepairCostUrl,
  JobDoneAttachment,
  JobDoneAttachments,
  getProjectRepairCostSummaryUrl,
  ProjectSpecStatusData,
  GetCancelledJob,
  ProjectRepairClose,
  startProjectRepairUrl,
  getProjectRepairDataUrl,
  RepairDatesUrl,
  cancelledDefferredJobCount,
  NotCompletedTask,
  CostSummaryExportUrl,
  TRDHistory,
  SaveRepairSpecAttachment,
  DeleteRepairAttachment,
  lockSpecUrl,
  getSpecListForDownloadUrl,
  createDraftSpecUrl,
  replaceDraftSpecUrl,
  GetSupervisorUsersUrl,
  UpdateSpecJobStatusUrl,
  mergeDraftSpecUrl
} = apiEndPoints;
const { downloadFile, getExportedFileName } = helper;

export const getAllSpecList = (payload) => {
  return serviceHandler({
    url: `${RepairSpecList}?projectId=${payload.id}&searchString=${payload.type}&jobStatus=${payload.jobStatus}`,
    methordType: 'get',
    payload
  });
};
export const getSpecListForDownload = (payload) => {
  return serviceHandler({
    url: `${getSpecListForDownloadUrl}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const getGalleryImages = (payload) => {
  return serviceHandler({
    url: `${GetGalleryImages}?Id=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const deleteGalleryImages = (payload) => {
  return serviceHandler({
    url: `${GetGalleryImages}?specId=${payload.id}&imageId=${payload.imageId}`,
    methordType: 'delete',
    payload
  });
};
export const saveGalleryImages = (payload) => {
  return serviceHandler({
    url: `${SaveGalleryImages}`,
    methordType: 'post',
    payload
  });
};
export const notCompletedSpecList = (payload) => {
  return serviceHandler({
    url: `${NotCompletedSpecList}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const repairSpecDetails = (payload) => {
  return serviceHandler({
    url: `${RepairSpecDetails}?projectSpecId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const saveSectionDetails = (payload) => {
  return serviceHandler({
    url: `${SaveSectionDetails}`,
    methordType: 'post',
    payload
  });
};
export const saveWorkOrderStatus = (payload) => {
  return serviceHandler({
    url: `${SaveWorkOrderStatus}`,
    methordType: 'post',
    payload
  });
};
export const repairSpecUsers = (payload) => {
  return serviceHandler({
    url: `${RepairSpecUsers}?vesselId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const getProjectRepair = (payload) => {
  return serviceHandler({
    url: `${getProjectRepairUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const getProjectRepairData = (payload) => {
  return serviceHandler({
    url: `${getProjectRepairDataUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const getProjectRepairCostSummary = (payload) => {
  return serviceHandler({
    url: `${getProjectRepairCostSummaryUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};

export const saveProjectRepair = (payload) => {
  return serviceHandler({
    url: `${saveProjectRepairUrl}`,
    methordType: 'post',
    payload
  });
};
export const getProjectRepairCost = (projectId, serviceLineId) => {
  return serviceHandler({
    url: `${getProjectRepairCostUrl}?projectId=${projectId}&serviceLineId=${serviceLineId}`,
    methordType: 'get'
  });
};

export const saveProjectRepairCost = (payload) => {
  return serviceHandler({
    url: `${saveProjectRepairCostUrl}`,
    methordType: 'post',
    payload
  });
};
export const jobDoneAttachment = (payload) => {
  return serviceHandler({
    url: `${JobDoneAttachment}`,
    methordType: 'post',
    payload
  });
};
export const deletejobAttachment = (payload) => {
  return serviceHandler({
    url: `${JobDoneAttachment}?specId=${payload.specId}&Id=${payload.id}`,
    methordType: 'delete',
    payload
  });
};
export const jobDoneAttachments = (payload) => {
  return serviceHandler({
    url: `${JobDoneAttachments}`,
    methordType: 'post',
    payload
  });
};
export const getCancelledJob = (payload) => {
  return serviceHandler({
    // url: `${GetAllProjectSpecList}?projectId=${payload.id}&searchString=${payload.type}`,
    url: `${GetCancelledJob}`,
    methordType: 'post',
    payload
  });
};
export const projectSpecStatusData = (payload) => {
  return serviceHandler({
    url: `${ProjectSpecStatusData}?projectId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const projectRepairClose = (payload) => {
  return serviceHandler({
    url: `${ProjectRepairClose}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};

export const startProjectRepair = (payload) => {
  return serviceHandler({
    url: `${startProjectRepairUrl}`,
    methordType: 'post',
    payload
  });
};

// RepairDatesUrl
export const repairDates = (payload) => {
  return serviceHandler({
    url: `${RepairDatesUrl}?projectId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const getCancelledDefferredJobCount = (payload) => {
  return serviceHandler({
    url: `${cancelledDefferredJobCount}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};
export const NotCompletedTasksDetails = (payload) => {
  return serviceHandler({
    url: `${NotCompletedTask}?phaseId=${payload.phaseId}&projectId=${payload.projectId}`,
    methordType: 'post',
    payload
  });
};
export const getTRDChanges = (payload) => {
  return serviceHandler({
    url: `${TRDHistory}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const fileDownloadCostSummary = (id) => {
  return axios
    .get(`${CostSummaryExportUrl}?projectId=${id}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const saveRepairSpecAttachment = (payload) => {
  return serviceHandler({
    url: `${SaveRepairSpecAttachment}`,
    methordType: 'post',
    payload
  });
};
export const deleteRepairAttachment = (payload) => {
  return serviceHandler({
    url: `${DeleteRepairAttachment}?projecId=${payload.projectId}&documentId=${payload.documentId}`,
    methordType: 'delete',
    payload
  });
};
export const lockSpec = (payload) => {
  return serviceHandler({
    url: `${lockSpecUrl}?id=${payload}`,
    methordType: 'get',
    payload
  });
};

export const createDraftSpec = (payload) => {
  return serviceHandler({
    url: `${createDraftSpecUrl}?specId=${payload}`,
    methordType: 'post',
    payload
  });
};

export const replaceDraftSpec = (payload) => {
  return serviceHandler({
    url: `${replaceDraftSpecUrl}?specId=${payload}`,
    methordType: 'post',
    payload
  });
};

export const getSupervisorUsers = (vesselId) => {
  return serviceHandler({
    url: `${GetSupervisorUsersUrl}?vesselId=${vesselId}`,
    methordType: 'get'
  });
};

export const updateSpecJobStatus = (specId, status) => {
  return serviceHandler({
    url: `${UpdateSpecJobStatusUrl}?id=${specId}&status=${status}`,
    methordType: 'get'
  });
};

export const mergeDraftSpec = (payload) => {
  return serviceHandler({
    url: `${mergeDraftSpecUrl}?specId=${payload}`,
    methordType: 'post',
    payload
  });
};
