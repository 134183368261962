import axios from 'axios';
import { serviceHandler } from '../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../utils/apiUrlConstants';
import helper from '../../../../../utils/helper';

const {
  SaveProjectSpecification,
  GetSpecificationbyProjectId,
  DeleteManualSpecification,
  AddManualSpecification,
  GetSpecLibraryList,
  DeleteExistingMajorSpecs,
  GetProjectSpecWorkOrderDefectList,
  DeleteProjectSpecWorkOrderDefect,
  GetDependentSpecMaster,
  GetDependentSpecs,
  SaveDependentSpecs,
  ProjSpecificationExportExcel,
  NonSpecTsiUrl,
  AcCodeMapping,
  SpecPdfExport,
  GetProjectSpecListUrl,
  UpdateSpecIsComplete,
  GetPrevProjectSpecs,
  ImportPrevProjSpec,
  GetEmptySpecList,
  GetEmptyAcCodeURL,
  ListKfpBySpec,
  UpdateKfp,
  UpdateSpecAssigne,
  UnmappedDeferredSpecList,
  specificationPdfExportUrl,
  GetEmptyServiceLineListUrl,
  ExportExcelForSpecLibraryUrl,
  ImportExcelUrl,
  SaveSpecificSpecsUrl
} = apiEndPoints;
const { downloadFile, getExportedFileName } = helper;

export const saveSpecification = (payload) => {
  return serviceHandler({
    url: `${SaveProjectSpecification}`,
    methordType: 'post',
    payload
  });
};

export const getSpecById = (payload) => {
  return serviceHandler({
    url: `${GetSpecificationbyProjectId}?projectId=${payload.id}&type=${payload.type}`,
    methordType: 'get',
    payload
  });
};
export const DeleteProjectSpec = (payload) => {
  return serviceHandler({
    url: `${DeleteProjectSpecWorkOrderDefect}?projectSpecificationId=${payload.projectSpecificationId}&workOrderId=${payload.workOrderId}`,
    methordType: 'post',
    payload
  });
};
export const ProjectSpecWorkOrderDefectList = (payload) => {
  return serviceHandler({
    url: `${GetProjectSpecWorkOrderDefectList}`,
    methordType: 'post',
    payload
  });
};
export const deleteManualSpec = (payload) => {
  return serviceHandler({
    url: `${DeleteManualSpecification}?id=${payload.id}`,
    methordType: 'put',
    payload
  });
};
export const addManualSpec = (payload) => {
  return serviceHandler({
    url: `${AddManualSpecification}`,
    methordType: 'post',
    payload
  });
};
export const getSpecLibraryList = (payload) => {
  return serviceHandler({
    url: `${GetSpecLibraryList}?projectId=${payload.id}&search=${payload.search}`,
    methordType: 'get',
    payload
  });
};
export const deleteExistingMajorSpecs = (payload) => {
  return serviceHandler({
    url: `${DeleteExistingMajorSpecs}?projectId=${payload.id}&specLibrarytype=${payload.enum}`,
    methordType: 'delete',
    payload
  });
};
export const getSpecMaster = (projectId, search) => {
  return serviceHandler({
    url: `${GetDependentSpecMaster}?projectId=${projectId}&search=${search}`,
    methordType: 'get'
  });
};
export const getSpecsDependent = (projectSpecId) => {
  return serviceHandler({
    url: `${GetDependentSpecs}?projectSpecId=${projectSpecId}`,
    methordType: 'get'
  });
};

export const saveDependentSpecs = (payload) => {
  return serviceHandler({
    url: `${SaveDependentSpecs}`,
    methordType: 'post',
    payload
  });
};
export const exportProjectSpecExcel = (projectId, projName) => {
  return axios
    .get(`${ProjSpecificationExportExcel}?projectId=${projectId}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, `RFQ-${projName}..xlsx`);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const specPdfExport = (projectId) => {
  return axios
    .get(`${SpecPdfExport}?projectId=${projectId}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const specificationPdfExport = (payload) => {
  return serviceHandler({
    url: `${specificationPdfExportUrl}`,
    methordType: 'post',
    payload
  });
};

// export const specificationPdfExport = (payload) => {
//   return axios
//     .post(`${specificationPdfExportUrl}`, payload, {
//       responseType: 'blob'
//     })
//     .then((response) => {
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       downloadFile(url, getExportedFileName(response));
//     })
//     .catch((errors) => {
//       Promise.reject(errors);
//     });
// };

export const getNonSpecTsi = (payload) => {
  return serviceHandler({
    url: `${NonSpecTsiUrl}?projectId=${payload}`,
    methordType: 'get',
    payload
  });
};

export const savegetNonSpecTsi = (payload) => {
  return serviceHandler({
    url: `${NonSpecTsiUrl}`,
    methordType: 'post',
    payload
  });
};
export const SaveAcCodeMapping = (payload) => {
  return serviceHandler({
    url: `${AcCodeMapping}`,
    methordType: 'put',
    payload
  });
};
export const deleteNonSpecTsi = (payload) => {
  return serviceHandler({
    url: `${NonSpecTsiUrl}?id=${payload}`,
    methordType: 'delete',
    payload
  });
};
export const GetProjectSpecList = (payload) => {
  return serviceHandler({
    url: `${GetProjectSpecListUrl}?projectId=${payload.projectId}&searchString=${payload.searchString}`,
    methordType: 'get',
    payload
  });
};
export const updateSpecIsComplete = (payload) => {
  return serviceHandler({
    url: `${UpdateSpecIsComplete}`,
    methordType: 'post',
    payload
  });
};
export const updateSpecAssigne = (payload) => {
  return serviceHandler({
    url: `${UpdateSpecAssigne}`,
    methordType: 'post',
    payload
  });
};
export const getPrevProjectSpecs = (payload) => {
  return serviceHandler({
    url: `${GetPrevProjectSpecs}?prevProjId=${payload.prevProjId}&currentProjId=${payload.currentProjId}`,
    methordType: 'get',
    payload
  });
};
export const GetEmptySpecificationList = (payload) => {
  return serviceHandler({
    url: `${GetEmptySpecList}?projectId=${payload.projectId}&type=${payload.type}`,
    methordType: 'get',
    payload
  });
};
export const GetEmptyServiceLineList = (payload) => {
  return serviceHandler({
    url: `${GetEmptyServiceLineListUrl}?projectId=${payload.projectId}&type=${payload.type}`,
    methordType: 'get',
    payload
  });
};
export const GetEmptyACCode = (payload) => {
  return serviceHandler({
    url: `${GetEmptyAcCodeURL}?projectId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const importPrevProjSpecs = (payload) => {
  return serviceHandler({
    url: `${ImportPrevProjSpec}?currentProjectId=${payload.currentProjectId}&prevProjectSpecIds=${payload.prevProjectSpecIds}`,
    methordType: 'put',
    payload
  });
};
export const listKfpBySpec = (payload) => {
  return serviceHandler({
    url: `${ListKfpBySpec}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const updateKfp = (payload) => {
  return serviceHandler({
    url: `${UpdateKfp}`,
    methordType: 'post',
    payload
  });
};
export const unmappedDeferredSpecList = (payload) => {
  return serviceHandler({
    url: `${UnmappedDeferredSpecList}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const ExportExcelForSpecLibrary = (projectId, projectName, type = 0) => {
  return axios
    .get(`${ExportExcelForSpecLibraryUrl}?projectId=${projectId}&type=${type}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const dateTime = new Date().toISOString().replace(/[:\-T]/g, '').slice(0, 14);
      const fileName = `${projectName}_${dateTime}.xlsx`;
      downloadFile(url, fileName);
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};
export const ImportExcel = (projectId, payload) => {
  return serviceHandler({
    url: `${ImportExcelUrl}?projectId=${projectId}`,
    methordType: 'post',
    payload
  });
};

export const SaveSpecificSpecs = (projectId, payload) => {
  return serviceHandler({
    url: `${SaveSpecificSpecsUrl}?projectId=${projectId}`,
    methordType: 'post',
    payload
  });
};
