import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';
import InputType1 from '../../../../common/InputType1';
import { getProjectSpecImages } from '../../Gallery/services';
import { downloadPhotoReport, getPhoto, savePhoto, getPhotoHistory } from '../services';
import showAlert from '../../../../../utils/alert';
import ModalBox from '../../../../common/ModalBox';
import Loader from '../../../../common/loader';
import helper from '../../../../../utils/helper';
import PhotoReportComponent from './PhotoReportComponent';
import ReportHistory from './ReportHistory';
// import SharePhotoReport from './SharePhotoReport';

const PhotoReport = (props) => {
  const { charValidation, roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isMaster = userRole === roles.VESSEL_USER;
  const { isTSI, projInfo } = props;
  const { id } = useParams();
  const [heading, setHeading] = useState('');
  const inImage = [
    {
      imageId: '',
      subImageName: '',
      imageName: '',
      imageDescription: '',
      imageUrl: '',
      order: 1
    },
    {
      imageId: '',
      subImageName: '',
      imageName: '',
      imageDescription: '',
      imageUrl: '',
      order: 2
    }
  ];
  const [images, setImages] = useState(inImage);

  const initialData = {
    projectId: id,
    imageList: [],
    heading: ''
  };
  const [allData, setAllData] = useState(initialData);
  const [openDeleteModal, setOpenDeleteModal] = useState('');
  const [activeImageIndex, setActiveImageIndex] = useState('');
  const [galleryImages, setGalleryImages] = useState();
  const [currentpage, setCurrentpage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [canDownload, setCanDownload] = useState(false);
  const [show, setShow] = useState(false);
  // const [shareReport, setShareReport] = useState(false);
  const [list, setList] = useState(false);

  const handleAddMore = () => {
    const i = cloneDeep(images);
    i.push({
      imageId: '',
      subImageName: '',
      imageName: '',
      imageDescription: '',
      imageUrl: '',
      order: images.length + 1
    });
    i.push({
      imageId: '',
      subImageName: '',
      imageName: '',
      imageDescription: '',
      imageUrl: '',
      order: images.length + 2
    });
    setImages(i);
  };
  const hanleNameChange = (n, index) => {
    const i = cloneDeep(images);
    i[index].imageDescription = charValidation(n, 200);
    setImages(i);
  };
  const handleDeleteImage = (index) => {
    console.log(index, images);
    const i = cloneDeep(images);
    // i.splice(index, 1);
    i[index].imageId = '';
    i[index].subImageName = '';
    i[index].imageName = '';
    i[index].imageUrl = '';
    i[index].imageDescription = '';
    setImages(i);
    setOpenDeleteModal('');
    showAlert('Please click save as draft to include corrected data in the final Report', 'warning');
  };

  const getImages = () => {
    const payload = {
      page: currentpage,
      pageSize,
      search: '',
      sortProperty: '',
      isDescending: true,
      projectId: id
    };
    getProjectSpecImages(payload).then((res) => {
      setLoading(false);
      setGalleryImages(res?.data);
    });
  };
  useEffect(() => {
    getImages();
  }, [currentpage, pageSize]);
  useEffect(() => {
    setLoading(true);
  }, []);
  const setActiveImageIndexWraper = (indexImage) => {
    const payload = {
      page: currentpage,
      pageSize,
      search: '',
      sortProperty: '',
      isDescending: true,
      projectId: id
    };
    getProjectSpecImages(payload).then((res) => {
      setLoading(false);
      setGalleryImages(res?.data);
      setActiveImageIndex(indexImage);
    });
  };
  const reloadTable = () => {
    getPhoto(id).then((res) => {
      setLoading(false);
      setAllData(res.data);
      // if (res.data.id && res.data.id !=) {
      //   setCanDownload(false);
      // } else {
      //   setCanDownload(true);
      // }
      setHeading(res.data.heading || '');
      const imList = res.data.imageList || [];
      if (imList.length) {
        if (imList.length % 2 === 0) {
          setImages(imList);
        } else {
          imList.push({
            imageId: '',
            subImageName: '',
            imageName: '',
            imageDescription: '',
            imageUrl: '',
            order: imList[imList.length - 1]?.order + 1
          });
          setImages(imList);
        }
      } else {
        setImages(inImage);
      }
    });
    getPhotoHistory(id).then((res) => {
      setLoading(false);
      setList(res.data);
    });
  };

  useEffect(() => {
    reloadTable();
  }, []);
  const pageClick = (e) => {
    setCurrentpage(e.selected + 1);
  };
  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentpage(1);
  };
  const handleUploadImage = (data) => {
    let found = false;
    images.forEach((ele) => {
      const sm = data.imageId;
      if (!found && sm === ele.imageId) {
        found = true;
      }
    });
    if (found) {
      showAlert('Image already added', 'error');
    } else {
      const i = cloneDeep(images);
      i[activeImageIndex].imageId = data.imageId;
      i[activeImageIndex].subImageName = String(data.subImageName + data.subNumber);
      i[activeImageIndex].imageName = data.imageName;
      i[activeImageIndex].imageUrl = data.imageUrl;
      setImages(i);
      setActiveImageIndex('');
    }
  };
  const save = () => {
    const f = cloneDeep(allData);
    f.heading = heading;
    const v = heading.trim();
    f.projectId = id;
    const imArray = [];
    images.forEach((ele) => {
      if (ele.imageId) {
        imArray.push(ele);
      }
    });
    f.imageList = imArray;
    if (imArray.length === 0) {
      showAlert('Please upload Image', 'error');
    } else if (!v || v === null || v === '' || v === ' ') {
      showAlert('Please enter Heading', 'error');
    } else {
      savePhoto(f).then((res) => {
        if (res.success) {
          setLoading(false);
          showAlert('Successfully saved as draft', 'success');
          reloadTable();
        }
      });
    }
  };
  const generateReport = () => {
    const f = cloneDeep(allData);
    f.heading = heading;
    const v = heading.trim();
    f.projectId = id;
    const imArray = [];
    images.forEach((ele) => {
      if (ele.imageId) {
        imArray.push(ele);
      }
    });
    f.imageList = imArray;
    if (imArray.length === 0) {
      showAlert('Please upload Image', 'error');
    } else if (!v || v === null || v === '' || v === ' ') {
      showAlert('Please enter Heading', 'error');
    } else {
      savePhoto(f).then((respo) => {
        if (respo.success && respo.data) {
          setLoading(false);
          downloadPhotoReport(id, respo.data.id).then(() => {
            setHeading('');
            reloadTable();
            setOpenModal(false);
          });
        } else {
          setOpenModal(false);
        }
      });
    }
  };
  const isReport = true;
  return (
    <div className="" data-testid="photo-report-t">
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="align-items-center d-flex justify-content-between report-ctrl">
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center me-4">
                <div className="font-20 font-bold">Photo Report</div>
              </div>
            </div>
            <div className="d-flex">
              <button id="report-history" disabled={!list.length} onClick={() => setShow(true)} type="button" className="secondary-btn ">
                Report History
              </button>
              <button type="button" className="secondary-btn ms-3 hide" onClick={() => setShareReport(true)}>
                Share
              </button>
            </div>
          </div>
          <div className="photo-report border-down">
            <div className="report-canvas mt-4">
              <div className="p-4">
                <InputType1
                  value={heading}
                  onChange={(e) => {
                    setHeading(e.target.value);
                  }}
                  disabled={!isTSI}
                  className="report-head mb-5 mx-auto py-5 text-box text-center"
                  placeholder="Add Header"
                  // data-tooltip-id="project-imageDescription"
                  // data-tooltip-content={heading}
                />
                <PhotoReportComponent
                  setActiveImageIndexWraper={setActiveImageIndexWraper}
                  images={images}
                  activeImageIndex={activeImageIndex}
                  isReport={isReport}
                  galleryImages={galleryImages}
                  loading={loading}
                  isTSI={isTSI}
                  currentpage={currentpage}
                  pageSize={pageSize}
                  setOpenDeleteModal={setOpenDeleteModal}
                  setActiveImageIndex={setActiveImageIndex}
                  hanleNameChange={hanleNameChange}
                  getImages={getImages}
                  setLoading={setLoading}
                  setCurrentpage={setCurrentpage}
                  handleUploadImage={handleUploadImage}
                  pageClick={pageClick}
                  onSizeChange={onSizeChange}
                  id={id}
                />
              </div>
            </div>
            {isTSI && (
              <div>
                <div className="my-4 text-center">
                  <button disabled={projInfo?.isClosed} id="add-more" onClick={() => handleAddMore()} className="link-btn large" type="button">
                    + Add New Row
                  </button>
                </div>
                <div className="fixed-footer card d-flex justify-content-end ">
                  <div className="text-black font-12 col-6">
                    <div className=" footer-photoreport">{`© ${new Date().getFullYear()} ThinkPalm`}</div>

                  </div>
                  <button disabled={projInfo?.isClosed} id="save" onClick={() => save()} type="button" className="large secondary-btn mx-3">
                    Save as Draft
                  </button>
                  {isMaster ? (
                    ''
                  ) : (
                    <button
                      id="download"
                      // disabled={canDownload}
                      disabled={projInfo?.isClosed}
                      onClick={() => setOpenModal(true)}
                      type="button"
                      className="large primary-btn"
                    >
                      Save and Download Report
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          {openDeleteModal !== '' ? (
            <ModalBox
              openDeleteModal={openDeleteModal}
              modalClassName="medium"
              heading="Delete Image"
              onSave={() => handleDeleteImage(openDeleteModal)}
              closeModal={() => setOpenDeleteModal('')}
              buttonName="Delete"
            >
              <p>Are you sure you want to delete image ?</p>
            </ModalBox>
          ) : (
            ''
          )}
        </div>
      )}
      {openModal ? (
        <ModalBox
          openDeleteModal={openModal}
          modalClassName="medium"
          heading="Save and Download Report"
          onSave={() => generateReport()}
          closeModal={() => setOpenModal(false)}
          buttonName="Yes"
          isNoLabel="No"
        >
          {loading ? <Loader /> : <p>Are you sure you want to save and download Report ?</p>}
        </ModalBox>
      ) : (
        ''
      )}
      {show ? <ReportHistory show={show} setShow={setShow} list={list} /> : ''}
      {/* {shareReport ? (
        <SharePhotoReport
          shareReport={shareReport}
          setShareReport={setShareReport}
        />
      ) : ''} */}
      <Tooltip id="project-imageDescription" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default PhotoReport;
