/* eslint-disable jsx-a11y/no-autofocus */
import moment from 'moment';
import React from 'react';
import Select from 'react-select';
import helper from '../../../../../utils/helper';
// import 'react-dates/lib/css/_datepicker.css';
// import 'react-dates/initialize';
import InputType1 from '../../../../common/InputType1';

const ProjectInfo = (props) => {
  const {
    surveyType,
    projectCurrency,
    projectType,
    vesselManagementUsers,
    Priority,
    handleChange,
    formData,
    currencyName,
    isPlannedDryDock,
    isTsiDisabled,
    ismonth,
    isyear,
    setMonth,
    setYear,
    pType,
    setPType,
    handleDates,
    projectDates
  } = props;
  const { roles } = helper;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const years = Array.from({ length: 100 }, (_, i) => `${i + 1950}`);
  const customFilter = (option, searchText) => {
    // Compare the option label with the search text
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  };
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isTSI = userRole === roles.AUTHOR;
  return (
    <div data-testid="project-info">
      <div className="border-down" />
      <div className="row gutter-4 my-4 ">
        <div className="col-6">
          <div className="invisible-table">
            <div className="d-flex align-items-center">
              <label className="col-8">
                {' '}
                Drydocking Due Date
                <span className="text-primaryRed-1 ms-2">*</span>
              </label>
              {isTSI
                ? (
                  <input
                    className="text-box large ms-1 col-4"
                    type="date"
                    value={projectDates?.dryDockingSurveyDueDate ? moment(projectDates?.dryDockingSurveyDueDate).format('YYYY-MM-DD') : '-'}
                    onChange={(e) => { handleDates(e, 'dryDockingSurveyDueDate'); }}
                  />
                )
                : <div className="value col-4">{projectDates?.dryDockingSurveyDueDate ? moment(projectDates?.dryDockingSurveyDueDate).format(' DD-MMM-YYYY') : '-'}</div>}
            </div>
            <div className="d-flex align-items-center mt-4">
              <label className="col-8">
                Intermediate Hull Survey Due Date
                <span className="text-primaryRed-1 ms-2">*</span>
              </label>
              {isTSI
                ? (
                  <input
                    className="text-box large ms-1 col-4"
                    type="date"
                    value={projectDates?.intermediateSurveyDueDate ? moment(projectDates?.intermediateSurveyDueDate).format('YYYY-MM-DD') : '-'}
                    onChange={(e) => { handleDates(e, 'intermediateSurveyDueDate'); }}
                  />
                )
                : <div className="value col-4">{projectDates?.intermediateSurveyDueDate ? moment(projectDates?.intermediateSurveyDueDate).format(' DD-MMM-YYYY') : '-'}</div>}
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="invisible-table">
            <div className="d-flex align-items-center">
              <label className="col-8">
                Tailshift Survey Due Date
                <span className="text-primaryRed-1 ms-2">*</span>
              </label>
              {isTSI
                ? (
                  <input
                    className="text-box large ms-1 col-4"
                    type="date"
                    value={projectDates?.tailshaftSurveyDueDate ? moment(projectDates?.tailshaftSurveyDueDate).format('YYYY-MM-DD') : '-'}
                    onChange={(e) => { handleDates(e, 'tailshaftSurveyDueDate'); }}
                  />
                )
                : <div className="value col-4">{projectDates?.tailshaftSurveyDueDate ? moment(projectDates?.tailshaftSurveyDueDate).format(' DD-MMM-YYYY') : '-'}</div>}
            </div>
            <div className="d-flex align-items-center mt-4">
              <label className="col-8">
                {' '}
                Special Survey Due Date
                <span className="text-primaryRed-1 ms-2">*</span>
              </label>
              {isTSI
                ? (
                  <input
                    className="text-box large ms-1 col-4"
                    type="date"
                    value={projectDates?.specialSurveyDueDate ? moment(projectDates?.specialSurveyDueDate).format('YYYY-MM-DD') : '-'}
                    onChange={(e) => { handleDates(e, 'specialSurveyDueDate'); }}
                  />
                )
                : <div className="value col-4">{projectDates?.specialSurveyDueDate ? moment(projectDates?.specialSurveyDueDate).format(' DD-MMM-YYYY') : '-'}</div>}
            </div>
          </div>
        </div>
      </div>
      <div className="border-down" />
      <div className="row mb-4 gutter-4  mt-5">
        <div className="col-7">
          <label className="required">Project Type</label>
          <Select
            className="modal-select"
            label="code"
            id="projectTypeId"
            isDisabled={isTsiDisabled}
            placeholder="Select"
            options={projectType}
            value={projectType?.filter((option) => option.id === formData?.projectTypeId)}
            onChange={(option) => {
              handleChange(option, 'projectTypeId', 'select');
              if (option) setPType(option.name);
            }}
            filterOption={customFilter}

          />
        </div>
        <div className="col-5">
          <label className="required">Priority</label>
          <Select
            value={Priority.filter((option) => option.value === formData?.priority)}
            onChange={(option) => handleChange(option, 'priority', 'select')}
            className="modal-select"
            label="Priority"
            isDisabled={isTsiDisabled}
            placeholder="Select"
            options={Priority}
            id="priority"
          />
        </div>
        {pType?.toLowerCase()?.includes('emergency') && (
          <div className="schdate mt-3">
            <label>Scheduled Date</label>
            <div className="yearmonth mt-1">
              <div className="monthmap">
                <div>
                  {' '}
                  <select
                    // className=" css-1s2u09g-control d-flex select-width col-gap-1"
                    className="modal-select c-sel"
                    value={ismonth}
                    id="Month"
                    onChange={(e) => setMonth(e.target.value)}
                    autoFocus
                  >
                    {months.map((m) => (
                      <option key={m} value={m}>
                        {m}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="ms-2">
                  <select
                    // className=" css-1s2u09g-control d-flex  select-width"
                    className="modal-select c-sel"
                    value={isyear || moment().format('YYYY')}
                    placeholder="Select"
                    id="Year"
                    onChange={(e) => setYear(e.target.value)}
                    autoFocus
                  >
                    {years.map((y) => (
                      <option key={y} value={y}>
                        {y}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row mb-5 gutter-4">
        <div className="col-7">
          {isPlannedDryDock && (
            <>
              <label className="required">Survey Type</label>
              <Select
                className="modal-select"
                label="code"
                id="surveyTypeId"
                isClearable
                isDisabled={isTsiDisabled}
                placeholder="Select"
                options={surveyType}
                value={surveyType?.filter((option) => option.id === formData?.surveyTypeId)}
                onChange={(option) => handleChange(option, 'surveyTypeId', 'select')}
              />
            </>
          )}

          {/* <div className="mt-2">
            <span className="icon-Info brand-color font-16  me-3" />
            <span>{`Project estimated ${diffInDays()} days
            from ${moment(vesselInfo?.ddDueDate).format(' DD-MMM-YYYY')}`}</span>
          </div> */}
        </div>
      </div>
      <div>
        <div className="bg-sky-white md-radius py-2 px-4 my-4">
          {vesselManagementUsers?.map((i) => (
            <div className=" d-flex whitespace-pre ">
              <label className="col-4 d-flex">
                {' '}
                {i.roleName}
                <div className="ms-1">:</div>
                {' '}
              </label>
              <p className="value">
                <span className="icon-user-filled me-2" />
                {i.userName}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="row gutter-4 mb-5 mt-5">
        {/* <div className="col-7">
          <label className="required">Backup TSI</label>
          <Select
            className="modal-select"
            label="code"
            id="backupTsiId"
            isDisabled={isTsiDisabled}
            placeholder="Select"
            options={backupTsi}
            value={backupTsi?.filter((option) => option.id === formData?.backupTsiId)}
            onChange={(option) => handleChange(option, 'backupTsiId', 'select')}
          />
        </div> */}
        <div className="col-5">
          <label className="required">Project Currency</label>
          <Select
            className="modal-select"
            label="code"
            id="currencyId"
            placeholder="Select"
            isDisabled={isTsiDisabled}
            options={projectCurrency}
            value={projectCurrency?.filter((option) => option.id === formData?.currencyId)}
            onChange={(option) => handleChange(option, 'currencyId', 'select')}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="d-flex mb-4">
          <div className="col-4">
            <label className="">Indicative Budget</label>
            <div className="">
              <div className="ribbon-text-box">
                <label className="label">
                  Yard
                  <div className="ms-1">{currencyName}</div>
                </label>
                <InputType1
                  min="0"
                  disabled={isTsiDisabled}
                  step="0.01"
                  className="modal-select large"
                  type="number"
                  value={formData?.yardIndicativeBudget === 0 ? '' : formData?.yardIndicativeBudget}
                  onChange={(e) => handleChange(e, 'yardIndicativeBudget', '')}
                />
              </div>
            </div>
          </div>
          <div className="col-4 ms-2">
            <label className="">Indicative Budget</label>
            <div className="">
              <div className="ribbon-text-box">
                <label className="label">
                  Owner
                  <div className="ms-1">{currencyName}</div>
                </label>
                <InputType1
                  min="0"
                  disabled={isTsiDisabled}
                  step="0.01"
                  className="modal-select large"
                  type="number"
                  value={formData?.ownerIndicativeBudget === 0 ? '' : formData?.ownerIndicativeBudget}
                  onChange={(e) => handleChange(e, 'ownerIndicativeBudget', '')}
                />
              </div>
            </div>
          </div>
          <div className="col-4 px-2">
            <InputType1
              id="totalRepairDays"
              onChange={(e) => handleChange(e, 'totalRepairDays', '')}
              value={formData?.totalRepairDays || ''}
              className="modal-Project hide-arrows w-full"
              label="Indicative Total Repair Days"
              isMandatory="true"
              type="number"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectInfo;
