/* eslint-disable */
/* istanbul ignore file */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import _, { toNumber } from 'lodash';

import helper from '../../../../../../../utils/helper';
import attachment from '.././images/attachment.svg';
import showAlert from '../../../../../../../utils/alert';

const measureHeight = (node) => {
  return node ? node.getBoundingClientRect().height : 40;
};

const flattenTree = (nodes, expandedNodes, level = 1) => {
  let flatList = [];
  nodes && nodes.length > 0 && nodes.forEach((node) => {
    flatList.push({ ...node, specificationLevel: level });

    if (expandedNodes.includes(node.id) && node.specificationLevel === 1 && node.specList) {
      flatList = flatList.concat(flattenTree(node.specList, expandedNodes, level + 1));
    }

    if (expandedNodes.includes(node.id) && node.specificationLevel === 2 && node.serviceLineList) {
      node.serviceLineList.forEach((serviceLine) => {
        flatList.push({
          ...serviceLine,
          title: serviceLine.serviceLineName,
          specificationLevel: level + 1
        });
      });
    }
  });
  return flatList;
};

function TreeNode({ index, style, data }) {
  const { formatNumber, ZERO_UUID } = helper;
  const { nodes, properties, itemHeights, setItemHeight } = data;
  const {
    yardDetails, compareData, setCompareData, units, isTSI, disableFields, repairStarted, expandedNodes,
    setDocData, setShowSideBar, setCommentClicked, SaveCQ, recalculate, setApplyAll, setCloseModal,
    onClickShowComment, isAdmin
  } = properties;
  const node = nodes[index];
  const ref = useRef();
  const isOpen = expandedNodes && expandedNodes.includes(node.id);
  const isDisabled = !isTSI || disableFields || repairStarted;

  node?.specList?.length > 0 &&
    node?.specList?.map((f) => {
      f?.serviceLineList?.length > 0 &&
        f?.serviceLineList?.map((g) => {
          if (g?.commentList?.length && g.commentList.find(x => x.userName === 'Yard user')) {
            f.commentExist = true;
            node.commentExist = true;
          }
        });
    });

  useEffect(() => {
    if (ref.current) {
      const height = measureHeight(ref.current);
      if (itemHeights[index] !== height) {
        setItemHeight(index, height);
      }
    }
  }, [index, itemHeights, setItemHeight]);

  const disableOE = node.specAssigned === 2 || node.specAssigned === 3;

  const handleChangeRow = (e, yardId, field, isSave, type) => {
    let UOM = null;
    if (isSave) {
      SaveCQ(node, false);
      if (field === 'unitPrice' || field === 'quantity')
        showAlert('Any changes to fields will not automatically apply to cells where these are used for calculation. Please visit the service line to apply changes there.', 'info');
      recalculate(compareData);
      return;
    }
    if (type === 'select') {
      const selectedUnit = units.find(m => e.target?.value === m.value);
      if (selectedUnit) {
        UOM = {
          uomId: selectedUnit.value,
          uomName: selectedUnit.label,
          unit: selectedUnit.name
        };
      }
    } else {
      let num = 0;
      if (field === 'discount') {
        if (e.target && e.target.value) {
          num = e.target.value.match(/^\d{0,3}(\.\d{0,2})?$/)
            ? e.target.value : node[field];
          if (num <= 100) {
            node[field] = num.toString();
          }
        }
        if (e.target.value === '') {
          node[field] = null;
        }
      } else {
        const regex = /^\d+(\.\d{0,2})?$/;
        if (regex.test(e.target.value) || e.target.value !== '') {
          let inputValue = e.target.value;
          inputValue = inputValue.replace(/[^\d.]/g, (match, index) => {
            if (match === '.') {
              return index === 0 || inputValue.indexOf('.') !== -1 ? '.' : '';
            }
            return '';
          });
          if (field === 'quantity')
            inputValue = inputValue.match(/^\d{0,6}(\.\d{0,2})?$/) ? inputValue : node[field];
          else inputValue = inputValue.match(/^\d{0,5}(\.\d{0,2})?$/) ? inputValue : node[field];
          node[field] = inputValue;
        }
        if (e.target.value === '') {
          node[field] = 0;
        }
      }
    }
    const x = node.quantity;
    const y = node.unitPrice;
    const z = node.discount;
    const res = x * y - ((x * y) / 100) * z;
    setCompareData(prevData => {
      const updatedData = prevData.map(yard =>
        yard.id === yardId
          ? {
            ...yard,
            sectionQuoteList: yard.sectionQuoteList.map(section => ({
              ...section,
              specList: section.specList.map(spec =>
                spec.id === node.parentSpecId
                  ? {
                    ...spec,
                    serviceLineList: spec.serviceLineList.map(service =>
                      service.id === node.id
                        ? {
                          ...service,
                          ...(UOM
                            ? {
                              uomId: UOM.uomId,
                              uomName: UOM.uomName,
                              unit: UOM.unit
                            }
                            : {
                              [field]: node[field],
                              yardEstimate: res,
                              ...(!service.formula && spec.specAssigned === 1
                                ? { ownerEstimate: res }
                                : {})
                            })
                        }
                        : service
                    )
                  }
                  : spec
              )
            }))
          }
          : yard
      )
      recalculate(updatedData);

      return updatedData;
    });
  };

  const updateFieldStatus = (specId, serviceId, field, oldField, oldValue) => {
    setCompareData(prevData =>
      prevData.map(yard =>
        yard.id === yardDetails.id
          ? {
            ...yard,
            sectionQuoteList: yard.sectionQuoteList.map(section => ({
              ...section,
              specList: section.specList.map(spec =>
                spec.id === specId
                  ? {
                    ...spec,
                    serviceLineList: spec.serviceLineList.map(service =>
                      service.id === serviceId
                        ? {
                          ...service,
                          [field]: oldField ? true : false,
                          ...(oldField ? { [oldField]: oldValue } : {})
                        }
                        : service
                    )
                  }
                  : spec
              )
            }))
          }
          : yard
      )
    );
  };

  const unitField = () => {
    if (node.specificationLevel === 1 || node.specificationLevel === 2) {
      return <div className="cell"></div>
    } else {
      return (
        <div className="cell">
          {!node?.editUnit ? (
            <div
              className={`editable-cell px-2 justify-content-start ${isDisabled || node?.id === '00000000-0000-0000-0000-000000000000' ? '' : 'cursor-pointer'}`}
              data-tooltip-id="project-specNamed"
              data-tooltip-content={node?.uomName || ''}
              data-test="newClick1"
              onClick={() => {
                if (
                  !isDisabled &&
                  node?.id !== '00000000-0000-0000-0000-000000000000' &&
                  node?.specificationLevel !== 4
                ) {
                  updateFieldStatus(node.parentSpecId, node.id, 'editUnit', 'oldUnitId', node?.uomId);
                }
              }}
            >
              <span className="timeline-text-wrap">{node?.uomName}</span>
            </div>
          ) : (
            <div className="display-center h-full ms-1 me-1">
              <select
                className="select small active uom-select"
                data-test="change1"
                value={node.uomId}
                autoFocus
                onChange={(e) => handleChangeRow(e, yardDetails.id, 'uomId', false, 'select')}
                onBlur={(e) => {
                  if (node?.oldUnitId !== e.target.value) {
                    handleChangeRow(e, yardDetails.id, 'uomId', true, 'select');
                  }
                  updateFieldStatus(node.parentSpecId, node.id, 'editUnit', '',);
                }}
              >
                {units.map(m => (
                  <option key={m.value} value={m.value}>
                    {m.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )
    }
  };

  const qtyField = () => {
    if (node.specificationLevel === 1 || node.specificationLevel === 2) {
      return <div className="cell"></div>
    } else {
      return (
        <div className="cell">
          {!node?.editQuantity ? (
            <div
              className={`editable-cell px-2 ${isDisabled || node?.id === '00000000-0000-0000-0000-000000000000' ? '' : 'cursor-pointer'}`}
              data-test="newClick2"
              onClick={() => {
                if (!isDisabled && node?.id !== '00000000-0000-0000-0000-000000000000') {
                  updateFieldStatus(node.parentSpecId, node.id, 'editQuantity', 'oldQuantity', node?.quantity);
                }
              }}
            >
              {_.truncate(node?.quantity, {
                length: 11,
                separator: ' '
              })}
            </div>
          ) : (
            <div className="editable-cell justify-content-center">
              <input
                type="numeric"
                className="text-box small compare-quote-input"
                value={node?.quantity ? node?.quantity : ''}
                data-test="change2"
                autoFocus
                onChange={(e) => {
                  handleChangeRow(e, yardDetails.id, 'quantity', false, '');
                }}
                onBlur={(e) => {
                  updateFieldStatus(node.parentSpecId, node.id, 'editQuantity', '', '');
                  if (toNumber(node?.oldQuantity) !== toNumber(e.target.value) && (node?.isFromYard || compareData?.length === 1)) {
                    handleChangeRow(e, yardDetails.id, 'quantity', true, '');
                    return;
                  }
                  if (toNumber(node?.oldQuantity) !== toNumber(e.target.value)) {
                    setApplyAll({
                      status: true, value: e.target.value, field: 'quantity', line: node, yardId: yardDetails.id
                    });
                  }
                }}
              />
            </div>
          )}
        </div>
      )
    }
  };

  const unitPriceField = () => {
    if (node.specificationLevel === 1 || node.specificationLevel === 2) {
      return <div className="cell"></div>
    } else {
      return <div className="cell">
        {!node?.editUnitPrice ? (
          <div
            className={`editable-cell px-2 ${isDisabled || node?.id === '00000000-0000-0000-0000-000000000000' ? '' : 'cursor-pointer'}`}
            data-test="newClick2"
            onClick={() => {
              if (!isDisabled && node?.id !== '00000000-0000-0000-0000-000000000000') {
                updateFieldStatus(node.parentSpecId, node.id, 'editUnitPrice', 'oldUnitPrice', node?.unitPrice);
              }
            }}
          >
            {_.truncate(formatNumber(node?.unitPrice), {
              length: 11,
              separator: ' '
            })}
          </div>
        ) : (
          <div className="editable-cell justify-content-center">
            <input
              type="numeric"
              className="text-box small compare-quote-input"
              value={node?.unitPrice ? node?.unitPrice : ''}
              data-test="change2"
              autoFocus
              onChange={(e) => {
                handleChangeRow(e, yardDetails.id, 'unitPrice', false, '');
              }}
              onBlur={(e) => {
                updateFieldStatus(node.parentSpecId, node.id, 'editUnitPrice', '', '');
                if (toNumber(node?.oldUnitPrice) !== toNumber(e.target.value)) {
                  handleChangeRow(e, yardDetails.id, 'unitPrice', true, '');
                }
              }}
            />
          </div>
        )}
      </div>
    }
  };

  const discountField = () => {
    if (node.specificationLevel === 1 || node.specificationLevel === 2) {
      return <div className="cell"></div>
    } else {
      return <div className="cell">
        {!node?.editDiscount ? (
          <div
            className={`editable-cell px-2 ${isDisabled || node?.id === '00000000-0000-0000-0000-000000000000' ? '' : 'cursor-pointer'}`}
            data-test="newClick2"
            onClick={() => {
              if (!isDisabled && node?.id !== '00000000-0000-0000-0000-000000000000') {
                updateFieldStatus(node.parentSpecId, node.id, 'editDiscount', 'oldDiscount', node?.discount);
              }
            }}
          >
            {formatNumber(node?.discount)}
          </div>
        ) : (
          <div className="editable-cell justify-content-center">
            <input
              type="numeric"
              className="text-box small compare-quote-input"
              value={node?.discount && node.discount !== "0" ? node.discount : ''}
              data-test="change2"
              autoFocus
              onChange={(e) => {
                handleChangeRow(e, yardDetails.id, 'discount', false, '');
              }}
              onBlur={(e) => {
                updateFieldStatus(node.parentSpecId, node.id, 'editDiscount', '', '');
                if (toNumber(node?.oldDiscount) !== toNumber(e.target.value)) {
                  handleChangeRow(e, yardDetails.id, 'discount', true, '');
                }
              }}
            />
          </div>
        )}
      </div>
    }
  };

  const yardEstimateField = () => {
    if (node.specificationLevel === 1 || node.specificationLevel === 2) {
      const estimateContent = (
        <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(node?.yardEstimate)}>
          {_.truncate(formatNumber(node?.yardEstimate), {
            length: 11,
            separator: ' '
          })}
        </span>
      );

      return (
        <div className={`timeline-text-wrap cell display-end w-10 ${isOpen ? 'font-bold' : ''}`}>
          {estimateContent}
        </div>
      );
    }

    return <div className="cell w-10">
      <div
        data-tooltip-id="project-specNamed"
        data-tooltip-content={formatNumber(node?.yardEstimate)}
        className={
          node?.isFromYard && node?.yardEstimate > 0
            ? 'display-end font-bold font-yardQuotedEstimate h-full me-2'
            : 'display-end h-full me-2'
        }
      >
        {_.truncate(formatNumber(node?.yardEstimate), {
          length: 11,
          separator: ' '
        })}
      </div>
    </div>;
  };


  const ownerEstimateField = () => {
    if (node.specificationLevel === 1 || node.specificationLevel === 2) {
      const estimateContent = (
        <span
          data-tooltip-id="project-specNamed"
          data-tooltip-content={(node.specificationLevel === 1 || node?.specAssigned === 1 && node.specificationLevel === 2)
            ? formatNumber(node?.ownerEstimate) : formatNumber(0)}>
          {_.truncate((node.specificationLevel === 1 || node?.specAssigned === 1 && node.specificationLevel === 2)
            ? formatNumber(node?.ownerEstimate) : formatNumber(0), {
            length: 11,
            separator: ' '
          })}
        </span>
      );

      return (
        <div className={`timeline-text-wrap cell display-end w-10 ${isOpen ? 'font-bold' : ''}`}>
          {estimateContent}
        </div>
      );
    }

    return <div className="cell w-10">
      <div
        data-tooltip-id="project-specNamed"
        data-tooltip-content={node?.formula ? node?.formula : formatNumber(node?.ownerEstimate)}
        className={
          node?.isFromYard && node?.ownerEstimate > 0
            ? 'display-end font-bold h-full me-2'
            : 'display-end h-full me-2'
        }
      >
        <input
          type="numeric"
          className={`text-box small compare-quote-input ${disableOE || isDisabled || node?.id === '00000000-0000-0000-0000-000000000000' ? '' : 'cursor-pointer'}`}
          value={
            node?.formula
              ? formatNumber(node?.ownerEstimate)
              : node?.specAssigned === 1
                ? formatNumber(node?.ownerEstimate)
                : formatNumber(0)
          }
          disabled={node.id === "00000000-0000-0000-0000-000000000000" || isDisabled || disableOE} 
          data-test="click4"
          onClick={() => {
            setApplyAll({
              status: false, value: '', field: 'ownerEstimate', line: node, yardId: yardDetails.id
            });
            setCloseModal(true);
          }}
        />
      </div>
    </div>;
  };

  const ownerCostField = () => {
    const estimateContent = (
      <button
        data-tooltip-id="project-specNamed"
        data-tooltip-content="Yard commented in service line"
        type="button"
        className="d-flex link-btn large me-auto w-1/4 ms-2"
      >
        <span className="icon-message-square font-20" />
        <span className="dot-indicator ms-3" />
      </button>
    );

    if (node.specificationLevel === 1) {
      return (
        <div className={`timeline-text-wrap cell display-end w-10 ${isOpen ? 'font-bold' : ''}`}>
          {node?.commentExist && !isOpen && (
            estimateContent
          )}
          <span className={isOpen ? 'w-full' : 'w-3/4'} data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(node?.ownerCost)}>
            {formatNumber(node?.ownerCost)}
          </span>
        </div>
      );
    } else if (node.specificationLevel === 2) {
      return (
        <div
          className={`cell display-end w-10 ${isOpen && ' font-bold'}`}
        >
          {node?.hasSpecDocuments && isOpen && (
            <img
              className="cursor-pointer icon-att me-auto p-1"
              src={attachment}
              alt="flag"
              data-test="imgClick1"
              onClick={() => {
                setDocData(node.specDocuments);
                setShowSideBar(true);
                setCommentClicked(false);
              }}
            />
          )}
          {node?.commentExist && !isOpen && (
            estimateContent
          )}
          <span className={node?.commentExist ? "timeline-text-wrap w-3/4" : "timeline-text-wrap w-full ms-1"} data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(node?.ownerCost)}>
            {formatNumber(node?.ownerCost)}
          </span>
        </div>
      )
    }

    return <div className="cell w-10">
      <div className="display-end h-full d-flex justify-content-end">
        {(isAdmin || isTSI) && (
          <div className="col-1 ps-2 border-down d-flex justify-content-end">
            <span className="font-22 icon-add-comment pe-2 brand-color pointer" onClick={() => onClickShowComment(node, yardDetails)} />
            {(node.commentList?.length > 0 ? true: false) && (
              <span className="dot-indicator-comment" />
            )}
          </div>
        )}
      </div>
    </div>;
  };

  return (
    <>
      <div ref={ref} style={{ ...style }}>
        <div
          key={node.id}
          style={{ background: (node.specificationLevel && node.isFromYard && node?.color) || node?.id === ZERO_UUID ? node?.color : '' }}
          className={node.number === 'DEF' ? 'd-none' : 'compare-quote-table d-flex h-full'}
        >
          {yardDetails?.showFull && unitField(node)}
          {yardDetails?.showFull && qtyField(node)}
          {yardDetails?.showFull && unitPriceField(node)}
          {yardDetails?.showFull && discountField(node)}
          {yardEstimateField(node)}
          {ownerEstimateField(node)}
          {ownerCostField(node)}
        </div>
      </div>
      
    </>
  );
}

function VirtualizedTreeView({ treeData, properties, listRef }) {
  const { scrollIndex, expandedNodes, listHeight, syncScroll } = properties;
  const flattenedNodes = useMemo(() => flattenTree(treeData, expandedNodes), [treeData, expandedNodes]);
  const [itemHeights, setItemHeights] = useState([]);

  const setItemHeight = (index, size) => {
    setItemHeights((prevHeights) => {
      const updatedHeights = [...prevHeights];
      updatedHeights[index] = size;
      return updatedHeights;
    });
  };

  return (
    <List
      ref={listRef}
      height={listHeight || 400}
      itemCount={flattenedNodes.length}
      itemSize={(index) => itemHeights[index] || 40}
      itemData={{
        nodes: flattenedNodes, properties, itemHeights, setItemHeight
      }}
      width="100%"
      onScroll={({ scrollOffset }) => syncScroll(scrollIndex, scrollOffset)}
    >
      {TreeNode}
    </List>
  );
}

const QuotesValue = ({ yardDetails, index, containerRefs, ...props }) => (
  <div className="d-flex">
    <VirtualizedTreeView
      key={yardDetails.id}
      treeData={yardDetails.sectionQuoteList}
      properties={{ scrollIndex: index, yardDetails, ...props }}
      listRef={el => (containerRefs.current[index] = el)}
    />
  </div>
);

export default QuotesValue;
