import React, { useState } from 'react';
import PrivacyPolicy from '../../../../../Policies/privacy';
import TermsOfUse from '../../../../../Policies/TermsOfUse';
import Comments from '../../../Comments';
import helper from '../../../../../../utils/helper';

const PreviewFooter = (props) => {
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = userRole === roles.VESSEL_USER;
  const isAllowerUser = userRole === roles.AUTHOR || userRole === roles.ADMIN;
  const {
    onSelectYard, setShowSideBar, setIsSupplementaryClicked, id, projectBasicDetails
  } = props;
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  return (
    <>
      <div className="fixed-footer card d-flex">
        {!isVesselUser && (
          <Comments id={id} appendClassName="comments-btn" />
        )}
        <div className={isVesselUser ? 'text-black font-12 col-4 d-flex justify-content-end' : 'text-black font-12 col-4 '}>
          <div className=" footer-tender">{`© ${new Date().getFullYear()} ThinkPalm`}</div>
        </div>
        <div className={isVesselUser ? 'd-flex justify-content-end ms50p ms-auto me-4' : 'd-flex col-gap-1 ms-auto me-4'}>
          <button
            type="button"
            onClick={() => {
              setShowSideBar(true);
              setIsSupplementaryClicked(false);
            }}
            disabled={projectBasicDetails?.phase > 3}
            className="secondary-btn large"
            id="add-supspec"
          >
            Add Supplementary Spec
          </button>
          {isAllowerUser && (
            <button
              type="button"
              className="primary-btn large"
              disabled={projectBasicDetails?.phase > 3}
              id="spec-download"
              onClick={() => { onSelectYard(0); }}
            >
              Send to Yard
            </button>
          )}
        </div>
      </div>
      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
    </>
  );
};
export default PreviewFooter;
