/* istanbul ignore file */
import React, { useState } from 'react';
import ModalBox from '../../../common/ModalBox';

const ExcelSpecModal = (props) => {
  const { setExcelConfirmModal, exportExcel } = props;
  const [isCheckedGT, setIsCheckedGT] = useState(true);
  const [isCheckedMajor, setIsCheckedMajor] = useState(true);
  const [downloadWithGT, setDownloadWithGT] = useState(false);
  const [downloadWithMajor, setDownloadWithMajor] = useState(false);

  const handleGTCheckboxChange = () => {
    setDownloadWithGT(!downloadWithGT);
    setIsCheckedGT(!isCheckedGT);
  };
  const handleMajorCheckboxChange = () => {
    setDownloadWithMajor(!downloadWithMajor);
    setIsCheckedMajor(!isCheckedMajor);
  };

  return (
    <ModalBox
      heading="Download Specs"
      closeModal={() => { setExcelConfirmModal(false); }}
      buttonName="Ok"
      onSave={() => {
        exportExcel({
          type: (isCheckedGT && isCheckedMajor) ? 0 : (isCheckedGT ? 1 : 2)
        });
        setExcelConfirmModal(false);
      }}
      saveDisable={!(isCheckedGT || isCheckedMajor)}
    >
      <div className="text-content-grey">
        Please choose from the below options to download:
      </div>
      <div className="mt-2 check-box">
        <input type="checkbox" id="GT Specs" name="GT" onChange={handleGTCheckboxChange} checked={isCheckedGT} />
        <label htmlFor="GT Specs"> Download GT Specs</label>
      </div>
      <div className="mt-2 check-box">
        <input type="checkbox" id="Major Specs" name="Major" onChange={handleMajorCheckboxChange} checked={isCheckedMajor} />
        <label htmlFor="Major Specs"> Download Major Specs</label>
      </div>
    </ModalBox>
  );
};
export default ExcelSpecModal;
