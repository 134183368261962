/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Select from 'react-select';
import SidePanel from '../../../../../common/sidePanel';
import EmailPreview from '../EmailPreview';

const YardConfirm = (props) => {
  const { shortListedYard, onYardSelect, selectedYard, emailDetails, days, setDays,
    setYardPage, listLoading = false, slotDate, setSlotDate, confirmYard } = props;
  const [rfqEmail, setRfqEmail] = useState(false);
  const [email, setEmail] = useState([]);

  const handleEmailPreview = (item) => {
    const emailId = item?.contactInfo.email;
    setEmail(emailId);

    setRfqEmail(true);
  };

  const onViewClose = () => {
    setRfqEmail(false);
  };
  const dayList = Array.from({ length: 60 }, (_, i) => ({ label: `${i + 1}`, value: `${i + 1}` }));
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div data-testid="gmap-yard">
        <div className="d-flex align-items-center">
          <div className="label head-12 d-flex align-items-center justify-content-en me-2 yard-dd width-half">
            Receive RFQ within Days
            <Select
              className="ms-2 rfq-date"
              id="select-day"
              classNamePrefix="custom-select"
              menuPlacement="bottom"
              options={dayList}
              maxMenuHeight={200}
              value={{ label: days, value: days }}
              onChange={(e) => {
                setDays(e.value);
                confirmYard(slotDate, e.value);
              }}
            />
          </div>
          <div className="label head-12 d-flex align-items-center justify-content-en me-2 yard-dd width-half ms-auto">
            Slot Request Date
            <span className="text-primaryRed-1">*</span>
            <div className="date-picker ms-4">
              <input
                id="slot-date"
                type="date"
                className="text-box large"
                name="slotRequestDate"
                value={slotDate}
                min={getTodayDate()}
                onChange={(e) => {
                  setSlotDate(e.target.value);
                  confirmYard(e.target.value, days);
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4 mb-3 align-items-center">
          <div>
            Selected Yards
            <span className="bg-brand-color mx-2 px-2 white md-radius">{selectedYard.length}</span>
          </div>
          <div className="d-flex align-items-center">
            <div className="me-3">
              Receive RFQ within
              {' '}
              {days}
              {' '}
              days
            </div>
            <button type="button" id="select-yard" className="link-btn" onClick={() => { setYardPage(1); }}>
              <span className="me-1">+</span>
              Select More Yards
            </button>
          </div>
        </div>
        {(selectedYard.length < 1) && <div className="pt-2 font-semibold mt-4 gray text-center">Please select Yard</div>}
        {shortListedYard.map((item) => {
          if (selectedYard.indexOf(item.id) > -1) {
            return (
              <div className="mb-3 p-3 sm-radius border d-flex justify-content-between align-items-center">
                <div className="col-6 word-break me-2">
                  <div className="font-bold mb-1">{item.yardName}</div>
                  <div>
                    <span className="icon-location me-2" />
                    {item.countryName}
                  </div>
                </div>
                <div className="d-flex">
                  <button type="button" id="delete" className="link-btn me-4" onClick={() => { onYardSelect(item.id); }}>
                    <span className="icon-delete me-2" />
                    Remove
                  </button>
                  <button type="button" id="email-preview" disabled={!slotDate} className="link-btn me-2" onClick={() => handleEmailPreview(item)}>
                    <span className="icon-eye me-2" />
                    {!slotDate ? 'Loading' : 'Email Preview'}
                  </button>
                </div>

              </div>
            );
          }
          return null;
        })}
      </div>
      {rfqEmail && (
        <SidePanel title="RFQ Email Preview" showView={rfqEmail} onClose={onViewClose} bgColor="#fff">
          <EmailPreview emailDetails={emailDetails} email={email} />
        </SidePanel>
      )}
    </>
  );
};
export default YardConfirm;
