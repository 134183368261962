/* eslint-disable */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import SidePanelCustom from '../SupplementarySpecs/sidePanelCustom';

const CQSidePanel = (props) => {
  const { showSideBar, commentClicked, onClose, docData } = props;

  return (
    <div>
      <SidePanelCustom size="small" title={commentClicked ? 'Comment' : 'Attachments'} onClose={onClose} buttonName="save" showView={showSideBar}>
        <div className="compare-quote-classes">
          {!commentClicked ? (
            <div>
              {docData.length > 0 &&
                docData.map((i, index) => {
                  return (
                    <div className="data-container-cq mt-4">
                      <div key={index} className="d-flex p-3">
                        <div className="w-90">
                          <div className="font-medium timeline-text-wrap w-30">
                            <span data-tooltip-id="project-specName" data-tooltip-content={i.name ? i.name : ''}>
                              {i?.name}
                            </span>
                          </div>
                          <div className="font-gray-kpf text-md w-1/4">Size:&nbsp;{Math.floor(i.size / 1024)}&nbsp;KB</div>
                        </div>
                        {(i?.type === 'pdf' || i?.type === 'png' || i?.type === 'jpg') && (
                          <button
                            type="button"
                            className="d-flex link-btn large ms-auto mt-2 me-2"
                            data-test="imgClick2"
                            onClick={() => {
                              window.open(i?.path);
                            }}
                          >
                            <span className="icon-eye font-26" />
                          </button>
                        )}
                        <button type="button" id="excel" className="link-btn large ms-auto">
                          <a href={i?.path} target="_blank" download="downloaded_file" className="" rel="noreferrer">
                            <span className="icon-download font-22" />
                          </a>
                        </button>
                      </div>
                      <Tooltip id="project-specName " place="top" className="tooltip mt-3" />
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="data-container-cq">
              <div className="p-4">{docData}</div>
            </div>
          )}
        </div>
      </SidePanelCustom>
    </div>
  );
};

export default CQSidePanel;
