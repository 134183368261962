/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import moment from 'moment';
import ModalBox from '../../../common/ModalBox';
import DeferredSpecList from './deferredSpecList';
import PolicyFooter from '../../policyFooter';
import PrivacyPolicy from '../../../Policies/privacy';
import TermsOfUse from '../../../Policies/TermsOfUse';

const Cards = (props) => {
  const { tableData } = props;
  const [viewModal, setViewModal] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsPolicy, setShowTermsPolicy] = useState(false);
  const [dataList, setdataList] = useState({});
  const viewData = (data) => {
    setViewModal(true);
    setdataList(data);
  };
  const userRole = localStorage.getItem('USER_ROLE');
  const truncate = (string, length) => {
    if (string.length > length) {
      return `${string.substring(0, length)}...`;
    }
    return string;
  };
  return (
    <div data-testid="deferredList-card">
      {' '}
      <div className="scorll-body-wrap">
        <table className="card-table">
          <thead>
            <tr>
              <th>
                Project  Name
              </th>
              <th>
                Closed Date
              </th>
              <th>
                Actions
              </th>

            </tr>
          </thead>
          <tbody>
            {tableData?.map((list) => {
              return (
                <>
                  {' '}
                  <tr>
                    <td>
                      <h6 className="mb-2 head-14 timeline-text-wrap">
                        <span
                          className=""
                          data-tooltip-id="project-projectName"
                          data-tooltip-content="fgyrt"
                        >
                          {truncate(list.projectName, { length: 30 })}
                        </span>
                      </h6>
                    </td>
                    <td>
                      <span
                        className=" "
                        data-tooltip-id="project-projectName"
                        data-tooltip-content="ghj"
                      >
                        {truncate(moment(list?.closedDate).format('DD-MMM-YYYY'), { length: 30 })}
                      </span>
                    </td>
                    <td>
                      <button type="button" className="link-btn" id="viewdata" onClick={() => viewData(list)}>
                        <span className="icon-eye font-22" />
                      </button>
                    </td>
                  </tr>
                </>

              );
            })}

          </tbody>
        </table>
      </div>
      {viewModal ? (
        <ModalBox
          modalClassName="medium modal-scroll"
          closeModal={() => setViewModal(false)}
          heading={(
            <div className="d-flex align-items-center">
              <div className="">Deferred Spec List</div>
              <div className="couter-bubbles status-due ms-3 font-12">
                {dataList?.deferredSpecCount || 0}
              </div>
            </div>
          )}
          className="view-modal"
        >
          <DeferredSpecList dataList={dataList} />
        </ModalBox>
      ) : (
        ''
      )}

      {showPrivacyPolicy && (
        <PrivacyPolicy
          setShowPrivacyPolicy={setShowPrivacyPolicy}
          showPrivacyPolicy={showPrivacyPolicy}
        />
      )}
      {showTermsPolicy && (
        <TermsOfUse
          setShowTermsPolicy={setShowTermsPolicy}
          showTermsPolicy={showTermsPolicy}
        />
      )}
      {userRole === 'Admin' ? (
        <div className="non-sticky-footer">
          <div className="container">
            <div className="d-flex justify-content-between font-13 mx-0 my-2">
              <PolicyFooter
                setShowPrivacyPolicy={setShowPrivacyPolicy}
                setShowTermsPolicy={setShowTermsPolicy}
              />
            </div>
          </div>

        </div>
      ) : (
        <div className="footer justify-content-between font-13">
          <div className="copy-text m-auto">
            {`© ${new Date().getFullYear()} ThinkPalm`}
          </div>

        </div>
      )}

    </div>
  );
};

export default Cards;
