/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Phases from './Phases';
import SpecList from './SpecsList';
import NonSpecsList from './NonSpecsList';
import { getSpecById, deleteManualSpec, addManualSpec, deleteExistingMajorSpecs, getNonSpecTsi, updateSpecIsComplete, updateSpecAssigne, getPrevProjectSpecs, SaveSpecificSpecs } from './services';
import ModalBox from '../../../../common/ModalBox';
import { getMajorSpecs, majorSpecsSectionList, moveDeferredSpecs } from '../../../../Admin/DataConfig/SpecificationSetup/MajorSpecs/services';
import AddNewSec from './Phases/children/addNewSec';
import AddNewSubSec from './Phases/children/addNewSubSec';
import showAlert from '../../../../../utils/alert';
import helper from '../../../../../utils/helper';
import EmptySpecSelected from './EmptySpecSelected';
import MajorSpecPackagesList from '../../../../Admin/DataConfig/SpecificationSetup/SpecPackages/MajorSpecPackagesList';
import { getAcCode } from './SpecsList/ServiceLine/services';
import { getProjectDetailsTSI, getGeneralTariff, saveProjectSpecsTSI } from '../../NewProject/services';
import Loader2 from '../../../../common/loader2';
import DeferredSpecList from './Phases/children/deferredSpecList';
import { createDraftSpec, replaceDraftSpec, mergeDraftSpec } from '../Repair/services';

const PhasePage = ({ isTSI }) => {
  const isSidePanel = true;
  const { id } = useParams();
  const vesselId = localStorage.getItem('vesselId');
  // eslint-disable-next-line no-unused-vars
  // const { reload, specs } = useOutletContext() || '';
  const {
    reload: [reload],
    specs: [specDetails, setSpecDetails]
  } = useOutletContext() || { reload: [], specs: [] };
  const { formValidation, generateOptions, ZERO_UUID, SpecificationType, roles } = helper;
  const SpecificationTypeNumber = SpecificationType.MajorSpecs;

  const [viewSpec, setViewSpec] = useState(true);
  const [gtSpecData, setGtSpecData] = useState([]);
  const [mtSpecData, setMtSpecData] = useState([]);
  const [visibleSpec, setVisibleSpec] = useState(['mt', 'gt']);
  const [selectedSpec, setSelectedSpec] = useState({});
  const [selectedSpecType, setSelectedSpecType] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openLockModal, setOpenLockModal] = useState(false);
  const [openReplaceModal, setOpenReplaceModal] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false);
  const [projectData, setProjectData] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const [currency, setCurrency] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [showAddNewSub, setShowAddNewSub] = useState(false);
  const [newSubData, setNewSubData] = useState({ specificationNo: '', title: '' });
  const [newMainData, setNewMainData] = useState({ specificationNo: '', title: '' });
  const [closedSection, setClosedSection] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);
  const [confirmChange, setConfirmChange] = useState(false);
  const [isDeleteSpec, setIsDeleteSpec] = useState(false);
  const [gtNonSpecData, setGtNonSpecData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [acCodeList, setAcCodeList] = useState([]);
  const [isTsiDisabled, setIsTsiDisabled] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [dropDownList, setDropDownList] = useState([]);
  const [isTsi, setIsTsi] = useState({});
  const [selectedAssignee, setSelectedAssignee] = useState(1);
  const [showServiceLineModalSpecify, setShowServiceLineModalSpecify] = useState(false);
  const [prevListInView, setPrevListInView] = useState();
  const [prevProjItems, setPrevProjItems] = useState({ projectName: '', fleetName: '', dDdate: '', projectId: '', projectFullName: '' });
  // const [isNotDisabledForTSI, setIsNotDisabledForTSI] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSpecList, setSelectedSpecList] = useState();
  const [isUpload, setIsUpload] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [modalBox, setModalBox] = useState(false);
  const [isExpand, setIsExpand] = useState('');
  const [formDataToFillList, setformDataToFillList] = useState([]);
  const [disableSave, setDisableSave] = useState(false);

  const onCardSelect = (key) => {
    setSelectedCard(key);
  };
  const getPrevProjectSpecList = () => {
    const prevProjectId = prevProjItems.projectId;
    getPrevProjectSpecs({ prevProjId: prevProjectId, currentProjId: id }).then((res) => {
      setLoading(false);
      if (res.success) {
        setPrevListInView(res.data);
      }
    });
  };
  const getMSpecData = () => {
    getSpecById({ id, type: 2 }).then((resp) => {
      if (resp.success) {
        setLoading(false);
        setMtSpecData(resp.data);
      }
    });
  };
  const showContent = (field) => {
    majorSpecsSectionList({ id }).then((resp) => {
      if (resp.success) {
        setOpenModal(field);
        setLoading(false);
        setDropDownList(resp.data);
      }
    });
  };
  const handleSave = () => {
    moveDeferredSpecs({
      specId: openModal.id,
      projectId: id,
      parentSpecId: selectedSpecList.value
    }).then((res) => {
      setLoading(false);
      if (res.success) {
        getMSpecData();
        setOpenModal(false);
        setSelectedSpecList('');
        showAlert('Successfully Saved', 'success');
      }
    });
  };

  const getMajorSpecsData = () => {
    getMajorSpecs(ZERO_UUID, SpecificationTypeNumber).then((resp) => {
      if (resp.success) {
        setIsTsi(resp.data);
      }
    });
  };
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = (userRole === roles.VESSEL_USER);
  const TSIUser = (userRole === roles.AUTHOR) || (userRole === roles.EDITOR);
  /* istanbul ignore next */
  const getProjDetails = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      if (res?.success) {
        localStorage.setItem('managementId', res?.data?.managementId);
        setLoading(false);
        const projInfo = res?.data;
        setProjectData(projInfo);
        setCurrency(res?.data?.currencySymbol);
        setCurrencyId(res?.data?.currencyId);
        let disabled = true;
        if (!isTSI) {
          disabled = true;
        } else if (isTSI
          && (projInfo?.specApprovalStatus === 0 || projInfo?.specApprovalStatus === 3)) {
          disabled = false;
        } else {
          disabled = true;
        }
        if (localStorage.getItem('USER_ID') === projInfo?.backupTsiId) {
          disabled = true;
        }
        // if (userRole === roles.VESSEL_USER && projInfo?.isSpecLocked) {
        //   disabled = true;
        // }
        setIsTsiDisabled(disabled);
      }
    });
  };
  /* istanbul ignore next */
  const getGSpecData = () => {
    getSpecById({ id, type: 1 }).then((res) => {
      if (res.success) {
        setLoading(false);
        setGtSpecData(res.data);
      }
    });
  };
  /* istanbul ignore next */

  useEffect(() => {
    if (visibleSpec.indexOf(selectedSpecType) < 0) {
      setSelectedSpec({});
      setClosedSection([]);
    }
  }, [visibleSpec]);
  const changeView = (flag) => {
    setViewSpec(flag);
  };
  const onDeleteSpec = (specId, isSpec, draft) => {
    setOpenDeleteModal(specId);
    setIsDraft(draft);
    setIsDeleteSpec(isSpec);
  };
  /* istanbul ignore next */
  const createDraft = (specId, type) => {
    setOpenLockModal(specId);
    setSelectedSpecType(type);
  };

  /* istanbul ignore next */
  const replaceDraft = (specId, type) => {
    setOpenReplaceModal(specId);
    setSelectedSpecType(type);
  };
  const mergeDraft = (specId, type) => {
    setOpenMergeModal(specId);
    setSelectedSpecType(type);
  };

  const deleteApiCall = () => {
    deleteManualSpec({ id: openDeleteModal }).then((res) => {
      setOpenDeleteModal(false);
      if (res.success) {
        showAlert('Successfully Deleted', 'success');
        getMSpecData();
        getGSpecData();
        // if (isDeleteSpec) {
        setSelectedSpec({});
        // }
        getPrevProjectSpecList();
      }
    });
  };

  useEffect(() => {
    getSpecById({ id, type: 1 }).then((resp) => {
      if (resp.success) {
        setLoading(false);
        setGtSpecData(resp.data);
      }
    });
    getSpecById({ id, type: 2 }).then((resp) => {
      if (resp.success) {
        setLoading(false);
        setMtSpecData(resp.data);
      }
    });
  }, [closedSection[0], selectedSpec]);

  /* istanbul ignore next */
  const reloadAfterGTMT = (typeCR, resDataId, newSpecCreated, statusChange) => {
    let dataSpec = typeCR === 'create' ? openLockModal : (typeCR === 'replace' ? openReplaceModal : openMergeModal);
    if (newSpecCreated) {
      dataSpec = { ...newSpecCreated, parentSpecId: newSpecCreated.parentSpecificationId };
    }
    if (selectedSpecType === 'gt') {
      getSpecById({ id, type: 1 }).then((resp) => {
        if (resp.success) {
          setLoading(false);
          setGtSpecData(resp.data);
          resp.data?.forEach((i) => {
            if (i?.id === dataSpec?.parentSpecId || statusChange) {
              i.specifications?.forEach((j) => {
                if (j?.id === resDataId) {
                  setSelectedSpec(j);
                }
              });
            }
          });
        }
        if (typeCR === 'create') {
          setOpenLockModal(false);
        } else if (typeCR === 'replace') {
          setOpenReplaceModal(false);
        } else {
          setOpenMergeModal(false);
        }
      });
    }
    if (selectedSpecType === 'mt' || newSpecCreated) {
      getSpecById({ id, type: 2 }).then((resp) => {
        if (resp.success) {
          setLoading(false);
          setMtSpecData(resp.data);
          let nextSpec = '';
          resp.data?.forEach((i, index) => {
            if (i?.id === dataSpec?.parentSpecId || statusChange) {
              nextSpec = resp.data[index + 1].id;
              i.specifications?.forEach((j) => {
                if (j?.id === resDataId) {
                  setSelectedSpec(j);
                }
              });
            }
          });
          setTimeout(() => {
            if (newSpecCreated && nextSpec) {
              const element = document?.getElementById(nextSpec);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'center' });
                setClosedSection([dataSpec.parentSpecId]);
              }
            }
          }, 20);
        }
        if (typeCR === 'create') {
          setOpenLockModal(false);
        } else if (typeCR === 'replace') {
          setOpenReplaceModal(false);
        } else {
          setOpenMergeModal(false);
        }
      });
    }
  };
  /* istanbul ignore next */
  const replaceDraftMode = () => {
    replaceDraftSpec(openReplaceModal?.id).then((res) => {
      if (res.success) {
        showAlert('Successfully Replaced', 'success');
        reloadAfterGTMT('replace', res?.data);
      }
    });
  };
  /* istanbul ignore next */
  const mergeDraftMode = () => {
    mergeDraftSpec(openMergeModal?.id).then((res) => {
      if (res.success) {
        showAlert('Successfully Merged', 'success');
        reloadAfterGTMT('merge', res?.data);
      }
    });
  };
  /* istanbul ignore next */
  const createDraftMode = () => {
    createDraftSpec(openLockModal?.id).then((res) => {
      if (res.success) {
        showAlert('Successfully Drafted', 'success');
        reloadAfterGTMT('create', res?.data);
      }
    });
  };
  const onEditSpec = (sid, data, spec) => {
    setShowAddNewSub(true);
    const lastSpec = spec?.specifications && spec?.specifications.length > 0
      ? spec?.specifications[spec?.specifications?.length - 1]
      : { specificationNo: spec?.specificationNo };
    const lastSpecNum = parseInt(lastSpec?.specificationNo?.replace(/[^0-9]/g, '')) + 1;
    const subData = data ? { ...data } : {
      ...newSubData,
      nextNumber: parseInt(spec.specificationNo) + 100,
      specificationNo: lastSpecNum
    };
    subData.parentSpecificationId = sid;
    setNewSubData(subData);
    getPrevProjectSpecList();
  };
  const addNewSpec = () => {
    setShowAddNew(true);
    setSelectedSpecType('mt');
  };

  const gtEditSpec = async (section) => {
    const resp = await getGeneralTariff(id, vesselId);
    if (resp.success) {
      let isDisable = true;
      const filterData = resp.data.filter((item) => item.specificationNo === section.specificationNo)[0];
      if (section.specifications.length && filterData.childData.length) {
        section.specifications.forEach((i) => {
          filterData.childData.forEach((j) => {
            j.parentSpecificationId = section.id;
            if (i.specificationNo === j.specificationNo) {
              j.isChecked = true;
              j.disableSpec = true;
            }
          });
        });
        isDisable = filterData.childData.every((j) => j.isChecked);
        filterData.isChecked = isDisable;
        filterData.disableSpec = isDisable;
        setDisableSave(isDisable);
      } else if (filterData.childData.length) {
        filterData.childData.forEach((j) => {
          j.parentSpecificationId = section.id;
        });
        filterData.isChecked = false;
        setDisableSave(false);
      }
      setformDataToFillList([filterData]);
      setModalBox(true);
    }
  };

  /* istanbul ignore next */
  const saveNewSpec = (isSub) => {
    const reqData = {
      projectId: id,
      type: 2
    };
    if (isSub) {
      reqData.specificationNo = newSubData.specificationNo.toString();
      reqData.title = newSubData.title.trimStart().trimEnd().toUpperCase();
      reqData.specificationLevel = 2;
      reqData.parentSpecificationId = newSubData.parentSpecificationId;
      reqData.id = newSubData.id || undefined;
    } else {
      reqData.specificationNo = newMainData.specificationNo;
      reqData.title = newMainData.title.trimStart().trimEnd().toUpperCase();
      reqData.specificationLevel = 1;
    }
    let isValid = true;
    if (isSub) {
      if (newSubData.nextNumber && (newSubData.specificationNo >= newSubData.nextNumber)) {
        isValid = false;
      }
    }
    if (isValid && formValidation(['specificationNo', 'title'], isSub ? newSubData : newMainData)) {
      addManualSpec(reqData).then((res) => {
        if (res.success) {
          setLoading(false);
          showAlert(newSubData.id ? 'Successfully Updated' : 'Successfully Saved', 'success');
          setNewMainData({ specificationNo: '', title: '' });
          setNewSubData({ specificationNo: '', title: '' });
          setShowAddNew(false);
          setShowAddNewSub(false);
          majorSpecsSectionList({ id });
          reloadAfterGTMT('', isVesselUser ? res?.data.draftSpecificationId : res?.data.id, res?.data);
        }
      });
    } else if (!isValid) showAlert('Invalid Specification Number', 'error');
    else showAlert('Please enter the mandatory(*) fields', 'error');
  };
  const formattedlist = dropDownList.map((item) => ({
    label: item.specificationNo,
    value: item.id
  }));
  useEffect(() => {
    getMajorSpecsData();
    getGSpecData();
    getMSpecData();
    getProjDetails();
  }, [reload]);
  const onLibrarySave = () => {
    setConfirmChange(true);
  };
  const onConfirmChange = () => {
    deleteExistingMajorSpecs({ id, enum: selectedCard }).then((res) => {
      if (res.success) {
        setConfirmChange(false);
        setLoading(false);
        setShowModal(false);
        setSelectedSpec({});
        getMSpecData();
        getPrevProjectSpecList();
        showAlert('Successfully changed', 'success');
      }
      setIsUpload(false);
    });
  };
  const onReload = () => {
    getMSpecData();
    getGSpecData();
  };
  const reloadTable = () => {
    getNonSpecTsi(id).then((res) => {
      setLoading(false);
      setGtNonSpecData(res?.data);
      if (selectedSpec && selectedSpec.id) {
        res?.data?.forEach((element) => {
          if (element.id === selectedSpec.id) {
            setSelectedSpec(element);
          }
        });
      }
    });
  };
  const managementId = localStorage.getItem('managementId');
  useEffect(() => {
    reloadTable();
    getAcCode({
      page: 1,
      pageSize: 1000,
      managementGroupId: managementId || ZERO_UUID }).then((resp) => {
      if (resp.success) {
        setLoading(false);
        setAcCodeList(generateOptions(resp.data.items, 'id', 'name', 'code'));
      }
    });
  }, [managementId]);
  useEffect(() => {
    setClosedSection([]);
  }, [viewSpec]);
  useEffect(() => {
    setLoading(true);
  }, []);
  const specIsComplete = (e) => {
    const payloads = {
      specId: selectedSpec?.id,
      projectId: id,
      specificationNo: selectedSpec?.specificationNo,
      completetionStatus: e?.value
    };
    updateSpecIsComplete(payloads).then((res) => {
      if (res.success) {
        setLoading(false);
        showAlert(res?.data?.responseMessage, 'success');
        setSelectedStatus(e);
        onReload();
      }
    });
  };
  const specAssignedTo = (e) => {
    const payload = {
      specId: selectedSpec?.id,
      projectId: id,
      specificationNo: selectedSpec?.specificationNo,
      specAssignedTo: e?.value
    };
    updateSpecAssigne(payload).then((res) => {
      if (res.success) {
        // setLoading(false);
        showAlert(res?.data?.responseMessage, 'success');
        // setSelectedStatus(e);
        setSelectedAssignee(e);
        onReload();
      }
    });
  };
  const handleStatusChange = (e) => {
    specIsComplete(e);
  };

  const handleAssigneeChange = (e) => {
    specAssignedTo(e);
  };

  const handleChangeType = (index, level, subIndex) => {
    const temp = cloneDeep(formDataToFillList);
    if (level === 'main') {
      temp.map((item, ind) => {
        if (index === ind) {
          item.isChecked = !item.isChecked;
          item.childData.map((i) => {
            i.isChecked = item.isChecked;
          });
        }
      });
    } else {
      temp.map((item) => {
        let isSelected = true;
        item.childData.map((i, inde) => {
          if (subIndex === inde) {
            i.isChecked = !i.isChecked;
          }
          if (!i.isChecked) {
            isSelected = false;
          }
        });
        if (!isSelected) {
          item.isChecked = false;
        } else {
          item.isChecked = true;
        }
      });
    }
    setformDataToFillList(temp);
  };

  const saveGtSpec = () => {
    const arr = [];
    let checked = false;
    const temp = cloneDeep(formDataToFillList);
    temp[0].childData.map((i) => {
      if (i.isChecked) {
        i.tempOrder = 1;
        checked = true;
        arr.push(i);
      }
    });
    if (checked) {
      SaveSpecificSpecs(id, arr).then((res) => {
        if (res.success) {
          showAlert('Successfully Added', 'success');
          setModalBox(false);
          setDisableSave(false);
        }
      });
    } else {
      showAlert('Please check at least one spec before saving.', 'error');
    }
  };

  useEffect(() => {
    if (selectedSpec && setSpecDetails) { setSpecDetails(selectedSpec); }
  }, [selectedSpec]);
  return (
    <div data-testid="specify-main">
      {loading ? <Loader2 /> : (
        <div className="content-wrap mb-5">
          <div className="phase-wrapper">
            <div className="row">
              <Phases
                gtNonSpecData={gtNonSpecData}
                setGtNonSpecData={setGtNonSpecData}
                projectId={id}
                viewSpec={viewSpec}
                changeView={changeView}
                gtSpecData={gtSpecData}
                mtSpecData={mtSpecData}
                visibleSpec={visibleSpec}
                currency={currency}
                setVisibleSpec={setVisibleSpec}
                selectedSpec={selectedSpec}
                setSelectedSpec={setSelectedSpec}
                onDeleteSpec={onDeleteSpec}
                createDraft={createDraft}
                projInfo={projectData}
                onEditSpec={onEditSpec}
                gtEditSpec={gtEditSpec}
                isTsiDisabled={isTsiDisabled}
                addNewSpec={addNewSpec}
                setClosedSection={setClosedSection}
                closedSection={closedSection}
                setSelectedSpecType={setSelectedSpecType}
                showModal={showModal}
                setShowModal={setShowModal}
                onCardSelect={onCardSelect}
                selectedCard={selectedCard}
                onLibrarySave={onLibrarySave}
                setSelectedStatus={setSelectedStatus}
                onReload={onReload}
                currencyId={currencyId}
                reloadTable={reloadTable}
                isTSI={isTSI}
                prevListInView={prevListInView}
                setPrevListInView={setPrevListInView}
                setPrevProjItems={setPrevProjItems}
                prevProjItems={prevProjItems}
                getPrevProjectSpecList={getPrevProjectSpecList}
                arrayList={dropDownList}
                showContent={showContent}
                isVesselUser={isVesselUser}
                replaceDraft={replaceDraft}
                formDataToFillList={formDataToFillList}
                mergeDraft={mergeDraft}
              />
              {selectedSpec.id
                ? (
                  <>
                    {viewSpec
                      ? (
                        <SpecList
                          isSidePanel={isSidePanel}
                          isTSI={isTSI}
                          projectId={id}
                          isTsiDisabled={isTsiDisabled
                            || ((!selectedSpec?.isDraft) && userRole === roles?.VESSEL_USER)
                            || (selectedSpec?.isDraft && userRole !== roles?.VESSEL_USER)}
                          specDetails={selectedSpec}
                          selectedSpec={selectedSpec.id || ''}
                          selectedSpecType={selectedSpecType}
                          onReload={onReload}
                          currency={currency}
                          selectedStatus={selectedStatus}
                          handleStatusChange={handleStatusChange}
                          handleAssigneeChange={handleAssigneeChange}
                          setSelectedStatus={setSelectedStatus}
                          setSelectedAssignee={setSelectedAssignee}
                          selectedAssignee={selectedAssignee}
                          fromMainSpecify
                          setShowServiceLineModalSpecify={setShowServiceLineModalSpecify}
                          showServiceLineModalSpecify={showServiceLineModalSpecify}
                          currencyId={currencyId}
                          isDraft={selectedSpec?.isDraft}
                          projectData={projectData}
                          reloadAfterGTMT={reloadAfterGTMT}
                        />
                      )
                      : (
                        <NonSpecsList
                          acCodeList={acCodeList}
                          setAcCodeList={setAcCodeList}
                          loading={loading}
                          projectId={id}
                          currency={currency}
                          isTsiDisabled={isTsiDisabled
                            || (selectedSpec?.isLocked && userRole === roles?.VESSEL_USER)}
                          specDetails={selectedSpec}
                          selectedSpec={selectedSpec.id || ''}
                          selectedSpecType={selectedSpecType}
                          reloadTable={reloadTable}
                          currencyId={currencyId}
                        />
                      )}
                  </>
                )
                : <EmptySpecSelected viewSpec={viewSpec} />}
            </div>
          </div>
          {openDeleteModal && (
            <ModalBox
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall()}
              closeModal={() => setOpenDeleteModal(false)}
              buttonName="Delete"
            >
              <span>
                <p>{`Once deleted, the ${isDeleteSpec ? 'specification' : 'section'} cannot be retrieved.`}</p>
                {!isDraft && TSIUser
                  && (
                    <span>
                      <p>
                        Please Note - On deleting any specification (except for the last specification
                        within the section), all specification numbers below it will be automatically
                        readjusted.
                      </p>
                      <p>Do you want to continue?</p>
                    </span>
                  )}
              </span>
            </ModalBox>
          )}
          {openLockModal ? (
            <ModalBox
              openDeleteModal={openLockModal}
              modalClassName="medium"
              heading="Edit Specs Details"
              onSave={() => createDraftMode()}
              closeModal={() => setOpenLockModal(false)}
              buttonName="Continue"
            >
              A draft will be created for this spec on which you can make edits.
              Once verified by shore, the changes made will reflect in the main spec.
              Do you want to continue?
            </ModalBox>
          ) : (
            ''
          )}
          {openReplaceModal ? (
            <ModalBox
              openDeleteModal={openReplaceModal}
              modalClassName="medium"
              heading="Replace Spec Details"
              onSave={() => replaceDraftMode()}
              closeModal={() => setOpenReplaceModal(false)}
              buttonName="Continue"
            >
              <p>
                If this option is selected, information provided by vessel, which includes description, service lines, attachments,
                tags will overwrite all of the information provided by TSI in the specification and which cannot be retrieved.
                <br />
                <br />
                Do you want to continue?
              </p>
            </ModalBox>
          ) : (
            ''
          )}
          {openMergeModal ? (
            <ModalBox
              openDeleteModal={openMergeModal}
              modalClassName="medium"
              heading="Merge Spec Details"
              onSave={() => mergeDraftMode()}
              closeModal={() => setOpenMergeModal(false)}
              buttonName="Continue"
            >
              <p>
                If this option is selected, information provided by vessel, which includes description, service lines, attachments,
                tags will be brought into the the specification. Information entered by TSI will remain. TSI must then edit the specification as required.
                <br />
                <br />
                Do you want to continue?
              </p>
            </ModalBox>
          ) : (
            ''
          )}
          {showAddNew && (
            <ModalBox
              modalClassName="medium"
              heading="Add New Section"
              onSave={() => saveNewSpec()}
              closeModal={() => {
                setShowAddNew(false);
                setNewMainData({ specificationNo: '', title: '' });
              }}
              buttonName="Save"
            >
              <AddNewSec newMainData={newMainData} setNewMainData={setNewMainData} />
            </ModalBox>
          )}
          {showAddNewSub && (
            <ModalBox
              modalClassName="medium"
              heading={newSubData.id ? 'Edit Specification' : 'Add New Specification'}
              onSave={() => saveNewSpec(true)}
              closeModal={() => {
                setShowAddNewSub(false);
                setNewSubData({ specificationNo: '', title: '' });
              }}
              buttonName="Save"
            >
              <AddNewSubSec newSubData={newSubData} setNewSubData={setNewSubData} />
            </ModalBox>
          )}
          {confirmChange && (
            <ModalBox
              modalClassName="medium"
              heading="Do you want to change package?"
              onSave={() => {
                setIsUpload(true);
                onConfirmChange();
              }}
              closeModal={() => {
                setConfirmChange(false);
              }}
              buttonName="Continue"
              saveDisable={isUpload}
            >
              Once changed, all changes made to the existing major specifications will be lost.
            </ModalBox>
          )}
          {openModal && (
            <ModalBox
              modalClassName="medium"
              heading="Deferred spec mapping"
              onSave={() => handleSave()}
              closeModal={() => {
                setOpenModal(false);
                setSelectedSpecList('');
              }}
              saveDisable={!selectedSpecList}
              buttonName="Save"
            >
              <DeferredSpecList
                openModal={openModal}
                formattedlist={formattedlist}
                selectedSpecList={selectedSpecList}
                setSelectedSpecList={setSelectedSpecList}
              />
            </ModalBox>
          )}
        </div>
      )}
      {modalBox && (
        <ModalBox
          modalClassName="medium"
          heading="Save"
          onSave={() => saveGtSpec()}
          closeModal={() => {
            setModalBox(false);
            setDisableSave(false);
          }}
          buttonName="Save"
          saveDisable={disableSave}
        >
          <span>
            <MajorSpecPackagesList
              isExpand={isExpand}
              formDataToFillList={formDataToFillList}
              isTsiDisabled={isTsiDisabled}
              setIsExpand={setIsExpand}
              handleChangeType={handleChangeType}
            />
          </span>
        </ModalBox>
      )}
      {isVesselUser && (
        <div className="footer justify-content-between font-13">
          <div className="vessel-footer">
            {`© ${new Date().getFullYear()} ThinkPalm`}
          </div>
        </div>
      )}

    </div>
  );
};
export default PhasePage;
