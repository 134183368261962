/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cards from './cards';
import TimelinePage from './childrens/timeLinePage';
import { getProjectDetailsTSI } from '../NewProject/services';
import { getAllTaskList, getTaskCount, deleteMicroTask, saveMicroTask, updateTaskStatus, getProjectDetails } from './services';
// import { sample } from './childrens/constant';
import { groupsList, itemList } from './childrens/utilities';
import ModalBox from '../../../common/ModalBox';
import InputType1 from '../../../common/InputType1';
import showAlert from '../../../../utils/alert';
import helper from '../../../../utils/helper';
// import Loader2 from '../../../common/loader2';

const TsiTask = () => {
  const { id } = useParams();
  const { formValidation } = helper;
  const [taskList, setTaskList] = useState([]);
  const [countList, setCountList] = useState({});
  const [openedList, setOpenedList] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [taskSelected, setTaskSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [phaseId, setPhaseId] = useState(1);
  const [details, setDetails] = useState({});
  const [projDetails, setProjDetails] = useState({});

  const getAllData = () => {
    getAllTaskList({ phaseId,
      projectId: id,
      phaseIdList: phaseId === 6 ? [1, 2, 3, 4, 5] : [phaseId] }).then((res) => {
      if (res.success) {
        setTaskList(res.data);
        setLoading(false);
      }
    });
    getTaskCount({ phaseId,
      projectId: id,
      phaseIdList: phaseId === 6 ? [1, 2, 3, 4, 5] : [phaseId] }).then((resp) => {
      if (resp.data) {
        setCountList(resp.data);
      }
    });
  };
  useEffect(() => {
    getProjectDetails({ id }).then((res) => {
      if (res.success) {
        setLoading(false);
        const queryParams = new URLSearchParams(window.location.search);
        const phaseIdParam = queryParams.get('phaseId');
        setPhaseId(phaseIdParam ? Number(phaseIdParam) : res.data.phaseId);
        setDetails(res.data);
      }
    });
    getProjectDetailsTSI({ id }).then((res) => {
      if (res.success) {
        setLoading(false);
        setProjDetails(res.data);
      }
    });
    setLoading(true);
  }, []);
  useEffect(() => {
    getAllData();
  }, [phaseId]);
  const test = (data) => {
    const currentKeys = [...openedList];
    if (openedList.indexOf(data.id) > -1) {
      const index = openedList.indexOf(data.id);
      currentKeys.splice(index, 1);
    } else {
      currentKeys.push(data.id);
    }
    setOpenedList(currentKeys);
  };
  const editMicro = (e, isNew) => {
    const selectedTask = { ...taskSelected };
    selectedTask.projectId = id;
    if (isNew) {
      selectedTask.offset = '';
      selectedTask.microTaskName = '';
      selectedTask.taskCategoryId = e.id;
      selectedTask.id = undefined;
    } else {
      selectedTask.offset = e.offset;
      selectedTask.microTaskName = e.microTaskName;
      selectedTask.taskCategoryId = e.taskCategoryId;
      selectedTask.id = e.id;
    }
    setTaskSelected(selectedTask);
    setOpenAddModal(e);
  };
  const deleteMicro = (e) => {
    setOpenDeleteModal(e);
  };
  const deleteMTask = () => {
    deleteMicroTask({ id: openDeleteModal.id }).then((res) => {
      if (res.success) {
        setLoading(false);
        showAlert('Successfully Deleted', 'success');
        getAllData();
      }
    });
    setOpenDeleteModal(false);
  };
  const updateMtask = () => {
    if (taskSelected.offset === '0' || taskSelected.offset === '00') {
      showAlert('Please enter valid milestone days', 'error');
    } else if (formValidation(['microTaskName', 'offset'], taskSelected)) {
      saveMicroTask(taskSelected).then((res) => {
        if (res.success) {
          showAlert(taskSelected?.id ? 'Successfully updated' : 'Successfully saved', 'success');
          getAllData();
        }
      });
      setOpenAddModal(false);
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  const statusChange = (e, type, taskId, status) => {
    const reqBody = {
      projectId: id,
      taskId,
      status
    };
    if (type === 'micro') {
      reqBody.microTaskId = e.id;
      reqBody.taskCategoryId = e.taskCategoryId;
    } else if (type === 'sub') {
      reqBody.taskCategoryId = e.id;
    } else {
      reqBody.microTaskId = '00000000-0000-0000-0000-000000000000';
      reqBody.taskCategoryId = '00000000-0000-0000-0000-000000000000';
    }
    updateTaskStatus([reqBody]).then((res) => {
      if (res.success) {
        showAlert('Status updated successfully', 'success');
        getAllData();
      }
    });
  };
  const onTaskUpdate = (e) => {
    const selectedTask = { ...taskSelected };
    if (e.target.name === 'offset') {
      selectedTask[e.target.name] = e.target.value.replace(/[^0-9]/g, '').substring(0, 2);
    } else {
      selectedTask[e.target.name] = e.target.value.substring(0, 250);
    }
    setTaskSelected(selectedTask);
  };
  const updatePhaseId = (e) => {
    // eslint-disable-next-line radix
    const phaseVal = parseInt(e.target.value);
    setPhaseId(phaseVal);
  };
  return (
    <>
      <div className="sub-header z-index-998" data-testid="tsi-task">
        <div className="d-flex  col-gap-3">
          <div className="d-flex justify-content-start align-items-center">
            {/* <a href="/user/projects" className="link-btn ms-3 me-4">
              <span className="icon-arrow-left2 me-2" />
              Back to projects
            </a> */}
            <button disabled={window.history?.length < 3} className="link-btn ms-5" id="click1" type="button" onClick={() => window.history.back()}>
              <span className="icon-arrow-left2 me-2" />
              Back
            </button>
            <h2 className="main-head me-5 ms-5">
              Task Manager
            </h2>
          </div>
        </div>
      </div>
      <div className="content-wrap mt-4">
        <div className="container-fluid height-fix-yard">
          {/* <Loader /> */}
          <Cards countList={countList} />
          <TimelinePage
            taskList={taskList}
            // eslint-disable-next-line max-len
            groupsList={groupsList(taskList, test, openedList, editMicro, deleteMicro, statusChange, details)}
            itemList={itemList(taskList, openedList)}
            openedList={openedList}
            setOpenedList={setOpenedList}
            phaseId={phaseId}
            updatePhaseId={updatePhaseId}
            loading={loading}
            editMicro={editMicro}
            statusChange={statusChange}
            deleteMicro={deleteMicro}
            details={details}
            onOpenTask={test}
            projDetails={projDetails}
          />
          {openAddModal
        && (
          <ModalBox
            modalClassName="medium"
            heading={taskSelected?.id ? 'Edit Microtask' : 'Add Microtask'}
            onSave={() => updateMtask()}
            closeModal={() => setOpenAddModal(false)}
            buttonName="Save"
          >
            <div className="row">
              <div className="col-8"><InputType1 autoFocus id="tname" isMandatory="true" type="text" name="microTaskName" label="Microtask Name" value={taskSelected.microTaskName} onChange={onTaskUpdate} /></div>
              <div className="col-4"><InputType1 id="offset" isMandatory="true" type="text" name="offset" label="Milestone(Days)" value={taskSelected.offset} onChange={onTaskUpdate} /></div>
            </div>
          </ModalBox>
        )}
          {openDeleteModal
        && (
          <ModalBox
            openDeleteModal={openDeleteModal}
            modalClassName="medium"
            heading="Delete"
            onSave={() => deleteMTask()}
            closeModal={() => setOpenDeleteModal(false)}
            buttonName="Delete"
          >
            <p>Are you sure you want to delete microtask?</p>
          </ModalBox>
        )}
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-6">
              <div className=" footer-sof mb-2">{`© ${new Date().getFullYear()} ThinkPalm`}</div>

            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default TsiTask;
