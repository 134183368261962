import { serviceHandler } from '../../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../../utils/apiUrlConstants';

const {
  SaveSpecServiceLine,
  DeleteSpecServiceLine,
  UpdateSpecServiceLines,
  GetSpecServiceLine,
  GetPublishUnitOfMeasuresList,
  GetMgmtAcCodesUrl,
  UpdateAcCode
} = apiEndPoints;

export const saveSpecServiceLine = (payload) => {
  return serviceHandler({
    url: `${SaveSpecServiceLine}`,
    methordType: 'post',
    payload
  });
};

export const deleteSpecServiceLine = (payload) => {
  return serviceHandler({
    url: `${DeleteSpecServiceLine}?parentId=${payload.id}`,
    methordType: 'put',
    payload: payload.number
  });
};
export const getSpecServiceLine = (payload) => {
  return serviceHandler({
    url: `${GetSpecServiceLine}?parentId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const getPublishUomList = (payload) => {
  return serviceHandler({
    url: `${GetPublishUnitOfMeasuresList}`,
    methordType: 'get',
    payload
  });
};
export const getAcCode = (payload) => {
  return serviceHandler({
    url: `${GetMgmtAcCodesUrl}`,
    methordType: 'post',
    payload
  });
};
export const updateAcCode = (payload) => {
  return serviceHandler({
    url: `${UpdateAcCode}`,
    methordType: 'post',
    payload
  });
};
export const updateSpecServiceLines = (payload) => {
  return serviceHandler({
    url: `${UpdateSpecServiceLines}`,
    methordType: 'post',
    payload
  });
};
