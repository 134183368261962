/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import TermsSection from './termsSection';
import AgreementFooter from './agreementFooter';
import { termsAndCondition } from '../service';
import Loader from '../../../common/loader';

const Agreement = (props) => {
  const { params } = props;
  const { loading } = props;
  const [terms, setTerms] = useState('');

  useEffect(() => {
    termsAndCondition({ key: params.key }).then((res) => {
      if (res.success) {
        setTerms(res.data);
      }
    });
  }, []);
  return (
    <>
      {loading && <Loader />}
      <div className="d-flex quote_agreement" data-testid="yard-agreement">
        <TermsSection {...props} terms={terms} />
        <AgreementFooter {...props} />
      </div>

    </>
  );
};
export default Agreement;
