import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const ServiceLineTable = ({
  SpecificationType,
  SpecificationLevel,
  uomList,
  handleTableChange,
  indexToEdit,
  ZERO_UUID,
  groupedKfp,
  showHeader,
  setOpenDltModal
}) => {
  return (
    <div data-testid="mt-tab-inner-serviceLine">
      <div className="custom-table">
        {showHeader && (
        <div className="row custom-table-header">
          <div className={SpecificationType === 1 ? 'col-2' : 'col-3'}>Service #</div>
          <div className={SpecificationType === 1 ? 'col-4' : 'col-5'}>Service Lines</div>
          <div className={SpecificationType === 1 ? 'col-2' : 'col-2'}>UOM</div>
          {SpecificationType === 1 && <div className={SpecificationType === 1 ? 'col-2' : 'col-1'}>Quantity</div>}
          <div className={SpecificationType === 1 ? 'col-2' : 'col-1'}>Action</div>
        </div>
        )}
        {groupedKfp.map((original) => {
          return (
            <div className="row d-flex">
              <div className={SpecificationType === 1 ? 'col-2 ps-5 py-4' : 'col-3 ps-5 py-4'}>
                <div className="text-ellipsis">
                  <span
                    data-tooltip-id="project-Spec#"
                    data-tooltip-content={original.specificationNo}
                  >
                    {original.specificationNo}
                  </span>
                  <Tooltip id="project-Spec#" place="top" className="tooltip mt-4 me-5" />
                </div>
              </div>
              <div className={SpecificationType === 1 ? 'col-4 ps-4 py-4' : 'col-5 ps-4 py-4'}>
                {indexToEdit && original.specificationLevel === 3 ? (
                  <input
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={!!original.isNew}
                    data-test="change1"
                    className="text-box c-w-full"
                    type="text"
                    id={`new-title-${original.specificationNo}`}
                    value={original.title}
                    onChange={(e) => handleTableChange(e, 'title', original)}
                  />
                ) : (
                  <div className="text-ellipsis">
                    <span
                      data-tooltip-id="project-Spectitle"
                      data-tooltip-content={original.title}
                    >
                      {original.title}

                    </span>
                    <Tooltip id="project-Spectitle" place="top" className="tooltip mt-4 me-5" />
                  </div>
                )}
              </div>
              <div className={SpecificationType === 1 ? 'col-2 py-4' : 'col-2 py-4'}>
                {indexToEdit && original.specificationLevel === 3 ? (
                  <select
                    data-test="change2"
                    onChange={(option) => handleTableChange(option, 'uomId', original)}
                    className="custom-select c-w-full"
                    label="UOM"
                    value={original.uomId}
                  >
                    <option value={ZERO_UUID}>Select</option>
                    {uomList?.map((i) => {
                      return <option value={i.value}>{i.unit}</option>;
                    })}
                  </select>
                ) : (
                  <div className="text-ellipsis">
                    <span
                      data-tooltip-id="project-uomName"
                      data-tooltip-content={original.uomName}
                    >
                      {original.uomName}

                    </span>
                    <Tooltip id="project-uomName" place="top" className="tooltip mt-4 me-5" />
                  </div>
                )}
              </div>
              {SpecificationType === 1 ? (
                <div className={SpecificationType === 1 ? 'col-2 py-4 pe-4' : 'col-1 py-4 pe-4'}>
                  {indexToEdit ? (
                    <>
                      {original.specificationLevel === SpecificationLevel.KFP ? (
                        <select
                          data-test="change3"
                          onChange={(option) => handleTableChange(option, 'quanityEnterBy', original)}
                          className="custom-select c-w-full"
                          label="UOM"
                          placeholder="Data input by"
                          value={original.quanityEnterBy === '' ? ' ' : original.quanityEnterBy}
                          id={`new-uom-${original.specificationNo}`}
                        >
                          <option value="">Select</option>
                          <option value="Yard">Yard</option>
                          <option value="TSI ">TSI</option>
                        </select>
                      ) : (
                        <input className="text-box c-w-full disabled-field" readOnly disabled="true" />
                      )}
                    </>
                  ) : (
                    <div>{original.quanityEnterBy}</div>
                  )}
                </div>
              ) : ''}
              <div className={SpecificationType === 1 ? 'col-2 py-4' : 'col-1 py-4'}><span id="delete" className="icon-delete icon-btn  brand-color cursor-pointer font-24" onClick={() => { setOpenDltModal(original); }} /></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ServiceLineTable;
