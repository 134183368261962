/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { evaluate } from 'mathjs';
import { cloneDeep } from 'lodash';

import QuotesHeader from './QuotesHeader';
import QuotesValue from './QuotesValue';
import KFPCalculator from '../KFPCalculator';
import CostCalculator from '../CostCalculator';
import CostSummaryAmount from './CostSummaryAmounts';
import ModalBox from '../../../../../../common/ModalBox';
import showAlert from '../../../../../../../utils/alert';
import RecievedMsg from '../../../../ChatScreen/recievedMsg'
import SentMsg from '../../../../ChatScreen/sentMsg';

const YardListsHome = (props) => {
  const {
    checkList, compareData, setCompareData, generalData, sortedQuotes, recalculate, applyAll,
    setApplyAll, SaveCQ, closeModal, setCloseModal, searchList, kfpList, serviceLines, setServiceLines,
    onSaveSLComments, isTSI, disableFields, repairStarted, selectModal, setSelectModal, yardComment,
    commentValue, setCommentValue, sidePanelData, setSidePanelData, postDOCostDetails, showSideBarCost,
    onClose, setCanSend, SaveComment, setSelectedYard, addYardComment, setAddYardComment, addComment,
    setAddComment, commentList, setCommentList, comment, setComment, handleChange
  } = props;

  const isDisabled = !isTSI || disableFields || repairStarted;

  const setContainerClass = (i, index) => {
    const bgContainer = (index + 1) % 2 === 0 ? '' : 'cq-bg-even';
    if (i?.showFull) {
      return 'data-container-cq selected-yard-highlight ms-2 me-2 ' + bgContainer;
    } else if (i?.isSelected) {
      return 'data-container-green-cq ms-2 me-2 ' + bgContainer;
    } else {
      return 'data-container-cq ms-2 me-2 ' + bgContainer;
    }
  };
  const [scrollPosition, setScrollPosition] = useState(0);

  /* istanbul ignore next */
  const handleScroll = (direction) => {
    const scrollingBox = document.getElementById('scrolling-box');
    if (!scrollingBox) return;
    const containerWidth = scrollingBox.offsetWidth;
    const scrollWidth = scrollingBox.scrollWidth;
    const maxScrollPosition = scrollWidth - containerWidth;
    const scrollIncrement = containerWidth / 2;

    if (direction === 'right') {
      const newPosition = Math.min(scrollPosition + scrollIncrement, maxScrollPosition);
      setScrollPosition(newPosition);
      scrollingBox.scrollTo(newPosition, 0);
    } else {
      const newPosition = Math.max(scrollPosition - scrollIncrement, 0);
      setScrollPosition(newPosition);
      scrollingBox.scrollTo(newPosition, 0);
    }
  };

  /* istanbul ignore next */
  const handleApplyQuantity = (applyForAll) => {
    setCompareData(prevData => {
      const updatedData = prevData.map(yard =>
        applyForAll || applyAll.yardId === yard.id
          ? {
            ...yard,
            sectionQuoteList: yard.sectionQuoteList.map(section =>
              section.id !== applyAll.line.parentSectionId
                ? section
                : {
                  ...section,
                  specList: section.specList.map(spec =>
                    spec.id !== applyAll.line.parentSpecId
                      ? spec
                      : {
                        ...spec,
                        serviceLineList: spec.serviceLineList.map(service => {
                          if (service.id !== applyAll.line.id) {
                            return service;
                          }
                          const x = applyAll.value;
                          const y = service.unitPrice;
                          const z = service.discount;
                          const res = x * y - ((x * y) / 100) * z;
                          const updatedService = {
                            ...service,
                            ...(applyForAll
                              ? {
                                isUpdateQuantity: true,
                                isUpdateFormula: false
                              }
                              : {
                                isUpdateQuantity: false,
                                isUpdateFormula: false,
                              }),
                            quantity: applyAll.value,
                            yardEstimate: res,
                            ...(!service.formula && spec.specAssigned === 1
                              ? { ownerEstimate: res }
                              : {}),
                          };
                          if (applyAll.yardId === yard.id) {
                            SaveCQ(updatedService, true);
                          }

                          return updatedService;
                        }),
                      }
                  ),
                }
            ),
          }
          : yard
      );

      recalculate(updatedData);
      return updatedData;
    });

    setApplyAll({ status: false, value: '', field: '', line: {}, yardId: '' });
    showAlert('Any changes to fields will not automatically apply to cells where these are used for calculation. Please visit the service line to apply changes there.', 'info');
  };

  /* istanbul ignore next */
  const handleApplyKFP = (output, formula, sLine, yardId, saveAll = false) => {
    setCompareData(prevData => {
      const shouldUpdateAll = output === "" && formula === "" && saveAll;

      const updatedData = prevData.map(yard => {
        if (!shouldUpdateAll && yard.id !== yardId) {
          return yard;
        }

        return {
          ...yard,
          sectionQuoteList: yard.sectionQuoteList.map(section =>
            section.id !== sLine.parentSectionId
              ? section
              : {
                ...section,
                specList: section.specList.map(spec =>
                  spec.id !== sLine.parentSpecId
                    ? spec
                    : {
                      ...spec,
                      serviceLineList: spec.serviceLineList.map(service => {
                        if (service.id !== sLine.id) {
                          return service;
                        }

                        const updatedService = {
                          ...service,
                          ...(output === "" && formula === ""
                            ? { ownerEstimate: service.yardEstimate }
                            : { ownerEstimate: output }),
                          formula
                        };

                        if (output === "" && formula === "") {
                          SaveCQ(updatedService, true);
                          if (!saveAll) setApplyAll({ status: false, value: '', field: '', line: {}, yardId: '' });
                        }

                        return updatedService;
                      }),
                    }
                ),
              }
          ),
        };
      });

      recalculate(updatedData);
      return updatedData;
    });

    setApplyAll({ status: false, value: '', field: '', line: {}, yardId: '' });
  };

  /* istanbul ignore next */
  const calculateFormula = (equation, applyAllYard) => {
    let fmla = equation ? equation.toLowerCase() : '';

    const regExNum = /^[0-9+\-*/.]+$/; // Numbers and operators
    const regExSym = /^[a-zA-Z0-9^.*+%\-/()]+$/; // Special symbols
    const pattern = /[+\-*.\/]{2}/; // Two consecutive operators

    const validateFormula = (formula) => {
      const endsWithOperator = /[\+\-\*\/]$/.test(formula);
      return (
        regExNum.test(formula) &&
        !endsWithOperator &&
        !pattern.test(formula) &&
        regExSym.test(formula)
      );
    };

    let sLineToSave = null;

    setCompareData((prevData) => {
      const updatedData = prevData.map((yard) => {
        if (!applyAllYard && yard.id !== applyAll.yardId || (applyAll.line.isFromYard && yard.id !== applyAll.yardId)) {
          return yard;
        }

        let updatedFmla = fmla;
        let formulaValidated = false;

        return {
          ...yard,
          sectionQuoteList: yard.sectionQuoteList.map((section) => ({
            ...section,
            specList: section.specList.map((spec) => ({
              ...spec,
              serviceLineList: spec.serviceLineList.map((sLine) => {
                if (formulaValidated) return sLine;

                if (sLine.id === applyAll.line.id) {
                  sLineToSave = { ...sLine };
                }

                if (sLine.id === '00000000-0000-0000-0000-000000000000') {
                  updatedFmla = updatedFmla
                    .replaceAll(`${sLine.number}.qty`, 0)
                    .replaceAll(`${sLine.number}.unitprice`, 0)
                    .replaceAll(`${sLine.number}.discount`, '0')
                    .replaceAll(`${sLine.number}.yardestimate`, 0)
                    .replaceAll(`${sLine.number}.ownersestimate`, 0);
                } else {
                  if (sLine.number) {
                    const lowerNum = sLine.number.toLowerCase();
                    const replacements = {
                      qty: sLine.quantity || 0,
                      unitprice: sLine.unitPrice || 0,
                      discount: sLine.discount != null ? sLine.discount.toString() : '0',
                      yardestimate: sLine.yardEstimate || 0,
                      ownersestimate: sLine.ownerEstimate || 0,
                    };

                    updatedFmla = Object.keys(replacements).reduce((acc, key) => {
                      const searchStr = `${lowerNum}.${key}`;
                      return acc.replaceAll(searchStr, replacements[key]);
                    }, updatedFmla);

                    const words = updatedFmla.match(/\b[a-zA-Z]+[a-zA-Z0-9]*\b/g) || [];

                    words.forEach(word => {
                      const standaloneRegex = new RegExp(`\\b${word}\\b(?!\\.)`, 'g');
                      if (word.toLowerCase() === lowerNum) {
                        updatedFmla = updatedFmla.replace(standaloneRegex, sLine.quantity || 0);
                      }
                    });
                  }
                }

                if (validateFormula(updatedFmla) || updatedFmla === "") {
                  const result = evaluate(updatedFmla);

                  if (sLineToSave && sLineToSave.id === applyAll.line.id) {
                    sLineToSave.ownerEstimate = !result ? sLineToSave.yardEstimate : result;
                    sLineToSave.formula = equation;
                    formulaValidated = true;
                    SaveCQ(sLineToSave, true);
                    handleApplyKFP(!result ? sLineToSave.yardEstimate : result, equation, sLineToSave, yard.id);
                    sLineToSave = null;
                  }
                }

                return sLine;
              }),
            })),
          })),
        };
      });

      return updatedData;
    });

    setApplyAll({ status: false, value: '', field: '', line: {}, yardId: '' });
  };

  /* istanbul ignore next */
  const onClickShowComment = (data, yard) => {
    const specNo = data.number.split('.') || [];
    let section = yard.sectionQuoteList.find(x => x.id === data.parentSectionId);
    const spec = section.specList.find(x => x.number === specNo.slice(0, specNo.length - 1).join('.'));
    setAddComment(true);
    setCommentList(data.commentList);
    setComment({
      projectId: '00000000-0000-0000-0000-000000000000',
      specId: spec?.id,
      serviceLineId: data.id,
      projectRfqId: data.rfqId,
      comment: null
    });
  };

  const onSaveComment = (data) => {
    onSaveSLComments(data);
    setAddComment(false);
    setComment({
      projectId: null,
      specId: null,
      serviceLineId: null,
      projectRfqId: null,
      comment: null
    });
    setCommentList([]);
  };
  const messagesEndRef = useRef(null);

  /* istanbul ignore next */
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
  };

  /* istanbul ignore next */
  useEffect(() => {
    scrollToBottom();
  }, [addComment]);


  return (
    <>
      <CostCalculator
        repairStarted={repairStarted}
        showSideBarCost={showSideBarCost}
        onClose={onClose}
        sidePanelData={sidePanelData}
        setSidePanelData={setSidePanelData}
        postDOCostDetails={postDOCostDetails}
        compareData={compareData}
        setCompareData={setCompareData}
        disableFields={disableFields}
        recalculate={recalculate}
      />
      {addYardComment && (
        <ModalBox
          onSave={() => {
            handleChange(commentValue?.comment, commentValue?.index, commentValue?.rfqId, commentValue?.isSelected, true, 'selectionComment', 'save');
            setAddYardComment(false);
          }}
          closeModal={() => {
            setAddYardComment(false);
            const temp = {
              comment: '',
              index: '',
              rfqId: '',
              isSelected: '',
            };
            setCommentValue(temp);
          }}
          heading={isDisabled ? 'Comment' : yardComment ? 'Update Comment' : 'Add Comment'}
          modalClassName="medium modal-scroll"
          buttonName={isDisabled ? '' : 'Save'}
          className="comment-popup"
        >
          <textarea
            disabled={isDisabled ? true : false}
            placeholder="Comment"
            className="text-box w-full h-text-area pt-2"
            data-test="comment-popup"
            onChange={(e) => {
              setCommentValue({ ...commentValue, comment: e.target.value });
            }}
            value={commentValue?.comment}
          />
        </ModalBox>
      )}
      {selectModal?.status && (
        <ModalBox
          modalClassName="medium modal-scroll"
          heading={selectModal?.data?.isSelected ? 'Deselect' : 'Select'}
          closeModal={() => {
            setSelectModal({ status: false, index: '', data: '' });
          }}
          onSave={() => {
            setSelectModal({ status: false, index: '', data: '' });
            const g = cloneDeep(compareData);
            let count = 0;
            g.map((ii, ind) => {
              if (ind === selectModal?.index) {
                if (ii.isSelected) {
                  ii.isSelected = false;
                } else {
                  ii.isSelected = true;
                  setCanSend(true);
                  setSelectedYard(ii.id);
                  count = count + 1;
                }
              } else {
                ii.isSelected = false;
              }
            });
            if (count === 0) {
              setCanSend(false);
              setSelectedYard('');
            }
            const temp = {
              rfqId: selectModal?.data?.rfqId,
              comment: selectModal?.data?.comment,
              isSelected: !selectModal?.data?.isSelected ? true : false,
              additionalDiscount: selectModal?.data?.additionalDiscount,
              additionalDiscountPercentage: selectModal?.data?.additionalDiscountPercentage
            };
            SaveComment(temp, 'select');
            setCompareData(g);
            recalculate(g);
          }}
          buttonName="Confirm"
          className="select-popup"
        >
          <div>Are you sure you want to {selectModal?.data?.isSelected ? 'Deselect' : 'Select'} yard?</div>
        </ModalBox>
      )}
      {closeModal && (
        <ModalBox
          closeModal={() => {
            setCloseModal(false);
          }}
          onSave={() => {
            if (applyAll.line.isFromYard || compareData?.length === 1) {
              calculateFormula(applyAll.value, false);
              setCloseModal(false);
            } else {
              setApplyAll({ ...applyAll, status: true });
              setCloseModal(false);
            }
          }}
          heading="KFP Calculator"
          modalClassName="medium modal-scroll kfp"
          buttonName="Apply"
          className="kfp-modal-box"
        >
          <KFPCalculator
            applyAll={applyAll}
            setApplyAll={setApplyAll}
            compareData={compareData}
            setCompareData={setCompareData}
            checkList={checkList}
            generalData={generalData}
            kfpList={kfpList}
            searchList={searchList}
            serviceLines={serviceLines}
            setServiceLines={setServiceLines}
          />
        </ModalBox>
      )}
      {applyAll.status && (
        <ModalBox
          closeModal={() => {
            if (applyAll.field === 'quantity') {
              handleApplyQuantity(false);
            } else {
              if (applyAll.value !== "") {
                calculateFormula(applyAll.value, false);
              } else {
                handleApplyKFP("", "", applyAll.line, applyAll.yardId, false);
              }
            }
          }}
          onSave={() => {
            if (applyAll.field === 'quantity') {
              handleApplyQuantity(true);
            } else {
              if (applyAll.value !== "") {
                calculateFormula(applyAll.value, true);
              } else {
                handleApplyKFP("", "", applyAll.line, applyAll.yardId, true);
              }
            }
          }}
          heading="Apply All"
          modalClassName="medium modal-scroll"
          isNoLabel
          buttonName="Yes"
          className="applyall-popup"
        >
          <div>Do you want to apply the value for all yards?</div>
        </ModalBox>
      )}
      {addComment && (
        <ModalBox
          modalClassName="medium"
          heading="Add Comment"
          onSave={() => onSaveComment(comment)}
          closeModal={() => {
            setAddComment(false);
            setComment({
              projectId: null,
              specId: null,
              serviceLineId: null,
              projectRfqId: null,
              comment: null
            });
            setCommentList([]);
          }}
          buttonName={isDisabled ? "" : "Save"}
          className="chat-list"
        >
          <div className="modal-sp">
            {commentList?.map((item) => {
              const msg = {
                ...item,
                name: item.userName,
                updatedAt: item.timeStamp,
                message: item.comment
              };
              if (localStorage.getItem('USER_ID') === msg?.userId) {
                return <SentMsg send={{ ...msg, showUserName: true }} />;
              }
              return <RecievedMsg item={msg} />;
            })}
            <div ref={messagesEndRef} />
          </div>
          <textarea
            disabled={isDisabled}
            placeholder="Type Messages here..."
            className="text-box w-full h-text-area pt-3 mt-3"
            data-test="chat-list"
            onChange={(e) => {
              setComment({ ...comment, comment: e.target.value });
            }}
            value={comment.comment}
          />
        </ModalBox>
      )}
      {compareData?.length > 1 && (
        <button type="button" id="excel" className="link-btn large left-icon-cq" data-test="scroll1" onClick={() => handleScroll('left')}>
          <span className="icon-arrow-thin-left font-22" />
        </button>
      )}
      <div id="scrolling-box" className="scrolls-cq mb-9 ms-2 text-xl">
        {compareData?.map((yard, index) => {
          let classToBeAdded = '';
          let classToBeAddedd = 'ribbon ribbon-top-left'
          if (compareData?.length > 1) {
            sortedQuotes?.map((quote) => {
              if (quote.id === yard.id) {
                if (quote?.isHighest) {
                  classToBeAdded = 'Highest';
                  classToBeAddedd = 'Highest ribbon ribbon-top-left';
                } else if (quote?.isMedian) {
                  classToBeAdded = 'Average';
                  classToBeAddedd = 'Average ribbon ribbon-top-left';
                } else if (quote?.isLowest) {
                  classToBeAdded = 'Lowest';
                  classToBeAddedd = 'Lowest ribbon ribbon-top-left';
                } else {
                  classToBeAdded = '';
                }
              }
            });
          }
          return (
            <div className={setContainerClass(yard, index)}>
              <QuotesHeader
                yardDetails={yard}
                setContainerClass={setContainerClass}
                classToBeAdded={classToBeAdded}
                classToBeAddedd={classToBeAddedd}
                index={index}
                {...props}
              />
              <QuotesValue yardDetails={yard} index={index} {...props} onClickShowComment={onClickShowComment} />
              <CostSummaryAmount yardDetails={yard} index={index} {...props} />
            </div>
          );
        })}
      </div>
      {compareData?.length > 1 && (
        <button type="button" id="excel" className="link-btn large right-icon-cq" data-test="scroll2" onClick={() => handleScroll('right')}>
          <span className="icon-arrow-thin-right font-22" />
        </button>
      )}
    </>
  );
};

export default YardListsHome;
