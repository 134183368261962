/* eslint-disable max-len */
/* istanbul ignore file */
import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import helper from '../../../../../../utils/helper';
import ModalBox from '../../../../../common/ModalBox';
import RecievedMsg from '../../../ChatScreen/recievedMsg';
import SentMsg from '../../../ChatScreen/sentMsg';

const ReceivedQuotesTable = (props) => {
  const { formatNumber, roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isAdmin = userRole === roles.ADMIN;
  const isTSI = userRole === roles.AUTHOR
    || userRole === roles.EDITOR
    || userRole === roles.ADMIN;
  const { specList, mainSec, showsubSec, onSecOpen, setShowMore,
    viewMoreAttachments, setParentSpecId, onSaveSLComments } = props;
  const [addComment, setAddComment] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState({
    projectId: null,
    specId: null,
    serviceLineId: null,
    projectRfqId: null,
    comment: null
  });

  const onSaveComment = (data) => {
    onSaveSLComments(data);
  };
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ block: 'end', inline: 'nearest' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [addComment]);

  return (
    <>
      <div className="spc-map ps-3" data-testid="received-table">
        <div className="d-flex border-down py-2 ps-3">
          <div className="col-1 font-bold">SEC#</div>
          <div className="col-9 font-bold">SPEC SECTION</div>
          <div className="col-2 font-bold text-left-align">TOTAL</div>
        </div>
        {
          specList?.sectionData?.map((sec) => {
            return (
              <div
                id={sec.projectSpecId}
                className={(sec.projectSpecId === mainSec && showsubSec) ? 'highlight-tender' : ''}
              >
                <div className="d-flex border-down py-2 ps-3">
                  <div className="col-1 d-flex align-items-center">
                    <span
                      className={sec.projectSpecId === mainSec ? 'font-14 icon-sort-up me-3 brand-color pointer rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                      onClick={() => {
                        onSecOpen(sec.projectSpecId, true);
                        setParentSpecId(sec?.projectSpecId);
                      }}
                    />
                    <span
                      data-tooltip-id="project-specNo"
                      data-tooltip-content={sec?.specificationNo}
                    >
                      {_.truncate(sec?.specificationNo, { length: 7 })}
                    </span>
                    <Tooltip id="project-specNo" place="top" className="tooltip mt-3" />
                  </div>
                  <div
                    className="col-9"
                  >
                    <span
                      data-tooltip-id="project-poha"
                      data-tooltip-content={sec?.title}
                    >
                      {_.truncate(sec?.title, { length: 80 })}
                    </span>

                  </div>
                  <Tooltip id="project-sectitle" place="top" className="tooltip mt-3" />
                  <div className="col-2  text-ellipsis text-left-align head-14">
                    {specList?.rfqData?.currencySymbol}
                    {' '}
                    {formatNumber(sec?.total) || 0}
                  </div>
                </div>
                {
                  sec.projectSpecId === mainSec && sec.specDataList.map((subsec) => {
                    return (
                      <>
                        <div
                          id={subsec?.projectSpecId}
                          className="d-flex mb-2 py-2 ps-3"
                        >
                          <div className="col-1" />
                          <div className="col-3 d-flex align-items-center">
                            <span
                              className={subsec.projectSpecId === showsubSec ? 'font-14 icon-sort-up brand-color pointer me-3 rotate-180' : 'font-14 icon-sort-up brand-color rotate-90 pointer me-2'}
                              onClick={() => { onSecOpen(subsec?.projectSpecId, false); }}
                            />
                            <span className="me-4">{subsec?.specificationNo}</span>
                            <div
                              className="text-ellipsis"
                              data-tooltip-id="project-poha"
                              data-tooltip-content={subsec?.title}
                            >
                              {_.truncate(subsec.title, { length: 60 })}

                            </div>
                          </div>
                          <div className="col-6 d-flex">
                            {subsec.projectSpecId === showsubSec ? (
                              <>
                                <div className="rq-attachment d-flex align-items-center">
                                  {subsec?.specDocuments?.length > 0 && (
                                    <span className="dot-indicator-rq" />
                                  )}
                                  <button
                                    className="link-btn small"
                                    type="button"
                                    onClick={() => {
                                      viewMoreAttachments(subsec);
                                    }}
                                  >
                                    <span
                                      id="attachment-click"
                                      className="icon-attachments font-20 brand-color"
                                    />
                                    Attachments
                                  </button>
                                </div>
                                <button
                                  className="secondary-btn small ml-30 mt-2"
                                  type="button"
                                  onClick={() => {
                                    setShowMore(subsec);
                                  }}
                                >
                                  View Spec
                                </button>

                              </>
                            ) : ''}

                          </div>
                          <div
                            className={subsec.projectSpecId === showsubSec
                              ? 'd-flex mt-2 col-2 text-ellipsis justify-content-end head-14 brand-color'
                              : 'd-flex mt-2 col-2 text-ellipsis justify-content-end brand-color'}
                          >
                            <span
                              data-tooltip-id="project-poha"
                              data-tooltip-content={specList?.rfqData?.currencySymbol?.concat(formatNumber(subsec
                                ?.total))}
                            >
                              {specList?.rfqData?.currencySymbol}
                              {' '}
                              {formatNumber(subsec?.total) || 0}
                            </span>

                          </div>
                        </div>
                        {
                          subsec.projectSpecId === showsubSec
                          && subsec.serviceLineList.map((serv, num) => {
                            return (
                              <>
                                {num === 0 && (
                                  <div className="d-flex ps-3 align-items-center">
                                    <div className="col-1" />
                                    <div className="col-1 bg-sky-white ps-3 border-down py-2">Service LN#</div>
                                    <div className="col-03 bg-sky-white ps-3 border-down py-2">Service Lines</div>
                                    <div className="col-1 bg-sky-white ps-3 border-down py-2">AC Code</div>
                                    <div className="col-1 bg-sky-white ps-3 border-down py-2 text-center">Qty</div>
                                    <div className="col-1 bg-sky-white ps-3 border-down py-2">UOM</div>
                                    <div className="col-1 bg-sky-white ps-3 border-down py-2 text-right">Rate</div>
                                    <div className="col-1 bg-sky-white ps-3 border-down py-2 text-center">Disc[%]</div>
                                    <div className="col-1 bg-sky-white ps-2 border-down py-2 text-left-align">Amount</div>
                                    <div className="col-1 bg-sky-white ps-3 border-down py-2">Comment</div>
                                  </div>
                                )}
                                <div className={serv.isRemoved ? 'd-flex pb-2 ps-3 py-1 removed-SL' : 'd-flex pb-2 ps-3 py-1'}>
                                  <div className="col-1" />
                                  <div
                                    className={serv?.isNew || serv?.isFromYard ? 'col-1 ps-3 border-down bg-lightorange text-ellipsis' : 'text-ellipsis col-1 ps-3 border-down'}
                                  >
                                    <span
                                      data-tooltip-id="project-poha"
                                      data-tooltip-content={serv.number}
                                    >
                                      {serv.number}
                                    </span>
                                  </div>
                                  <div
                                    className={serv?.isNew || serv?.isFromYard ? 'col-03 ps-3 text-justify text-break-all border-down  bg-lightorange' : ' text-justify text-break-all col-03 ps-3 border-down'}
                                    data-tooltip-id="project-poha"
                                    data-tooltip-content={serv.serviceLineName}
                                  >
                                    {_.truncate(serv.serviceLineName, { length: 40 })}
                                  </div>
                                  <div
                                    className={serv?.isNew || serv?.isFromYard ? 'col-1 ps-3 border-down bg-lightorange text-ellipsis' : 'text-ellipsis col-1 ps-3 border-down'}
                                    data-tooltip-id="project-poha"
                                    data-tooltip-content={serv.acCode}
                                  >
                                    {_.truncate(serv.acCode, { length: 10 })}
                                  </div>
                                  <div
                                    className={serv?.isNew || serv?.isFromYard ? 'col-1 ps-3 border-down bg-lightorange text-ellipsis text-center' : 'text-ellipsis col-1 ps-3 border-down text-center'}
                                    data-tooltip-id="project-poha"
                                    data-tooltip-content={serv.quantity}
                                  >
                                    {_.truncate(serv.quantity, { length: 10 })}
                                  </div>
                                  <div
                                    className={serv?.isNew || serv?.isFromYard ? 'col-1 ps-3 border-down bg-lightorange text-ellipsis' : 'text-ellipsis col-1 ps-3 border-down'}
                                    data-tooltip-id="project-poha"
                                    data-tooltip-content={serv.uomName}
                                  >
                                    {_.truncate(serv.uomName, { length: 15 })}
                                  </div>
                                  <div
                                    className={serv?.isNew || serv?.isFromYard ? 'col-1 ps-3 border-down bg-lightorange text-ellipsis text-right' : 'text-ellipsis col-1 ps-3 border-down text-right'}
                                    data-tooltip-id="project-poha"
                                    data-tooltip-content={formatNumber(serv.rate)}
                                  >
                                    {_.truncate(formatNumber(serv.rate), { length: 10 })}
                                  </div>
                                  <div
                                    className={serv?.isNew || serv?.isFromYard ? 'col-1 ps-3 border-down bg-lightorange text-ellipsis text-center' : 'text-ellipsis col-1 ps-3 border-down text-center'}
                                    data-tooltip-id="project-poha"
                                    data-tooltip-content={serv.discount}
                                  >
                                    {_.truncate(serv.discount, { length: 5 })}
                                  </div>
                                  <div
                                    className={serv?.isNew || serv?.isFromYard ? 'col-1 ps-2 border-down bg-lightorange text-ellipsis text-left-align' : 'text-ellipsis col-1 ps-2 border-down text-left-align'}
                                    data-tooltip-id="project-poha"
                                    data-tooltip-content={formatNumber(serv.amount)}
                                  >
                                    {_.truncate(formatNumber(serv.amount), { length: 15 })}
                                  </div>
                                  <div
                                    className={serv?.isNew || serv?.isFromYard
                                      ? 'col-1 ps-3 border-down bg-lightorange d-flex justify-content-end'
                                      : 'col-1 ps-3 border-down d-flex justify-content-end'}
                                  /* onClick={() => {
                                    if (serv?.comments !== '' && serv?.comments !== null) {
                                      viewMoreComments(serv?.comments);
                                    }
                                  }} */
                                  >
                                    {(isAdmin || isTSI)
                                      && (
                                      <>
                                        <span
                                          className="font-22 icon-add-comment pe-2 brand-color pointer"
                                          onClick={() => {
                                            setAddComment(true); setCommentList(serv.commentList);
                                            setComment({
                                              projectId: specList.projectId,
                                              specId: subsec.projectSpecId,
                                              serviceLineId: serv.id,
                                              projectRfqId: specList?.rfqData?.id,
                                              comment: null
                                            });
                                          }}
                                        />
                                        {(serv.commentList?.length > 0) && (
                                          <span className="dot-indicator-comment" />
                                        )}
                                      </>
                                      )}
                                  </div>
                                </div>
                              </>
                            );
                          })
                        }
                      </>
                    );
                  })
                }
              </div>
            );
          })
        }
        <Tooltip id="project-poha" place="bottom" className="tooltip" />
      </div>
      {addComment && (
        <ModalBox
          modalClassName="medium"
          heading="Add Comment"
          onSave={() => onSaveComment(comment)}
          closeModal={() => {
            setAddComment(false);
            setComment({
              projectId: null,
              specId: null,
              serviceLineId: null,
              projectRfqId: null,
              comment: null
            });
            setCommentList([]);
          }}
          buttonName="Save"
        >
          <div className="modal-sp">
            {commentList?.map((item) => {
              const msg = {
                ...item,
                name: item.userName,
                updatedAt: item.timeStamp,
                message: item.comment
              };
              if (localStorage.getItem('USER_ID') === msg?.userId) {
                return <SentMsg send={{ ...msg, showUserName: true }} />;
              }
              return <RecievedMsg item={msg} />;
            })}
            <div ref={messagesEndRef} />
          </div>
          <textarea
            disabled={specList?.variationQuoteStatus === 4}
            placeholder="Type Messages here..."
            className="text-box w-full h-text-area pt-3 mt-3"
            onChange={(e) => {
              setComment({ ...comment, comment: e.target.value });
            }}
            value={comment.comment}
          />
        </ModalBox>
      )}
    </>
  );
};
export default ReceivedQuotesTable;
