/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import Select from 'react-select';
import moment from 'moment';
import { getProjectDetailsTSI, getVesselDetails, getVesselList, checkForMultipleProjects } from '../services';
import Card from './Card';
import { AccordList } from './constants';
import NavigateButtons from './NavigateButtons';
import ModalBox from '../../../../common/ModalBox';
import { getYardNotification } from '../../services';

const VesselDetails = (props) => {
  const params = qs.parse(window.location.search);
  const [vesselInfo, setVesselInfo] = useState({});
  const [vessels, setVessels] = useState([]);
  const [multiProjChecking, setmultiProjChecking] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [alert, setAlert] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedVesselId, setSelectedVesselId] = useState();
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  const { handleActiveComponent, getVesselInfo, setSelectedVessel, selectedVessel,
    stepperList, setStepperList, handleSetProjectId, initialData, isSelectedVessel,
    setProjectId, projectId, isTsiDisabled } = props;
  const isSearchable = true;
  const isClearable = true;
  const ZERO_UUID = '00000000-0000-0000-0000-000000000000';
  const [projectDetails, setProjectDetails] = useState();

  useEffect(() => {
    if (localStorage.getItem('createProjectVesselId')) {
      setSelectedVessel(localStorage.getItem('createProjectVesselId'));
      setIsSelectDisabled(true);
    }
  });

  const getPDetails = () => {
    getProjectDetailsTSI({ id: projectId }).then((response) => {
      if (response?.success) {
        setProjectDetails(response?.data);
      }
    });
  };

  const MultipleProjectsCheck = (e) => {
    checkForMultipleProjects({ vesselId: e.id }).then((response) => {
      if (response?.success) {
        setmultiProjChecking(response?.data);
      }
    });
  };
  useEffect(() => {
    if (projectId) {
      getPDetails();
    }
  }, [projectId]);

  const getDetails = (selected) => {
    getVesselDetails({ id: selected }).then((res) => {
      if (res?.success) {
        setVesselInfo(res?.data);
        getVesselInfo(res?.data);
      }
    });
  };

  /* istanbul ignore next */
  const handleChange = (e) => {
    localStorage.removeItem('createProjectVesselId');
    setIsChanged(true);
    if (e?.id) {
      MultipleProjectsCheck(e);
      if (params?.projectId && selectedVessel && selectedVessel !== e?.id) {
        setAlert(true);
        setSelectedVesselId(e);
      } else {
        setSelectedVessel(e.id);
        getDetails(e.id);
      }
    } else if (projectId) {
      setCloseModal(true);
    } else {
      setSelectedVessel('');
      setStepperList(initialData);
      setProjectId('');
      setmultiProjChecking(true);
      handleActiveComponent('vessel-details');
    }
  };
  useEffect(() => {
    getYardNotification().then((res) => {
      if (res.success) {
        setNotificationData(res.data);
      }
    });
  }, []);

  const getVesselLists = () => {
    const payload = {
      projectId: params?.projectId || ZERO_UUID
    };
    getVesselList(payload).then((res) => {
      if (res.data) {
        const list = res.data.map((opt, key) => {
          return ({
            label:
  <div className="bg-sky-white p-3" key={key.id}>
    <div className="d-flex align-items-center">
      <img className="thmb-md ship-thumb" src={opt.imageUrl ? opt.imageUrl : '/static/media/vessel-thmd.304ab3bca4863ea93cc6.jpg'} alt="not found" />
      <div className="d-flex flex-column ps-1">
        <p className="head-16">{opt.name}</p>
        <p className="pt-2">{opt.vesselSubType}</p>
      </div>
    </div>
  </div>,
            value: opt.name,
            id: opt.id
          });
        });
        setVessels(list);
      }
    });
  };
  /* istanbul ignore next */
  const onSelection = () => {
    if (isSelectedVessel !== selectedVesselId?.value) {
      handleSetProjectId('');
      setStepperList(initialData);
    }
    setAlert(false);
    setSelectedVessel(selectedVesselId?.id);
    getDetails(selectedVesselId?.id);
  };
  const onSelectionClick = () => {
    if (projectId) {
      setSelectedVessel('');
      setStepperList(initialData);
      setProjectId('');
      handleActiveComponent('vessel-details');
    }
    setCloseModal(false);
  };
  useEffect(() => {
    getVesselLists();
    if (selectedVessel) {
      getDetails(selectedVessel);
    }
  }, [selectedVessel]);
  console.log(selectedVessel, 'selectedVessel');
  return (
    <div className="col-8 card m-auto p-3em mb-5" data-testid="vessel-details">
      <div className="mb-4">
        <h3 className="head-18 mb-2">Vessel Details</h3>
        <p>Choose vessel and confirm details</p>
      </div>
      <label className="required"> Choose Vessel</label>
      <div style={{ width: 'full' }} className="mt-3" data-testid="my-select-component" id="code">
        <Select
          onChange={(e) => { handleChange(e, false); }}
          className="modal-select vessel-select"
          label="vessel"
          id="vessel"
          options={vessels}
          isDisabled={isTsiDisabled || isSelectDisabled}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              primary25: '#EBF2FF',
              primary: 'LightGray'
            }

          })}
          placeholder="Select a Vessel"
          isSearchable={isSearchable}
          isClearable={isClearable}
          value={vessels.filter((opt) => (opt.id === selectedVessel))}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              background: '#EBF2FF',
              boxShadow: state.isFocused ? null : null,
              borderColor: state.isFocused ? '#EBF2FF' : '#EBF2FF',
              border: ' 0px solid red',
              cursor: 'pointer'
            }),
            menuList: (base) => ({
              ...base,
              background: '#EBF2FF'
            })
          }}
        />
        {!multiProjChecking && (
          <div className="red mt-4 font-18 align-items-center">
            <span className="icon-Info me-2" />
            New project cannot be created as there is an active project for the vessel now
          </div>
        )}
      </div>
      <div>
        {selectedVessel && (
          <div className="d-flex justify-content-between mt-5 pt-5">
            <h3 className="head-18">
              {vesselInfo.generalInfo && (vesselInfo.generalInfo.name || '')}
            </h3>
            <div className="d-flex">
              <label>
                Previous DD Date :
              </label>
              <div className="value ms-2">
                {vesselInfo.generalInfo?.previousDdDate ? moment(vesselInfo.generalInfo.previousDdDate).format('DD-MMM-YYYY') : '-'}
              </div>
            </div>
            <div className="d-flex">
              <label>
                DD Due Date :
              </label>
              <div className="value ms-2">
                {vesselInfo?.generalInfo?.ddDueDate ? moment(vesselInfo.generalInfo.ddDueDate).format('DD-MMM-YYYY') : '-'}
              </div>
            </div>
          </div>
        )}
        {selectedVessel && (
          <div className="my-4">
            {
              AccordList.map((list) => {
                return <Card data={list} vesselInfo={vesselInfo} />;
              })
            }
          </div>
        )}
      </div>
      {alert && (
        <ModalBox
          modalClassName="medium"
          heading="Selecting Another vessel"
          closeModal={() => setAlert(false)}
          onSave={() => onSelection()}
          buttonName="Yes"
        >
          <p>
            If vessel is changed, all details entered so far
            for the project will be cleared. Do you want to continue?

          </p>
        </ModalBox>
      )}
      {closeModal && (
        <ModalBox
          modalClassName="medium"
          heading="Clearing the vessel"
          closeModal={() => setCloseModal(false)}
          onSave={() => onSelectionClick()}
          buttonName="Yes"
        >
          <p>
            If vessel is cleared, all details entered so far
            for the project will be cleared. Do you want to continue?

          </p>
        </ModalBox>
      )}
      <NavigateButtons
        multiProjChecking={multiProjChecking}
        isChanged={isChanged}
        selectedVessel={selectedVessel}
        setSelectedVessel={setSelectedVessel}
        setVesselInfo={setVesselInfo}
        handleActiveComponent={handleActiveComponent}
        stepperList={stepperList}
        setStepperList={setStepperList}
        isTsiDisabled={isTsiDisabled}
        projectId={params?.projectId}
        projectDetails={projectDetails}
      />
    </div>
  );
};

export default VesselDetails;
