/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _, { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import showAlert from '../../../../../utils/alert';
import ModalBox from '../../../../common/ModalBox';
import SidePanel from '../../../../common/sidePanel';
import ViewMore from '../../../../Quotations/YardQuotation/ViewMore';
import PreviewContent from './childrens/previewContent';
import PreviewFooter from './childrens/previewFooter';
import SelectYard from './childrens/selectYard';
import {
  getAllYardList, getAllSpecList, getSendRfqYardList, saveProjectRfq, rfqConfirmYardList,
  removeMutipleServiceLines, retrieveServiceLine, savePreQuoteSLComments, saveReceivedQuoteSLComments
} from './services';
import Loader from '../../../../common/loader';

const QuotationPrew = (props) => {
  const { setShowSideBar, setIsSupplementaryClicked, showSideBar, newSpecs, projectBasicDetails } = props;
  const { id } = useParams();
  const [showYard, setShowYard] = useState(false);
  const [modalBox, setModalBox] = useState(false);
  const [shortListedYard, setShortListedYard] = useState([]);
  const [yardList, setYardList] = useState([]);
  const [selectedYard, setSelectedYard] = useState([]);
  const [specList, setSpecList] = useState({});
  const [mainSec, setMainSec] = useState('');
  const [showsubSec, setShowSubSec] = useState('');
  const [sendedYardList, setSendedYardList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [yardPage, setYardPage] = useState(1);
  const [emailDetails, setEmailDetails] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [days, setDays] = useState(7);
  const [slotDate, setSlotDate] = useState('');
  const [listLoading, setListLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState();
  const [restoreModal, setRestoreModal] = useState();
  const [serviceLineIds, setServiceLineIds] = useState({
    activeSpecId: '',
    serviceLineList: []
  });
  const [enableDelete, setEnableDelete] = useState(false);
  const [enableRestore, setEnableRestore] = useState(false);

  const sendRfqList = () => {
    getSendRfqYardList({ id }).then((sendList) => {
      if (sendList.success) {
        setLoading(false);
        setSendedYardList(sendList.data);
      }
    });
  };

  const getSpecListData = () => {
    getAllSpecList({ id, type: searchText }).then((resp) => {
      if (resp.success) {
        setSpecList(resp.data);
      }
      sendRfqList();
      setEnableRestore(false);
      setEnableDelete(false);
      setLoading(false);
    });
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const confirmYard = (date, day) => {
    const sDate = date || slotDate;
    const eday = day || days;
    if (sDate && getTodayDate() >= sDate) {
      showAlert('Please select a valid date.', 'error');
      return;
    }
    if (sDate && eday) {
      const date1 = `${moment(sDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
      rfqConfirmYardList({
        projectId: id,
        yardId: selectedYard,
        expiryDays: eday,
        slotRequestDate: date1
      }).then((resp) => {
        if (resp.success) {
          setLoading(false);
          setEmailDetails(resp.data);
          setListLoading(false);
        }
      });
    }
  };

  const getYardLists = () => {
    getAllYardList().then((res) => {
      if (res.success) {
        setYardList(res.data);
      }
    });
    getSpecListData();
    sendRfqList();
  };

  useEffect(() => {
    getYardLists();
  }, []);

  const onSelectYard = (val) => {
    getYardLists();
    if (newSpecs && newSpecs.length > 0 && val !== 1) {
      setModalBox(true);
    } else {
      setShowYard(!showYard);
    }
  };

  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
      setShowSubSec('');
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };

  const onCancelYard = (show = true) => {
    const visibility = yardPage === 2 ? false : show;
    setShowYard(visibility);
    setSelectedYard([]);
    setShortListedYard([]);
    setYardPage(1);
    setSlotDate('');
  };

  const onSaveYard = () => {
    const saveReq = [];
    const date = `${moment(slotDate).format('YYYY-MM-DD')}T00:00:00.000Z`;
    if (slotDate && getTodayDate() >= slotDate) {
      showAlert('Please select a valid date.', 'error');
      return;
    }
    selectedYard.map((item) => {
      saveReq.push({
        projectId: id, yardId: item, expiryDays: days, slotRequestDate: date
      });
      return null;
    });
    if (yardPage === 1) {
      setYardPage(2);
      setListLoading(true);
    } else if (slotDate) {
      saveProjectRfq(saveReq).then((res) => {
        if (res.success) {
          showAlert('Successfully sent RFQ to selected yards', 'success');
          sendRfqList();
          setSlotDate('');
          onCancelYard(false);
        }
      });
    } else {
      showAlert('Please select the slot request date.', 'error');
    }
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      getSpecListData();
    }
  };

  const removeSL = () => {
    removeMutipleServiceLines(serviceLineIds).then((res) => {
      if (res.success) {
        showAlert('Successfully Deleted Service Line(s)', 'success');
        setDeleteModal(false);
        setServiceLineIds({
          activeSpecId: '',
          serviceLineList: []
        });
        getSpecListData();
      }
    });
  };

  const restoreSL = () => {
    retrieveServiceLine(serviceLineIds).then((res) => {
      if (res.success) {
        showAlert('Successfully Restored Service Line(s)', 'success');
        setRestoreModal(false);
        setServiceLineIds({
          activeSpecId: '',
          serviceLineList: []
        });
        getSpecListData();
      }
    });
  };

  const specClick = (e, secId, specId) => {
    const temp = cloneDeep(specList);
    let newServiceLineIds = [...serviceLineIds.serviceLineList];
    if (specId !== serviceLineIds.activeSpecId) {
      newServiceLineIds = [];
    }

    temp.sectionData.forEach((sec) => {
      if (sec.projectSpecId === secId) {
        sec.specDataList.forEach((spec) => {
          if (spec.projectSpecId === specId) {
            spec.isSelected = true;
            if (!spec?.serviceLineList?.length) newServiceLineIds = [];
            spec.serviceLineList.forEach((service) => {
              if (e.target.checked) {
                if (!newServiceLineIds.includes(service.id)) {
                  newServiceLineIds.push(service.id);
                  setEnableRestore(spec.serviceLineList.some((item) => !item.isRemoved));
                  setEnableDelete(spec.serviceLineList.some((item) => item.isRemoved));
                }
              } else {
                spec.isSelected = false;
                newServiceLineIds = [];
                setEnableRestore(false);
                setEnableDelete(false);
              }
            });
          } else {
            spec.isSelected = false;
          }
        });
      }
    });
    setSpecList(temp);
    setServiceLineIds({
      activeSpecId: e.target.checked ? specId : null,
      serviceLineList: newServiceLineIds
    });
  };

  const serviceClick = (e, secId, specId, servId) => {
    const temp = cloneDeep(specList);
    let newServiceLineIds = [...serviceLineIds.serviceLineList];
    if (specId !== serviceLineIds.activeSpecId) {
      newServiceLineIds = [];
    }

    temp.sectionData.forEach((sec) => {
      if (sec.projectSpecId === secId) {
        sec.specDataList.forEach((spec) => {
          if (spec.projectSpecId === specId) {
            spec.serviceLineList.forEach((service) => {
              if (servId === service.id) {
                spec.isSelected = true;
                if (e.target.checked && !service.isRemoved) setEnableRestore(true);
                if (e.target.checked && service.isRemoved) setEnableDelete(true);
                if (e.target.checked) {
                  if (!newServiceLineIds.includes(servId)) {
                    newServiceLineIds.push(servId);
                  }
                } else {
                  newServiceLineIds = newServiceLineIds.filter((item) => item !== servId);
                }
              }
              if (!newServiceLineIds.length) {
                spec.isSelected = false;
                setEnableRestore(false);
                setEnableDelete(false);
              }
            });
          }
        });
      }
    });
    setSpecList(temp);
    setServiceLineIds({
      activeSpecId: newServiceLineIds?.length ? specId : null,
      serviceLineList: newServiceLineIds
    });
  };

  const onSaveSLComments = (data) => {
    savePreQuoteSLComments(data).then((resp) => {
      if (resp.success) {
        getSpecListData();
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!searchText) {
      getSpecListData();
    }
  }, [searchText]);

  useEffect(() => {
    if (!showSideBar) {
      setLoading(true);
      getSpecListData();
    }
  }, [showSideBar]);

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <div data-testid="prew-main" className="pre-q">
      {deleteModal
        && (
          <ModalBox
            modalClassName="medium"
            heading="Delete Service Line(s)"
            onSave={() => removeSL()}
            closeModal={() => setDeleteModal(false)}
            isNoLabel
            buttonName="Yes"
          >
            <div>
              Are you sure you want to delete the Service Line(s)?
            </div>
          </ModalBox>
        )}
      {restoreModal
        && (
          <ModalBox
            modalClassName="medium"
            heading="Restore Service Line(s)"
            onSave={() => restoreSL()}
            closeModal={() => setRestoreModal(false)}
            isNoLabel
            buttonName="Yes"
          >
            <div>
              Are you sure you want to restore the Service Line(s)?
            </div>
          </ModalBox>
        )}
      {modalBox
        && (
          <ModalBox
            modalClassName="medium modal-scroll"
            heading="New Specs"
            onSave={() => {
              setShowYard(!showYard);
              setModalBox(false);
            }}
            closeModal={() => {
              setModalBox(false);
            }}
            removeCancelBtn
            buttonName="Continue"
          >
            <>
              <div className="p-1 mt-2 mb-4 font-semibold font-16">
                From the list below, the approved specs will be sent to the yard, while the not approved specs will not be sent until they are approved by the authorized person.
              </div>
              <div className="d-flex font-bold border-down p-2">
                <span className="col-2">SPEC NO.</span>
                <span className="col-6">SPEC NAME</span>
                <span className="col-3">STATUS</span>
              </div>
              <div className="new-specList">
                {newSpecs && newSpecs.map((spec) => {
                  return (
                    <div className="d-flex border-down p-2">
                      <span className="col-2">
                        <div className="align-items-center gap-1">
                          <label className="text-ellipsis" htmlFor={spec.specificationNo}>{spec.specificationNo}</label>
                        </div>
                      </span>
                      <span className="col-6">{_.truncate(spec.title, { length: 40 })}</span>
                      <span className="col-3 ms-1">{spec.approvalStatusNum === 2 ? 'Not Approved' : 'Approved'}</span>
                    </div>
                  );
                })}
              </div>
            </>
          </ModalBox>
        )}
      {loading ? <Loader /> : (
        <div className="p-4">
          <PreviewContent
            specList={specList}
            mainSec={mainSec}
            showsubSec={showsubSec}
            onSecOpen={onSecOpen}
            setSearchText={setSearchText}
            onSearch={onSearch}
            setShowMore={setShowMore}
            searchText={searchText}
            loading={loading}
            specClick={specClick}
            serviceClick={serviceClick}
            serviceLineIds={serviceLineIds}
            setDeleteModal={setDeleteModal}
            setRestoreModal={setRestoreModal}
            enableDelete={enableDelete}
            enableRestore={enableRestore}
            projectBasicDetails={projectBasicDetails}
            onSaveSLComments={onSaveSLComments}
          />
          <PreviewFooter
            onSelectYard={onSelectYard}
            setIsSupplementaryClicked={setIsSupplementaryClicked}
            setShowSideBar={setShowSideBar}
            id={id}
            projectBasicDetails={projectBasicDetails}
          />
          {showYard && (
            <ModalBox
              modalClassName={yardPage === 1 ? 'large' : 'medium modal-scroll'}
              heading={yardPage === 1 ? 'Choose Yards' : 'Confirm RFQ'}
              onSave={() => { onSaveYard(); }}
              closeModal={() => { onCancelYard(yardPage === 1); }}
              closeIcon={() => { onCancelYard(false); }}
              id="select-yard"
              saveDisable={selectedYard.length < 1}
              time={yardPage === 1 ? 500 : 5000}
              buttonName={yardPage === 1 ? '' : 'Confirm and Send RFQ'}
              removeTopMargin
            >
              <div>
                <SelectYard
                  projectId={id}
                  yardList={yardList}
                  shortListedYard={shortListedYard}
                  setShortListedYard={setShortListedYard}
                  setSelectedYard={setSelectedYard}
                  selectedYard={selectedYard}
                  sendedYardList={sendedYardList}
                  yardPage={yardPage}
                  emailDetails={emailDetails}
                  setDays={setDays}
                  days={days}
                  slotDate={slotDate}
                  setSlotDate={setSlotDate}
                  setYardPage={setYardPage}
                  listLoading={listLoading}
                  onSave={() => {
                    if (slotDate) {
                      confirmYard();
                    }
                    onSaveYard();
                  }}
                  specList={specList}
                  closeModal={() => { onCancelYard(yardPage === 1); }}
                  closeModalBtn={() => { onCancelYard(false); }}
                  saveDisable={selectedYard.length < 1 || specList.repairStartDate}
                  buttonName={yardPage === 1 ? 'Send RFQ to Selected Yard' : ''}
                  confirmYard={confirmYard}
                />
              </div>

            </ModalBox>
          )}
          <SidePanel
            title={<span data-tooltip-id="project-more" data-tooltip-content={showMore.title}>{`${showMore.specificationNo}. ${_.truncate(showMore.title, { length: 55 })}`}</span>}
            size="large"
            onClose={() => { setShowMore(false); }}
            showView={showMore}
          >
            <Tooltip id="project-more" place="bottom" className="tooltip ms-5" />
            {showMore && <ViewMore showMore={showMore} isYard={false} />}
          </SidePanel>
        </div>
      )}

    </div>
  );
};
export default QuotationPrew;
