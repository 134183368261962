/* eslint-disable no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AgreementFooter = (props) => {
  const { onContiue, navigateToExit, onDownload, loading, specList, isManual, navigateToForm,
    navigateToFormExit, statusState } = props;
  const navigate = useNavigate();
  /* istanbul ignore next */
  const handleContinue = () => {
    if (statusState === 2 || statusState === 5) {
      navigateToForm(3);
    } else if (statusState === 3) {
      navigateToExit();
      navigate('/ExitPage');
    }
  };
  /* istanbul ignore next */
  return (
    <div className="fixed-footer card d-flex justify-content-end me-3">
      <button
        type="button"
        className="secondary-btn large mr-10"
        id="yard-dwnld"
        onClick={() => { onDownload(); }}
        disabled={!isManual && !specList?.rfqData?.isManual}
      >
        Download
      </button>
      <button
        type="button"
        className="secondary-btn large mr-10"
        id="yard-back"
        onClick={() => { onContiue(1, 'empty'); }}
        disabled={specList?.rfqData?.isManual}
      >
        Back
      </button>
      {!isManual ? (
        <button
          type="button"
          onClick={() => handleContinue()}
          className="primary-btn large mr-10"
          id="yard-continue"
          disabled={!statusState}
        >
          Continue
        </button>
      ) : (
        <button
          type="button"
          className="primary-btn large mr-10"
          id="yard-continue"
          onClick={() => (navigateToFormExit(4))}
          disabled={specList?.rfqData?.isManual}
        >
          Continue
        </button>
      )}
    </div>
  );
};
export default AgreementFooter;
