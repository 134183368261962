/* eslint-disable radix */
export const TotalEstimate = (allData) => {
  let total = 0;
  if (allData.sectionData) {
    allData.sectionData.map((lvl1) => {
      lvl1.specDataList.map((lvl2) => {
        lvl2.serviceLineList.map((lvl3) => {
          if (lvl3.amount && !lvl3.isRemoved) {
            total += parseFloat(lvl3.amount);
          }
          return null;
        });
        return null;
      });
      return null;
    });
  }
  return total.toFixed(2);
};
export const SecTotal = (secData) => {
  let secTotal = 0;
  secData.specDataList.map((lvl2) => {
    lvl2.serviceLineList.map((lvl3) => {
      if (lvl3.amount && !lvl3.isRemoved) {
        secTotal += parseFloat(lvl3.amount);
      }
      return null;
    });
    return null;
  });
  return secTotal.toFixed(2);
};
export const UpdateCalculations = (allData, index) => {
  const currentSpecList = { ...allData };
  currentSpecList.rfqData.total = TotalEstimate(currentSpecList);
  currentSpecList.rfqData.yardFinalEstimate = (currentSpecList?.rfqData
    ?.total - currentSpecList?.rfqData?.discount)?.toFixed(2)?.toString();
  currentSpecList.rfqData.yardEstimateWithoutDiscount = (currentSpecList?.rfqData
    ?.total).toString();
  currentSpecList.sectionData[index].total = SecTotal(currentSpecList.sectionData[index])
    .toString();
  return currentSpecList;
};

export const UpdateServiceNumbers = (allData, index1, index2) => {
  const currentSpecList = { ...allData };
  const specLength = currentSpecList.sectionData[index1]
    .specDataList[index2]
    .serviceLineList.filter((field) => field.specificationLevel === 3 && !field.isFromYard).length;
  const yardserviceLines = currentSpecList.sectionData[index1]
    .specDataList[index2]
    .serviceLineList.filter((field) => field.isFromYard);
  const allServiceLines = currentSpecList.sectionData[index1]
    .specDataList[index2].serviceLineList;
  const updatedLines = [];
  const yardserviceLinesIds = [];
  yardserviceLines.map((yLines) => {
    if (yLines.isFromYard) {
      yardserviceLinesIds.push(yLines.number);
    }
    return null;
  });
  allServiceLines.map((lines) => {
    if (lines.isFromYard) {
      const currentLine = { ...lines };
      currentLine.number = `${currentSpecList.sectionData[index1]
        .specDataList[index2].specificationNo}.${specLength + 1 + yardserviceLinesIds.indexOf(lines.number)}`;
      updatedLines.push(currentLine);
    } else {
      updatedLines.push(lines);
    }
    return null;
  });
  currentSpecList.sectionData[index1]
    .specDataList[index2]
    .serviceLineList = updatedLines;
  return currentSpecList;
};
