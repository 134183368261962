import { serviceHandler } from '../../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../../utils/apiUrlConstants';

const {
  ReceivedQuoteYardList,
  ReceivedQuoteData,
  ImportYardQuoteData,
  YardGetAttachments,
  YardSaveAttachments,
  DeleteYardAttachment,
  ShortListYardsUrl,
  isYardsSelectedUrl
} = apiEndPoints;

export const receivedQuote = (id) => {
  return serviceHandler({
    url: `${ReceivedQuoteYardList}?projectId=${id}`,
    methordType: 'get'
  });
};

export const receivedQuoteData = (rfqId, search) => {
  return serviceHandler({
    url: `${ReceivedQuoteData}?projectRfqId=${rfqId}&searchString=${search}`,
    methordType: 'get'
  });
};

export const importYardQuoteData = (id, payload) => {
  return serviceHandler({
    url: `${ImportYardQuoteData}?projectId=${id}`,
    methordType: 'post',
    payload
  });
};
export const getAttachments = (qId, rfqId) => {
  return serviceHandler({
    url: `${YardGetAttachments}?quoteId=${qId}&projRfqId=${rfqId}`,
    methordType: 'get'
  });
};

export const saveAttachments = (payload) => {
  return serviceHandler({
    url: `${YardSaveAttachments}`,
    methordType: 'post',
    payload
  });
};
export const deleteAttachment = (qId, docId) => {
  return serviceHandler({
    url: `${DeleteYardAttachment}?quoteId=${qId}&attachmentId=${docId}`,
    methordType: 'put'
  });
};

export const shortListYards = (projectId, payload) => {
  return serviceHandler({
    url: `${ShortListYardsUrl}?projectId=${projectId}`,
    methordType: 'put',
    payload
  });
};

export const isYardsSelected = (projectId) => {
  return serviceHandler({
    url: `${isYardsSelectedUrl}?projectId=${projectId}`,
    methordType: 'get'
  });
};
