import React from 'react';
import { Picky } from 'react-picky';

import ModalBox from '../../../../../../common/ModalBox';

const SideBarHeader = (props) => {
  const {
    checkList, compareData, generalData, modalBox, setModalBox, saveSelectedYards, subHeaderList,
    selectedYardList, setSelectedYardList
  } = props;

  return (
    <>
      {modalBox
      && (
        <ModalBox
          modalClassName="medium"
          heading="Select yard to compare quotes (Max 5)"
          buttonName="Submit"
          removeCancelBtn
          saveDisable={!(selectedYardList?.length >= 1 && selectedYardList?.length <= 5)}
          onSave={() => { saveSelectedYards(); }}
          closeModal={() => setModalBox(false)}
          className="selectyard-popup"
        >
          <div className="col-8">
            <label>
              Received Quotes List
              <span className="text-primaryRed-1">*</span>
            </label>
            <Picky
              className="mt-2 yards-dropdown"
              data-test="selectyards"
              value={selectedYardList}
              options={subHeaderList}
              onChange={(e) => { setSelectedYardList(e); }}
              valueKey="yardId"
              labelKey="yardName"
              multiple
              includeFilter
              dropdownHeight={600}
              clearFilterOnClose
            />
          </div>
        </ModalBox>
      )}
      {generalData && checkList?.length > 0 && (
        <div className="">
          <div className="d-flex">
            <div className="d-flex ms-4 mt-4 p-3 cl-head">
              <div className="font-semibold text-3xl font-navy w-full">Compare Quotation</div>
            </div>
            <div className="d-flex align-items-center mt-4 p-3 ms-auto me-4 cl-head">
              <button type="button" className="link-btn large ms-auto me-2" style={{ cursor: 'auto' }}>
                <span className="icon-Info brand-color font-18" />
              </button>
              <div className="font-blue mb-1">
                {compareData?.length > 0 ? compareData?.length : 0}
                {compareData?.length > 1 ? ' Quotes' : ' Quote'}
                {' '}
                Received
              </div>
            </div>
          </div>
          <div className="d-flex ms-4 p-3 text-md">
            <div className="font-medium">Project Currency : &nbsp;</div>
            <div className="font-dim">
              {generalData?.currency}
              {' '}
              (
              {generalData?.currencySymbol}
              )
            </div>
          </div>
          <div className="d-flex ms-4 p-3 text-md align-items-center">
            <div className="font-medium">Select Yards To Compare : &nbsp;</div>
            <div className="font-dim">
              <button
                type="button"
                className="me-4 primary-btn large"
                disabled={subHeaderList?.length === 1}
                data-test="btn-selectyards"
                onClick={() => {
                  setModalBox(true);
                }}
              >
                Select Yards
              </button>
            </div>
          </div>
          <div className="horizontal-line-main bg-gray-cq bR-2 mt-5c">
            <div className="font-bold ms-1" style={{ height: '39px' }} />
          </div>
        </div>
      )}
    </>
  );
};

export default SideBarHeader;
