/* eslint-disable */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { toNumber } from 'lodash';

import helper from '../../../../../../../utils/helper';
import arrowRight from './../images/arrow-right.svg';
import alertLeft from './../images/arrow-left.svg';

const QuotesHeader = (props) => {
  const { yardDetails, setCompareData, classToBeAdded, classToBeAddedd, index, generalData } = props;

  const { formatNumber } = helper;

  return (
    <>
      <div key={index}>
        <div className="border-b ">
          <div className="d-flex mt-2">
            <div
              className={
                yardDetails?.showFull
                  ? 'col-9 d-flex ms-2 text-lg font-bold p-3 capitalize timeline-text-wrap w-yardHeader-full'
                  : 'col-9 d-flex ms-2 text-lg font-bold p-3 capitalize timeline-text-wrap w-yardHeader'
              }
            >
              {classToBeAdded && (
                <div className={classToBeAddedd}><span>{classToBeAdded}</span></div>
              )}
              <span className="z-index-120" data-tooltip-id="yardName" data-tooltip-content={yardDetails?.yardName}>
                {classToBeAdded
                  ? <span className="badge-yardName w-full">{yardDetails?.yardName}</span>
                  : yardDetails?.yardName
                }
              </span>
              <Tooltip id="yardName" place="top" className="tooltip mt-3" />
            </div>
            <span
              className="ms-auto cursor-pointer p-3"
              id="click1"
              onClick={() => {
                /* istanbul ignore next */
                setCompareData(prevData =>
                  prevData.map((k, l) => ({
                    ...k,
                    showFull: index === l ? !k.showFull : false,
                    showCommentBox: index === l && k.showFull ? false : k.showCommentBox
                  }))
                );
              }}
            >
              {yardDetails?.showFull ? (
                <img className="icon-md-cq me-2 br-icon" src={alertLeft} alt="flag" />
              ) : (
                <img className="icon-md-cq me-2 br-icon" src={arrowRight} alt="flag" />
              )}
            </span>
          </div>
        </div>
        <div className="p-3 text-lg mt-6p">
          <div className="ms-2 d-flex">
            <div className="width-yard-headers">Revision</div>
            <div className="ms-2 font-dim">:&nbsp;{yardDetails?.version && yardDetails?.revision}</div>
          </div>
          <div className="ms-2 d-flex">
            <div className="width-yard-headers">TRD</div>
            <div className="ms-2 font-dim">:&nbsp;{yardDetails?.trd + ' Days'}</div>
          </div>
          <div className="ms-2 d-flex">
            <div className="width-yard-headers">Days In Dock</div>
            <div className="ms-2 font-dim">:&nbsp;{yardDetails?.inDockDays + ' Days'}</div>
          </div>
          <div className="ms-2 d-flex">
            <div className="width-yard-headers">Days Afloat</div>
            <div className="ms-2 font-dim">:&nbsp;{yardDetails?.daysAfloat + ' Days'}</div>
          </div>
          <div className="ms-2 d-flex">
            <div className="width-yard-headers">Payment Terms</div>
            <div
              className={yardDetails?.showFull ? 'ms-2 font-dim timeline-text-wrap w-yardcomment' : 'ms-2 font-dim timeline-text-wrap w-8'}
              data-tooltip-id="project-mapDatacontent"
              data-tooltip-content={yardDetails?.paymentTerms}
            >
              <span>:&nbsp;{yardDetails?.paymentTerms}</span>
            </div>
            <Tooltip id="project-mapDatacontent" place="top" className="tooltip mt-3" />
          </div>
          <div className="ms-2 d-flex">
            <div className="width-yard-headers">Yard Comments</div>
            <div className={yardDetails?.showFull ? 'ms-2 font-dim timeline-text-wrap w-yardcomment' : 'ms-2 font-dim timeline-text-wrap w-8'}>
              <span data-tooltip-id="project-mapData" data-tooltip-content={yardDetails?.comment}>
                :&nbsp;{yardDetails?.comment}
              </span>
            </div>
            <Tooltip id="project-mapData" place="top" className="tooltip mt-3" />
          </div>
          <div className="ms-2 d-flex">
            <div className="width-yard-headers">Deviation & Off Hire</div>
            <div className={yardDetails?.showFull ? 'ms-2 font-dim' : 'ms-2 font-dim timeline-text-wrap w-8'}>
              <span
                data-tooltip-id="project-mapData"
                data-tooltip-content={generalData?.currencySymbol + ' ' + formatNumber(toNumber(yardDetails?.deviationCost) + toNumber(yardDetails?.offhireCost))}
              >
                :&nbsp;{generalData?.currencySymbol + ' ' + formatNumber(toNumber(yardDetails?.deviationCost) + toNumber(yardDetails?.offhireCost))}
              </span>
            </div>
          </div>
        </div>
        <div className={yardDetails?.showFull ? 'table-header-cq header-full' : 'table-header-cq'}>
          {yardDetails?.showFull && (
            <div className="header-cell">
              <div className="center">Unit</div>
            </div>
          )}
          {yardDetails?.showFull && (
            <div className="header-cell">
              <div className="center">Qty</div>
            </div>
          )}
          {yardDetails?.showFull && (
            <div className="header-cell">
              <div>Unit</div>
              <div>Price</div>
            </div>
          )}
          {yardDetails?.showFull && (
            <div className="header-cell">
              <div className="center">Discount %</div>
            </div>
          )}
          <div className="header-cell w-10">
            <div>Yard</div>
            <div>Cost Estimate</div>
          </div>
          <div className="header-cell w-10">
            <div>Yard</div>
            <div>Estimate</div>
          </div>
          <div className="header-cell w-10">
            <div>Owner's</div>
            <div>Cost</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotesHeader;
