import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import _, { cloneDeep } from 'lodash';
import helper from '../../../../../utils/helper';
import ModalBox from '../../../../common/ModalBox';
import AddAttachments from './AddAttachments';
import showAlert from '../../../../../utils/alert';
import { getDocumentCategryList } from '../../../../Admin/Vessels/VesselDetails/Attachment/services';
import {
  s3UploadMutiple,
  saveProjectAttachments,
  deleteProjectAttachment
} from '../services';

const acceptedFiles = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/bmp',
  'image/tiff'
];
const AddPlans = (props) => {
  const { generateOptions, handleValidation, formValidation, MAX_FILE_SIZE } = helper;
  const { getAllAttachments, projectDetails } = props;
  const initialFormData = {
    documentCategory: '',
    projectId: '',
    phase: 1,
    projectAttachments: []
  };
  const [documentCategoryList, setDocumentCategoryList] = useState([]);
  const [addModal, setaddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  useEffect(() => {
    getDocumentCategryList(2)?.then((res) => {
      if (res.success) {
        setDocumentCategoryList(generateOptions(res?.data, 'id', 'name'));
      }
    });
  }, []);
  const ondrop = async (e) => {
    if (e.target.files.length < 6) {
      const name = formData.projectAttachments.map((items) => {
        return items.name;
      });
      const selectedFiles = [];
      let isDuplicate = false;
      Array.from(e.target.files).forEach((item) => {
        const fileSize = item.size / 1024 / 1024;
        if (!acceptedFiles.includes(item.type)) showAlert('Please upload a valid file type', 'error');
        else if (fileSize > MAX_FILE_SIZE) showAlert(`File size exceeds ${MAX_FILE_SIZE} MB`, 'error');
        else if (name.indexOf(item.name) < 0) {
          selectedFiles.push(item);
        } else {
          isDuplicate = true;
        }
      });
      if (isDuplicate) {
        showAlert('Duplicate Files Removed', 'error');
      }

      if (selectedFiles && selectedFiles.length) {
        const body = new FormData();
        selectedFiles.forEach((item) => {
          body.append('files', item);
        });
        const res = await s3UploadMutiple(body);
        if (res && res.data && res.data.length) {
          const attach = _.cloneDeep(formData);
          res.data.map((item) => {
            const newItem = {
              name: item.name,
              fileName: item.path,
              projectId: projectDetails.projectId,
              fileType: item.fileType,
              fileSize: item.size,
              documentCategory: formData.documentCategory,
              phase: 1
            };

            return attach.projectAttachments.push(newItem);
          });
          setFormData(attach);
          if (res.success) {
            showAlert('New File Uploaded', 'success');
          }
        }
      }
    } else {
      showAlert('You are only allowed to upload a maximum of 5 files at a time', 'error');
    }
    e.target.value = '';
  };

  const handleChange = (e, field, type) => {
    const fd = cloneDeep(formData);
    if (type === 'select') {
      fd[field] = e.value;
    } else {
      fd[field] = charValidation(e.target.value);
    }
    setFormData(fd);
    handleValidation(['name', 'documentCategory'], fd, field);
  };

  const openAddModal = () => {
    setFormData({
      projectId: projectDetails.id,
      documentCategory: '',
      phase: 1,
      projectAttachments: []
    });
    setaddModal(true);
  };

  const closeAddModal = () => {
    setaddModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllAttachments();
  }, []);

  const removeAttach = () => {
    const vesselsIds = removeFile?.id;
    deleteProjectAttachment({ id: vesselsIds })?.then((res) => {
      if (res.success) {
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
        getAllAttachments();
      }
    });
  };
  const saveApi = (data) => {
    saveProjectAttachments(data)?.then((res) => {
      // saveProjectsAttachment(data).then((res) => {
      if (res.success) {
        showAlert('Successfully Saved', 'success');

        setaddModal(false);
        getAllAttachments();
        setFormData(initialFormData);
      }
    });
  };
  const saveApiCall = () => {
    const inputObj = formData;
    inputObj.projectId = projectDetails.id;
    formData.projectAttachments.forEach((item, key) => {
      inputObj.projectAttachments[key].documentCategory = formData.documentCategory;
    });
    if (formData.projectAttachments.length > 0 && formValidation(['name', 'documentCategory'], inputObj)) {
      saveApi(inputObj);
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };
  return (
    <>
      <div className="d-flex mt-3 justify-content-end pb-4 ">
        <button
          type="button"
          id="add-attachment"
          disabled={false}
          className="secondary-btn  p-3 "
          onClick={() => {
            openAddModal();
          }}
        >
          Add vessel plans and drawings
        </button>
        {addModal && (
          <ModalBox
            modalClassName="medium"
            heading="Add Documents"
            onSave={saveApiCall}
            closeModal={() => {
              closeAddModal();
            }}
            buttonName="Save"
          >
            <AddAttachments
              handleChange={handleChange}
              formData={formData}
              ondrop={ondrop}
              setFormData={setFormData}
              removeAttach={removeAttach}
              documentCategoryList={documentCategoryList}
            />
          </ModalBox>
        )}
        {openDeleteModal && (
          <ModalBox
            modalClassName="medium"
            heading="Remove Attachment"
            onSave={() => {
              removeAttach();
            }}
            closeModal={() => {
              setOpenDeleteModal(false);
            }}
            buttonName="Delete"
          >
            <p>Are you sure to delete the attachment?</p>
          </ModalBox>
        )}
      </div>
    </>
  );
};
export default AddPlans;
