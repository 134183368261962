/* eslint-disable consistent-return */
import axios from 'axios';
import { serviceHandler } from '../../../../../utils/serviceHandler';
import apiEndPoints from '../../../../../utils/apiUrlConstants';
import helper from '../../../../../utils/helper';

const {
  GetAllYardList,
  GetAllProjectSpecList,
  GetSendRfqYardList,
  SaveProjectRfq,
  ProjectSpecDetails,
  RfqConfirmYardList,
  QuotesUpdate,
  QuoteStatusList,
  GetSupplementaryYardMailList,
  ProjSpecificationExportExcel,
  YardExportList,
  ExportExcelQuote,
  GetYardListForExportUrl,
  ExportExcelUrl,
  RemoveMultipleServiceLinesUrl,
  RetrieveServiceLineUrl,
  PreQuoteAddSLComment,
  ReceivedQuoteAddSLComment,
  CompareQuoteAddSLComment
} = apiEndPoints;
const { downloadFile, getExportedFileName } = helper;
export const getAllYardListExport = (payload) => {
  return serviceHandler({
    url: `${GetYardListForExportUrl}`,
    methordType: 'get',
    payload
  });
};

export const getAllYardList = (payload) => {
  return serviceHandler({
    url: `${GetAllYardList}`,
    methordType: 'get',
    payload
  });
};

export const getAllSpecList = (payload) => {
  return serviceHandler({
    url: `${GetAllProjectSpecList}?projectId=${payload.id}&searchString=${payload.type}`,
    methordType: 'get',
    payload
  });
};
export const saveProjectRfq = (payload) => {
  return serviceHandler({
    url: `${SaveProjectRfq}`,
    methordType: 'post',
    payload
  });
};
export const getSendRfqYardList = (payload) => {
  return serviceHandler({
    url: `${GetSendRfqYardList}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const projectSpecDetails = (payload) => {
  return serviceHandler({
    url: `${ProjectSpecDetails}?projectSpecId=${payload.id}`,
    methordType: 'get',
    payload
  });
};
export const rfqConfirmYardList = (payload) => {
  return serviceHandler({
    url: `${RfqConfirmYardList}`,
    methordType: 'post',
    payload
  });
};
export const getQuoteStatusList = (payload) => {
  return serviceHandler({
    url: `${QuoteStatusList}?projectId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const getSupplementaryYardMailList = (payload) => {
  return serviceHandler({
    url: `${GetSupplementaryYardMailList}?projectId=${payload.projectId}`,
    methordType: 'get',
    payload
  });
};
export const yardMailListConfirm = (payload) => {
  return serviceHandler({
    url: `${GetSupplementaryYardMailList}`,
    methordType: 'post',
    payload
  });
};
export const quotesUpdate = (payload) => {
  return serviceHandler({
    url: `${QuotesUpdate}`,
    methordType: 'post',
    payload
  });
};

export const exportYardExcel = (projectId, yardName, yardId) => {
  return axios
    .get(`${ProjSpecificationExportExcel}?projectId=${projectId}&yardId=${yardId}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const yardListExport = (payload) => {
  return serviceHandler({
    url: `${YardExportList}?projectId=${payload.id}`,
    methordType: 'get',
    payload
  });
};

export const removeMutipleServiceLines = (data) => {
  return serviceHandler({
    url: `${RemoveMultipleServiceLinesUrl}?specId=${data?.activeSpecId}`,
    methordType: 'put',
    payload: data.serviceLineList
  });
};

export const retrieveServiceLine = (data) => {
  return serviceHandler({
    url: `${RetrieveServiceLineUrl}?&specId=${data?.activeSpecId}`,
    methordType: 'put',
    payload: data.serviceLineList
  });
};

export const excelExportQuote = async (payload) => {
  try {
    const response = await axios.post(`${ExportExcelQuote}`, payload, { responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    downloadFile(url, getExportedFileName(response));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const exportYardQuote = (projectId, projectRfqId) => {
  return axios
    .get(`${ExportExcelUrl}?projectId=${projectId}&projectRfqId=${projectRfqId}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      downloadFile(url, getExportedFileName(response));
    })
    .catch((errors) => {
      Promise.reject(errors);
    });
};

export const savePreQuoteSLComments = (data) => {
  return serviceHandler({
    url: `${PreQuoteAddSLComment}`,
    methordType: 'post',
    payload: data
  });
};

export const saveReceivedQuoteSLComments = (data) => {
  return serviceHandler({
    url: `${ReceivedQuoteAddSLComment}`,
    methordType: 'post',
    payload: data
  });
};

export const saveCompareQuoteSLComments = (data) => {
  return serviceHandler({
    url: `${CompareQuoteAddSLComment}`,
    methordType: 'post',
    payload: data
  });
};
