/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import Pagination from '../../../common/pagination';
import SidePanel from '../../../common/sidePanel';
import Navbar from './navbar';
import WohaWrapper from './wohaWrapper';
import helper from '../../../../utils/helper';
import { WorkOrderDefectList, SpecificationbyProject, SaveSpec, TotalCount, importWohaData, saveWohaStatus, exportExcelWOHA } from './services';
import { getProjectDetailsTSI } from '../NewProject/services';
import showAlert from '../../../../utils/alert';
import UploadCard from './uploadCard';

let isSearchUpdated = false;
const WohaPage = ({ onCloseWindow, openModal, isTSI, onReload, isTsiDisabled, isShowImport }) => {
  const { id } = useParams();
  const params = qs.parse(window.location.search);
  const { getDropDownOptions, roles } = helper;
  const userRole = localStorage.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = (userRole === roles.VESSEL_USER);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [workOrder, setWorkOrder] = useState('');
  const [count, setCount] = useState({});
  const [loading, setLoading] = useState(false);
  const [dropDownOption, setDropDownOption] = useState([]);
  const [visibleSpec, setVisibleSpec] = useState(['0', '1']);
  const [visiblesubSpec, setVisiblesubSpec] = useState(['0', '1']);
  const [selectedCard, setSelectedCard] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [currentSpec, setCurrentSpec] = useState('');
  const [viewUpload, setViewUpload] = useState(false);
  const [file, setFile] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [projectBasicDetails, setProjectBasicDetails] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onReloadSpecData = () => {
    getProjectDetailsTSI({ id }).then((res) => {
      setProjectBasicDetails(res.data);
    });
  };

  useEffect(() => {
    onReloadSpecData();
  }, []);

  const showView = openModal === 'woha';
  const { formValidation } = helper;
  const changeVisibility = (key) => {
    const visibility = [...visibleSpec];
    if (visibility.indexOf(key) > -1) {
      visibility.splice(visibility.indexOf(key), 1);
    } else {
      visibility.push(key);
    }
    setVisibleSpec(visibility);
  };

  const checkType = () => {
    if (visibleSpec.length === 2) {
      return 0;
    } if (visibleSpec.length === 0) {
      return 3;
    } if (visibleSpec[0] === '0') {
      return 1;
    }
    return 2;
  };

  const changeVisibilitymap = (key) => {
    const visibility = [...visiblesubSpec];
    if (visibility.indexOf(key) > -1) {
      visibility.splice(visibility.indexOf(key), 1);
    } else {
      visibility.push(key);
    }
    setVisiblesubSpec(visibility);
  };

  const checkTypemap = () => {
    if (visiblesubSpec.length === 2) {
      return 0;
    } if (visiblesubSpec.length === 0) {
      return 3;
    } if (visiblesubSpec[0] === '0') {
      return 1;
    }
    return 2;
  };

  const dropDownSpecification = () => {
    SpecificationbyProject({ id, listWithoutNonSpec: true }).then((response) => {
      if (response?.success) {
        setDropDownOption(response?.data);
        setDropDownOption(getDropDownOptions(response?.data, 'id', 'specificationNo', 'title'));
      }
    });
  };

  const totalSpecCount = () => {
    TotalCount({ id }).then((response) => {
      if (response?.success) {
        setCount(response?.data);
      }
    });
  };

  const workorderDefect = (p = page) => {
    WorkOrderDefectList({
      page: p,
      pageSize,
      projectId: id,
      type: checkType(),
      mapType: checkTypemap(),
      searchString: isSearchUpdated ? searchText : ''
    }).then((response) => {
      if (response?.success) {
        setWorkOrder(response.data);
        setLoading(false);
      }
    });
  };

  const onSearch = (e) => {
    if (e.key === 'Enter') {
      isSearchUpdated = true;
      workorderDefect(1);
      setPage(1);
    }
  };

  const onSave = (cardId, specId) => {
    if (formValidation(['specId'], { specId })) {
      setIsSaved(true);
      SaveSpec({ id: cardId, projectSpecId: specId }).then((response) => {
        if (response?.success) {
          workorderDefect();
          showAlert('Successfully Saved', 'success');
          setIsSaved(false);
          setCurrentSpec('');
        }
      });
    } else {
      showAlert('Please enter the mandatory(*) fields', 'error');
    }
  };

  const onCancel = () => {
    setSelectedCard('');
    setCurrentSpec('');
  };

  const onSelected = (key) => {
    setSelectedCard(key);
  };

  useEffect(() => {
    saveWohaStatus({ projectId: id });
    setLoading(true);
  }, []);

  useEffect(() => {
    workorderDefect();
    dropDownSpecification();
    totalSpecCount();
  }, [visibleSpec, visiblesubSpec]);

  const showUpload = () => {
    setViewUpload(!viewUpload);
    setFile('');
  };

  const onUpload = () => {
    const body = new FormData();
    body.append('file', file);
    setIsUploading(true);
    importWohaData(body, id, params.vesselId).then((res) => {
      if (res.success) {
        workorderDefect();
        totalSpecCount();
        showAlert(res.data.message, res.data.importSuccess ? 'success' : 'error');
        setViewUpload(false);
        setFile('');
        onReload();
        setIsUploading(false);
      }
    });
  };

  useEffect(() => {
    if (searchText === '') {
      setPage(1);
      workorderDefect(1);
    }
  }, [searchText]);

  useEffect(() => {
    workorderDefect();
  }, [page, pageSize]);

  const downloadExcel = () => {
    const payload = {
      projectId: projectBasicDetails?.id,
      searchString: searchText,
      mapType: checkTypemap(),
      type: checkType()
    };
    exportExcelWOHA(payload);
  };

  const pageClick = (e) => {
    setPage(e.selected + 1);
  };

  const onSizeChange = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };

  return (
    <SidePanel
      changeVisibility={changeVisibility}
      visibleSpec={visibleSpec}
      fromdefects
      title={(
        <div className="d-flex align-items-center">
          <div className="">WOHA/Defects</div>
          <div className="couter-bubbles status-due ms-3 font-12">{count.totalCount && workOrder?.items?.length !== count.totalCount ? `${workOrder?.items?.length} / ${count.totalCount}` : count.totalCount}</div>
        </div>
      )}
      bgColor="bg-sky-white"
      onClose={onCloseWindow}
      size="large"
      openModal={openModal}
      showView={showView}
    >
      <div className="ps-3 pe-3" data-testid="woha-page">
        <Navbar
          onSearch={onSearch}
          setSearchText={setSearchText}
          searchText={searchText}
          visibleSpec={visibleSpec}
          setVisibleSpec={setVisibleSpec}
          changeVisibility={changeVisibility}
          showUpload={showUpload}
          isTsiDisabled={isTsiDisabled}
          isShowImport={isShowImport}
          visiblesubSpec={visiblesubSpec}
          setVisiblesubSpec={setVisiblesubSpec}
          changeVisibilitymap={changeVisibilitymap}
          isVesselUser={isVesselUser}
          projectBasicDetails={projectBasicDetails}
          loading={loading}
          downloadExcel={downloadExcel}
          workOrder={workOrder}
        />
        {viewUpload && (
          <UploadCard
            file={file}
            setFile={setFile}
            onUpload={onUpload}
            isUploading={isUploading}
          />
        )}
        {workOrder?.items && workOrder?.items?.length > 0
          ? (
            <div className="scroll-wrap-poha mt-4">
              <WohaWrapper
                dropDownOption={dropDownOption}
                loading={loading}
                selectedCard={selectedCard}
                onSelected={onSelected}
                onSave={onSave}
                isTsiDisabled={isTsiDisabled}
                onCancel={onCancel}
                setSelectedCard={setSelectedCard}
                workOrder={workOrder}
                isSaved={isSaved}
                setCurrentSpec={setCurrentSpec}
                currentSpec={currentSpec}
                isTSI={isTSI}
                projectBasicDetails={projectBasicDetails}
                workorderDefect={workorderDefect}
                openDeleteModal={openDeleteModal}
                setOpenDeleteModal={setOpenDeleteModal}
              />
            </div>
          ) : <div data-testid="card-wrapper" className="mt-3 p-4 d-flex justify-content-center"> No data found</div>}
      </div>
      <footer className="d-flex justify-content-start side-panel__footer">
        {workOrder?.totalItems > 10 && (
          <Pagination
            pageClick={pageClick}
            pageCount={workOrder?.totalPages}
            currentpage={page}
            onSizeChange={onSizeChange}
            size={pageSize}
            height="w-full"
          />
        )}
      </footer>
    </SidePanel>
  );
};
export default WohaPage;
