/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import SupplementaryJobs from './SupplementaryJobs/index';
import { getConfirmation } from '../../NewProject/services';
import ReceivedQuotes from './ReceivedQuotes';
import { receivedQuote } from './ReceivedQuotes/services';

const Tender = (props) => {
  const { id } = useParams();
  const { isTSI, userRole } = props;
  const { state } = useLocation();

  const [activeTab, setActiveTab] = useState(true);
  const [currency, setCurrency] = useState('');
  const [isTsiDisabled, setIsTsiDisabled] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [quoteLength, setQuoteLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getBasicDetails = () => {
    const payload = {
      id
    };
    getConfirmation(payload).then((res) => {
      if (res?.success) {
        setLoading(false);
        setProjectDetails(res?.data);
        setCurrency(res?.data?.currencySymbol);
        if (localStorage.getItem('USER_ID') === res?.data?.backupTsiId) {
          setIsTsiDisabled(true);
        }
      }
    });
  };
  const getQuotes = () => {
    receivedQuote(id).then((resp) => {
      if (resp?.success) {
        setLoading(false);
        setQuoteLength(resp?.data?.length);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    getBasicDetails();
    getQuotes();
    if (state?.from === 'Comparequotes') {
      setActiveTab(false);
    }
  }, []);

  return (
    <div data-testid="approval-wraper">
      <div className="card mt-4 mb-5 p-3">
        <div className="list-tab-head d-flex pt-2 ms-2 border-down pb-2">
          <div id="supplementary" className={`tab-items text-center px-2 ${activeTab ? 'active' : ''}`} onClick={() => { setActiveTab(true); }}>
            Supplementary Jobs
          </div>
          <div
            id="activeTab"
            className={`d-flex tab-items text-center px-2 ms-2 ${!activeTab ? 'active' : ''}`}
            onClick={() => {
              setActiveTab(false);
            }}
          >
            Received Quotes
            <div className="quote-bubble ms-2">{quoteLength || 0}</div>
          </div>
        </div>
        {activeTab ? (
          <SupplementaryJobs
            isTSI={isTSI}
            id={id}
            isTsiDisabled={isTsiDisabled}
            projectDetails={projectDetails}
            userRole={userRole}
          />
        ) : (
          <ReceivedQuotes
            isTSI={isTSI}
            id={id}
            currency={currency}
            isTsiDisabled={isTsiDisabled}
            quoteLength={quoteLength}
          />
        )}
        {!activeTab
          && (
            <div className="fixed-footer card d-flex justify-content-end">
              <div className="text-black font-12 col-6">
                <div className=" footer-tender">{`© ${new Date().getFullYear()} ThinkPalm`}</div>
              </div>
              <button
                type="button"
                className={quoteLength > 0 ? 'me-4 primary-btn large' : 'me-4 primary-btn large disabled'}
                onClick={() => {
                  if (quoteLength > 0) {
                    navigate(`/user/project/${id}/tender/compare-quotes?vesselId=${projectDetails?.vesselId}`);
                  }
                }}
              >
                {(!state?.redirect && projectDetails?.tenderApprovalStatus === 0) ? 'Compare Quote' : 'Continue Comparison'}
              </button>
            </div>
          )}
      </div>

    </div>
  );
};
export default Tender;
