/* eslint-disable */
import React from "react";
import { useNavigate } from 'react-router-dom';

import Comments from "../../../Comments";
import ModalBox from "../../../../../common/ModalBox";
import ExportExcelModal from "./ExportExcelModal";
const FooterApproval = (props) => {

  const {
    id, projectStatus, setApproveClick, setButtonText, setOpenedRemarks, reload, setMessage, fromFooter,
    onYardSelect, compareData, selectedExcelYard, ExportQuote, setSelectedExcelYard, showExportModal,
    setshowExportModal
  } = props;

  const navigate = useNavigate();

  return (
    <div className="fixed-footer card d-flex justify-content-end">
      <div className="text-black font-12 col-6">
        <div className="footer-specify">{`© ${new Date().getFullYear()} ThinkPalm`}</div>
      </div>
      {fromFooter ?
        <button
          type="button"
          className={projectStatus === 1 || projectStatus === 2 ? "secondary-btn large mr-foot" : "secondary-btn large mr-10  mr-foot"}
          data-test="navigationClick"
          onClick={() => {
            navigate(-1);
          }}
        >
          Close
        </button> :
        <>
          <div className="me-auto">
            <Comments id={id} appendClassName="comments-btn" />
          </div>
          <button
            type="button"
            className="secondary-btn large me-5"
            data-test="FooterClick2"
            onClick={() => {
              setshowExportModal(true);
            }}
          >
            Excel Download
          </button>
          <button
            type="button"
            className={projectStatus === 1 || projectStatus === 2 ? "secondary-btn large mr-10" : "secondary-btn large disabled me-5"}
            data-test="FooterClick"
            onClick={() => {
              if ((projectStatus === 1 || projectStatus === 2)) {
                setButtonText('revision');
                setMessage('revision');
                setOpenedRemarks(true);
                reload(1);
              }
            }}
          >
            Submit for revision
          </button>
          <button
            type="button"
            className={projectStatus === 1 || projectStatus === 2 ? "primary-btn large mr-10" : "primary-btn large disabled mr-10"}
            data-test="FooterClick1"
            onClick={() => {
              if ((projectStatus === 1 || projectStatus === 2)) {
                setButtonText('approve');
                setMessage('approve');
                setApproveClick(true);
                setOpenedRemarks(true);
                reload(1);
              }
            }}
          >
            Approve
          </button>
          {showExportModal && (
            <ModalBox
              heading="Select Quote to Export"
              closeModal={() => {
                setshowExportModal(false);
                setSelectedExcelYard([]);
              }}
              buttonName="Download"
              modalClassName="medium"
              onSave={() => ExportQuote()}
              saveDisable={selectedExcelYard.length === 0}
              className="export-excel"
            >
              <div className={`${compareData.length > 7 ? "scroll-wrap" : ''}`}>
                <ExportExcelModal compareData={compareData} onYardSelect={onYardSelect} selectedExcelYard={selectedExcelYard} />
              </div>
            </ModalBox>
          )}
        </>
      }
    </div>
  );
};
export default FooterApproval;
