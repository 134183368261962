/* eslint-disable */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { cloneDeep, round, toNumber } from 'lodash';

import helper from '../../../../../../../utils/helper';
import plusCircle from './../images/plus-blue.svg';

const CostSummaryNames = (props) => {
  const {
    yardDetails, index, compareData, setCompareData, isTSI, disableFields, repairStarted,
    projectDetails, setSelectModal, setAddYardComment, setYardComment, setCommentValue, setSidePanelData,
    setShowSideBarCost, recalculate, canSend, SaveComment, handleChange
  } = props;
  const { formatNumber } = helper;

  const isDisabled = !isTSI || disableFields || repairStarted;

  const getClass = (index) => {
    const bgColor = (index + 1) % 2 === 0 ? '' : 'cq-bg-even';
    return 'padding-blank ' + bgColor;
  };

  const handleChangeDiscount = (e, i, save, alertValue) => {
    const temp = cloneDeep(compareData);
    if (e.target && e.target.value) {
      const num = e.target.value.match(/^\d{0,3}(\.\d{0,2})?$/) ? e.target.value : temp[i].additionalDiscountPercentage;
      if (num <= 100) {
        temp[i].additionalDiscountPercentage = num.toString();
      }
    } else {
      temp[i].additionalDiscountPercentage = 0;
    }
    const data = {
      rfqId: temp[i].rfqId,
      comment: temp[i].selectionComment,
      isSelected: temp[i].isSelected,
      additionalDiscount: temp[i].additionalDiscount,
      additionalDiscountPercentage: e.target.value ? e.target.value : 0,
    };
    if (save) SaveComment(data, alertValue);
    setCompareData(temp);
    recalculate(temp);
  };

  return (
    <>
      <div>
        <div className="font-gray bg-gray-cq">
          <div className="ms-3 cost-details font-bold"></div>
        </div>
        <div className="horizontal-line-main font-gray bg-gray-cq">
          <div className="ms-3 cost-details font-semibold cl-head">{formatNumber(round(yardDetails?.ownerEstimate))}</div>
        </div>
        <div className="horizontal-line-main font-gray bg-gray-cq">
          <div className="ms-3 cost-details font-semibold cl-head">
            {isTSI && yardDetails?.showFull ? (
              <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(yardDetails?.additionalDiscountPercentage)}>
                <input
                  type="text"
                  data-test="additionalDiscountPercentage"
                  className={
                    yardDetails?.showFull
                      ? 'cursor-pointer text-box small compare-quote-input inp-lar'
                      : 'cursor-not-allowed text-box small compare-quote-input inp-lar'
                  }
                  disabled={isDisabled}
                  value={yardDetails?.additionalDiscountPercentage ? yardDetails?.additionalDiscountPercentage : isDisabled ? formatNumber(0) : ''}
                  onClick={() => (yardDetails.oldAdditionalDiscountPercentage = yardDetails?.additionalDiscountPercentage)}
                  onChange={(e) => {
                    handleChangeDiscount(e, index, false, 'save');
                  }}
                  onBlur={(e) => {
                    if (toNumber(yardDetails.oldAdditionalDiscountPercentage) !== toNumber(e.target.value)) {
                      handleChangeDiscount(e, index, true, 'save');
                    }
                  }}
                />
              </span>
            ) : (
              <div>{formatNumber(round(yardDetails?.additionalDiscountPercentage))}</div>
            )}
          </div>
        </div>
        <div className="horizontal-line-main font-gray bg-gray-cq">
          <div className="ms-3 cost-details font-semibold cl-head">
            {isTSI && yardDetails?.showFull ? (
              <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(round(yardDetails?.additionalDiscount))}>
                <input
                  type="text"
                  data-test="additionalDiscount"
                  className={
                    yardDetails?.showFull
                      ? 'cursor-pointer text-box small compare-quote-input inp-lar'
                      : 'cursor-not-allowed text-box small compare-quote-input inp-lar'
                  }
                  disabled={isDisabled}
                  value={yardDetails?.additionalDiscount ? yardDetails?.additionalDiscount : isDisabled ? formatNumber(0) : ''}
                  onClick={() => (yardDetails.oldAdditionalDiscount = yardDetails?.additionalDiscount)}
                  onChange={(e) => {
                    handleChange(e, index, yardDetails.rfqId, yardDetails.isSelected, false, 'additionalDiscount', 'save');
                  }}
                  onBlur={(e) => {
                    if (toNumber(yardDetails.oldAdditionalDiscount) !== toNumber(e.target.value)) {
                      handleChange(e, index, yardDetails.rfqId, yardDetails.isSelected, true, 'additionalDiscount', 'save');
                    }
                  }}
                />
              </span>
            ) : (
              <div>{formatNumber(round(yardDetails?.additionalDiscount))}</div>
            )}
          </div>
        </div>
        <div className="horizontal-line-main font-gray bg-gray-cq">
          <div className="ms-3 cost-details font-semibold cl-head">
            {formatNumber(
              round(yardDetails.ownerEstimate)
              - round((yardDetails.ownerEstimate / 100) * toNumber(yardDetails.additionalDiscountPercentage))
              - round(yardDetails?.additionalDiscount)
            )}
          </div>
        </div>
      </div>
      <div className="horizontal-line-main">
        <div className={getClass(index)}></div>
      </div>
      <div className="horizontal-line-main font-gray bg-gray-cq">
        <div className="ms-3 cost-details font-semibold cl-head">{formatNumber(round(yardDetails?.ownerCost))}</div>
      </div>
      <div className="horizontal-line-main">
        <div className={getClass(index)}></div>
      </div>
      <div className="horizontal-line-main font-gray bg-gray-cq">
        <div className="ms-3 cost-details font-semibold cl-head">
          <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(round(yardDetails?.deviationCost))}>
            <input
              type="text"
              data-test="cost1"
              className={
                yardDetails?.showFull
                  ? 'cursor-pointer text-box small compare-quote-input inp-lar'
                  : 'cursor-not-allowed text-box small compare-quote-input inp-lar'
              }
              disabled={isDisabled}
              value={formatNumber(round(yardDetails?.deviationCost))}
              onClick={() => {
                const temp = {
                  rfqId: yardDetails.rfqId,
                  repairDays: toNumber(yardDetails?.trd),
                  deviationDays: yardDetails?.deviationDays,
                  hireRate: yardDetails?.hireRate,
                  fuelPriceType1: yardDetails?.fuelPriceType1,
                  fuelType1Consumption: yardDetails?.fuelType1Consumption,
                  fuelPriceType2: yardDetails?.fuelPriceType2,
                  fuelType2Consumption: yardDetails?.fuelType2Consumption,
                  miscCost: yardDetails?.miscCost,
                  offHireCost: yardDetails?.offhireCost,
                  fuelCost: yardDetails?.fuelCost,
                  deviationCost: yardDetails?.deviationCost,
                  daysAfloat: yardDetails?.daysAfloat,
                  inDockDays: yardDetails?.inDockDays,
                  fuelType1Id: yardDetails?.fuelType1Id,
                  fuelType2Id: yardDetails?.fuelType2Id
                };
                setSidePanelData(temp);
                setShowSideBarCost(true);
              }}
            />
          </span>
        </div>
      </div>
      <div className="horizontal-line-main font-gray bg-gray-cq">
        <div className="ms-3 cost-details font-semibold cl-head">
          <span data-tooltip-id="project-specNamed" data-tooltip-content={formatNumber(round(yardDetails?.offhireCost))}>
            <input
              type="text"
              data-test="cost2"
              className={
                yardDetails?.showFull
                  ? 'cursor-pointer text-box small compare-quote-input inp-lar'
                  : 'cursor-not-allowed text-box small compare-quote-input inp-lar'
              }
              disabled={isDisabled}
              value={formatNumber(round(yardDetails?.offhireCost))}
              onClick={() => {
                const temp = {
                  rfqId: yardDetails.rfqId,
                  repairDays: toNumber(yardDetails?.trd),
                  deviationDays: yardDetails?.deviationDays,
                  hireRate: yardDetails?.hireRate,
                  fuelPriceType1: yardDetails?.fuelPriceType1,
                  fuelType1Consumption: yardDetails?.fuelType1Consumption,
                  fuelPriceType2: yardDetails?.fuelPriceType2,
                  fuelType2Consumption: yardDetails?.fuelType2Consumption,
                  miscCost: yardDetails?.miscCost,
                  offHireCost: yardDetails?.offhireCost,
                  fuelCost: yardDetails?.fuelCost,
                  deviationCost: yardDetails?.deviationCost,
                  daysAfloat: yardDetails?.daysAfloat,
                  inDockDays: yardDetails?.inDockDays,
                  fuelType1Id: yardDetails?.fuelType1Id,
                  fuelType2Id: yardDetails?.fuelType2Id
                };
                setSidePanelData(temp);
                setShowSideBarCost(true);
              }}
            />
          </span>
        </div>
      </div>
      <div className="horizontal-line-main font-gray bg-gray-cq">
        <div className="ms-3 cost-details font-semibold cl-head">{formatNumber(round(yardDetails?.deviationCost) + round(yardDetails?.offhireCost))}</div>
      </div>
      <div className="horizontal-line-main">
        <div className={getClass(index)}></div>
      </div>
      <div className="horizontal-line-main font-gray bg-gray-cq text-xl">
        <div className="ms-3 cost-details font-bold cl-head">
          {formatNumber(
            round(yardDetails.ownerEstimate)
            - round((yardDetails.ownerEstimate / 100) * toNumber(yardDetails.additionalDiscountPercentage))
            - round(yardDetails?.additionalDiscount)
            + round(yardDetails?.ownerCost)
          )}
        </div>
      </div>
      <div className="horizontal-line-main border-b-sec font-gray bg-gray-cq text-xl">
        <div className="ms-3 cost-details font-bold cl-head">
          {formatNumber(
            round(yardDetails.ownerEstimate)
            - round((yardDetails.ownerEstimate / 100) * toNumber(yardDetails.additionalDiscountPercentage))
            - round(yardDetails?.additionalDiscount)
            + round(yardDetails?.ownerCost)
            + round(yardDetails?.deviationCost)
            + round(yardDetails?.offhireCost)
          )}
        </div>
      </div>
      <div
        data-tooltip-id="project-specNamed"
        data-tooltip-content={yardDetails?.showFull ? '' : 'Expand to add/view comment'}
        className={yardDetails?.showFull ? 'd-flex align-items-center ms-3 p-2 mt-1 cursor-pointer' : 'd-flex align-items-center ms-3 p-2 mt-1'}
        data-test="commentClick"
        onClick={() => {
          if (yardDetails?.showFull) {
            setAddYardComment(true);
            setYardComment(yardDetails.selectionComment);
            const temp = {
              comment: yardDetails.selectionComment,
              index,
              rfqId: yardDetails.rfqId,
              isSelected: yardDetails.isSelected
            };
            setCommentValue(temp);
          }
        }}
      >
        <img className="icon-sm ms-2" src={plusCircle} alt="flag" data-test="click2" />
        <div
          className="font-darkblue font-semibold ms-2 me-4 ms-2 font-13"
          data-tooltip-id="project-specNamed"
          data-tooltip-content={yardDetails?.showFull ? '' : 'Expand to add/view comment'}
        >
          {(!yardDetails?.selectionComment && !isDisabled) ? 'Add Comment' : 'View Comment'}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3 mb-2">
        <Tooltip id="project-specNamed" place="top" className="tooltip mt-3" />
        <button
          className={
            isTSI && !repairStarted && (!disableFields || projectDetails?.tenderApprovalStatus === 2)
              ? yardDetails?.isSelected
                ? 'secondary-btn large btn-success'
                : canSend
                  ? 'secondary-btn disabled large'
                  : 'secondary-btn large'
              : yardDetails?.isSelected
                ? 'secondary-btn large disabled btn-success'
                : 'secondary-btn large disabled'
          }
          data-test="buttonclick2"
          onClick={() => {
            if (isTSI && !repairStarted && (yardDetails?.isSelected || !canSend) && (!disableFields || projectDetails?.tenderApprovalStatus === 2)) {
              setSelectModal({ status: true, index, data: yardDetails });
            }
          }}
        >
          {yardDetails?.isSelected ? 'Deselect' : 'Select Yard'}
        </button>
      </div>
    </>
  );
};

export default CostSummaryNames;
