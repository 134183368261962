/* eslint-disable */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

const CostSummaryAmount = (props) => {
  const { checkList, generalData } = props;

  return (
    <>
      {generalData && checkList?.length > 0 && (
        <div className="text-uppercase" style={{ marginTop: "1px" }}>
          <div className="font-gray bg-gray-cq">
            <div className="ms-3 cost-div font-bold">COST SUMMARY</div>
          </div>
          <div className="horizontal-line-main font-gray bg-gray-cq">
            <div className="d-flex">
              <div className="ms-3 cost-div font-semibold cl-head">Yard Cost Estimate Without Discount</div>
              <span
                className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                data-tooltip-id="project-Discount"
                data-tooltip-content="Sum of Yard Estimate Column"
              />
              <Tooltip id="project-Discount" place="top" className="tooltip mt-4 " />
            </div>
          </div>
          <div className="horizontal-line-main font-gray bg-gray-cq">
            <div className="ms-3 cost-div font-semibold cl-head">Overall Discount (%)</div>
          </div>
          <div className="horizontal-line-main font-gray bg-gray-cq">
            <div className="ms-3 cost-div font-semibold cl-head">Additional Discount</div>
          </div>
          <div className="horizontal-line-main font-gray bg-gray-cq">
            <div className="d-flex">
              <div className="ms-3 cost-div font-semibold cl-head">Yard Final Bill Estimate</div>
              <span
                className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                data-tooltip-id="project-Discount"
                data-tooltip-content="Yard Cost Estimate Without Discount - Overall Discount(%) - Additional Discount"
              />
            </div>
          </div>
          <div className="horizontal-line-main">
            <div className="padding-blank"></div>
          </div>
          <div className="horizontal-line-main font-gray bg-gray-cq">
            <div className="d-flex">
              <div className="ms-3 cost-div font-semibold cl-head">Owner's Cost Estimate</div>
              <span
                className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                data-tooltip-id="project-Discount"
                data-tooltip-content="Sum of Owner's Cost Column"
              />
            </div>
          </div>
          <div className="horizontal-line-main">
            <div className="padding-blank"></div>
          </div>
          <div className="horizontal-line-main font-gray bg-gray-cq">
            <div className="ms-3 cost-div font-semibold cl-head">Deviation Cost</div>
          </div>
          <div className="horizontal-line-main font-gray bg-gray-cq">
            <div className="ms-3 cost-div font-semibold cl-head">Off Hire</div>
          </div>
          <div className="horizontal-line-main font-gray bg-gray-cq">
            <div className="d-flex">
              <div className="ms-3 cost-div font-semibold cl-head">Deviation & Off Hire Estimate</div>
              <span
                className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                data-tooltip-id="project-Discount"
                data-tooltip-content="Deviation Cost + Off Hire"
              />
            </div>
          </div>
          <div className="horizontal-line-main">
            <div className="padding-blank"></div>
          </div>
          <div className="horizontal-line-main font-gray bg-gray-cq text-xl">
            <div className="d-flex">
              <div className="ms-3 cost-div font-bold cl-head">Project Cost Estimate(W/O Deviation & Off Hire)</div>
              <span
                className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                data-tooltip-id="project-Discount"
                data-tooltip-content="Yard Final Bill Estimate + Owner's Cost Estimate"
              />
            </div>
          </div>
          <div className="horizontal-line-main border-b-sec font-gray bg-gray-cq text-xl">
            <div className="d-flex">
              <div className="ms-3 cost-div font-bold cl-head">Project Cost Estimate(W Deviation & Off Hire)</div>
              <span
                className="brand-color icon-Info ms-1 font-14 d-flex align-items-center me-2"
                data-tooltip-id="project-Discount"
                data-tooltip-content="Yard Final Bill Estimate + Owner's Cost Estimate + Deviation & Off Hire Estimate"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CostSummaryAmount;
