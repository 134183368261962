/* istanbul ignore file */
import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import ModalBox from '../../../common/ModalBox';
import helper from '../../../../utils/helper';

const RoleSelector = (props) => {
  const { isViewMode, setIsViewMode, user, role } = props;
  const { logOut } = helper;

  const changeUser = (item) => {
    localStorage.setItem('USER_ROLE', item.role);
    localStorage.setItem('USER_ROLE_NAME', item.roleName);
    localStorage.setItem('USER_TOKEN', item.token);
    window.location.reload();
    setIsViewMode(false);
    localStorage.setItem('isFirst', 'false');
  };
  if (isViewMode && role?.length > 1) {
    return (
      <ModalBox
        modalClassName="medium dashboard-modal"
        heading={(
          <div>
            <div>{`Welcome ${user?.firstName} ${user?.middleName} ${user?.lastName} !!`}</div>
            <div className="font-12 opacity-8">Click to change the user role</div>
          </div>
        )}
        id="ModalBox"
        onSave={() => {
          logOut();
        }}
        closeModal={() => {
          setIsViewMode(false);
          localStorage.setItem('isFirst', 'false');
        }}
        buttonName="Log Out"
      >
        {role?.map((item, index) => {
          return (
            <div
              className={index === 0
                ? 'd-flex justify-content-between mb-3 p-3 border md-radius pointer role-item'
                : 'd-flex justify-content-between mb-3 p-3 border md-radius pointer role-item'}
              onClick={() => { changeUser(item); }}
            >
              <div className="d-flex align-items-center">
                <div className="black">
                  <div className="mngmnt-name">
                    <span
                      data-tooltip-id="project-dashboard"
                      data-tooltip-content={item.management}
                    />
                    {_.truncate(item.management, { length: 50 })}

                  </div>
                  <div className="opacity-6">
                    <span
                      data-tooltip-id="project-dashboard"
                      data-tooltip-content={item.roleName}
                    >
                      {_.truncate(item.roleName, { length: 50 })}
                      {' '}
                    </span>

                  </div>
                </div>
              </div>
              <div className="">
                <div className={item.approvalCount ? 'w-230 d-flex align-items-center' : 'w-230 d-flex align-items-center opacity-0'}>
                  <span className="icon-disc high font-12 me-1"> </span>
                  <div>
                    {item.approvalCount}
                    {' '}
                    Approval(s) pending
                  </div>
                </div>
                <div className="absolute dir-ico font-22"><span className="icon-arrow-right2" /></div>
              </div>
              <Tooltip id="project-dashboard" place="top" className="tooltip mt-3" />
            </div>
          );
        })}
      </ModalBox>
    );
  }
  return null;
};
export default RoleSelector;
