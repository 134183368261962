/* istanbul ignore file */
/*eslint-disable*/
import React, { useState } from 'react';
import SidePanel from '../../../../common/sidePanel';
import { SendMail } from '../services';
import { useParams } from 'react-router-dom';
import InputType1 from '../../../../common/InputType1';
import showAlert from '../../../../../utils/alert';
import Loader from '../../../../common/loader';
import { ReactMultiEmail } from 'react-multi-email';
import helper from '../../../../../utils/helper';

const ShareReport = ({ shareReport, setShareReport, data, loading, setReport, isDaily }) => {
  const { id } = useParams();
  const {  ValidateEmail } = helper;

  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);
  const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };
 /* istanbul ignore next */
  const mailSend = () => {
    if (!emails.length || !emails[0]) {
      showAlert('Please provide an email address.', 'error');
      return;
    }
    if (!emails.every(isValidEmail)) {
      showAlert('Please enter a valid email format', 'error');
      return;
    }

    if (isDaily) {
      showAlert('Successfully sent daily report', 'success');
    } else {
      showAlert('Successfully sent photo report', 'success');
    }

    const payload = {
      category: data.category,
      email: emails,
      mailbody: data.mailHeader + '<br>' + data.reportLink + '<br><br>' + data.mailFooter,
      subject: data.subject,
    };
    SendMail(payload)
      .then((res) => {
        if (res.success) {
        } else {
          showAlert('Error sending message', 'error');
        }
      })
      .catch((error) => {
        showAlert('Error sending message', 'error');
      });
  };

  const handleBackToReports = () => {
    setShareReport(false);
    setReport(true);
  };
  const checkEmail = (e) => {
    if (ValidateEmail(e)) {
      return true;
    }
    showAlert('Invalid email Id will be discarded', 'error');
    return false;
  };
  return (
    <div>
      <div data-testid="share-report" className="row">
        <SidePanel
          title="Share report"
          showView={shareReport}
          disabled
          size="medium"
          bgColor="#fff"
          onClose={() => {
            setShareReport(false);
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <div className="side-panel__container bg-white specify-side">
              <div className="side-panel__content">
                <div className="mb-3 ">
                <div className="head-14 d-flex pb-3">
                    <span className='mt-2'>To</span>
                    <span className="ms-1 mt-2">:</span>
                    <div className="share-multi-email share-multi">
                      <ReactMultiEmail
                        placeholder="Enter email here..."
                        className=""
                        emails={emails}
                        validateEmail={(e) => checkEmail(e)}
                        onChange={(_emails) => {
                          setEmails(_emails);
                        }}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              <div data-tag-item>{email}</div>
                              <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="head-12 mt-2">
                    <ul className="subject-margin">
                      Subject : <span className="custom-underline">{data?.subject}</span>
                    </ul>
                  </div>
                  <div className="card mt-3 py-5 px-5 card-share bg-sky-white">
                    <div className="head-12 mail-foot" dangerouslySetInnerHTML={{ __html: data?.mailHeader }}></div>
                    <div className="head-12 report-link break-all brand-color mt-2">
                      <a href={data?.reportLink}>{data?.reportLink}</a>
                    </div>
                    <div className="head-12 mt-4 mail-foot" dangerouslySetInnerHTML={{ __html: data?.mailFooter }}></div>
                  </div>
                </div>
                <footer className="side-panel__footer side-wid" data-testid="EmailPreview">
                  <button type="button" className="secondary-btn me-4 large" onClick={handleBackToReports}>
                    Discard
                  </button>
                  <button
                    type="button"
                    className="primary-btn me-4 large"
                    onClick={() => {
                      mailSend();
                      if (emails.length && emails.every(isValidEmail)) {
                        setShareReport(false);
                        setReport(true);
                      }
                    }}
                  >
                    Send
                  </button>
                </footer>
              </div>
            </div>
          )}
        </SidePanel>
      </div>
    </div>
  );
};

export default ShareReport;
