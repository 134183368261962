/* istanbul ignore file */
import { cloneDeep } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import showAlert from '../../../../../../../utils/alert';
import { getProjectDescription, saveProjectDescription } from './services';
import Loader from '../../../../../../common/loader';

const index = (props) => {
  const [formData, setFormData] = useState({ bodyHtml: '' });
  const [loading, setLoading] = useState(false);
  const [cancelData, setCancelData] = useState('');
  const { projectSpecificationId, fromSpecify, projInfo, isTsiDisabled, isTSI, fromSupplementary,
    fromMainSpecify, reloadAfterGTMT, fromSpecLibrary } = props;
  const [readonly, setReadonly] = useState(false);
  const buttons = [
    // 'font',
    'undo',
    'redo',
    'fontsize',
    'bold',
    'italic',
    'underline',
    'strikethrough',
    'ul',
    'ol',
    'indent',
    'video',
    'align',
    'brush',
    'image',
    'table'
  ];
  const config = useMemo(
    () => ({
      readonly,
      toolbarAdaptive: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: 'insert_as_html',
      tabIndex: -1,
      buttons,
      uploader: {
        insertImageAsBase64URI: true,
        isMultiple: true
      },
      spellcheck: true
    }),
    [readonly]
  );
  const [content, setContent] = useState('');
  const getDescription = () => {
    getProjectDescription(
      projectSpecificationId
    ).then((res) => {
      if (res.success) {
        setLoading(false);
        setCancelData(res?.data?.description);
        setFormData(res?.data);
      }
    });
  };
  const cancel = () => {
    setLoading(true);
    const fd = cloneDeep(formData);
    fd.description = cancelData;
    setContent(cancelData);
    setFormData(fd);
    setTimeout(() => {
      setLoading(false);
    }, 20);
  };
  const saveDescription = () => {
    const payload = {
      projectSpecificationId,
      description: content
    };
    saveProjectDescription(payload).then((res) => {
      setLoading(false);
      if (res.success) {
        showAlert('Description Updated Successfully', 'success');
        setCancelData('');
        setFormData({});
        getDescription();
        reloadAfterGTMT('', projectSpecificationId, false, true);
      }
    });
  };

  useEffect(() => {
    if (projectSpecificationId) {
      setLoading(true);
      getDescription();
    }
  }, [projectSpecificationId]);
  useEffect(() => {
    setLoading(true);
    if (fromSpecify) {
      document.body.classList.add('model-open');
    } else {
      document.body.classList.remove('model-open');
    }
  }, []);
  useEffect(() => {
    setReadonly((window.location.pathname.includes('/specify') && projInfo?.phase > 2) || fromSpecLibrary
    || isTsiDisabled || !isTSI || fromSupplementary || projInfo?.isClosed);
  }, [fromSpecLibrary, isTsiDisabled, isTSI, fromSupplementary, projInfo]);

  return (
    <div data-testid="description-main" className={fromMainSpecify ? 'description-main jodit-wrapper-specify' : 'description-main jodit-wrapper'}>
      {!loading ? (
        <>
          <div className="d-flex my-2 justify-content-end" />
          <div className="d-flex justify-content-end mt-2 " />

          <div className=" jodit-bg">
            <div className={readonly ? 'editor-wrap' : ''}>
              <div className="jj">
                {loading ? (
                  <Loader />
                ) : (
                  <JoditEditor
                    value={formData?.description}
                    config={config}
                    onChange={(newContent) => {
                      if (newContent) setContent(newContent);
                      else setContent('');
                    }}
                    onBlur={() => {
                      if (cancelData !== content) {
                        saveDescription();
                        cancel();
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default index;
