import React from 'react';
import { truncate } from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ModalBox from '../../../../common/ModalBox';

const ServiceLineModal = (props) => {
  const { setServiceLineShowModalGT, setContinueBtn,
    emptySpecList, navigateFn, handleSearchAfter,
    emptyMajorSpecList, SpecificationTypeNumber } = props;
  const handleContinue = () => {
    const array = SpecificationTypeNumber === 1 ? emptySpecList : emptyMajorSpecList;
    if (array?.length > 0) {
      setServiceLineShowModalGT(false);
    } else {
      setContinueBtn(true);
      navigateFn();
    }
  };
  const goToSpec = (item, itemName) => {
    setServiceLineShowModalGT(false);
    const list = [];
    if (itemName) list.push(itemName);
    if (item) list.push(item);
    handleSearchAfter(list);
  };
  const array = SpecificationTypeNumber === 1 ? emptySpecList : emptyMajorSpecList;
  console.log(array, 'array');
  return (
    <div data-testid="ServiceLine">
      {array?.length > 0 && (
      <>
        <div className="">
          <ModalBox
            modalClassName="medium modal-scroll"
            className="secondary-btn"
            heading="Map to Vessel Category"
            buttonName="Ok"
            onSave={handleContinue}
            closeModal={() => setServiceLineShowModalGT(false)}
            removeCancelBtn
            serviceLineMandatoryText
            text={SpecificationTypeNumber === 1 ? 'mapped to Vessel Category' : 'Republish'}
          >
            {array?.map((items) => {
              return (
                <div className="mt-3">
                  <div
                    id="click1"
                    onClick={() => goToSpec(items?.specificationNo, items?.parentSpecificationNo)}
                    className="mt-3 py-2 d-flex align-items-center bg-sky-white md-radius cursor-pointer"
                  >
                    <div className="ps-4 w-100">
                      {items?.specificationNo}
                    </div>
                    <div className="ms-3">
                      <span data-tooltip-id="view-major" data-tooltip-content={items?.title}>
                        {truncate(items?.title, {
                          length: 60,
                          separator: ' '
                        })}
                      </span>

                    </div>
                  </div>
                </div>
              );
            })}

          </ModalBox>
        </div>
      </>
      )}
      <Tooltip id="view-major" place="top" className="tooltip mt-3" />
    </div>
  );
};
export default ServiceLineModal;
