import React, { useState, useEffect } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import _ from 'lodash';
import { fileImages } from '../../../../common/mappingConst';
import helper from '../../../../../utils/helper';
import showAlert from '../../../../../utils/alert';
import AddPlans from '../../NewProject/Attachments/AddPlans';
import ModalBox from '../../../../common/ModalBox';

const Attachments = (props) => {
  const { formatBytes } = helper;
  const {
    allFiles,
    download,
    isDownloading,
    getAllAttachments,
    projectDetails,
    deleteProjectAttachment,
    getDetails
  } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [removeFile, setRemoveFile] = useState(allFiles);
  const deleteModal = (data) => {
    setOpenDeleteModal(true);
    setRemoveFile(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllAttachments();
    if (getDetails) {
      getDetails();
    }
  }, []);

  const removeAttach = () => {
    const vesselsIds = removeFile?.id;
    deleteProjectAttachment({ id: vesselsIds })?.then((res) => {
      if (res.success) {
        setOpenDeleteModal(false);
        showAlert('Successfully Deleted', 'success');
        getAllAttachments();
      }
    });
  };
  return (
    <div className="table-responsive mb-5" data-testid="add-attachs">
      <table className="card-table">
        <thead>
          <tr>
            <th>
              <span className="head-14">
                Vessel Plans and Drawings
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {allFiles?.map((item) => {
            return (
              <>
                <tr>
                  <td>
                    <div className="">
                      <div className="d-flex m-4 justify-content-between">
                        <span className="head-14 mb-1 text-ellipsis" data-tooltip-id="project-utility" data-tooltip-content={item?.documentCategoryName}>
                          {_.truncate(item?.documentCategoryName, {
                            length: 50,
                            separator: ' '
                          })}
                        </span>
                        {projectDetails.isClosed && (
                          <div>
                            <button
                              type="button"
                              disabled={isDownloading}
                              className="link-btn ml-8"
                              onClick={() => {
                                download(item?.documentCategory);
                              }}
                            >
                              <span className="icon-download me-3" />
                              Download All
                            </button>

                          </div>
                        )}
                      </div>
                      <div className=" my-3">
                        {item?.projectAttachments?.map((doc) => {
                          return (
                            <div className="ms-4 pt-3 pb-3 pe-3">
                              <div className="d-flex justify-content-between full-width align-items-center">
                                <div className="d-flex col-gap-1">
                                  <div className="thmb-icon-md">
                                    <span className={fileImages[_.toLower(doc.fileType)]} />
                                  </div>
                                  <div className="d-flex flex-column justify-content-center">
                                    <a
                                      href={doc.physicalFileName}
                                      target="_blank"
                                      download="downloaded_file"
                                      className=""
                                      rel="noreferrer"
                                    >
                                      <span className="font-semibold mb-1 text-ellipsis" data-tooltip-id="project-utility" data-tooltip-content={doc.name}>
                                        {_.truncate(doc.name, {
                                          length: 50,
                                          separator: ' '
                                        })}
                                      </span>
                                    </a>
                                    <div className="font-14">
                                      <label className="me-2">Format :</label>
                                      <span className="value">{doc.fileType}</span>
                                      <span className="mx-4">|</span>
                                      <label className="me-2"> Size :</label>
                                      <span className="value">{formatBytes(doc.fileSize)}</span>
                                    </div>
                                  </div>
                                </div>
                                {!projectDetails.isClosed && (
                                  <div className="d-flex col-gap-2">
                                    <button className="link-btn" type="button">
                                      <a
                                        href={doc.physicalFileName}
                                        target="_blank"
                                        download="downloaded_file"
                                        className=""
                                        rel="noreferrer"
                                      >
                                        <span className="icon-download font-20" />
                                      </a>
                                    </button>
                                    <button
                                      id="delete"
                                      className="link-btn"
                                      type="button"
                                      onClick={() => { deleteModal(doc); }}
                                    >
                                      <span className="icon-delete font-20" />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </td>
                </tr>

              </>
            );
          })}
        </tbody>
      </table>
      {openDeleteModal && (
        <ModalBox
          modalClassName="medium"
          heading="Remove Attachment"
          // onSave={() => { removeAttach(removeFile); }}
          onSave={() => { removeAttach(); }}
          closeModal={() => {
            setOpenDeleteModal(false);
          }}
          buttonName="Delete"
        >
          <p>Are you sure to delete the attachment?</p>
        </ModalBox>
      )}
      {!projectDetails.isClosed && (
        <AddPlans getAllAttachments={getAllAttachments} projectDetails={projectDetails} />
      )}
      <Tooltip id="project-utility" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default Attachments;
