/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useParams } from 'react-router-dom';
import { downloadVarianceReport, getVarianceReport } from '../services';
import DeviationChart from './DeviationChart';
import RepairPeriodChart from './RepairPeriodChart';
import VarianceReportComponent from './VarianceReportComponent';
import VariaianceNonSpec from './VariaianceNonSpec';

const VarianceReport = () => {
  const [projectBasicDetails, setProjectBasicDetails] = useState();
  const { id } = useParams();
  console.log(id, 'iddddd');
  // eslint-disable-next-line no-unused-vars
  const [variance, setVariance] = useState([]);
  const VarianceReports = () => {
    getVarianceReport({ id }).then((res) => {
      if (res.success) {
        setVariance(res.data);
      }
    });
  };
  const VarianceDownload = () => {
    downloadVarianceReport({ id }).then((res) => {
      if (res.success) {
        setVariance(res.data);
      }
    });
    useEffect(() => {
      VarianceDownload();
    }, []);
  };

  console.log(variance, 'variance');
  useEffect(() => {
    VarianceReports();
  }, []);


  return (
    <div className="">
      <div className="align-items-center d-flex justify-content-between report-ctrl" data-testid="Variance-Report">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center me-4">
            <div className="font-20 font-bold">Variance Report</div>
            {/* <div className="ms-3 couter-bubbles status-due
            ">{tableData?.costData?.totalItems}</div> */}
          </div>
          <div className="d-flex align-items-center me-5">
            <label>Repair Period<span className='ms-2'>:</span></label>
            &nbsp;
            <div className="value">{variance?.repairStartDate ? moment(variance?.repairStartDate).format('DD-MMM-yyyy') : ''}</div>
            <div className="mx-4 value"> - </div>
            <div className="value">{variance?.repairEndDate ? moment(variance?.repairEndDate).format('DD-MMM-yyyy') : ''}</div>
          </div>
        </div>
        {/* <div>
          <button type="button" className="secondary-btn">
            Print/Share
          </button>
        </div> */}
      </div>
      <div className="report-canvas mt-4">
        <div className="scroll-wrap scroll-wrap1">
          <div className="mt-5 d-flex">
            <DeviationChart graphData={variance?.costSummaryData?.graphData} projectBasicDetails={projectBasicDetails} variance={variance} />
            <div className="ms-4 col-6 repaircard-width">
              <RepairPeriodChart graphData={variance?.costSummaryData?.graphData} projectBasicDetails={projectBasicDetails} variance={variance} />
            </div>
          </div>
          <div />
          <div>
            <div className="card my-5 section-Card-table">
              <VarianceReportComponent variance={variance} />
            </div>
            <div>
              <VariaianceNonSpec variance={variance} />
            </div>
            <div className="fixed-footer card d-flex justify-content-end">
            <div className="text-black font-12 col-6">
              <div className=" footer-jobprogress">{`© ${new Date().getFullYear()} ThinkPalm`}</div>

            </div>
              <button onClick={() => VarianceDownload()} type="button" className="primary-btn large mx-3">
                Download Report
              </button>
            </div>
          </div>
             </div>
      </div>
    </div>
  );
};

export default VarianceReport;
