/*eslint-disable*/
// /*eslint-disable-next-line template-curly-spacing*/
import React from 'react';
import moment from 'moment/moment';
import _ from 'lodash';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { toNumber } from 'lodash';

const VarianceReportComponent = ({ variance }) => {
  const getClass = (value1, value2, type) => {
    console.log(value1,value2, "hellooooo");
    // if(value1 || value2 === 0)
    // {
    //   return '';
    // }
    if (type === 'color') {
      if (toNumber(value1) < toNumber(value2)) {
        return 'red';
      } else {
        return 'green';
      }
    } else {
      if (toNumber(value1) < toNumber(value2)) {
        return 'icon-arrow-up2 red';
      } else {
        return 'icon-arrow-down2 green';
      }
    }
  };

  return (
    <div data-testid="Variance-ReportComponent">
      {(variance && variance?.costSummaryData && variance?.costSummaryData.specs?.length) || variance?.costSummaryData?.nonSpecs?.length ? (
        <div className="">
          <div className="border p-3 mt-4">
            <div>
              <div className="d-flex">
                <div className="head-14">Specs</div>
              </div>
              <div className="bg-blue font-16 font-bold mt-2 py-2 row">
                <div className="d-flex  col-1">
                  <div className="ms-2 ">Spec #</div>
                </div>
                <div className="d-flex col-5 ">
                  <div className="ms-2 ">Spec Name</div>
                </div>
                <div className="d-flex  col-2">
                  <div className="ms-2 ">Project Est</div>
                </div>
                <div className="d-flex col-2 ">
                  <div className="ms-2 ">Project Actual</div>
                </div>
                <div className="d-flex  col-2">
                  <div className="ms-2 ">
                    <span data-tooltip-id="project-specName" data-tooltip-content="(estimate-actual)/estimate*100">
                    Variance (%)
                      </span></div>
                </div>
              </div>

              {variance?.costSummaryData?.specs.map((c) => {
                return (
                  <div className="pt-3 row">
                    <div className="d-flex  col-1">
                      <div className="">
                        {/* {c.updatedAt
                                ? (
                                  <div>
                                    {moment.utc(c.updatedAt.split('.')[0]).format('DD-MMM-YYYY HH:mm')}
                                    {' '}
                                    (UTC)
                                  </div>
                                )
                                : ''} */}
                        {c.number}
                      </div>
                    </div>
                    <div className="d-flex col-5">
                      <div className="ms-4">
                        <span className="text-ellipsis pe-2" data-tooltip-id="project-specName" data-tooltip-content={c?.specName}>
                          {_.truncate(c.specName, {
                            length: 40,
                            separator: ' ',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex  col-2">
                      <div className="ms-4">
                        <span className="text-ellipsis pe-2" data-tooltip-id="project-specName" data-tooltip-content={variance.currencySymbol + c?.projectEstimate.toFixed(2)}>
                          {variance.currencySymbol}
                          {' '}
                          {_.truncate(c.projectEstimate.toFixed(2), {
                            length: 40,
                            separator: ' ',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex col-2 ">
                      <div className="ms-4">
                        <span className="text-ellipsis pe-2" data-tooltip-id="project-specName" data-tooltip-content={variance.currencySymbol + c?.projectActual.toFixed(2)}>
                          {variance.currencySymbol}
                          {' '}
                          {_.truncate(c.projectActual.toFixed(2), {
                            length: 40,
                            separator: ' ',
                          })}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex  col-2">
                      <div className="d-flex ms-4">
                        <span
                          className={
                            c.variancePercentage
                              ? 'text-ellipsis ' +
                                getClass(c.projectEstimate,c.projectActual, 'color')
                              : 'text-ellipsis'
                          }
                          id="de-vi-at"
                        >
                          <span
                            className="text-ellipsis pe-2"
                            data-tooltip-id="project-specName"
                            data-tooltip-content={Math.abs(c?.variancePercentage.toFixed(2))}
                          >
                            {_.truncate(Math.abs(c.variancePercentage.toFixed(2)), {
                              length: 15,
                              separator: ' ',
                            })}
                          </span>
                        </span>

                        <span
                          id="de-vi"
                          className={
                            c.variancePercentage
                              ? 'font-18 ms-2 mt-1 ' +
                                getClass(c.projectEstimate, c?.projectActual, 'icon')
                              : 'font-18 ms-2 mt-1 '
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="d-flex col-2 ">
                            <div className="ms-2 text-ellipsis" data-tooltip-id="project-variation" data-tooltip-content={c.description}>
                              {c.description}
                            </div>
                          </div> */}
                  </div>
                );
              })}
              <div className="d-flex">
                <div className="d-flex col-1 ms-1">
                  <div className=""></div>
                </div>
                <div className="d-flex  mt-4 ms-3 col-5 ">
                  <div className="Head-16 font-16 font-bold">Total</div>
                </div>
                <div className="d-flex mt-4 ms-3 col-2">
                  <div className="Head-16 font-16 font-bold">
                    <span data-tooltip-id="project-specName" data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.graphData?.specsProjectEstimate.toFixed(2)}>
                    {variance.currencySymbol}
                    {' '}
                    {variance?.costSummaryData?.graphData?.specsProjectEstimate.toFixed(2)}
                    </span>

                  </div>
                </div>
                <div className="d-flex mt-4 ">
                  <div className="Head-16 font-16 font-bold">
                    <span data-tooltip-id="project-specName" data-tooltip-content={variance.currencySymbol + variance?.costSummaryData?.graphData?.specsProjectActual.toFixed(2)}>
                    {variance.currencySymbol}
                    {' '}
                    {variance?.costSummaryData?.graphData?.specsProjectActual.toFixed(2)}
                    </span>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
        <div className="mt-5 p-4 d-flex justify-content-center align-items-center">
          <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
          <div className="font-18 animate__animated animate__headShake">No data found</div>
        </div>
        <div className="fixed-footer card d-flex justify-content-end">

        <div className="text-black font-12 col-6">
          <div className="mt-2 mb-2">{`© ${new Date().getFullYear()} ThinkPalm`}</div>

        </div>
      </div>
      </>
      )}
      <Tooltip id="project-specName" place="top" className="tooltip mt-3" />
    </div>
  );
};

export default VarianceReportComponent;
