/* istanbul ignore file */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import ModalBox from '../../../../../common/ModalBox';
import showAlert from '../../../../../../utils/alert';
import Navbar from './navbar';
import { DeleteProjectSpec, getSpecListByIdVariation, DeleteVariation, supplemetarySpecLists } from './services';
import SupplementaryTable from './supplementaryTable';
import AdditonalSpecs from './additionalSpecs';
import Loader from '../../../../../common/loader';
import { getProjectHasApprover } from '../../../NewProject/services';
import helper from '../../../../../../utils/helper';

const SpecListing = (props) => {
  const { roles } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const isVesselUser = userRole === roles.VESSEL_USER;
  const { setIsSupplementaryClicked, setSpecDetails,
    setFormData, setIsSaved, initialFormData,
    setSpecId, setSpecList, specList, isSubmited, projInfo,
    setButtonDisable, buttonDisable, setSendForApproval, sendForApproval,
    onSubmit, setIsAddClicked, fromVariation, setEmptySpecListRepair,
    emptySpecificationList, getEmptySpecListSupplementary, isTSI, listOfSpecs,
    statusUpdate, fromSupplementary, projectBasicDetails } = props;
  const { id } = useParams();
  const [showDelete, setShowDelete] = useState(false);
  const [showsubSec, setShowSubSec] = useState('');
  const [mainSec, setMainSec] = useState('');
  const [subSpec, setSubSpec] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);

  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/create')) {
      type = 1;
    }
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(id, type).then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);
  const onReload = () => {
    if (fromVariation) {
      setLoading(true);
      getSpecListByIdVariation(id).then((resp) => {
        if (resp.success) {
          setLoading(false);
          setSpecList(resp.data);
          if (resp.data && resp?.data?.length > 0) {
            if (resp?.data[0]?.isEditable === false) {
              setButtonDisable(true);
            }
          }
        }
      });
    } else {
      if (!statusUpdate) {
        setLoading(true);
        supplemetarySpecLists({ projectId: id, status: !isVesselUser ? 1 : 5 }).then((res) => {
          if (res.success) {
            setLoading(false);
            setSpecList(res.data);
            if (res.data && res?.data?.length > 0) {
              if (res?.data[0]?.isEditable === false && !isVesselUser) {
                setButtonDisable(true);
              }
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    onReload();
  }, [isSubmited, hasApprover]);

  const editData = (spec) => {
    setIsSupplementaryClicked(true);
    setIsSaved(true);
    setSpecId(spec?.id);
    setSpecDetails(spec);
    setFormData({
      sectionId: spec?.parentSpecificationId,
      sectionNo: spec?.sectionNo,
      sectionTitle: '',
      sectionType: 0,
      specId: spec?.id,
      specNoAutoGenerated: spec?.specificationNo,
      specTitle: spec?.title,
      specType: 0,
      projectId: id,
      specAssigned: spec?.specAssigned
    });
  };

  const deteleData = (spec) => {
    setSubSpec(spec);
    setShowDelete(true);
  };
  /* istanbul ignore next */

  const deleteApiCall = () => {
    if (fromVariation) {
      DeleteVariation(subSpec?.id).then((response) => {
        if (response?.success) {
          setLoading(false);
          showAlert('Successfully Deleted', 'success');
          setShowDelete(false);
          emptySpecificationList();
          onReload();
        }
      });
    } else {
      setLoading(true);
      DeleteProjectSpec(subSpec?.id).then((response) => {
        if (response?.success) {
          setLoading(false);
          showAlert('Successfully Deleted', 'success');
          setShowDelete(false);
          getEmptySpecListSupplementary();
          onReload();
        }
      });
    }
  };
  const onSecOpen = (identity, isSec) => {
    if (isSec) {
      const newId = mainSec === identity ? '' : identity;
      setMainSec(newId);
    } else {
      const newsubId = showsubSec === identity ? '' : identity;
      setShowSubSec(newsubId);
    }
  };
  return (
    <div data-testid="sp-mainss">
      {loading ? <Loader /> : (
        <div>
          <Navbar
            setIsSupplementaryClicked={setIsSupplementaryClicked}
            setSpecDetails={setSpecDetails}
            setFormData={setFormData}
            setIsSaved={setIsSaved}
            initialFormData={initialFormData}
            setSpecId={setSpecId}
            buttonDisable={buttonDisable}
            setIsAddClicked={setIsAddClicked}
            fromVariation={fromVariation}
            isTSI={isTSI}
            projInfo={projInfo}
          />
          <div className={fromVariation ? 'mt-4' : ''}>
            {specList?.length > 0 && (
              fromVariation
                ? (
                  <SupplementaryTable
                    setShowDelete={setShowDelete}
                    editData={editData}
                    deteleData={deteleData}
                    specList={specList}
                    mainSec={mainSec}
                    showsubSec={showsubSec}
                    onSecOpen={onSecOpen}
                    setIsAddClicked={setIsAddClicked}
                    fromVariation={fromVariation}
                    emptySpecificationList={emptySpecificationList}
                    isTSI={isTSI}
                    projInfo={projInfo}
                  />
                )
                : (
                  <AdditonalSpecs
                    setShowDelete={setShowDelete}
                    editData={editData}
                    deteleData={deteleData}
                    specList={listOfSpecs}
                    mainSec={mainSec}
                    showsubSec={showsubSec}
                    onSecOpen={onSecOpen}
                    setIsAddClicked={setIsAddClicked}
                    fromVariation={fromVariation}
                    emptySpecificationList={emptySpecificationList}
                    isTSI={isTSI}
                    projectBasicDetails={projectBasicDetails}
                    isVesselUser={isVesselUser}
                  />
                )
            )}

            {specList?.length < 1
          && (
            <div className="mt-5 p-4 d-flex justify-content-center align-items-center ">
              <span className="icon-exclamation_fill font-24 brand-color me-3 animate__animated animate__headShake " />
              <div className="font-18 animate__animated animate__headShake">
                No data found
              </div>
            </div>
          )}
          </div>
          {showDelete ? (
            <ModalBox
              modalClassName="medium"
              heading="Delete"
              onSave={() => deleteApiCall()}
              closeModal={() => setShowDelete(false)}
              buttonName="Delete"
            >
              <p>{fromVariation ? 'Are you sure you want to remove Variation ?' : 'Are you sure you want to remove Supplementary Spec ?'}</p>
            </ModalBox>
          ) : (
            ''
          )}
          {sendForApproval
        && (
          <ModalBox
            modalClassName="medium"
            onSave={() => onSubmit()}
            closeModal={() => setSendForApproval(false)}
            buttonName={specList && specList?.length > 0 && !specList[0]?.isRevision ? 'Submit' : 'Resubmit'}
          >
            <div className="d-flex justify-content-center flex-column align-items-center" data-testid="preconfirmbuttonpage">
              <div className="head-18 pb-4">
                <span
                  data-tooltip-id="project-supplementary"
                  data-tooltip-content={projInfo?.name}
                  className="white-space-preserve"
                >
                  {projInfo?.name}
                </span>

              </div>
              <div className="mt-3">
                {hasApprover ? <p className="font-15 font-semibold text-black">Supplementary Spec will be sent for next level of approval</p>
                  : ''}
              </div>
              <span className="head-18">{`Are you sure you want to ${specList && specList?.length > 0 && specList[0]?.isRevision ? 're' : ''}submit?`}</span>
            </div>
          </ModalBox>
        )}
        </div>
      )}
      <Tooltip id="project-supplementary" place="top" className="tooltip mt-3" />
    </div>

  );
};
export default SpecListing;
