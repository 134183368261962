/*eslint-disable*/
/* istanbul ignore file */
import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  CompareQuotesYardList, getKfp, saveCQ, saveComment, saveAssignee, sendForApproval, getUnits, getprojectStatus,
  getprojectDetails, updateStatus, saveDOCost, saveServiceLine, getAcCode
} from "./services";
import { receivedQuote, shortListYards } from '../ReceivedQuotes/services';
import SideBarHome from './SideBar/SideBarHome';
import YardListsHome from "./YardList/YardListHome";
import Loader from "../../../../../common/loader";
import ModalBox from "../../../../../common/ModalBox";
import helper from "../../../../../../utils/helper";
import AddRemarks from "../SupplementaryJobs/addRemarks";
import showAlert from "../../../../../../utils/alert";
import FooterApproval from "./FooterApproval";
import SubmitView from "./submitView";
import Comments from "../../../Comments";
import { excelExportQuote, saveCompareQuoteSLComments } from "../services";
import ExportExcelModal from "./ExportExcelModal";
import { getProjectHasApprover } from "../../../NewProject/services";
import CQSidePanel from './CQSidePanel';
import { cloneDeep, debounce, round, toNumber } from "lodash";

const CompareQuotesHome = () => {

  const { roles, ZERO_UUID, formatNumber, charValidation } = helper;
  const userRole = localStorage?.getItem('USER_ROLE')?.toLowerCase();
  const managementId = localStorage.getItem('managementId');
  const isTSI = userRole === roles.AUTHOR
    || userRole === roles.EDITOR
    || userRole === roles.ADMIN;
  const isAdmin = userRole === roles.ADMIN;
  const isApprover = userRole === roles.AUTHOR || userRole === roles.ADMIN;
  const [checkList, setCheckList] = useState([]);
  const [compareData, setCompareData] = useState([]);
  const [generalData, setGeneralData] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [kfpList, setKfpList] = useState([]);
  const [units, setUnits] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isOpenedRemarks, setOpenedRemarks] = useState(false);
  const [approvalSend, setApprovalSend] = useState(false);
  const [projectStatus, setProjectStatus] = useState('');
  const [projectDetails, setProjectDetails] = useState('');
  const [approveClick, setApproveClick] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [remarks, setRemarks] = useState('');
  const [canSend, setCanSend] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedYard, setSelectedYard] = useState('');
  const [yardComment, setYardComment] = useState('');
  const [selectedExcelYard, setSelectedExcelYard] = useState([]);
  const [acCodes, setAcCodes] = useState([]);
  const [selectModal, setSelectModal] = useState({ status: false, index: '', data: '' });
  const [sidePanelData, setSidePanelData] = useState({});
  const [showSideBar, setShowSideBar] = useState(false);
  const [showSideBarCost, setShowSideBarCost] = useState(false);
  const [commentClicked, setCommentClicked] = useState(false);
  const [docData, setDocData] = useState('');
  const [showExportModal, setshowExportModal] = useState(false);
  const [modalBox, setModalBox] = useState(false);
  const [selectedYardList, setSelectedYardList] = useState([]);
  const [subHeaderList, setSubHeaderList] = useState([]);
  const [applyAll, setApplyAll] = useState({
    status: false, value: '', field: '', line: {}, yardId: ''
  });
  const [serviceLines, setServiceLines] = useState([]);
  const [commentValue, setCommentValue] = useState({
    comment: '',
    index: '',
    rfqId: '',
    isSelected: ''
  });
  const [addYardComment, setAddYardComment] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [comment, setComment] = useState({
    projectId: null,
    specId: null,
    serviceLineId: null,
    projectRfqId: null,
    comment: null
  });
  const disableFields = !(projectDetails?.tenderApprovalStatus === 0 || projectDetails?.tenderApprovalStatus === 3 || projectDetails?.tenderApprovalStatus === 2);
  const repairStarted = generalData?.repairStartDate ? true : false;
  const { id } = useParams();
  const Exportdata = {
    projectId: id,
    yardIds: []
  }
  const navigate = useNavigate();
  const location = useLocation();
  const [hasApprover, setHasApprover] = useState(true);
  const [sortedQuotes, setSortQuotes] = useState([]);
  const [newACCode, setnewACCode] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [listHeight, setListHeight] = useState(400);

  /* istanbul ignore next */
  const getName = (resubmit = false) => {
    let n = 'Submit';
    if (resubmit) {
      n = 'Resubmit';
    }
    if (hasApprover) {
      n += ' for Approval';
    }
    return n;
  };

  /* istanbul ignore next */
  useEffect(() => {
    let type = '';
    if (location?.pathname.includes('/plan')) {
      type = 1;
    }
    if (location?.pathname.includes('/specify')) {
      type = 2;
    }
    if (location?.pathname.includes('/tender')) {
      type = 3;
    }
    if (location?.pathname.includes('/tender/compare-quotes')) {
      type = 4;
    }
    if (location?.pathname.includes('/repair')) {
      type = 5;
    }
    if (location?.pathname.includes('/close')) {
      type = 6;
    }
    getProjectHasApprover(id, type).then((res) => {
      if (res?.success) {
        setHasApprover(res.data);
      }
    });
  }, []);

  /* istanbul ignore next */
  const reload = async (value) => {
    window.scrollTo(0, 0);
    if (value) setIsLoading(true);
    const payload = {
      projectId: id
    }
    await CompareQuotesYardList(payload).then((res) => {
      if (res?.data?.shipYards?.length) {
        res?.data?.checkLists?.specQuoteList?.map((j, index) => {
          j?.specDataList?.map((k, inde) => {
            k?.serviceLineList?.map((l, m) => {
              var lightColor = '#DAFFE0';
              const serviceLine = {
                id: '00000000-0000-0000-0000-000000000000',
                parentSpecId: '00000000-0000-0000-0000-000000000000',
                serviceLineName: '',
                specificationLevel: 3,
                uomId: '00000000-0000-0000-0000-000000000000',
                uomName: '',
                acCodeId: '00000000-0000-0000-0000-000000000000',
                acCode: '',
                number: l.number || l.specificationNo,
                quantity: 0,
                unitPrice: 0,
                discount: 0,
                yardEstimate: 0,
                ownerEstimate: 0,
                ownerCost: 0,
                comments: '',
                isFromYard: false,
                formula: null,
                rfqId: '00000000-0000-0000-0000-000000000000',
                specAssigned: 0,
                isUpdateQuantity: false,
                isUpdateFormula: false,
                color: lightColor
              };
              res?.data?.shipYards?.map((mm) => {
                if (l?.isFromYard) {
                  if (!mm?.sectionQuoteList[index]?.specList[inde]?.serviceLineList[m] || mm?.sectionQuoteList[index]?.specList[inde]?.serviceLineList[m]?.id !== l.id) {
                    mm?.sectionQuoteList[index].specList[inde].serviceLineList.splice(m, 0, serviceLine);
                  }
                  if (mm?.sectionQuoteList[index]?.specList[inde]?.serviceLineList[m]?.isFromYard) {
                    l.color = lightColor;
                    mm.sectionQuoteList[index].specList[inde].serviceLineList[m]['color'] = lightColor;
                  }
                }
              });
            });
          });
        });
      }
      setCheckList(res?.data?.checkLists?.specQuoteList);
      setGeneralData(res?.data?.projectInfo);
      setSearchList(res?.data?.kfpSections);
      const yardQuoteList = res?.data?.shipYards?.map((item) => ({ yardId: item.id, yardName: item.yardName }));
      setSelectedYardList(yardQuoteList);
      res?.data?.shipYards?.length > 0 && res?.data?.shipYards?.map((i, index) => {
        if (index === 0) {
          i.showFull = true;
        } else i.showFull = false;
      });
      setCompareData(res?.data?.shipYards);
      recalculate(res?.data?.shipYards);
      sortQuotes(res?.data?.shipYards);
      res?.data?.shipYards?.map((i) => {
        if (i?.isSelected) {
          setSelectedYard(i.id);
          setCanSend(true);
        }
      });
      setIsLoading(false);
    });
    await receivedQuote(id).then((response) => {
      if (response.success) {
        setSubHeaderList(response?.data);
      }
    });
    await getKfp(payload).then((response) => {
      setKfpList(response.data);
    });
    await getUnits().then((response) => {
      const options = response?.data?.map((opt) => {
        return ({ label: opt.uomName, value: opt.uomId, name: opt.unit });
      });
      setUnits(options);
    });
    const i = {
      projectId: id,
      type: 4
    };
    await getprojectStatus(i).then((response) => {
      setProjectStatus(response?.data);
    });
    await getprojectDetails(i).then((response) => {
      setProjectDetails(response?.data);
    });
  };

  /* istanbul ignore next */
  useEffect(() => {
    getAcCode({
      page: 1,
      pageSize: 100,
      search: '',
      sortProperty: 'asc',
      isDescending: false,
      managementGroupId: managementId || ZERO_UUID
    }).then((response) => {
      const options = response?.data?.items?.map((opt) => {
        return ({ label: opt.name, value: opt.id, name: opt.code });
      });
      setAcCodes(options);
    });
  }, [managementId]);

  useEffect(() => {
    reload(1);
  }, []);

  /* istanbul ignore next */
  const SaveCQ = (data, alert) => {
    saveCQ(data).then((res) => {
      if (res?.success && (!alert || data.isFromYard)) showAlert('Saved', 'success');
    });
  };

  /* istanbul ignore next */
  const SaveComment = (data, alertValue) => {
    saveComment(data).then((res) => {
      if (res?.success && alertValue === 'save') showAlert('Saved', 'success');
    });
  };

  /* istanbul ignore next */
  const SaveAssignee = (data) => {
    saveAssignee(data).then((res) => {
      if (res?.success) {
        showAlert('Saved', 'success');
        if (data.Assign === 1) reload(1);
      }
    })
  };

  /* istanbul ignore next */
  const SendForApproval = () => {
    const data = {
      projectId: id,
      type: 4
    };
    sendForApproval(data).then((response) => {
      if (response.success) {
        setApprovalSend(true);
        reload(1);
      }
    });
  };

  /* istanbul ignore next */
  const onSave = (isSuccess) => {
    if (remarks || buttonText === 'approve') {
      const data = {
        projectId: id,
        type: 4,
        level: projectStatus,
        status: buttonText === 'revision' ? 3 : 2,
        comment: remarks
      };
      updateStatus(data).then((res) => {
        if (res.success) {
          setOpenModal(true);
          if (isSuccess) {
            setApprovalSend(true);
          }
        }
      });
      setOpenedRemarks(false);
      reload(1);
    } else {
      showAlert('Please enter mandatory field', 'error');
    }
  };

  /* istanbul ignore next */
  const postDOCostDetails = (data) => {
    saveDOCost(data).then((res) => {
      if (res?.success) showAlert('Saved', 'success');
    });
  };

  /* istanbul ignore next */
  const newServiceLine = (data) => {
    saveServiceLine(data).then((response) => {
      if (response.success) {
        showAlert('Saved', 'success');
        reload();
      }
    });
  };

  /* istanbul ignore next */
  const ExportQuote = () => {
    Exportdata.yardIds = selectedExcelYard;
    excelExportQuote(Exportdata).then(() => {
      setshowExportModal(false);
      setSelectedExcelYard([]);
      showAlert('Successfully Downloaded', 'success');
    });
  };

  const [selectAll, setSelectedAll] = useState(false);
  useEffect(() => {
    if (!selectAll) {
      setSelectedExcelYard([]);
    }
  }, [selectAll])

  /* istanbul ignore next */
  const handleChange = (e, i, rfqId, selected, save, field, alertValue) => {
    const temp = cloneDeep(compareData);
    let value = 0;
    if (field === 'selectionComment') {
      if (e) temp[i][field] = e;
    } else {
      if (e.target && e.target.value) {
        if (field === 'additionalDiscount') {
          const regex = /^\d+(\.\d{0,2})?$/;
          if (regex.test(e.target.value) || e.target.value !== '') {
            let inputValue = e.target.value;
            inputValue = inputValue.replace(/[^\d.]/g, (match, index) => {
              if (match === '.') {
                return index === 0 || inputValue.indexOf('.') !== -1 ? '.' : '';
              }
              return '';
            });
            inputValue = inputValue.match(/^\d{0,5}?$/) ? inputValue : temp[i].additionalDiscount;
            temp[i].additionalDiscount = inputValue;
            value = inputValue;
          }
        } else {
          value = toNumber(charValidation(e.target.value.replace(/[^0-9]/g, '')));
          temp[i][field] = value;
        }
      } else {
        temp[i][field] = null;
      }
    }
    const data = {
      rfqId: rfqId,
      comment: field === 'selectionComment' ? e : temp[i].selectionComment,
      isSelected: selected,
      additionalDiscount: field === 'additionalDiscount' ? value : temp[i] ? temp[i].additionalDiscount : 0,
      additionalDiscountPercentage: temp[i] ? temp[i].additionalDiscountPercentage : 0,
    };
    if (save) SaveComment(data, alertValue);
    setCompareData(temp);
    recalculate(temp);
  };

  /* istanbul ignore next */
  const recalculate = (array = []) => {
    const CQ = array || compareData;
    CQ?.map((yard) => {
      let sumY = 0;
      let sumO = 0;
      yard?.sectionQuoteList?.map((sec) => {
        let yE_Sec = 0;
        let oE_Sec = 0;
        sec?.specList?.map((spec, ink) => {
          let yE_Spec = 0;
          let oE_Spec = 0;
          spec?.serviceLineList?.map((serv, inl) => {
            yE_Spec = yE_Spec + serv?.yardEstimate;
            if (spec?.specAssigned === 1) {
              oE_Spec = oE_Spec + serv?.ownerEstimate;
            }
            if (spec?.serviceLineList?.length === (inl + 1)) {
              spec.yardEstimate = yE_Spec;
              spec.ownerEstimate = oE_Spec;
              yE_Sec = yE_Sec + yE_Spec;
              oE_Sec = oE_Sec + oE_Spec;
            }
          });
          if (sec?.specList?.length === (ink + 1)) {
            sec.yardEstimate = yE_Sec;
            sec.ownerEstimate = oE_Sec;
            sumY = sumY + yE_Sec;
            sumO = sumO + oE_Sec;
            yard.yardTotal = sumY;
            yard.ownerEstimate = sumO;
          }
        });
      });
    });
    sortQuotes(array || compareData);
  };

  /* istanbul ignore next */
  const onYardSelect = async (eid) => {
    let selYard = [...selectedExcelYard];
    if (eid === 'selectAll') {
      const selectedallYard = [];
      compareData.map((item) => {
        selectedallYard.push(item.id);
      })
      setSelectedAll(!selectAll);
      selYard = selectedallYard;
    } else {
      if (selectedExcelYard.indexOf(eid) > -1) {
        selYard.splice(selectedExcelYard.indexOf(eid), 1);
      } else {
        selYard.push(eid);
      }
    }
    setSelectedExcelYard(selYard);
  };

  /* istanbul ignore next */
  const saveSelectedYards = () => {
    const list = selectedYardList.map((item) => item.yardId);
    shortListYards(id, list).then((res) => {
      if (res.success) {
        showAlert('QuoteList added for compare', 'success');
        setModalBox(false);
        reload(1);
      }
    });
  };

  /* istanbul ignore next */
  const assignFlags = (array) => {
    const len = array.length;

    if (len > 1) {
      array[0].isLowest = true;
      array[len - 1].isHighest = true;

      if (len % 2 === 0) {
        const mid1 = Math.floor(len / 2) - 1;
        const mid2 = Math.floor(len / 2);

        if (array[mid1].total < array[mid2].total) {
          array[mid1].isMedian = true;
        } else {
          array[mid2].isMedian = true;
        }
      } else {
        const mid = Math.floor(len / 2);
        array[mid].isMedian = true;
      }
    }
    return array;
  };

  /* istanbul ignore next */
  const sortQuotes = (quotes) => {
    let sortArray = [];

    quotes?.forEach((i) => {
      const total = formatNumber(
        round(i.ownerEstimate) -
        round((i.ownerEstimate / 100) * toNumber(i.additionalDiscountPercentage)) -
        round(i?.additionalDiscount) +
        round(i?.ownerCost) +
        round(i?.deviationCost) +
        round(i?.offhireCost)
      );
      const object = {
        id: i.id,
        total: parseFloat(total.replace(/,/g, '')),
        isHighest: false,
        isLowest: false,
        isMedian: false
      };
      sortArray.push(object);
    });

    sortArray.sort((a, b) => a.total - b.total);
    const sortedArrayWithFlags = assignFlags(sortArray);
    setSortQuotes(sortedArrayWithFlags);
  };

  const onClose = () => {
    setShowSideBar(false);
    setShowSideBarCost(false);
  };

  /* istanbul ignore next */
  const handleNodeToggle = (projectSpecId, level) => {
    if (expandedNodes.includes(projectSpecId) && level === 1) {
      return setExpandedNodes([]);
    } else if (!expandedNodes.includes(projectSpecId) && level === 1) {
      setExpandedNodes([]);
    } else if (!expandedNodes.includes(projectSpecId) && level === 2 && expandedNodes?.length > 1) {
      return setExpandedNodes(prevNodes => [...prevNodes.slice(0, -1), projectSpecId]);
    }
    setExpandedNodes((prev) =>
      prev.includes(projectSpecId)
        ? prev.filter((id) => id !== projectSpecId)
        : [...prev, projectSpecId]
    );
  };

  const sidebarRef = useRef(null);
  const containerRefs = useRef([]);

  /* istanbul ignore next */
  const handleScrollSync = debounce((sourceIndex, scrollOffset) => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollTo(scrollOffset);

      containerRefs.current.forEach((ref, index) => {
        if (ref && index !== sourceIndex) {
          ref.scrollTo(scrollOffset);
        }
      });
    }
  }, 100);

  /* istanbul ignore next */
  const handleScrollToTop = (guid) => {
    const element = document.getElementById(guid);
    if (element) {
      const headerHeight = document.querySelector('header')?.offsetHeight || 100;
      const containerTopOffset = element.closest(".scroll-container")?.getBoundingClientRect()?.top || 100;

      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetFromTop = window.innerHeight * 0.25;

      const scrollTarget = elementPosition - headerHeight - offsetFromTop;

      window.scrollTo({
        top: scrollTarget,
        behavior: 'smooth',
      });
    } else {
      console.log(`Element with GUID ${guid} not found.`);
    }
  };

  // useEffect(() => {
  //   const updateHeight = () => setListHeight(window.innerHeight - 299);
  //   updateHeight();
  //   window.addEventListener("resize", updateHeight);
  //   return () => window.removeEventListener("resize", updateHeight);
  // }, []);

  const onSaveSLComments = (data) => {
    saveCompareQuoteSLComments(data).then((resp) => {
      if (resp.success) {
        reload(0);
      }
    });
  };

  return (
    <div data-testid="qewewr">
      {isLoading && <Loader />}
      {openModal &&
        <ModalBox
          modalClassName="medium"
          onSave={() => {
            setTimeout(SendForApproval(), 1000);
          }}
          closeModal={() => {
            setMessage('sendforapproval');
            if (approvalSend) {
              setApprovalSend(false);
              setOpenModal(false);
            } else {
              setOpenModal(false)
            }
          }}
          heading={approvalSend ? "" : "Yard Confirmation Request"}
          buttonName={approvalSend ? "" : getName()}
        >
          {!approvalSend ?
            <div>
              <div>{hasApprover ? 'Are you sure you want to send for approval?' : 'Are you sure you want to submit?'}</div>
            </div>
            :
            <SubmitView
              projectDetails={projectDetails}
              message={message}
            />
          }
        </ModalBox>
      }
      {isOpenedRemarks &&
        <ModalBox
          closeModal={() => {
            setApproveClick(false);
            setOpenedRemarks(false);
            setButtonText('');
            setRemarks('');
          }}
          buttonName="Submit"
          modalClassName="medium modal-scroll"
          onSave={() => {
            onSave(true);
          }}
        >
          <AddRemarks
            name={projectDetails?.name}
            nameTooltip={projectDetails?.nameTooltip}
            setRemarks={setRemarks}
            buttonText={buttonText}
            setButtonText={setButtonText}
            remarks={remarks}
            approveClick={approveClick}
            CompareQuote={true}
          />
        </ModalBox>
      }
      <div>
        {!isLoading && checkList &&
          <div className="d-flex compare-quote-classes compare-quote-container ms-2">
            <SideBarHome
              checkList={checkList}
              setCheckList={setCheckList}
              compareData={compareData}
              setCompareData={setCompareData}
              generalData={generalData}
              modalBox={modalBox}
              setModalBox={setModalBox}
              saveSelectedYards={saveSelectedYards}
              subHeaderList={subHeaderList}
              selectedYardList={selectedYardList}
              setSelectedYardList={setSelectedYardList}
              isTSI={isTSI}
              disableFields={disableFields}
              repairStarted={repairStarted}
              recalculate={recalculate}
              onClose={onClose}
              units={units}
              acCodes={acCodes}
              newACCode={newACCode}
              setnewACCode={setnewACCode}
              newServiceLine={newServiceLine}
              SaveAssignee={SaveAssignee}
              treeData={checkList}
              expandedNodes={expandedNodes}
              handleNodeToggle={handleNodeToggle}
              listHeight={listHeight}
              syncScroll={scrollOffset => handleScrollSync(-1, scrollOffset)}
              sidebarRef={sidebarRef}
              handleScrollToTop={handleScrollToTop}
            />
            <YardListsHome
              checkList={checkList}
              compareData={compareData}
              setCompareData={setCompareData}
              generalData={generalData}
              isTSI={isTSI}
              disableFields={disableFields}
              repairStarted={repairStarted}
              projectDetails={projectDetails}
              selectModal={selectModal}
              setSelectModal={setSelectModal}
              closeModal={closeModal}
              setCloseModal={setCloseModal}
              applyAll={applyAll}
              setApplyAll={setApplyAll}
              searchList={searchList}
              kfpList={kfpList}
              serviceLines={serviceLines}
              setServiceLines={setServiceLines}
              addYardComment={addYardComment}
              setAddYardComment={setAddYardComment}
              addComment={addComment}
              setAddComment={setAddComment}
              commentList={commentList}
              setCommentList={setCommentList}
              comment={comment}
              setComment={setComment}
              yardComment={yardComment}
              setYardComment={setYardComment}
              commentValue={commentValue}
              setCommentValue={setCommentValue}
              setDocData={setDocData}
              setCommentClicked={setCommentClicked}
              postDOCostDetails={postDOCostDetails}
              sidePanelData={sidePanelData}
              setSidePanelData={setSidePanelData}
              sortedQuotes={sortedQuotes}
              setShowSideBar={setShowSideBar}
              showSideBarCost={showSideBarCost}
              setShowSideBarCost={setShowSideBarCost}
              sortQuotes={sortQuotes}
              recalculate={recalculate}
              onClose={onClose}
              handleChange={handleChange}
              canSend={canSend}
              setCanSend={setCanSend}
              setSelectedYard={setSelectedYard}
              SaveComment={SaveComment}
              SaveCQ={SaveCQ}
              units={units}
              expandedNodes={expandedNodes}
              listHeight={listHeight}
              syncScroll={handleScrollSync}
              containerRefs={containerRefs}
              onSaveSLComments={onSaveSLComments}
              isAdmin={isAdmin}
            />
          </div>
        }
        <CQSidePanel commentClicked={commentClicked} showSideBar={showSideBar} onClose={onClose} docData={docData} />
        {isTSI &&
          <div className="fixed-footer card d-flex justify-content-end">
            <div className="me-auto">
              <Comments id={id} appendClassName="comments-btn" />
            </div>
            <div className="text-black font-12 col-6">
              <div className="footer-tender">{`© ${new Date().getFullYear()} ThinkPalm`}</div>
            </div>
            <button type="button" className="secondary-btn large me-5" onClick={() => { setshowExportModal(true); }}>Excel Download</button>
            <button
              className='secondary-btn large me-5'
              data-test="my-button"
              onClick={() => {
                navigate(`/user/project/${id}/tender?vesselId=${projectDetails?.vesselId}`,
                  { state: { from: 'Comparequotes' } });
              }}
            >
              Back
            </button>
            {userRole !== roles.ADMIN &&
              <button
                className={`primary-btn large me-5 ${canSend && !generalData?.repairStartDate &&
                  (projectDetails?.tenderApprovalStatus === 0 ||
                    projectDetails?.tenderApprovalStatus === 3 ||
                    projectDetails?.tenderApprovalStatus === 2) && selectedYard !== generalData?.previousSelectedYard
                  ? '' : 'disabled'}`}
                data-test="click2"
                onClick={() => {
                  if (canSend && !generalData?.repairStartDate &&
                    (projectDetails?.tenderApprovalStatus === 0 ||
                      projectDetails?.tenderApprovalStatus === 3 ||
                      projectDetails?.tenderApprovalStatus === 2) && selectedYard !== generalData?.previousSelectedYard)
                    setOpenModal(true);
                }}
              >
                {projectDetails?.tenderApprovalStatus === 0 ? getName() :
                  projectDetails?.tenderApprovalStatus === 1 || projectDetails?.tenderApprovalStatus === 3 ?
                    getName(true) :
                    getName()}
              </button>}
          </div>
        }
        {showExportModal && (
          <ModalBox
            heading="Select Quote to Export"
            closeModal={() => {
              setshowExportModal(false);
              setSelectedExcelYard([]);
              setSelectedAll(false);
            }}
            buttonName="Download"
            modalClassName="medium"
            onSave={() => ExportQuote()}
            saveDisable={selectedExcelYard.length === 0}>
            <div className={`${compareData.length > 7 ? "scroll-wrap" : ''}`}>
              <ExportExcelModal compareData={compareData} onYardSelect={onYardSelect} selectedExcelYard={selectedExcelYard} selectAll={selectAll} />
            </div>
          </ModalBox>
        )}
        {!isApprover && projectStatus > 0 &&
          <FooterApproval
            id={id}
            showExportModal={showExportModal}
            setSelectedExcelYard={setSelectedExcelYard}
            ExportQuote={ExportQuote}
            selectedExcelYard={selectedExcelYard}
            compareData={compareData}
            onYardSelect={onYardSelect}
            projectStatus={projectStatus}
            setButtonText={setButtonText}
            setApproveClick={setApproveClick}
            setOpenedRemarks={setOpenedRemarks}
            reload={reload}
            setMessage={setMessage}
            fromFooter={false}
            setshowExportModal={setshowExportModal}
          />}
      </div>
    </div>
  );
};

export default CompareQuotesHome;
