/* eslint-disable radix */
import React, { useEffect } from 'react';
import Select from 'react-select';
import InputType1 from '../../../common/InputType1';
import helper from '../../../../utils/helper';

const AddServiceLine = (props) => {
  const { fieldValidation } = helper;
  const initialData = {
    id: '00000000-0000-0000-0000-000000000000',
    serviceLineName: '',
    type: 1,
    specificationLevel: 3,
    uomId: '00000000-0000-0000-0000-000000000000',
    uomName: '',
    acCodeId: '00000000-0000-0000-0000-000000000000',
    acCode: '',
    number: '',
    quantity: 0,
    order: 1,
    rate: '',
    discount: '0',
    amount: '0',
    comments: '',
    isFromYard: true
  };
  const { specList, setNewServiceData, newServiceData, addServiceLine, uomList } = props;
  useEffect(() => {
    const specLength = specList.sectionData[addServiceLine.index1]
      .specDataList[addServiceLine.index2]
      .serviceLineList.filter((field) => field.specificationLevel === 3).length;
    initialData.number = `${specList.sectionData[addServiceLine.index1]
      .specDataList[addServiceLine.index2].specificationNo}.${specLength + 1}`;
    setNewServiceData(initialData);
  }, []);
  const onselectionchange = (e, label, name) => {
    const fd = { ...newServiceData };
    fd[name] = e.value;
    fd[label] = e.label;
    setNewServiceData(fd);
  };
  const onFieldChange = (e) => {
    const currentData = { ...newServiceData };
    if (e.target.name === 'rate') {
      currentData[e.target.name] = fieldValidation(e.target.value.replace(/[^0-9.]/g, ''), 5, 'returnString');
    } else if (e.target.name === 'quantity') {
      currentData[e.target.name] = fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 6);
    } else if (e.target.name === 'serviceLineName' || e.target.name === 'comments') {
      currentData[e.target.name] = e.target.value.trimStart();
    } else {
      currentData[e.target.name] = fieldValidation(e.target.value.replace(/^0+/, '').replace(/[^0-9.]/g, ''), 3, 'returnString');
    }
    if (currentData.quantity && currentData.rate && currentData.discount) {
      currentData
        .amount = (((currentData.quantity * currentData.rate) / 100) * (100 - currentData.discount))
          .toFixed(2).toString();
    } else if (currentData.quantity && currentData.rate) {
      currentData.amount = (currentData.quantity * currentData.rate).toFixed(2).toString();
    } else {
      currentData.amount = '0';
    }
    setNewServiceData(currentData);
  };
  return (
    <div data-testid="new-sline">
      <div className="ps-3 align-items-center">
        <div className="mb-3">
          <InputType1 label="Service Line#" name="number" id="number" className="cursor-disable" value={newServiceData.number} />
        </div>
        <div className="mb-3">
          <InputType1
            label="Service Line Name"
            isMandatory="true"
            Placeholder="Enter Service Line Name"
            name="serviceLineName"
            id="serviceLineName"
            className=""
            value={newServiceData.serviceLineName}
            onChange={(e) => { onFieldChange(e); }}
          />
        </div>
        <div className="mb-3">
          <label>
            UOM
          </label>
          <Select
            className=""
            placeholder="Select UOM"
            options={uomList}
            classNamePrefix="react-tag"
            id="UOM"
            value={uomList.filter((val) => val.value === newServiceData.uomId)}
            onChange={(e) => { onselectionchange(e, 'uomName', 'uomId'); }}
          />
        </div>
        <div className="mb-3">
          <InputType1
            label="Quantity"
            Placeholder="Enter Quantity"
            name="quantity"
            id="quantity"
            className=""
            value={newServiceData.quantity}
            onChange={(e) => { onFieldChange(e); }}
          />
        </div>
        <div className="mb-3">
          <InputType1
            label={`Rate (${specList.rfqData?.currencySymbol})`}
            Placeholder="Enter Rate"
            name="rate"
            id="rate"
            className=""
            onChange={(e) => { onFieldChange(e); }}
            value={!newServiceData.rate || newServiceData.rate === '0' ? '' : newServiceData.rate}

          />
        </div>
        <div className="mb-3">
          <InputType1
            label="Discount[%]"
            name="discount"
            id="discount"
            Placeholder="Enter Discount"
            className=""
            value={!newServiceData.discount || newServiceData.discount === '0' ? '' : newServiceData.discount}
            onChange={(e) => { onFieldChange(e); }}
          />
        </div>
        <div className="mb-3">
          <InputType1 label={`Amount (${specList.rfqData?.currencySymbol})`} name="amount" id="amount" className="cursor-disable" value={newServiceData.amount} />
        </div>
        <div className="mb-3">
          <InputType1 label="Yard Comments" Placeholder="Enter Yard Comments" name="comments" id="comments" className="" value={newServiceData.comments} onChange={(e) => { onFieldChange(e); }} />
        </div>
      </div>
    </div>
  );
};
export default AddServiceLine;
